import { navigate } from 'gatsby'
import { FLOCK_INVESTOR_URL, ORDERS_URL, ENABLE_SIMULATOR } from './constants'

export const onLogoClick = (shouldHideAccount: boolean) => () => {
  if (!ENABLE_SIMULATOR) {
    if (shouldHideAccount) {
      navigate(ORDERS_URL as string)
    } else {
      navigate(FLOCK_INVESTOR_URL as string)
    }
  }
}

export const addDays = (date: Date, days: number) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const quarterStringToDate = (quarterString: string) => {
  const quarter = quarterString.split(' ')[0]
  const year = quarterString.split(' ')[1]
  switch (quarter) {
    case 'Q1':
      return new Date(`march, 31, ${year}`)
    case 'Q2':
      return new Date(`june, 30, ${year}`)
    case 'Q3':
      return new Date(`september, 30, ${year}`)
    case 'Q4':
      return new Date(`december, 31, ${year}`)
    default:
      return new Date()
  }
}
