import { Core_PropertySummaryDetails } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  formatIntegerDollars,
  formatNumberWithCommas,
  formatPercentage,
} from '@flock/utils'
import { Box, Paper, styled, Typography } from '@mui/material'
import { RightArrowFilledIcon } from '@flock/shared-ui'
import React from 'react'
import DefaultImage from '../../../images/default-home-coming-soon.png'

import useAssetViewCard from './useAssetViewCard'
import { DETAILED_ASSET_URL } from '../../../constants'

const MapContainer = styled('div')({
  width: 'fit-content',
  alignSelf: 'center',
  textAlign: 'center',
  position: 'relative',
  maxHeight: '288px',
  maxWidth: '288px',
})

const Marker = styled('div')({
  position: 'absolute',
  background: '#03341D',
  borderRadius: '50%',
  border: '8px solid #03341D',
  width: '8px',
  height: '8px',
  marginLeft: '120px',
  marginTop: '-100px',

  '&:after': {
    position: 'absolute',
    width: '0px',
    height: '0px',
    bottom: '-30px',
    left: '-6px',
    border: '10px solid transparent',
    borderTop: '17px solid #03341D',
    content: '""',
  },
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MultifamilyAssetViewCard = (props: {
  assetDetailsArray: Core_PropertySummaryDetails[]
}) => {
  const { assetDetailsArray } = props
  const assetDetails = assetDetailsArray[0]
  let beds: number = 0
  let baths: number = 0
  let sqft: number = 0
  let monthlyRentCents: number = 0
  let marketRentCents: number = 0
  let valuationCents: number = 0
  let grossYield: number = 0
  let unitsRentedCount: number = 0
  let primaryPhotoUrl: string = ''
  let leaseStatus: string = 'vacant'

  assetDetailsArray.forEach((asset) => {
    beds += asset.beds
    baths += asset.baths
    sqft += asset.sqft
    grossYield += asset.grossYield || 0
    marketRentCents += asset.marketRentCents || 0
    if (
      asset.propertyValuationHistory &&
      asset.propertyValuationHistory.length > 0
    ) {
      valuationCents +=
        asset.propertyValuationHistory[
          asset.propertyValuationHistory.length - 1
        ]?.currentValuationCents || 0
    }
    if (primaryPhotoUrl === '' && asset.address?.primaryPhotoUrl) {
      primaryPhotoUrl = asset.address?.primaryPhotoUrl
    }
    if (
      asset.leaseStatus &&
      (asset.leaseStatus === 'mtm' || asset.leaseStatus === 'occupied')
    ) {
      monthlyRentCents += asset.monthlyRentCents || 0
      unitsRentedCount += 1
      leaseStatus = 'occupied'
    }
  })

  const { mapsUrl } = useAssetViewCard({
    assetDetails,
  })

  const tagColorMap: Record<string, string> = {
    occupied: 'green4.main',
    mtm: 'green4.main',
    vacant: 'errorRed.main',
    renovating: 'softGold.main',
    leasing: '#FFD9B7',
  }

  const textColorMap: Record<string, string> = {
    occupied: '#FFFFFF',
    mtm: '#FFFFFF',
    vacant: '#FFFFFF',
    renovating: 'moneyGreen.main',
    leasing: 'moneyGreen.main',
  }

  return (
    <Paper
      sx={{
        borderRadius: '16px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '16px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'gray1.main',
          transition: 'background-color 0.2s ease',
        },
      }}
      onClick={() => {
        window.open(`${DETAILED_ASSET_URL}/${assetDetails.propertyUuid}`)
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
          gap="24px"
        >
          <Box
            display="flex"
            justifyContent="center"
            width="256px"
            flexDirection="column"
            gap="16px"
          >
            {primaryPhotoUrl !== '' ? (
              <Box
                width="256px"
                height="148px"
                sx={{
                  borderRadius: '4px',
                  backgroundImage:
                    primaryPhotoUrl !== ''
                      ? `url(${primaryPhotoUrl})`
                      : `url(${DefaultImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            ) : (
              <MapContainer>
                <Box
                  component="img"
                  height="148px"
                  width="256px"
                  sx={{ borderRadius: '4px' }}
                  src={mapsUrl}
                />
                <Marker />
              </MapContainer>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            width="384px"
            flexDirection="column"
          >
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column">
                <Typography variant="p2" fontSize="20px">
                  {assetDetails.address?.streetNumber}{' '}
                  {assetDetails.address?.formattedStreet}
                </Typography>
                <Typography variant="p3" fontSize="16px">
                  {assetDetails.address?.city}, {assetDetails.address?.state}{' '}
                  {assetDetails.address?.zipcode}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pr="12px"
                  borderRight="1px solid #E0E0E0"
                >
                  <Typography variant="p3" fontSize="16px">
                    {beds} bed
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p="0px 12px"
                  borderRight="1px solid #E0E0E0"
                >
                  <Typography variant="p3" fontSize="16px">
                    {baths} bath
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p="0px 12px"
                  borderRight="1px solid #E0E0E0"
                >
                  <Typography variant="p3" fontSize="16px">
                    {sqft ? formatNumberWithCommas(sqft) : 'unknown'} sq ft
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  p="0px 12px"
                >
                  <Typography variant="p3" fontSize="16px">
                    {assetDetails.propertyType === 'SFR'
                      ? 'single-family home'
                      : assetDetails.propertyType?.toLowerCase()}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" gap="32px">
              {unitsRentedCount > 0 ? (
                <Box display="flex" flexDirection="column">
                  <Typography variant="p2" fontSize="20px">
                    {`${formatIntegerDollars(
                      (monthlyRentCents || 0) / 100,
                      true
                    )}`}
                  </Typography>
                  <Typography variant="p3" fontSize="14px">
                    {monthlyRentCents > 0 &&
                      unitsRentedCount > 1 &&
                      `for ${unitsRentedCount} units`}
                    {monthlyRentCents > 0 &&
                      unitsRentedCount === 1 &&
                      `for ${unitsRentedCount} unit`}
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column">
                  <Typography variant="p2" fontSize="20px">
                    {(marketRentCents || 0) > 0
                      ? `${formatIntegerDollars(
                          (marketRentCents || 0) / 100,
                          true
                        )}`
                      : '-'}
                  </Typography>
                  <Typography variant="p3" fontSize="14px">
                    for all units
                  </Typography>
                </Box>
              )}
              {valuationCents !== 0 ? (
                <Box display="flex" flexDirection="column">
                  <Typography variant="p2" fontSize="20px">
                    {`${formatIntegerDollars(valuationCents / 100, true)}`}
                  </Typography>
                  <Typography variant="p3" fontSize="14px">
                    valuation
                  </Typography>
                </Box>
              ) : (
                assetDetails?.acquisitionPriceCents !== 0 && (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="p2" fontSize="20px">
                      {`${formatIntegerDollars(
                        (assetDetails?.acquisitionPriceCents || 0) / 100,
                        true
                      )}`}
                    </Typography>
                    <Typography variant="p3" fontSize="14px">
                      acq price
                    </Typography>
                  </Box>
                )
              )}
              {grossYield !== 0 && (
                <Box display="flex" flexDirection="column">
                  <Typography variant="p2" fontSize="20px">
                    {`${formatPercentage(grossYield || 0, 1)}`}
                  </Typography>
                  <Typography variant="p3" fontSize="14px">
                    gross yield
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-end"
            width="96px"
          >
            {leaseStatus !== '' && (
              <Paper
                sx={{
                  borderRadius: '8px',
                  width: '96px',
                  height: '32px',
                  backgroundColor: tagColorMap[leaseStatus || 'occupied'],
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="c1"
                  color={textColorMap[leaseStatus || 'occupied']}
                >
                  {leaseStatus === 'mtm'
                    ? 'OCCUPIED'
                    : leaseStatus?.toUpperCase()}
                </Typography>
              </Paper>
            )}
            <RightArrowFilledIcon width="48px" height="48px" />
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default MultifamilyAssetViewCard
