import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const MessageIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M28.8843 10.575C28.1832 8.90164 27.1632 7.38067 25.8812 6.09687C24.603 4.81084 23.0847 3.78836 21.4125 3.0875C19.6994 2.36603 17.8588 1.99621 16 2H15.9375C14.0468 2.00937 12.2187 2.38437 10.4968 3.12187C8.83894 3.82992 7.33488 4.85421 6.06872 6.1375C4.79913 7.41842 3.79082 8.93406 3.09997 10.6C2.38283 12.3324 2.01945 14.1907 2.03122 16.0656C2.0406 18.2344 2.55935 20.3875 3.5281 22.3125V27.0625C3.5281 27.8562 4.17185 28.5 4.96247 28.5H9.70622C11.6402 29.4759 13.7744 29.9894 15.9406 30H16.0062C17.875 30 19.6843 29.6375 21.3906 28.9281C23.0544 28.2356 24.567 27.2251 25.8437 25.9531C27.1312 24.675 28.1437 23.1812 28.8531 21.5156C29.5875 19.7906 29.9625 17.9562 29.9718 16.0625C29.9781 14.1594 29.6093 12.3125 28.8843 10.575ZM9.76247 17.5C8.93747 17.5 8.2656 16.8281 8.2656 16C8.2656 15.1719 8.93747 14.5 9.76247 14.5C10.5875 14.5 11.2593 15.1719 11.2593 16C11.2593 16.8281 10.5906 17.5 9.76247 17.5ZM16 17.5C15.175 17.5 14.5031 16.8281 14.5031 16C14.5031 15.1719 15.175 14.5 16 14.5C16.825 14.5 17.4968 15.1719 17.4968 16C17.4968 16.8281 16.825 17.5 16 17.5ZM22.2375 17.5C21.4125 17.5 20.7406 16.8281 20.7406 16C20.7406 15.1719 21.4125 14.5 22.2375 14.5C23.0625 14.5 23.7343 15.1719 23.7343 16C23.7343 16.8281 23.0625 17.5 22.2375 17.5Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default MessageIcon
