import {
  DocumentsFilledIcon,
  DocumentsIcon,
  HouseFilledIcon,
  HouseOutlinedIcon,
  InstitutionFilledIcon,
  InstitutionOutlinedIcon,
  PageContainer,
  SidebarWithIcons,
  AccountIcon,
  AccountFilledIcon,
  useInView,
  scrollIntoView,
  ChartOutlinedIcon,
  ChartFilledIcon,
  MessageIcon,
  MessageOutlinedIcon,
} from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { ACCOUNT_URL, ORDERS_URL } from '../../constants'
import PageWrapper from '../SharedComponents/PageWrapper'
import HomeSection from './Home/HomeSection'
import FundHoldings from './FundHoldings'
import DocumentsSection from './DocumentsSection'
import HeaderSection from './HeaderSection'
import LoadingCardPage from '../SharedComponents/LoadingCardPage'
import HeaderSectionMobile from './HeaderSectionMobile'
import { useInvestorAccountContext } from '../InvestorAccountContext'
import AccountSection from './AccountSection/accountSection'
import FundPerformanceSection from './FundPerformanceSection/fundPerformanceSection'
import WelcomeToFlockModal from './WelcomeToFlockModal'
import { useAlertDuration } from '../utils'
import DirectorySection from './DirectorySection/DirectorySection'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InvestorDashboardPage = (_: RouteComponentProps) => {
  const [selectedSection, setSelectedSection] = useState(0)
  const {
    investorContextError,
    investorContextLoading,
    selectedLegalEntityInvestmentData,
    selectedLegalEntity,
    activeOrderStatuses,
    investorAccount,
  } = useInvestorAccountContext()
  const [messageCenterMobileOpen, setMessageCenterMobileOpen] = useState(false)
  const [welcomeToFlockModalOpen, setWelcomeToFlockModalOpen] = useState(false)
  useAlertDuration(
    investorAccount?.uuid || '',
    investorAccount?.firstName || '',
    investorAccount?.lastName || ''
  )

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  let numColumns = 12
  if (isMobile) {
    numColumns = 4
  } else if (isTablet) {
    numColumns = 7
  } else {
    numColumns = 12
  }

  const sidebarItems = useMemo(
    () => [
      {
        title: 'Home',
        unselectedIcon: HouseOutlinedIcon,
        selectedIcon: HouseFilledIcon,
        id: 'home',
      },
      {
        title: 'My Account',
        unselectedIcon: AccountIcon,
        selectedIcon: AccountFilledIcon,
        id: 'account',
      },
      {
        title: 'Fund Performance',
        unselectedIcon: ChartOutlinedIcon,
        selectedIcon: ChartFilledIcon,
        id: 'fund-performance',
      },
      {
        title: 'Fund Holdings',
        unselectedIcon: InstitutionOutlinedIcon,
        selectedIcon: InstitutionFilledIcon,
        id: 'fund-holdings',
      },
      {
        title: 'Documents',
        unselectedIcon: DocumentsIcon,
        selectedIcon: DocumentsFilledIcon,
        id: 'documents',
      },
      {
        title: 'Directory',
        unselectedIcon: MessageOutlinedIcon,
        selectedIcon: MessageIcon,
        id: 'directory',
      },
    ],
    []
  )

  const currentSection = useInView(sidebarItems.map((item) => item.id))

  const onChangeSidebar = (newTab: number) => {
    scrollIntoView(sidebarItems[newTab].id)
  }

  useEffect(() => {
    setSelectedSection(
      sidebarItems.map((item) => item.id).indexOf(currentSection)
    )
  }, [currentSection, sidebarItems])

  // auto redirect to orders page if there is an active order and no contribution amount
  useEffect(() => {
    if (
      !investorContextError &&
      !investorContextLoading &&
      activeOrderStatuses.length > 0
    ) {
      const firstOrder = activeOrderStatuses[0]
      if (
        selectedLegalEntity?.uuid &&
        selectedLegalEntityInvestmentData?.totalEquityValue === 0 &&
        firstOrder?.orderUuid
      ) {
        navigate(`${ORDERS_URL}/${firstOrder.orderUuid}`)
      }
    }
  }, [
    activeOrderStatuses.length,
    selectedLegalEntityInvestmentData,
    investorContextLoading,
    investorContextError,

    activeOrderStatuses,
    selectedLegalEntity?.uuid,
  ])

  useEffect(() => {
    if (selectedLegalEntity?.uuid !== 'aggregate') {
      const investorSinceDate = new Date(selectedLegalEntity?.dateJoined)
      const today = new Date()
      today.setHours(23, 59, 59, 999)
      const pastDate = new Date()
      pastDate.setHours(0, 0, 0, 0)
      pastDate.setDate(today.getDate() - 7)
      // for the first seven days after someone officially enters the fund
      // we want to show a confetti event for them one time as a welcome to Flock
      if (
        investorSinceDate &&
        // it is within 7 days of them joining
        investorSinceDate > pastDate &&
        // they joined before or equal to today
        investorSinceDate <= today
      ) {
        const welcomeToFlock = localStorage?.getItem('welcomeToFlock')
        if (!welcomeToFlock) {
          setWelcomeToFlockModalOpen(true)
          localStorage.setItem('welcomeToFlock', 'true')
        }
      }
    }
  }, [selectedLegalEntity])

  const closeWelcomeToFlockModal = () => {
    setWelcomeToFlockModalOpen(false)
  }

  return (
    <PageContainer title="FLOCK | Dashboard" trackingName="dashboard">
      {selectedLegalEntityInvestmentData?.totalEquityValue === null ||
      selectedLegalEntityInvestmentData?.totalEquityValue === undefined ||
      (selectedLegalEntityInvestmentData?.totalEquityValue === 0 &&
        selectedLegalEntityInvestmentData?.totalContribution === 0) ||
      investorContextLoading ||
      !selectedLegalEntity?.uuid ? (
        <LoadingCardPage text="Preparing your dashboard..." />
      ) : (
        <>
          <WelcomeToFlockModal
            isOpen={welcomeToFlockModalOpen}
            close={closeWelcomeToFlockModal}
          />
          {isMobile && (
            <HeaderSectionMobile
              sidebarItems={sidebarItems}
              selectedSection={selectedSection}
              setMessageCenterOpen={setMessageCenterMobileOpen}
              onChangeSidebar={onChangeSidebar}
            />
          )}
          <PageWrapper>
            <Grid
              container
              spacing={4}
              columns={numColumns}
              height="auto"
              pl={{
                xs: '32px',
                md: '0px',
              }}
              pr={{
                xs: '32px',
                md: '0px',
              }}
            >
              {!isMobile && (
                <>
                  <Grid item xs={numColumns}>
                    <Box position="relative">
                      <HeaderSection />
                    </Box>
                  </Grid>
                  <Grid item md={3} sm={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ position: 'sticky', top: '8%' }}
                    >
                      {!isTablet && (
                        <Box
                          onClick={() => navigate(ACCOUNT_URL)}
                          top="28px"
                          pl="16px"
                          pb="20px"
                        >
                          <Typography
                            variant="h4"
                            fontWeight={500}
                            color="moneyGreen.main"
                            sx={{ '&:hover': { cursor: 'pointer' } }}
                          >
                            F L O C K
                          </Typography>
                        </Box>
                      )}

                      <SidebarWithIcons
                        items={sidebarItems}
                        onChange={onChangeSidebar}
                        selected={selectedSection}
                      />
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={4} sm={6} md={9}>
                <Grid container spacing={8}>
                  {/* All sections go here */}
                  <HomeSection
                    id={sidebarItems[0].id}
                    messageCenterMobileOpen={
                      isMobile ? messageCenterMobileOpen : undefined
                    }
                    setMessageCenterMobileOpen={
                      isMobile ? setMessageCenterMobileOpen : undefined
                    }
                  />

                  <AccountSection id={sidebarItems[1].id} />
                  <FundPerformanceSection id={sidebarItems[2].id} />
                  <FundHoldings id={sidebarItems[3].id} />
                  <DocumentsSection id={sidebarItems[4].id} />
                  <DirectorySection id={sidebarItems[5].id} />
                </Grid>
              </Grid>
            </Grid>
          </PageWrapper>
        </>
      )}
    </PageContainer>
  )
}

export default InvestorDashboardPage
