import {
  Box,
  Typography,
  Paper,
  Slider,
  styled,
  TextField,
  SliderProps,
} from '@mui/material'
import {
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ComposedChart,
} from 'recharts'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { investorCalculateFlexibleCashFlowModel } from '@flock/utils/src/money/investorFlexibleCashFlowCalculator'
import millify from 'millify'
import { FlexibleCashFlowPresentationalProps } from './flexibleCashflowCalculatorTypes'
import TapAPointImage from '../../../../images/tap-a-point.webp'

interface StyledSliderProps extends SliderProps {
  topup?: boolean
}

const StyledSlider = styled(Slider, {
  shouldForwardProp: (prop) => prop !== 'topup',
})<StyledSliderProps>(({ topup }) => ({
  color: topup ? '#891A1A' : 'currentColor',
  '& .MuiSlider-track': {
    height: '2px',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: 'currentColor',
    border: '3px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: topup ? '#F6EDED' : '#E1EEEF',
    color: topup ? '#891A1A' : '#03341D',
    borderRadius: 4,
    position: 'absolute',
    top: '70px',
    width: '72px',
    padding: '4px',
    '&::before': {
      bottom: '96%',
    },
  },
}))

const StyledComposedChart = styled(ComposedChart)({
  '& .recharts-cartesian-grid > .recharts-cartesian-grid-horizontal > line:nth-last-child(2)':
    {
      display: 'none!important',
    },
})

const FlexibleCashflowCalculatorMobile = (
  props: FlexibleCashFlowPresentationalProps
) => {
  const {
    startingInvestment,
    cashFlowAllotment,
    cashFlowSelection,
    holdingYears,
    showCashFlowDeductions,
  } = props

  const tickFormatterY = useCallback((val: any) => {
    const precision = val / 100 < 100000 || val / 100 > 1000000 ? 1 : 0 // if val is between 100k and 1m, show no decimal places since we will overflow on the chart
    return `$${millify(val, {
      precision,
      lowercase: true,
      units: ['', 'K', 'M', 'B', 'T'],
    })}`
  }, [])

  const [cashFlowSlider, setCashFlowSlider] = useState(cashFlowSelection)
  const [activeLabel, setActiveLabel] = useState('YEAR 1')
  const flexibleCashFlowOutputs = investorCalculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield: cashFlowAllotment,
    cashFlowPercentage: 0.01 * cashFlowSlider,
    holdingPeriodOverride: holdingYears,
    hideNetYield: false,
  })
  const endingFirstYearBalanceForCashFlow =
    flexibleCashFlowOutputs.initialAccountValueAvg[0]
  const totalEquityQuarter = endingFirstYearBalanceForCashFlow / 4

  // we use noCashFlowOutputs to calculate the upper bound of projections in 5 years so the graph ticks can stay consistent
  const noCashFlowOutputs = investorCalculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield: cashFlowAllotment,
    cashFlowPercentage: 0,
    holdingPeriodOverride: holdingYears,
    hideNetYield: false,
  })
  const graphDomain = [
    noCashFlowOutputs.initialAccountValueLow[0],
    noCashFlowOutputs.initialAccountValueHigh[4],
  ]

  const data = [
    {
      name: 'YEAR 1',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[0],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[0],
        flexibleCashFlowOutputs.initialAccountValueLow[0],
      ],
    },
    {
      name: 'YEAR 2',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[1],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[1],
        flexibleCashFlowOutputs.initialAccountValueLow[1],
      ],
    },
    {
      name: 'YEAR 3',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[2],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[2],
        flexibleCashFlowOutputs.initialAccountValueLow[2],
      ],
    },
    {
      name: 'YEAR 4',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[3],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[3],
        flexibleCashFlowOutputs.initialAccountValueLow[3],
      ],
    },
    {
      name: 'YEAR 5',
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[4],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[4],
        flexibleCashFlowOutputs.initialAccountValueLow[4],
      ],
    },
  ]

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      setActiveLabel(label)
    } else if (!active) {
      setActiveLabel('YEAR 1')
    }

    return null
  }

  const PotentialAnnualEarnings = (
    <Box display="flex" flexDirection="column" gap="4px">
      <Typography variant="p2" color="gray8.main">
        My total Flock account value
      </Typography>

      <Typography
        variant="h4"
        color="moneyGreen.main"
        sx={{
          fontWeight: 600,
        }}
      >
        ${startingInvestment.toLocaleString()}
      </Typography>
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Paper
        sx={{
          padding: '16px',
          backgroundColor: 'gray1.main',
          borderRadius: '8px',
        }}
      >
        <Box display="flex" gap="8px" flexDirection="column">
          {PotentialAnnualEarnings}
        </Box>
      </Paper>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        padding="0px 32px"
        gap="64px"
      >
        <StyledSlider
          min={0}
          value={cashFlowSlider}
          defaultValue={4}
          onChange={(_, value) =>
            setCashFlowSlider(Array.isArray(value) ? value[0] : value)
          }
          max={cashFlowAllotment + 2}
          step={0.01}
          topup={cashFlowSlider > cashFlowAllotment}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="p2" fontWeight={500}>{`$${Math.round(
                value * (totalEquityQuarter * 1.1) * 0.01
              ).toLocaleString()}`}</Typography>
              <Typography variant="p3" fontWeight={500}>
                per qtr
              </Typography>
            </Box>
          )}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="8px"
        >
          <Box
            width="88px"
            height="40px!important"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="2px"
          >
            <TextField
              name="flow"
              type="number"
              value={cashFlowSlider.toFixed(2)}
              variant="outlined"
              size="small"
              fullWidth
              inputProps={{
                min: 0,
                step: 0.01,
                precision: 2,
                style: {
                  textAlign: 'center',
                  padding: '0px!important',
                },
                inputMode: 'numeric',
              }}
              sx={{
                '& > input': {
                  padding: 0,
                },
                width: '160px',
                '& > div > input': {
                  fontSize: '1rem!important',
                  padding: '0!important',
                  height: '40px!important',
                  color:
                    cashFlowSlider > cashFlowAllotment ? '#891A1A' : '#03341D',
                },
              }}
              InputLabelProps={{
                style: { padding: '0px!important' },
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // remove non-numeric characters and trim leading zeros
                let inputValue = e.target.value.replace(/[^\d]/g, '')
                inputValue = inputValue.replace(/^0+(?=\d)/, '')
                // if the input is longer than 3 characters, start from last character
                if (inputValue.length > 3) {
                  inputValue = inputValue.slice(3)
                }
                if (Number(inputValue) / 100 <= cashFlowAllotment + 2) {
                  setCashFlowSlider(Number(inputValue) / 100)
                } else {
                  setCashFlowSlider(cashFlowAllotment + 2)
                }
              }}
            />
            <Typography variant="p2">%</Typography>
          </Box>
          <Typography variant="p2">annualized</Typography>
        </Box>
      </Box>
      <Paper
        sx={{
          padding: '16px',
          backgroundColor: 'gray1.main',
          borderRadius: '8px',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box>
            <Box display="flex" flexDirection="column" gap="8px">
              <Box width="100%">
                <Box display="flex" gap="4px" flexDirection="column">
                  <Box
                    display="flex"
                    gap="8px"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Typography variant="p2" color="gray8.main">
                      Staying Invested Year 1
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap="8px"
                    flexDirection="row"
                    alignItems="baseline"
                  >
                    <Typography
                      variant="h4"
                      color="moneyGreen.main"
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      +$
                      {Math.round(
                        flexibleCashFlowOutputs.totalReturnAvg[0]
                      ).toLocaleString()}
                    </Typography>
                    <Typography variant="c1" color="gray5.main">
                      +
                      {(
                        (flexibleCashFlowOutputs.totalReturnAvg[0] /
                          startingInvestment) *
                        100
                      ).toFixed(1)}
                      % total return
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box width="100%">
                <Box display="flex" gap="4px" flexDirection="column">
                  <Box
                    display="flex"
                    gap="8px"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Typography
                      variant="p2"
                      color="gray8.main"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      Staying Invested Years 1-5
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap="8px"
                    flexDirection="row"
                    alignItems="baseline"
                  >
                    <Typography
                      variant="h4"
                      color="moneyGreen.main"
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      +$
                      {Math.round(
                        flexibleCashFlowOutputs.finalAccountValueAvg[4] -
                          startingInvestment
                      ).toLocaleString()}
                    </Typography>
                    <Typography variant="c1" color="gray5.main">
                      +
                      {(
                        ((flexibleCashFlowOutputs.finalAccountValueAvg[4] -
                          startingInvestment) /
                          startingInvestment) *
                        100
                      ).toFixed(1)}
                      % total return
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Paper
        sx={{
          padding: '16px',
          backgroundColor: 'gray1.main',
          borderRadius: '8px',
        }}
      >
        <Box display="flex" flexDirection="column" gap="8px">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="c1" color="gray6.main">
              PROJECTED RETURNS OVER 5 YEARS
            </Typography>
          </Box>
          <Paper
            sx={{
              padding: '8px',
              backgroundColor: 'green0.main',
              borderRadius: '8px',
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap="8px"
            >
              <Box
                component="img"
                src={TapAPointImage}
                height="36px"
                width="36px"
              />
              <Typography variant="c1" color="moneyGreen.main" width="160px">
                Tap a point on the graph to show earnings for that year.
              </Typography>
            </Box>
          </Paper>
          <Box>
            <ResponsiveContainer width="100%" height={200}>
              <StyledComposedChart
                width={530}
                height={200}
                data={data}
                margin={{ top: 5, right: 25, left: 5, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  style={{
                    fontFamily: 'Outfit',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                  interval={1}
                />
                <YAxis
                  scale="linear"
                  width={40}
                  domain={graphDomain}
                  tickFormatter={tickFormatterY}
                  tickCount={3}
                  style={{
                    fontFamily: 'Outfit',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                />
                <Tooltip content={<CustomTooltip active payload label />} />
                <Legend />
                <Area
                  legendType="none"
                  type="monotone"
                  dataKey="range"
                  fill="#C9DADB"
                  stroke="#C9DADB"
                />
                <Line
                  legendType="none"
                  type="monotone"
                  dataKey="Total Return"
                  stroke="#03341D"
                  strokeWidth={2}
                />
              </StyledComposedChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="8px"
            alignItems="center"
          >
            <Box width="100px" />
            <Typography variant="p4" width="85px" sx={{ textAlign: 'right' }}>
              {activeLabel}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="8px"
            alignItems="center"
          >
            <Typography
              variant="p4"
              width="100px"
              sx={{ whiteSpace: 'nowrap' }}
            >
              Initial Value
            </Typography>
            <Typography variant="p4" width="85px" sx={{ textAlign: 'right' }}>
              $
              {Math.round(
                flexibleCashFlowOutputs.initialAccountValueAvg[
                  Number(activeLabel[5]) - 1
                ]
              ).toLocaleString()}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="8px"
            alignItems="center"
          >
            <Typography
              variant="p4"
              color="gray8.main"
              width="100px"
              sx={{ whiteSpace: 'nowrap' }}
            >
              Total Return
            </Typography>
            <Typography
              variant="p4"
              width="85px"
              color="gray8.main"
              sx={{ textAlign: 'right' }}
            >
              $
              {Math.round(
                flexibleCashFlowOutputs.totalReturnAvg[
                  Number(activeLabel[5]) - 1
                ]
              ).toLocaleString()}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="8px"
            alignItems="center"
          >
            <Typography
              variant="p4"
              width="100px"
              sx={{ whiteSpace: 'nowrap' }}
              color="green4.main"
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Cash Taken
            </Typography>
            <Typography
              variant="p4"
              width="85px"
              color="green4.main"
              sx={{ textAlign: 'right' }}
            >
              $
              {Math.round(
                flexibleCashFlowOutputs.cashWithdrawnAvg[
                  Number(activeLabel[5]) - 1
                ]
              ).toLocaleString()}
            </Typography>
          </Box>
          {showCashFlowDeductions && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              pb="8px"
              alignItems="center"
            >
              <Typography
                variant="p4"
                width="100px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Deductions
              </Typography>
              <Typography variant="p4" width="85px" sx={{ textAlign: 'right' }}>
                $
                {Math.round(
                  flexibleCashFlowOutputs.cashDeductionAvg[
                    Number(activeLabel[5]) - 1
                  ]
                ).toLocaleString()}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
      <Box>
        <Typography variant="c1" color="green3.main">
          Changes to this section apply only to projections on this page. To
          edit quarter-end cash-flow elections, enter Settings from the main
          view of your Client Portal.
        </Typography>
      </Box>
    </Box>
  )
}

export default FlexibleCashflowCalculatorMobile
