import {
  Core_GetFundValuationPerQuarterResponse,
  Core_GetGainLossPerMarketResponse,
  Core_GetWaterfallDataResponse,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import { useStaticQuery, graphql } from 'gatsby'
import { useCallback, useEffect, useState } from 'react'
import { sortBy } from 'lodash'
import { quarterStringToDate } from '../../../utils'
import { FundPerformanceSectionData } from './fundPerformanceSectionTypes'

export const GAIN_LOSS_SECTION = 'GainLossSection'
export const NET_PERFORMANCE_SECTION = 'NetPerformanceSection'

const useFundPerformanceSection = (): FundPerformanceSectionData => {
  const [shouldShowDollarView, setShouldShowDollarView] = useState(false)
  const [selectedQuarter, setSelectedQuarter] = useState('')
  const [quarters, setQuarters] = useState<string[]>([])
  const [mostRecentQuarterDatestring, setMostRecentQuarterDatestring] =
    useState('')
  const [fundPerformanceWaterfallData, setFundPerformanceWaterfallData] =
    useState<Core_GetWaterfallDataResponse>()
  const [fundValuationsPerQuarter, setFundValuationsPerQuarter] =
    useState<Core_GetFundValuationPerQuarterResponse>()
  const [gainLossPerMarket, setGainLossPerMarket] =
    useState<Core_GetGainLossPerMarketResponse>()
  const [
    selectedQuarterTotalGainLossOnRealEstate,
    setSelectedQuarterTotalGainLossOnRealEstate,
  ] = useState(0)
  const [selectedQuarterTotalNetIncome, setSelectedQuarterTotalNetIncome] =
    useState(0)
  const [selectedQuarterPortfolioIncome, setSelectedQuarterPortfolioIncome] =
    useState(0)
  const [
    selectedQuarterPortfolioExpenses,
    setSelectedQuarterPortfolioExpenses,
  ] = useState(0)
  const [selectedQuarterFundValueChange, setSelectedQuarterFundValueChange] =
    useState(0)
  const [
    selectedQuarterTotalFundValuation,
    setSelectedQuarterTotalFundValuation,
  ] = useState(1)

  const [selectedSection, setSelectedSection] = useState<string>(
    NET_PERFORMANCE_SECTION
  )

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  // data
  const {
    getWaterfallData: waterfallData,
    getFundValuationPerQuarter: valuationsPerQuarter,
    getGainLossPerMarket: gainsLossesPerMarket,
  }: {
    getWaterfallData: Core_GetWaterfallDataResponse
    getFundValuationPerQuarter: Core_GetFundValuationPerQuarterResponse
    getGainLossPerMarket: Core_GetGainLossPerMarketResponse
  } = useStaticQuery(graphql`
    query InvestorFundPerformanceSection {
      getWaterfallData {
        fundsFromOperationsRows {
          amountCents
          quarterString
          rowName
        }
        gainLossOnRealEstateRows {
          rowName
          flockAmountCents
          quarterString
        }
      }
      getFundValuationPerQuarter {
        fundValuationsPerQuarter {
          quarterString
          valuationCents
        }
      }
      getGainLossPerMarket {
        gainLossPerMarket {
          marketName
          quarterString
          flockPercentChange
          redfinPercentChange
          zillowPercentChange
        }
      }
    }
  `)

  useEffect(() => {
    setFundPerformanceWaterfallData(waterfallData)
    setFundValuationsPerQuarter(valuationsPerQuarter)
    setGainLossPerMarket(gainsLossesPerMarket)
  }, [waterfallData, valuationsPerQuarter, gainsLossesPerMarket])

  useEffect(() => {
    const ffoQuarters = Array.from(
      new Set(
        waterfallData.fundsFromOperationsRows.map((row) => row.quarterString)
      )
    )
    const gainLossQuarters = Array.from(
      new Set(
        waterfallData.gainLossOnRealEstateRows.map((row) => row.quarterString)
      )
    )

    // we validate that the quarters here match up exactly with the quarters for ffo and gain loss on the backend, but do this as a precaution
    const intersection = ffoQuarters.filter((x) => gainLossQuarters.includes(x))
    const uniqueQuarters = Array.from(new Set(intersection))

    const sortedUniqueQuarters = sortBy(
      uniqueQuarters,
      (quarter: string) => `${quarter.split(' ')[1]} ${quarter.split(' ')[0]}`
    ) as string[]

    const mostRecentQuarter =
      sortedUniqueQuarters[sortedUniqueQuarters.length - 1]
    setQuarters(sortedUniqueQuarters)
    setSelectedQuarter(mostRecentQuarter)
    setMostRecentQuarterDatestring(
      quarterStringToDate(mostRecentQuarter).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        timeZone: 'UTC',
      })
    )
  }, [waterfallData])

  useEffect(() => {
    if (fundPerformanceWaterfallData) {
      const totalPortfolioGainLoss =
        (fundPerformanceWaterfallData.gainLossOnRealEstateRows.find(
          (row) =>
            row.quarterString === selectedQuarter &&
            row.rowName === 'Gain(loss) on real assets'
        )?.flockAmountCents || 0) / 100

      const totalPortfolioNetIncome =
        (fundPerformanceWaterfallData.fundsFromOperationsRows.find(
          (row) =>
            row.quarterString === selectedQuarter &&
            row.rowName === 'Total portfolio net income'
        )?.amountCents || 0) / 100

      const totalPortfolioIncome =
        (fundPerformanceWaterfallData.fundsFromOperationsRows.find(
          (row) =>
            row.quarterString === selectedQuarter &&
            row.rowName === 'Total portfolio income'
        )?.amountCents || 0) / 100

      const totalPortfolioExpenses =
        (fundPerformanceWaterfallData.fundsFromOperationsRows.find(
          (row) =>
            row.quarterString === selectedQuarter &&
            row.rowName === 'Total portfolio expenses'
        )?.amountCents || 0) / 100

      const totalFundValueChange =
        (fundPerformanceWaterfallData.fundsFromOperationsRows.find(
          (row) =>
            row.quarterString === selectedQuarter &&
            row.rowName === 'FUND VALUE CHANGE'
        )?.amountCents || 0) / 100

      const selectedQuarterFundValuation =
        (fundValuationsPerQuarter?.fundValuationsPerQuarter.find(
          (row) => row.quarterString === selectedQuarter
        )?.valuationCents || 100) / 100 // 100 so we don't get a divide by 0 when using this number

      setSelectedQuarterTotalGainLossOnRealEstate(totalPortfolioGainLoss)
      setSelectedQuarterPortfolioIncome(totalPortfolioIncome)
      setSelectedQuarterPortfolioExpenses(totalPortfolioExpenses)
      setSelectedQuarterTotalNetIncome(totalPortfolioNetIncome)
      setSelectedQuarterFundValueChange(totalFundValueChange)
      setSelectedQuarterTotalFundValuation(selectedQuarterFundValuation)
    }
  }, [selectedQuarter, fundPerformanceWaterfallData, fundValuationsPerQuarter])

  const toggleDollarViewSelected = useCallback(() => {
    setShouldShowDollarView(!shouldShowDollarView)
  }, [shouldShowDollarView])

  return {
    isMobile,
    isTablet,
    shouldShowDollarView,
    toggleDollarViewSelected,
    setSelectedQuarter,
    quarters,
    selectedQuarter,
    mostRecentQuarterDatestring,
    fundPerformanceWaterfallData,
    selectedQuarterTotalGainLossOnRealEstate,
    selectedQuarterPortfolioIncome,
    selectedQuarterPortfolioExpenses,
    selectedQuarterTotalNetIncome,
    selectedQuarterFundValueChange,
    selectedQuarterTotalFundValuation,
    gainLossPerMarket,
    selectedSection,
    setSelectedSection,
  }
}

export default useFundPerformanceSection
