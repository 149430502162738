import { Core_PropertySummaryDetails } from '@flock/flock-gql-server/src/__generated__/graphql'
import generateGoogleMapUrl from '../../../utils/googleMapUrl'
import { GOOGLE_MAPS_API_KEY } from '../../../constants'

const useAssetViewCard = (props: {
  assetDetails: Core_PropertySummaryDetails
}) => {
  const { assetDetails } = props

  const mapsUrl = generateGoogleMapUrl({
    type: 'staticmap',
    location: `${assetDetails.address?.formattedAddress}`,
    center: `${assetDetails.address?.latitude},${assetDetails.address?.longitude}`,
    size: '288x288',
    zoom: 16,
    scale: 2,
    key: GOOGLE_MAPS_API_KEY,
    style: `feature:poi|visibility:off`,
  })

  return {
    mapsUrl,
  }
}

export default useAssetViewCard
