import { useCallback } from 'react'
import { usePrevious } from './usePrevious'

export const useCallbackDebugger = (
  effectHook: any,
  dependencies: any,
  dependencyNames: string[] = []
) => {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce(
    (accum: any, dependency: any, index: number) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        }
      }

      return accum
    },
    {}
  )

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(effectHook, dependencies)
}
