import { formatIntegerDollars, formatPercentage } from '@flock/utils'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

export enum AssetLifecycleEventType {
  VALUATION_UPDATE = 'Valuation Update',
  RENOVATION_UPDATE = 'Renovation Update',
  RENT_UPDATE = 'Rent Update',
  ACQUISITION = 'Acquisition',
}

type AssetLifecycleEventRowProps = {
  eventType: AssetLifecycleEventType
  amountCents: number
  dateString: string
  previousAmount?: number
}

const AssetLifecycleEventRow = (props: AssetLifecycleEventRowProps) => {
  const { eventType, amountCents, dateString, previousAmount } = props

  const date = new Date(dateString)
  const formattedDateString = date.toLocaleDateString('en-US', {
    timeZone: 'UTC',
  })

  const amountDollars = formatIntegerDollars((amountCents || 0) / 100)
  const amountDifferencePercentage = formatPercentage(
    ((amountCents || 0) - (previousAmount || 0)) / (previousAmount || 1),
    2
  )

  let eventString = AssetLifecycleEventType.VALUATION_UPDATE
  if (eventType) {
    eventString = eventType
  }
  return (
    <>
      <Grid item xs={2.25}>
        <Box display="flex" justifyContent="left">
          {eventType === AssetLifecycleEventType.RENOVATION_UPDATE && (
            <Box
              sx={{ backgroundColor: '#FFBB7E' }}
              width="2px"
              height="20px"
              mr="4px"
            />
          )}
          {eventType === AssetLifecycleEventType.VALUATION_UPDATE && (
            <Box
              sx={{ backgroundColor: 'moneyGreen.main' }}
              width="2px"
              height="20px"
              mr="4px"
            />
          )}
          {eventType === AssetLifecycleEventType.RENT_UPDATE && (
            <Box
              sx={{ backgroundColor: 'green3.main' }}
              width="2px"
              height="20px"
              mr="4px"
            />
          )}
          <Typography variant="c1" fontWeight={500}>
            {eventString}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={1.25}>
        <Typography variant="p3" color="gray5.main">
          {dateString ? formattedDateString : ''}
        </Typography>
      </Grid>
      <Grid item xs={8.5}>
        {eventType === AssetLifecycleEventType.RENOVATION_UPDATE && (
          <Typography variant="p3">{`Flock completed renovations that cost ${amountDollars}.`}</Typography>
        )}
        {eventType === AssetLifecycleEventType.VALUATION_UPDATE && (
          <Typography variant="p3">{`Valuation updated to ${amountDollars} by Flock's Valuation Model${
            previousAmount && previousAmount > 0
              ? `, a ${amountDifferencePercentage} ${
                  (amountCents || 0) - (previousAmount || 0) < 0
                    ? 'decrease'
                    : 'increase'
                }.`
              : '.'
          }`}</Typography>
        )}
        {eventType === AssetLifecycleEventType.RENT_UPDATE && (
          <Typography variant="p3">
            {previousAmount && previousAmount > 0 ? (
              <>
                {amountCents !== previousAmount
                  ? `Total rent changed from ${formatIntegerDollars(
                      previousAmount / 100
                    )} to ${amountDollars}, a ${amountDifferencePercentage} ${
                      (amountCents || 0) - (previousAmount || 0) < 0
                        ? 'decrease'
                        : 'increase'
                    }.`
                  : `Total rent was renewed at ${amountDollars}.`}
              </>
            ) : (
              `Total rent was set at ${amountDollars}.`
            )}
          </Typography>
        )}
        {eventType === AssetLifecycleEventType.ACQUISITION && (
          <Typography variant="p3">{`Flock acquired the property for ${amountDollars}.`}</Typography>
        )}
      </Grid>
    </>
  )
}

export default AssetLifecycleEventRow

AssetLifecycleEventRow.defaultProps = {
  previousAmount: 0,
}
