import {
  AppBar,
  Box,
  Divider,
  Drawer,
  DrawerProps,
  Grid,
  Link,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@mui/material'
import { navigate } from 'gatsby-link'
import React, { useState } from 'react'
import { UserEventType } from '@flock/utils'
import { CloseMobileIcon } from '../icons/CloseMobileIcon'
import { HamburgerIcon } from '../icons/HamburgerIcon'
import { InfoIcon } from '../icons/InfoIcon'
import { TrackedButton } from '../TrackedButton'
import { TrackedIconButton } from '../TrackedIconButton'
import { TrackedLink } from '../TrackedLink'
import NavItem, { NavItemProps } from './NavItem'
import { FlockLogo } from '../logos/FlockLogo'

export type NavbarProps = {
  logoPath: string
  showBackToHome?: boolean
  navItems: NavItemProps[]
  mobileNavItems?: React.ReactNode
  mobileNavItemsFunction?: (callback: (arg: boolean) => void) => React.ReactNode
  headerBackground: string
  headerChange?: string
  isHomepage?: boolean
  actions: {
    label: string
    variant: 'primary' | 'secondary' | string
    onClick: () => void
    trackingConfig?: any
  }[]
  cobrandingConfig?: {
    fullName: string
    websiteUrl: string
    imageUrl: string
  }
  drawerProps?: DrawerProps
}

const MobileNavbar = (props: NavbarProps) => {
  const {
    logoPath,
    mobileNavItems,
    mobileNavItemsFunction,
    showBackToHome,
    actions,
    headerBackground,
    headerChange,
    drawerProps,
    isHomepage,
  } = props
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const setIsOpenCallback = (openState: boolean) => {
    setOpen(openState)
  }
  const hasScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  const homepageToolbarHeight = isTablet ? '80px' : '64px'

  return (
    <AppBar
      position="sticky"
      sx={
        !hasScrolled
          ? {
              backgroundColor: headerBackground,
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
            }
          : {
              backgroundColor: headerChange,
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
            }
      }
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: isHomepage ? '16px' : 'unset',
          height: isHomepage ? homepageToolbarHeight : 'unset',
        }}
      >
        {!isHomepage && <Box width="48px" />}
        <TrackedLink
          aria-label="Homepage"
          sx={{
            justifySelf: 'center',
            '&:hover': {
              backgroundColor: 'unset',
            },
          }}
          to={logoPath}
        >
          <FlockLogo width={97} height={28} />
        </TrackedLink>
        <Box display="flex" flexDirection="row" gap="4px">
          {isHomepage && (
            <TrackedButton
              key="mobile-get-offer"
              variant="primary"
              size="small"
              onClick={() => navigate('/onboarding')}
              trackingConfig={{
                properties: {
                  leadConversion: 'started',
                  pageWrapperCta: true,
                  actionType: UserEventType.BUTTON_CLICK,
                },
              }}
              sx={{
                whiteSpace: 'nowrap!important',
                paddingLeft: isHomepage ? '24px!important' : 'unset',
                paddingRight: isHomepage ? '24px!important' : 'unset',
              }}
            >
              {isTablet ? 'Get a valuation' : 'Get Valuation'}
            </TrackedButton>
          )}
          <TrackedIconButton aria-label="Menu" onClick={() => setOpen(true)}>
            <HamburgerIcon />
          </TrackedIconButton>
        </Box>
      </Toolbar>
      <Drawer
        anchor="right"
        open={open}
        disableEnforceFocus
        onClose={(_, reason: string) => {
          console.log(reason)
          return reason !== 'backdropClick' && setOpen(false)
        }}
        ModalProps={{
          onClose: (_, reason: string) => {
            console.log(reason)
            return reason !== 'backdropClick' && setOpen(false)
          },
        }}
        PaperProps={{
          sx: { width: '100vw', backgroundColor: 'secondary.main' },
        }}
        {...drawerProps}
      >
        <Box sx={{ height: '64px', pl: '8px', pr: '8px' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box width="48px" />
            <TrackedLink
              sx={{
                justifySelf: 'center',
                '&:hover': {
                  backgroundColor: 'unset',
                },
              }}
              to={logoPath}
            >
              <FlockLogo width={97} height={28} />
            </TrackedLink>
            <TrackedIconButton onClick={() => setOpen(false)}>
              <CloseMobileIcon />
            </TrackedIconButton>
          </Toolbar>
        </Box>
        <Box sx={{ p: '48px', pt: 0, height: '100%' }}>
          <Grid
            container
            spacing={2}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {actions.map((item) => {
              const { label, onClick, variant, trackingConfig } = item
              return (
                <Grid key={label} item xs={isHomepage ? 6 : 12}>
                  <TrackedButton
                    variant={variant as any}
                    size="small"
                    onClick={onClick}
                    sx={{
                      width: '100%',
                      whiteSpace: 'nowrap!important',
                    }}
                    trackingConfig={trackingConfig}
                  >
                    {label === 'Get a valuation' && !isTablet
                      ? 'Get Started'
                      : label}
                  </TrackedButton>
                </Grid>
              )
            })}
          </Grid>
          <Box
            display="flex"
            flexDirection="column"
            mt={isHomepage ? '0px' : '32px'}
            onClick={() =>
              mobileNavItemsFunction ? undefined : setOpen(false)
            }
            height="100%"
          >
            {mobileNavItemsFunction &&
              mobileNavItemsFunction(setIsOpenCallback)}
            {mobileNavItems}
          </Box>
        </Box>
        {showBackToHome && (
          <Box
            position="absolute"
            bottom="40px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <Box>
              <Link href="/" underline="none">
                <Typography variant="cta" color="primary.main">
                  BACK TO HOME
                </Typography>
              </Link>
            </Box>
          </Box>
        )}
      </Drawer>
    </AppBar>
  )
}

const Navbar = (props: NavbarProps) => {
  const {
    logoPath,
    navItems,
    actions,
    headerBackground,
    headerChange,
    cobrandingConfig,
  } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isPartnerSmall = useMediaQuery('(max-width:1452px)')

  const hasScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  if (isMobile) {
    return <MobileNavbar {...props} />
  }

  const visitCobrandingWebsite = () => {
    if (cobrandingConfig?.websiteUrl) {
      window.open(cobrandingConfig?.websiteUrl, '_blank')
    }
  }

  return (
    <AppBar
      position="sticky"
      sx={
        !hasScrolled
          ? {
              backgroundColor: headerBackground,
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
            }
          : {
              backgroundColor: headerChange,
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
            }
      }
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {cobrandingConfig ? (
          <Box
            display="flex"
            alignItems="center"
            gap="16px"
            color="primary.main"
          >
            {cobrandingConfig.imageUrl && (
              <Box
                sx={{
                  width: '3rem',
                  height: '3rem',
                  borderRadius: '10rem',
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '3rem',
                    height: 'auto',
                  }}
                  src={cobrandingConfig.imageUrl}
                  onClick={visitCobrandingWebsite}
                />
              </Box>
            )}
            <Typography
              onClick={visitCobrandingWebsite}
              sx={{
                fontSize: '1.5rem',
                fontFamily: 'Lato',
                textDecoration: 'none',
                fontWeight: 400,
                opacity: 0.95,
                transition: 'opacity 0.5s ease',
                cursor: 'pointer',

                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              {cobrandingConfig.fullName}
            </Typography>
            {!isPartnerSmall && (
              <>
                <Divider
                  sx={{
                    height: '50px',
                    borderColor: 'primary.main',
                    borderBottomWidth: '0px',
                  }}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.75rem',
                      lineHeight: '2rem',
                    }}
                  >
                    F L O C K
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      fontSize: '0.8rem',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    PARTNER AGENT{' '}
                    <Tooltip
                      title="Partner Agents are not employed by Flock Homes, Inc. or any of its affiliates."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <Box
                        sx={{
                          width: '0.85rem',
                          height: '0.85rem',
                          marginLeft: '4px',
                        }}
                      >
                        <InfoIcon />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <TrackedLink
            sx={{ '&:hover': { backgroundColor: 'unset' } }}
            to={logoPath}
            trackingConfig={{
              name: 'flock-logo',
            }}
          >
            <FlockLogo width={130} height={37} />
          </TrackedLink>
        )}

        <Box display="flex" alignItems="center" gap="64px">
          {navItems.map((item) => {
            const { label } = item
            return <NavItem key={label} {...item} />
          })}
          <Box display="flex" alignItems="center" gap="32px">
            {actions.map((item) => {
              const { label, onClick, variant } = item
              return (
                <TrackedButton
                  key={label}
                  variant={variant as any}
                  size="small"
                  onClick={onClick}
                  sx={{ fontSize: '20px!important' }}
                >
                  {label}
                </TrackedButton>
              )
            })}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar

Navbar.defaultProps = {
  cobrandingConfig: undefined,
  headerChange: 'secondary.main',
  mobileNavItems: [],
  isHomepage: false,
}
