import React from 'react'
import {
  usePopupState,
  bindHover,
  bindPopover,
  bindTrigger,
} from 'material-ui-popup-state/hooks'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import slugify from 'slugify'
import { Box } from '@mui/material'
import { TrackedLink } from '../TrackedLink'
import { CaretIcon } from '../icons/CaretIcon'

export type NavItemProps = {
  label: string
  to?: string
  dropdownContent?: React.ReactNode
  color?: any
}

const NavItem = (props: NavItemProps) => {
  const { label, dropdownContent, color, to } = props
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${slugify(label)}-popup`,
  })

  if (to) {
    return (
      <TrackedLink
        key={label}
        variant="cta"
        sx={{
          pt: '24px',
          pb: '24px',
          color: color || 'primary.main',
          '&:hover': { backgroundColor: 'unset' },
        }}
        to={to}
      >
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          sx={{ pointerEvents: 'none' }}
        >
          {label}
        </Box>
      </TrackedLink>
    )
  }

  return (
    <>
      <TrackedLink
        key={label}
        variant="cta"
        sx={{
          pt: '24px',
          pb: '24px',
          color: color || 'primary.main',
          '&:hover': { backgroundColor: 'unset' },
        }}
        component="button"
        {...bindHover(popupState)}
        {...bindTrigger(popupState)}
      >
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          sx={{ pointerEvents: 'none' }}
        >
          {label}{' '}
          {dropdownContent ? <CaretIcon color={color || 'primary'} /> : null}
        </Box>
      </TrackedLink>
      {dropdownContent && (
        <HoverPopover
          {...bindPopover(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          sx={{
            ml: '-36px',
            '& .MuiPaper-root': {
              borderRadius: '16px',
              boxShadow: '0px 8px 24px 0px rgba(69, 73, 77, 0.08)',
            },
          }}
          disableAutoFocus={false}
        >
          <Box sx={{ p: '36px' }}>{dropdownContent}</Box>
        </HoverPopover>
      )}
    </>
  )
}

export default NavItem

NavItem.defaultProps = {
  to: '',
}
