import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const InstitutionFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M5.33268 50.6667V21.3333H2.66602V16H10.666V10.6667C10.666 9.95942 10.947 9.28115 11.4471 8.78105C11.9472 8.28095 12.6254 8 13.3327 8H50.666C51.3733 8 52.0515 8.28095 52.5516 8.78105C53.0517 9.28115 53.3327 9.95942 53.3327 10.6667V16H61.3327V21.3333H58.666V50.6667H61.3327V56H2.66602V50.6667H5.33268ZM34.666 50.6667V32H29.3327V50.6667H34.666ZM21.3327 50.6667V32H15.9993V50.6667H21.3327ZM47.9993 50.6667V32H42.666V50.6667H47.9993ZM15.9993 13.3333V16H47.9993V13.3333H15.9993Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default InstitutionFilledIcon
