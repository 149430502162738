import React from 'react'
import HistoricalPerformanceDesktop from './HistoricalPerformanceDesktop'
import HistoricalPerformanceMobile from './HistoricalPerformanceMobile'
import HistoricalPerformanceTablet from './HistoricalPerformanceTablet'
import { HistoricalPerformanceProps } from './historicalPerformanceTypes'
import useHistoricalPerformance from './useHistoricalPerformance'

const HistoricalPerformance = (props: HistoricalPerformanceProps) => {
  const { filteredQuarterData, isMobile, isTablet } =
    useHistoricalPerformance(props)

  if (isMobile) {
    return (
      <HistoricalPerformanceMobile
        {...props}
        filteredQuarterData={filteredQuarterData}
      />
    )
  } else if (isTablet) {
    return (
      <HistoricalPerformanceTablet
        {...props}
        filteredQuarterData={filteredQuarterData}
      />
    )
  } else {
    return (
      <HistoricalPerformanceDesktop
        {...props}
        filteredQuarterData={filteredQuarterData}
      />
    )
  }
}

export default HistoricalPerformance
