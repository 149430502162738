import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import slugify from 'slugify'
import { UserEventType } from '@flock/utils'
import { MinusIcon } from '../icons/MinusIcon'
import { PlusIcon } from '../icons/PlusIcon'
import { TrackedLink } from '../TrackedLink'
import { TrackingConfig, useTracking } from '../useTracking'

export type TrackedAccordionProps = Partial<AccordionProps> & {
  trackingConfig?: TrackingConfig
  summary: string
  styleVariant?: 'standard' | 'filled'
  description?: string
  extended?: boolean
  summaryOnClick?: () => void
  children?: React.ReactNode
  collapsible?: boolean
  collapserText?: string
}

const TrackedAccordion = (props: TrackedAccordionProps) => {
  const {
    trackingConfig,
    summary,
    summaryOnClick,
    extended,
    description,
    children,
    collapsible,
    collapserText,
    styleVariant,
    ...otherProps
  } = props
  const { track } = useTracking()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onChangeTracked = (_: React.SyntheticEvent, isExpanded: boolean) => {
    let trackingName = 'accordion-click'
    if (trackingConfig?.name) {
      trackingName = trackingConfig?.name
    } else {
      trackingName = slugify(summary)
    }
    track(trackingName, {
      type: 'accordion',
      ...trackingConfig?.properties,
      actionType: UserEventType.ACCORDION_CLICK,
    })
    if (isExpanded) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }

  if (styleVariant === 'filled') {
    return (
      <Box
        sx={{
          backgroundColor: 'rgba(235, 241, 241, 0.5)',
          borderRadius: '12px',
        }}
      >
        <Accordion
          {...otherProps}
          expanded={extended ?? isOpen}
          onChange={onChangeTracked}
        >
          <AccordionSummary
            sx={{
              padding: '16px',
              borderBottom: 'unset',
              '& .MuiAccordionSummary-content': {
                marginTop: 0,
                marginBottom: 0,
              },
            }}
            expandIcon={
              extended ?? isOpen ? (
                <MinusIcon width="24px" height="24px" />
              ) : (
                <PlusIcon width="24px" height="24px" />
              )
            }
            onClick={summaryOnClick}
          >
            <Typography variant="h4">{summary}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: '16px',
            }}
          >
            {description && (
              <Typography variant="p2" color="gray8.main">
                {description}
              </Typography>
            )}
            {children}
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  return (
    <Accordion
      {...otherProps}
      expanded={extended ?? isOpen}
      onChange={onChangeTracked}
    >
      <AccordionSummary
        sx={{ pt: '16px', pb: '16px', pl: '0px', pr: '0px' }}
        expandIcon={extended ?? isOpen ? <MinusIcon /> : <PlusIcon />}
        onClick={summaryOnClick}
      >
        <Typography variant="h4">{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          pt: '24px',
          pl: '0px',
          pr: '0px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        {description && (
          <Typography variant="p2" color="gray8.main">
            {description}
          </Typography>
        )}
        {children}
        {collapsible && (
          <>
            {collapserText ? (
              <TrackedLink
                variant="c1"
                sx={{ alignSelf: 'center' }}
                onClick={() => setIsOpen(false)}
              >
                - {collapserText}
              </TrackedLink>
            ) : (
              <TrackedLink
                variant="c1"
                sx={{ alignSelf: 'center' }}
                onClick={() => setIsOpen(false)}
              >
                - Collapse {summary} Documents
              </TrackedLink>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

TrackedAccordion.defaultProps = {
  trackingConfig: null,
  onClick: undefined,
  extended: undefined,
  description: '',
  collapsible: undefined,
  collapserText: undefined,
  styleVariant: 'standard',
}

export default TrackedAccordion
