import { LeftArrowFilledIcon, PageContainer } from '@flock/shared-ui'
import { Info } from '@mui/icons-material'
import { Box, Grid, Paper, styled, Tooltip, Typography } from '@mui/material'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import React from 'react'
import { ACCOUNT_URL } from '../../constants'
import PageWrapper from '../SharedComponents/PageWrapper'
import useFundsFromOperationsPage from './useFundsFromOperationsPage'

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FundsFromOperationsPage = (_: RouteComponentProps) => {
  const {
    quarters,
    selectedQuarterFfoData,
    setSelectedQuarter,
    selectedQuarter,
  } = useFundsFromOperationsPage()

  const isBeforeQ3 = (quarter: string) => {
    const selectedQuarterYear = parseInt(quarter.split(' ')[1], 10)
    if (selectedQuarterYear !== 2024) {
      return selectedQuarterYear < 2024
    } else {
      return quarter.split(' ')[0] === 'Q1' || quarter.split(' ')[0] === 'Q2'
    }
  }

  return (
    <PageContainer title="FLOCK | FFO" trackingName="ffo">
      <PageWrapper>
        <Grid
          container
          spacing={4}
          columns={12}
          height="auto"
          pl="32px"
          pr="32px"
          pb="32px"
        >
          <Grid item xs={3}>
            <Paper
              sx={{
                borderRadius: '32px',
                padding: '0px 16px 0px 0px',
                cursor: 'pointer',
              }}
              onClick={() => navigate(ACCOUNT_URL)}
            >
              <Box display="flex" alignItems="center">
                <LeftArrowFilledIcon height="48px" width="48px" />
                <Typography variant="p3">
                  Back to Owners&apos; Portal
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={9} />
          <Grid item xs={2} marginTop="60px" />
          <Grid item xs={10}>
            <Box display="flex" justifyContent="center" width="100%">
              <Typography variant="h2">Funds From Operations</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" width="100%" gap={1}>
              {quarters.map((quarter) => (
                <Paper
                  key={quarter}
                  sx={{
                    padding: '16px',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    borderRadius: '16px',
                    boxShadow:
                      selectedQuarter !== quarter
                        ? 'none'
                        : '0px 8px 24px 0px rgba(69, 73, 77, 0.08)',
                    backgroundColor:
                      selectedQuarter !== quarter
                        ? 'green0.main'
                        : 'green2.main',
                    cursor: selectedQuarter !== quarter ? 'pointer' : 'default',
                  }}
                  onClick={() =>
                    selectedQuarter !== quarter
                      ? setSelectedQuarter(quarter)
                      : undefined
                  }
                >
                  <Typography variant="p2">{quarter}</Typography>
                </Paper>
              ))}
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Paper
              sx={{
                borderRadius: '16px',
                padding: '24px',
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                flexDirection="column"
                gap="12px"
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" color="gray5.main">
                    $,unaudited
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData.quarterString}
                  </Typography>
                </Box>
                {/* INCOME */}
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Total portfolio income
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterFfoData?.totalPortfolioIncome}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total rental income
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterFfoData?.totalRentalIncome}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Rental income
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.rentalIncome}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Below market lease adjustments&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          When occupied properties are contributed to Flock,
                          adjustments are made to contribution values for the
                          differences in current rents vs. market rents. These
                          adjustments supplement below-market lease cash flows
                          to help achieve fund targets.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.belowMarketLeaseAdjustments}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Bad debt - rent&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          Unpaid rent owed by residents.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.badDebtRent}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Rental assistance income
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.rentalAssistanceIncome}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total other income
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterFfoData?.totalOtherIncome}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Pet rent income
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.petRent}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Late fees
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.lateFees}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Application and administrative fees
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.applicationAndAdministrativeFees}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Insurance claim income
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.insuranceClaimIncome}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    LP processing fees
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.processingFees}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Damage fees
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.damageFees}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Security deposit forfeiture
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.securityDepositForfeiture}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Bad debt - other income&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          Unpaid charges to residents for items like damages to
                          property.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.badDebtOtherIncome}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Other miscellaneous income&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          Comprised of items like interest income, court fees,
                          etc.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.otherMiscellaneousIncome}
                  </Typography>
                </Box>
                {/* EXPENSES */}
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Total portfolio expenses
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterFfoData?.totalPortfolioExpenses}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total operating expenses
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterFfoData?.totalOperatingExpenses}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Property tax
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.propertyTax}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Property insurance
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.propertyInsurance}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Property management fee
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.propertyManagementFee}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    HOA dues and fines
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.hoaDuesAndFines}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Leasing fees
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.leasingFees}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Leasing commission amortization - rogers leasing fee
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.leasingAmortization}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Repairs and maintenance
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.repairsAndMaintenance}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Turn expense
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.turnExpense}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Utilities&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          While Flock does not pay for resident utilities, Flock
                          pays utilities on vacant units and, in some cases,
                          where utility meters are not separated for multi-unit
                          properties.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.utilities}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Property licenses and permits
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.propertyLicensesAndPermits}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Eviction expense
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.evictionExpense}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Other expense
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.otherExpense}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total administrative fees
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterFfoData?.totalAdministrativeFees}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Bank charges
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.bankCharges}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Third-party fund admin expense&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          Expenses rendered by Flock&apos;s fund administrator,
                          HCGlobal Fund Services, are currently subsidized by
                          the management company, Flock Homes, Inc. As fund cash
                          flows grow, these costs will be increasingly borne by
                          the fund.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.thirdPartyFundAdminExpense}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Third-party tax and audit expense&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          Flock partners with KPMG to facilitate a formal annual
                          financial audit of the fund and furnish annual filing
                          documentation. Costs for both efforts are currently
                          subsidized by Flock Homes, Inc., the fund&apos;s
                          management company, until fund cash flows are
                          sufficient to cover.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.thirdPartyTaxAndAuditExpense}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Third-party fund insurance
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.thirdPartyInsuranceExpense}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Software fees
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.softwareFees}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Flock asset management fee
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.flockAssetManagementFee}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Fund entity-level taxes
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.fundEntityLevelTaxes}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Fund servicing expense
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.fundServicingExpense}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total interest expense
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterFfoData?.totalInterestExpense}
                  </Typography>
                </Box>
                {isBeforeQ3(selectedQuarter) && (
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="l1" paddingLeft="32px">
                      Leasing fee amortization
                    </Typography>
                    <Typography variant="l1">
                      {selectedQuarterFfoData?.leasingFeeAmortization}
                    </Typography>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Loan fees amortization
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.loanFeesAmortization}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Interest expense - FirstBank
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.interestExpenseFirstBank}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Interest expense - Sunflower
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.interestExpenseSunflower}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Interest expense - LendingOne
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.interestExpenseLendingOne}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Interest expense - Evergreen
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.interestExpenseEvergreen}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Interest expense - Intercompany
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.interestExpenseIntercompany}
                  </Typography>
                </Box>
                <hr style={{ width: '100%', color: 'gray3.main' }} />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1">Early redemption fees</Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.earlyRedemptionFees}
                  </Typography>
                </Box>
                {!isBeforeQ3(selectedQuarter) && (
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="l1">
                      Leasing fee amortization
                    </Typography>
                    <Typography variant="l1">
                      {selectedQuarterFfoData?.leasingFeeAmortizationExpense}
                    </Typography>
                  </Box>
                )}
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Portfolio net income
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterFfoData?.portfolioNetIncome}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1">Non-cash items</Typography>
                  <Typography variant="l1">
                    {selectedQuarterFfoData?.nonCashItems}
                  </Typography>
                </Box>
                <hr style={{ width: '100%', color: 'gray3.main' }} />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Funds From Operations
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterFfoData?.fundsFromOperations}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </PageWrapper>
    </PageContainer>
  )
}

export default FundsFromOperationsPage
