import { Info } from '@mui/icons-material'
import { styled, Tooltip, Typography } from '@mui/material'
import React from 'react'

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

const StyledInfoTooltip = styled((props: any) => (
  <Tooltip
    classes={{ popper: props.className }}
    leaveTouchDelay={999999}
    {...props}
  />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }0
`

const DistributionYieldTooltip = () => (
  <StyledInfoTooltip
    title={
      <Typography variant="p3" marginRight="6px" color="gray8.main">
        Annualized cash yield shown here assumes any withdrawals and additional
        contributions are made at quarter-end for the respective period. This
        may differ from actual circumstances. Actual quarterly cash flow
        calculations account for any withdrawals and additional contributions
        appriopriately at the time made.
      </Typography>
    }
    enterTouchDelay={0}
  >
    <StyledInfoOutline fontSize="small" color="disabled" />
  </StyledInfoTooltip>
)

export default DistributionYieldTooltip
