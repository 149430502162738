import React, { useState } from 'react'
import { CircularProgress, Grid, Box, Typography, Button } from '@mui/material'
import { TrackedLink } from '@flock/shared-ui'
import { navigate } from 'gatsby'
import ParallelMarketsButton from './ParallelMarketsButton'
import {
  ACCREDITATION_VERIFICATION_URL,
  ORDERS_URL,
} from '../../../../constants'
import ErrorCardPage from '../../../SharedComponents/ErrorCardPage'
import { LpOnboardingFlowData } from './lpOnboardingTypes'

export type AccreditationAndIdentityVerificationInjectedProps = {
  orderUuid: string
  taskUuid: string
  legalEntityUuid: string
}

export type AccreditationAndIdentityVerificationProps = {
  onSubmit: (flowData: Partial<LpOnboardingFlowData>) => void
  loading?: boolean
  error?: boolean
}

const downloadVerification = () => {
  window.open(ACCREDITATION_VERIFICATION_URL, '_blank')
}

const AccreditationAndIdentityVerificationStep = ({
  taskUuid,
  onSubmit,
  loading,
  orderUuid,
  error,
  legalEntityUuid,
}: AccreditationAndIdentityVerificationProps &
  AccreditationAndIdentityVerificationInjectedProps) => {
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true)
  const [outError, setOutError] = useState(false)
  const [load, setLoad] = useState(false)

  const allowUserToContinue = () => {
    setContinueButtonDisabled(false)
  }

  const setOuterError = () => {
    setOutError(true)
  }

  const setOuterLoading = (newLoading: boolean) => {
    setLoad(newLoading)
  }

  if (error || outError) {
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="16px"
      >
        <ErrorCardPage customViewHeight />
      </Box>
    )
  }

  return (
    <>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Box
          display="flex"
          flexDirection="column"
          pt="16px"
          gap="32px"
          alignItems="center"
          height="352px"
        >
          {continueButtonDisabled ? (
            <>
              <Typography variant="p2" color="gray8.main">
                You&apos;ll now be able to verify your identity and
                accreditation status with our provider, Parallel Markets. Please
                fill out the requested information for yourself individually or
                for your business depending on how you&apos;re contributing.
              </Typography>
              {load ? <CircularProgress /> : <></>}
              <ParallelMarketsButton
                allowUserToContinue={allowUserToContinue}
                setOuterError={setOuterError}
                setOuterLoading={setOuterLoading}
                taskUuid={taskUuid}
                orderUuid={orderUuid}
                legalEntityUuid={legalEntityUuid}
              />
              <Typography variant="p2" color="gray5.main">
                If you wish to have a third party (your lawyer, CPA or
                investment advisor) verify your accredited status on your
                behalf, you can download our{' '}
                <TrackedLink onClick={downloadVerification}>
                  verification form
                </TrackedLink>
                , send it to them to fill out, and then upload the completed
                form when prompted within the Parallel Markets flow. Otherwise,
                you can just verify this information yourself in Parallel
                Markets.
              </Typography>
            </>
          ) : (
            <Typography variant="p2" color="gray8.main">
              Your Identity and Accreditation has been submitted. Please click
              next below to sign your generated Subscription Booklet.
            </Typography>
          )}

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pt="62px"
          >
            <Button
              sx={{ width: '200px', height: '40px' }}
              variant="secondary"
              onClick={() => navigate(`${ORDERS_URL}/${orderUuid}`)}
              disabled={loading}
            >
              <Typography variant="cta2">Return to Order</Typography>
            </Button>
            <Button
              sx={{ width: '104px', height: '40px' }}
              variant="primary"
              disabled={continueButtonDisabled || loading}
              onClick={() => onSubmit({})}
            >
              <Typography variant="cta2">
                {loading ? (
                  <CircularProgress size="24px" color="white" />
                ) : (
                  'Next'
                )}{' '}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} />
    </>
  )
}

export default AccreditationAndIdentityVerificationStep
