import React, { useCallback } from 'react'

import { GlobalTrackingProvider } from '@flock/shared-ui'
import { trackPage, track } from './analytics'
import { useInvestorAccountContext } from '../components/InvestorAccountContext'

type TrackingWrapperProps = {
  children: React.ReactNode
  groupedEvents?: { [key: string]: string }
}

const TrackingWrapper = (props: TrackingWrapperProps) => {
  const { children, groupedEvents } = props

  const { investorAccount } = useInvestorAccountContext()

  const trackingFunctionWrapper = useCallback(
    (name: string, properties?: any) => {
      const newProperties = {
        ...properties,
        investorAccountUuid: investorAccount?.uuid,
      }
      track(name, newProperties)
    },
    [investorAccount]
  )

  const trackPageFunctionWrapper = useCallback(
    (name: string, properties?: any) => {
      const newProperties = {
        ...properties,
        investorAccountUuid: investorAccount?.uuid,
      }
      trackPage(name, newProperties)
    },
    [investorAccount]
  )

  return (
    <GlobalTrackingProvider
      siteName="investor-fe"
      trackFn={trackingFunctionWrapper}
      trackPageFn={trackPageFunctionWrapper}
      groupedEvents={groupedEvents || {}}
    >
      {children}
    </GlobalTrackingProvider>
  )
}

TrackingWrapper.defaultProps = {
  groupedEvents: {},
}

export default TrackingWrapper
