import { Core_PropertySummaryDetails } from '@flock/flock-gql-server/src/__generated__/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import millify from 'millify'
import { useCallback } from 'react'
import { GOOGLE_MAPS_API_KEY } from '../../constants'
import generateGoogleMapUrl from '../../utils/googleMapUrl'
import { AssetLifecycleEventType } from './AssetLifecycleEventRow'
import {
  AssetViewPageProps,
  LeasingData,
  ValuationData,
} from './detailedAssetViewTypes'

const useAssetViewPage = (props: AssetViewPageProps) => {
  const { assetUuid } = props

  const tickFormatterX = useCallback((val: any) => {
    const currDate = new Date(val)
    const month = currDate.getMonth()
    const year = currDate.getFullYear()

    if (month < 3) {
      return `Q1 ${year}`
    } else if (month < 6) {
      return `Q2 ${year}`
    } else if (month < 9) {
      return `Q3 ${year}`
    }

    return `Q4 ${year}`
  }, [])

  const tickFormatterY = useCallback((val: any) => {
    const precision = val / 100 < 100000 || val / 100 > 1000000 ? 1 : 0 // if val is between 100k and 1m, show no decimal places since we will overflow on the chart
    return `$${millify(val / 100, {
      precision,
      lowercase: true,
      units: ['', 'K', 'M', 'B', 'T'],
    })}`
  }, [])

  const tickFormatterYLeasing = useCallback((val: any) => {
    const precision = val / 100 < 10000 ? 2 : 1
    return `$${millify(val / 100, {
      precision,
      lowercase: true,
      units: ['', 'K', 'M', 'B', 'T'],
    })}`
  }, [])

  const { getPropertySummaryDetails } = useStaticQuery(graphql`
    query InvestorPropertyAssetAssetViewPageQuery {
      getPropertySummaryDetails {
        propertyDetails {
          propertyUuid
          address {
            streetNumber
            formattedStreet
            formattedAddress
            unit
            city
            state
            zipcode
            latitude
            longitude
            primaryPhotoUrl
            parentAddressUuid
          }
          addressUuid
          propertyType
          beds
          baths
          halfBaths
          sqft
          yearBuilt
          acquisitionDate
          grossYield
          underwrittenGrossYield
          leaseEndDate
          leaseStatus
          monthlyRentCents
          acquisitionPriceCents
          marketRentCents
          propertyValuationHistory {
            propertyUuid
            currentValuationCents
            currentValuationSource
            effectiveDatetime
            currentQuarterCapexCents
            currentQuarterCapexIncludedInAppraisals
            appraisalAdjustedValuationCents
            hpiAdjustedPriceCents
            valuationBasePriceCents
            appraisalValueCents
            appraisalAsOfDate
            startDateInFund
          }
          propertyLeasingHistory {
            propertyUuid
            leaseStartDate
            leaseEndDate
            moveInDate
            moveOutDate
            monthlyRentCents
            leaseType
          }
          propertyRenovationHistory {
            propertyUuid
            renovationStartDate
            approvedScopeOfWorkCents
            finalCompletionDate
          }
          investorAccountUuid
        }
      }
    }
  `)

  if (!getPropertySummaryDetails) {
    return {
      tickFormatterX,
      tickFormatterY,
      tickFormatterYLeasing,
      valuationData: [],
      leasingData: [],
      valuationGraphData: [],
      leasingGraphData: [],
      acquisitionDate: '',
      acquisitionPrice: 0,
      assetLoading: true,
      assetError: false,
      assetDetails: undefined,
      assetDetailsArray: [],
      hasValuationData: false,
      hasLeasingData: false,
      mapsUrl: '',
    }
  }

  const details = (
    getPropertySummaryDetails.propertyDetails as Core_PropertySummaryDetails[]
  ).filter(
    (elem) => elem.propertyUuid !== '00000000-0000-0000-0000-000000000000'
  )
  const multifamilyPropertyAssetsMap: Map<
    string,
    Core_PropertySummaryDetails[]
  > = new Map<string, Core_PropertySummaryDetails[]>()
  details.map((asset) => {
    if (
      asset?.address?.parentAddressUuid &&
      asset?.address?.parentAddressUuid !==
        '00000000-0000-0000-0000-000000000000'
    ) {
      const parentAddressUuid = asset.address.parentAddressUuid || ''
      if (multifamilyPropertyAssetsMap.has(parentAddressUuid)) {
        const temp = multifamilyPropertyAssetsMap.get(
          parentAddressUuid
        ) as Core_PropertySummaryDetails[]
        temp.push(asset)
        // Sort first street number to front for display name and valuation
        temp.sort((a, b) => {
          if (a.address?.streetNumber && b.address?.streetNumber) {
            if (a.address?.streetNumber === b.address?.streetNumber) {
              return (a.address?.unit || '') < (b.address?.unit || '') ? -1 : 1
            } else {
              return (
                parseInt(a.address?.streetNumber || '0', 10) -
                parseInt(b.address?.streetNumber || '0', 10)
              )
            }
          } else {
            return 0
          }
        })
        multifamilyPropertyAssetsMap.set(parentAddressUuid, temp)
      } else {
        multifamilyPropertyAssetsMap.set(parentAddressUuid, [asset])
      }
    } else {
      multifamilyPropertyAssetsMap.set(asset.addressUuid, [asset])
    }
    return asset
  })

  let assetDetailsArray = (
    getPropertySummaryDetails.propertyDetails as Core_PropertySummaryDetails[]
  ).filter((elem) => elem.propertyUuid === assetUuid)
  const assetParent = assetDetailsArray[0].address?.parentAddressUuid

  if (assetParent && assetParent !== '') {
    assetDetailsArray =
      multifamilyPropertyAssetsMap.get(assetParent) || assetDetailsArray
  }

  // if SFR, length of array is 1
  // if multifamily, first unit contains the valuation data
  const assetDetails = assetDetailsArray[0]
  const { acquisitionDate } = assetDetails

  const hasValuationData =
    (assetDetails?.propertyValuationHistory?.length || 0) > 0

  const acquisitionPrice = assetDetails.acquisitionPriceCents || 0

  const lifecycleData: any = []
  let valuationData: any = []
  const valuationGraphData: ValuationData[] = []
  if (hasValuationData) {
    valuationData =
      assetDetails.propertyValuationHistory?.map((item) => ({
        source: item?.currentValuationSource,
        currentValuation: item?.currentValuationCents || 0,
        capex: item?.currentQuarterCapexCents || 0,
        effectiveDateTime: item?.effectiveDatetime,
        previousValuation: item?.valuationBasePriceCents || 0,
      })) || []
    // add a 0 previous valuation for the first quarter
    valuationData[0].previousValuation = 0

    let set = 0
    let previous = 0
    valuationData.forEach((val: any) => {
      if (val.capex > 0 && previous > 0) {
        valuationGraphData.push({
          effectiveDateTime: val.effectiveDateTime,
          currentValuation: val.currentValuation - val.capex,
          previousValuation: previous,
          capex: val.capex,
          source: val.source,
          set,
        })
        set += 1
        valuationGraphData.push({
          effectiveDateTime: val.effectiveDateTime,
          currentValuation: val.currentValuation - val.capex,
          previousValuation: previous,
          capex: val.capex,
          source: val.source,
          set,
          capexSet: true,
        })
        valuationGraphData.push({
          effectiveDateTime: val.effectiveDateTime,
          currentValuation: val.currentValuation,
          previousValuation: previous,
          capex: val.capex,
          source: val.source,
          set,
          capexSet: true,
        })
        set += 1
      }
      valuationGraphData.push({
        effectiveDateTime: val.effectiveDateTime,
        currentValuation: val.currentValuation,
        previousValuation: previous,
        capex: val.capex,
        source: val.source,
        set,
      })
      previous = val.currentValuation

      lifecycleData.push({
        type: AssetLifecycleEventType.VALUATION_UPDATE,
        effectiveDateTime: val.effectiveDateTime,
        currentValuation: val.currentValuation,
        previousValuation: val.previousValuation,
        capex: val.capex,
        source: val.source,
      })
    })
  }

  let hasLeasingData: boolean
  let leasingData: any = []
  const leasingGraphData: LeasingData[] = []
  if (assetDetailsArray.length > 1) {
    // Check if any propertyLeasingHistory object has a length over zero
    const hasNonEmptyLeasingHistory = assetDetailsArray.some(
      (unitDetails) => (unitDetails?.propertyLeasingHistory?.length || 0) > 0
    )
    // Check if any monthlyRent within the propertyLeasingHistory objects is greater than zero
    const hasRentGreaterThanZero = assetDetailsArray.some((unitDetails) =>
      unitDetails?.propertyLeasingHistory?.some(
        (leasingHistory) => (leasingHistory?.monthlyRentCents || 0) > 0
      )
    )
    hasLeasingData = hasNonEmptyLeasingHistory && hasRentGreaterThanZero

    if (hasLeasingData) {
      let allLeaseStartDates: string[] = []

      // Get all lease start dates
      assetDetailsArray.forEach((unitDetails) => {
        allLeaseStartDates = allLeaseStartDates.concat(
          unitDetails?.propertyLeasingHistory?.map(
            (history) => history?.leaseStartDate as string
          ) || []
        )
      })

      // Find the earliest lease start date
      const earliestLeaseStartDate = new Date(
        Math.min(...allLeaseStartDates.map((date) => new Date(date).getTime()))
      )
      const startYear = earliestLeaseStartDate.getFullYear()

      // Initialize variables
      const quarterlyRents: {
        [key: number]: {
          totalMonthlyRent: number
          unitRents: { [key: string]: number }
        }
      } = {}
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()

      // Calculate combined rent amount for the start date of each quarter since the first quarter any unit has been rented out
      for (let year = startYear; year <= currentYear; year += 1) {
        for (let quarter = 1; quarter <= 4; quarter += 1) {
          const quarterStartDate = new Date(year, (quarter - 1) * 3, 1, 12)
          if (quarterStartDate > currentDate) {
            break
          }
          quarterlyRents[quarterStartDate.valueOf()] = {
            totalMonthlyRent: 0,
            unitRents: {},
          }
          assetDetailsArray.forEach((unitDetails) => {
            unitDetails?.propertyLeasingHistory?.forEach((leasingHistory) => {
              const leaseStartDate = new Date(leasingHistory?.leaseStartDate)
              const moveOutDate = leasingHistory?.moveOutDate
                ? new Date(leasingHistory?.moveOutDate)
                : undefined
              let leaseEndDate = leasingHistory?.leaseEndDate
                ? new Date(leasingHistory?.leaseEndDate)
                : undefined
              if (moveOutDate) {
                leaseEndDate = moveOutDate
              }
              if (
                leaseStartDate <= quarterStartDate &&
                (!leaseEndDate || leaseEndDate > quarterStartDate)
              ) {
                quarterlyRents[quarterStartDate.valueOf()].totalMonthlyRent +=
                  leasingHistory?.monthlyRentCents || 0
                quarterlyRents[quarterStartDate.valueOf()].unitRents[
                  leasingHistory?.propertyUuid || ''
                ] =
                  (quarterlyRents[quarterStartDate.valueOf()].unitRents[
                    leasingHistory?.propertyUuid || ''
                  ] || 0) + (leasingHistory?.monthlyRentCents || 0)
              }
            })
          })
        }
      }

      // Convert the map to an array of LeasingData objects
      let set = 0
      let previous = 0
      Object.keys(quarterlyRents).forEach((key) => {
        const { totalMonthlyRent } = quarterlyRents[parseInt(key, 10)]
        if (totalMonthlyRent > 0) {
          if (previous > 0) {
            leasingGraphData.push({
              leaseStartDate: parseInt(key, 10),
              monthlyRent: previous,
              set,
            })
            set += 1
            leasingGraphData.push({
              leaseStartDate: parseInt(key, 10),
              monthlyRent: previous,
              set,
            })
            leasingGraphData.push({
              leaseStartDate: parseInt(key, 10),
              monthlyRent: totalMonthlyRent,
              set,
            })
            set += 1
          }
          leasingGraphData.push({
            leaseStartDate: parseInt(key, 10),
            monthlyRent: totalMonthlyRent,
            set,
          })

          // loop through lifecycle data and insert leasing data if leaseStartDate falls within the same quarter as effectiveDateTime
          for (let i = 0; i < lifecycleData.length; i += 1) {
            const item = lifecycleData[i]
            const existingDate = new Date(item.effectiveDateTime)
            const newDate = new Date(parseInt(key, 10))
            if (
              Math.floor((existingDate.getMonth() + 3) / 3) ===
                Math.floor((newDate.getMonth() + 3) / 3) &&
              existingDate.getFullYear() === newDate.getFullYear()
            ) {
              if (existingDate < newDate) {
                lifecycleData.splice(i + 1, 0, {
                  type: AssetLifecycleEventType.RENT_UPDATE,
                  effectiveDateTime: newDate.toDateString(),
                  monthlyRent: totalMonthlyRent,
                  previousMonthlyRent: previous,
                })
              } else {
                lifecycleData.splice(i, 0, {
                  type: AssetLifecycleEventType.RENT_UPDATE,
                  effectiveDateTime: newDate.toDateString(),
                  monthlyRent: totalMonthlyRent,
                  previousMonthlyRent: previous,
                })
              }
              break
            }
          }

          previous = totalMonthlyRent
        }
      })
      if (leasingGraphData.length > 0) {
        leasingGraphData.push({
          leaseStartDate: new Date(
            leasingGraphData[leasingGraphData.length - 1].moveoutDate ||
              leasingGraphData[leasingGraphData.length - 1].leaseEndDate ||
              String(new Date())
          ).valueOf(),
          monthlyRent:
            leasingGraphData[leasingGraphData.length - 1].monthlyRent,
          set,
        })
      }

      // Sort the array by effectiveDate
      leasingGraphData.sort((a, b) =>
        a.leaseStartDate < b.leaseStartDate ? -1 : 1
      )
    }
  } else {
    // Check if any propertyLeasingHistory object has a length over zero
    // and if any monthlyRent within the propertyLeasingHistory objects is greater than zero
    hasLeasingData =
      (assetDetails?.propertyLeasingHistory?.length || 0) > 0 &&
      (assetDetails?.propertyLeasingHistory?.some(
        (item) => (item?.monthlyRentCents || 0) > 0
      ) ||
        false)

    if (hasLeasingData) {
      leasingData =
        [...(assetDetails.propertyLeasingHistory || [])]
          .reverse()
          .map((item) => ({
            leaseStartDate: item?.leaseStartDate,
            leaseEndDate: item?.leaseEndDate,
            moveoutDate: item?.moveOutDate,
            monthlyRent: item?.monthlyRentCents || 0,
            leaseType: item?.leaseType,
          })) || []

      let set = 0
      let previous = 0
      leasingData.forEach((val: any) => {
        if (val.monthlyRent > 0) {
          if (previous > 0) {
            leasingGraphData.push({
              leaseStartDate: new Date(val.leaseStartDate).valueOf(),
              leaseEndDate: val.leaseEndDate,
              moveoutDate: val.moveoutDate,
              monthlyRent: previous,
              leaseType: val.leaseType,
              set,
            })
            set += 1
            leasingGraphData.push({
              leaseStartDate: new Date(val.leaseStartDate).valueOf(),
              leaseEndDate: val.leaseEndDate,
              moveoutDate: val.moveoutDate,
              monthlyRent: previous,
              leaseType: val.leaseType,
              set,
            })
            leasingGraphData.push({
              leaseStartDate: new Date(val.leaseStartDate).valueOf(),
              leaseEndDate: val.leaseEndDate,
              moveoutDate: val.moveoutDate,
              monthlyRent: val.monthlyRent,
              leaseType: val.leaseType,
              set,
            })
            set += 1
          }
          leasingGraphData.push({
            leaseStartDate: new Date(val.leaseStartDate).valueOf(),
            leaseEndDate: val.leaseEndDate,
            moveoutDate: val.moveoutDate,
            monthlyRent: val.monthlyRent,
            leaseType: val.leaseType,
            set,
          })

          // loop through lifecycle data and insert leasing data if leaseStartDate falls within the same quarter as effectiveDateTime
          for (let i = 0; i < lifecycleData.length; i += 1) {
            const item = lifecycleData[i]
            const existingDate = new Date(item.effectiveDateTime)
            const newDate = new Date(val.leaseStartDate)
            if (
              Math.floor((existingDate.getMonth() + 3) / 3) ===
                Math.floor((newDate.getMonth() + 3) / 3) &&
              existingDate.getFullYear() === newDate.getFullYear()
            ) {
              if (existingDate < newDate) {
                lifecycleData.splice(i + 1, 0, {
                  type: AssetLifecycleEventType.RENT_UPDATE,
                  effectiveDateTime: val.leaseStartDate,
                  monthlyRent: val.monthlyRent,
                  previousMonthlyRent: previous,
                  leaseType: val.leaseType,
                })
              } else {
                lifecycleData.splice(i, 0, {
                  type: AssetLifecycleEventType.RENT_UPDATE,
                  effectiveDateTime: val.leaseStartDate,
                  monthlyRent: val.monthlyRent,
                  previousMonthlyRent: previous,
                  leaseType: val.leaseType,
                })
              }
              break
            }
          }
          previous = val.monthlyRent
        }
      })
      if (leasingGraphData.length > 0) {
        leasingGraphData.push({
          leaseStartDate: new Date(
            leasingGraphData[leasingGraphData.length - 1].moveoutDate ||
              leasingGraphData[leasingGraphData.length - 1].leaseEndDate ||
              String(new Date())
          ).valueOf(),
          monthlyRent:
            leasingGraphData[leasingGraphData.length - 1].monthlyRent,
          set,
        })
      }
    }
  }

  // let hasRenovationData: boolean
  // let renovationData: any = []
  // if (assetDetailsArray.length > 1) {
  //   // Check if any propertyRenovationHistory object has a length over zero
  //   const hasNonEmptyRenovationHistory = assetDetailsArray.some(
  //     (unitDetails) => (unitDetails?.propertyRenovationHistory?.length || 0) > 0
  //   )
  //   // Check if any sow within the propertyRenovationHistory objects is greater than zero
  //   const hasSowGreaterThanZero = assetDetailsArray.some((unitDetails) =>
  //     unitDetails?.propertyRenovationHistory?.some(
  //       (renovationHistory) =>
  //         (renovationHistory?.approvedScopeOfWorkCents || 0) > 0
  //     )
  //   )
  //   hasRenovationData = hasNonEmptyRenovationHistory && hasSowGreaterThanZero

  //   if (hasRenovationData) {
  //     let allRenovationStartDates: string[] = []

  //     // Get all renovation start dates
  //     assetDetailsArray.forEach((unitDetails) => {
  //       allRenovationStartDates = allRenovationStartDates.concat(
  //         unitDetails?.propertyRenovationHistory?.map(
  //           (history) => history?.renovationStartDate as string
  //         ) || []
  //       )
  //     })

  //     // Find the earliest renovation start date
  //     const earliestRenovationStartDate = new Date(
  //       Math.min(
  //         ...allRenovationStartDates.map((date) => new Date(date).getTime())
  //       )
  //     )
  //     const startYear = earliestRenovationStartDate.getFullYear()

  //     // Initialize variables
  //     const quarterlyCapex: {
  //       [key: number]: {
  //         totalCapex: number
  //         unitCapex: { [key: string]: number }
  //       }
  //     } = {}
  //     const currentDate = new Date()
  //     const currentYear = currentDate.getFullYear()

  //     // Calculate combined capex amount for each quarter since the first quarter any unit has been renovated
  //     for (let year = startYear; year <= currentYear; year += 1) {
  //       for (let quarter = 1; quarter <= 4; quarter += 1) {
  //         const quarterStartDate = new Date(year, (quarter - 1) * 3, 1, 12)
  //         const nextQuarterStartDate = new Date(year, quarter * 3, 1, 12)
  //         if (quarterStartDate > currentDate) {
  //           break
  //         }
  //         quarterlyCapex[quarterStartDate.valueOf()] = {
  //           totalCapex: 0,
  //           unitCapex: {},
  //         }
  //         assetDetailsArray.forEach((unitDetails) => {
  //           unitDetails?.propertyRenovationHistory?.forEach(
  //             (renovationHistory) => {
  //               const renoStartDate = new Date(
  //                 renovationHistory?.renovationStartDate
  //               )

  //               if (
  //                 renoStartDate >= quarterStartDate &&
  //                 renoStartDate < nextQuarterStartDate
  //               ) {
  //                 quarterlyCapex[quarterStartDate.valueOf()].totalCapex +=
  //                   renovationHistory?.approvedScopeOfWorkCents || 0
  //                 quarterlyCapex[quarterStartDate.valueOf()].unitCapex[
  //                   renovationHistory?.propertyUuid || ''
  //                 ] =
  //                   (quarterlyCapex[quarterStartDate.valueOf()].unitCapex[
  //                     renovationHistory?.propertyUuid || ''
  //                   ] || 0) + (renovationHistory?.approvedScopeOfWorkCents || 0)
  //               }
  //             }
  //           )
  //         })
  //       }
  //     }

  //     // Convert the map to an array of LeasingData objects
  //     Object.keys(quarterlyCapex).forEach((key) => {
  //       const { totalCapex } = quarterlyCapex[parseInt(key, 10)]
  //       if (totalCapex > 0) {
  //         // loop through lifecycle data and insert data if renoStartDate falls within the same quarter as effectiveDateTime
  //         for (let i = 0; i < lifecycleData.length; i += 1) {
  //           const item = lifecycleData[i]
  //           const existingDate = new Date(item.effectiveDateTime)
  //           const newDate = new Date(parseInt(key, 10))
  //           if (
  //             Math.floor((existingDate.getMonth() + 3) / 3) ===
  //               Math.floor((newDate.getMonth() + 3) / 3) &&
  //             existingDate.getFullYear() === newDate.getFullYear()
  //           ) {
  //             if (existingDate < newDate) {
  //               lifecycleData.splice(i + 1, 0, {
  //                 type: AssetLifecycleEventType.RENOVATION_UPDATE,
  //                 effectiveDateTime: newDate.toDateString(),
  //                 amountCents: totalCapex,
  //               })
  //             } else {
  //               lifecycleData.splice(i, 0, {
  //                 type: AssetLifecycleEventType.RENOVATION_UPDATE,
  //                 effectiveDateTime: newDate.toDateString(),
  //                 amountCents: totalCapex,
  //               })
  //             }
  //             break
  //           }
  //         }
  //       }
  //     })
  //   }
  // } else {
  //   // Check if any propertyRenovationHistory object has a length over zero
  //   // and if any sow within the propertyRenovationHistory objects is greater than zero
  //   hasRenovationData =
  //     (assetDetails?.propertyRenovationHistory?.length || 0) > 0 &&
  //     (assetDetails?.propertyRenovationHistory?.some(
  //       (item) => (item?.approvedScopeOfWorkCents || 0) > 0
  //     ) ||
  //       false)

  //   if (hasRenovationData) {
  //     renovationData =
  //       [...(assetDetails.propertyRenovationHistory || [])]
  //         .reverse()
  //         .map((item) => ({
  //           renovationStartDate: item?.renovationStartDate,
  //           approvedScopeOfWorkCents: item?.approvedScopeOfWorkCents || 0,
  //           finalCompletionDate: item?.finalCompletionDate,
  //         })) || []

  //     renovationData.forEach((val: any) => {
  //       if (val.approvedScopeOfWorkCents > 0) {
  //         // loop through lifecycle data and insert data if renoStartDate falls within the same quarter as effectiveDateTime
  //         for (let i = 0; i < lifecycleData.length; i += 1) {
  //           const item = lifecycleData[i]
  //           const existingDate = new Date(item.effectiveDateTime)
  //           const newDate = new Date(val.leaseStartDate)
  //           if (
  //             Math.floor((existingDate.getMonth() + 3) / 3) ===
  //               Math.floor((newDate.getMonth() + 3) / 3) &&
  //             existingDate.getFullYear() === newDate.getFullYear()
  //           ) {
  //             if (existingDate < newDate) {
  //               lifecycleData.splice(i + 1, 0, {
  //                 type: AssetLifecycleEventType.RENOVATION_UPDATE,
  //                 effectiveDateTime: val.renovationStartDate,
  //                 amountCents: val.approvedScopeOfWorkCents,
  //               })
  //             } else {
  //               lifecycleData.splice(i, 0, {
  //                 type: AssetLifecycleEventType.RENOVATION_UPDATE,
  //                 effectiveDateTime: val.renovationStartDate,
  //                 amountCents: val.approvedScopeOfWorkCents,
  //               })
  //             }
  //             break
  //           }
  //         }
  //       }
  //     })
  //   }
  // }

  const mapsUrl = generateGoogleMapUrl({
    type: 'staticmap',
    location: `${assetDetails.address?.formattedAddress}`,
    center: `${assetDetails.address?.latitude},${assetDetails.address?.longitude}`,
    size: '288x288',
    zoom: 16,
    scale: 2,
    key: GOOGLE_MAPS_API_KEY,
    style: `feature:poi|visibility:off`,
  })

  return {
    tickFormatterX,
    tickFormatterY,
    tickFormatterYLeasing,
    valuationGraphData,
    leasingGraphData,
    lifecycleData,
    acquisitionDate,
    acquisitionPrice,
    assetLoading: false,
    assetError: false,
    assetDetails,
    assetDetailsArray,
    hasValuationData,
    hasLeasingData,
    mapsUrl,
  }
}

export default useAssetViewPage
