import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useInvestorAccountContext } from '../InvestorAccountContext'

type AccountSelectorProps = {
  totalEquityValues?: number[]
  selectedLegalEntityUuid: string
  includeAggregate?: boolean
  setSelectedLegalEntityUuidHelper: (uuid: string) => void
}
const AccountSelector = (props: AccountSelectorProps) => {
  const { legalEntities } = useInvestorAccountContext()
  const {
    totalEquityValues,
    selectedLegalEntityUuid,
    includeAggregate,
    setSelectedLegalEntityUuidHelper,
  } = props
  return (
    <Box display="flex" flexDirection="row" gap="8px" flexWrap="wrap">
      {legalEntities.map((legalEntity, idx) => {
        const selected = selectedLegalEntityUuid === legalEntity.uuid
        let disabled = false
        if (totalEquityValues) {
          disabled = totalEquityValues[idx] === 0
        }
        if (disabled && selected) {
          // if the legalEntities array is long enough to contain idx+1, set that as selected - else, set the first legal entity as selected
          if (legalEntities[idx + 1]) {
            setSelectedLegalEntityUuidHelper(legalEntities[idx + 1].uuid)
          } else {
            setSelectedLegalEntityUuidHelper(legalEntities[0].uuid)
          }
          return <></>
        }
        if (!includeAggregate && legalEntity.uuid === 'aggregate') {
          return <></>
        }
        const backgroundColor = () => {
          if (disabled) {
            return 'gray2.main'
          } else if (selected) {
            return 'green1.main'
          }
          return undefined
        }
        return (
          <Paper
            key={legalEntity.uuid}
            sx={{
              padding: '2px 12px 4px 12px',
              borderRadius: '24px',
              border: '1.5px solid',
              borderColor: 'moneyGreen.main',
              display: 'inline-block',
              backgroundColor: backgroundColor(),
              boxShadow: 'none',
              cursor: selected || disabled ? 'default' : 'pointer',
            }}
            onClick={
              !selected && !disabled
                ? () => {
                    setSelectedLegalEntityUuidHelper(legalEntity.uuid)
                    // setSelectedLegalEntity(legalEntity)
                  }
                : undefined
            }
          >
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <Typography variant="l1" color="moneyGreen.main">
                <div key={legalEntity.uuid}>{legalEntity.name}</div>
              </Typography>
            </Box>
          </Paper>
        )
      })}
    </Box>
  )
}

AccountSelector.defaultProps = {
  totalEquityValues: [],
  includeAggregate: true,
}

export default AccountSelector
