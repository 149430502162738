import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import Pagination from '@mui/material/Pagination'
import { CloseMobileIcon } from '@flock/shared-ui'

import HomeMessage from './HomeMessage'

type HomeMessageCenterProps = {
  open: boolean
  messages: any
  // loading: boolean
  onClose: () => void
}

const HomeMessageCenterMobile = (props: HomeMessageCenterProps) => {
  const { open, messages, onClose } = props
  const [page, setPage] = useState(1)
  const pageCount = Math.ceil((messages?.length || 0) / 5)
  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Drawer open={open} PaperProps={{ sx: { width: '100%' } }}>
      <Box position="fixed" top="8px" right="8px">
        <IconButton onClick={onClose}>
          <CloseMobileIcon />
        </IconButton>
      </Box>
      <Grid columns={4} spacing={2} pl="32px" pr="32px">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box display="flex" flexDirection="column" gap="16px">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              pt="64px"
              pb="24px"
            >
              <Typography>Message Center</Typography>
            </Box>
            {messages?.slice((page - 1) * 5, page * 5).map((message: any) => (
              <HomeMessage
                key={message.updateUuid}
                viewAllMessages
                message={message}
                modal
              />
            ))}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            gap="14px"
            alignContent="center"
            alignSelf="center"
            justifyContent="space-between"
            position="fixed"
            bottom="40px"
          >
            <Pagination
              count={pageCount}
              color="green1"
              defaultPage={0}
              page={page}
              onChange={handlePageChange}
              siblingCount={0}
            />
          </Box>
        </Box>
      </Grid>
    </Drawer>
  )
}

export default HomeMessageCenterMobile
