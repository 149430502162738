import React from 'react'
import FundPerformanceWaterfallDesktop from './FundPerformanceWaterfallDesktop'
import FundPerformanceWaterfallMobile from './FundPerformanceWaterfallMobile'
import FundPerformanceWaterfallTablet from './FundPerformanceWaterfallTablet'
import { FundPerformanceWaterfallContainerProps } from './fundPerformanceWaterFallTypes'
import useFundPerformanceWaterfall from './useFundPerformanceWaterfall'

const FundPerformanceWaterfall = (
  props: FundPerformanceWaterfallContainerProps
) => {
  const { selectedSection, setSelectedSection } = props
  const { isMobile, isTablet, ...otherProps } =
    useFundPerformanceWaterfall(props)

  if (isMobile) {
    return (
      <FundPerformanceWaterfallMobile
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        {...otherProps}
      />
    )
  } else if (isTablet) {
    return (
      <FundPerformanceWaterfallTablet
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        {...otherProps}
      />
    )
  } else {
    return (
      <FundPerformanceWaterfallDesktop
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        {...otherProps}
      />
    )
  }
}

export default FundPerformanceWaterfall
