import React, { useCallback, useEffect, useState } from 'react'
import {
  Modal,
  styled,
  Typography,
  CircularProgress,
  IconButton,
  Drawer,
  Grid,
  Box,
} from '@mui/material'
import { CloseMobileIcon, TrackedLink } from '@flock/shared-ui'
import theme from '@flock/shared-ui/src/theme/theme'
import { sessionStore } from '@flock/utils'
import { useQuery } from '@apollo/client'
import {
  Core_AggregateEquityInfo,
  InvestorDashboardGetEquityInfoTimeseriesForInvestorDocument,
  InvestorDashboardGetEquityInfoTimeseriesForLegalEntityDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import FlexibleDistributionSection from './FlexibleDistributions/FlexibleDistributionsSection'
import BankAccountSection from './BankAccount/BankAccountSection'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import AuditLogModal from './AuditLog/AuditLog'
import AccountSelector from '../AccountSelector'
import PrimaryMailingAddressModal from './PrimaryMailingAddressModal'

const LoadingWrapper = styled('div')({
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%)',
})

const StyledCircularProgress = styled(CircularProgress)({
  color: theme.palette.trustBlue.main,
})

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SettingsGroupHeader = styled(Typography)({
  paddingBottom: '0.4rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const SettingsGroupContainer = styled('div')({
  paddingTop: '0.7rem',
  paddingBottom: '0.7rem',
})

const StyledSettingsItem = styled(Typography)({
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
})

type AllTimeReturns = {
  appreciation: number
  contributions: number
  redemptions: number
  totalEquityValue: number
  netAccountPerformance: number
  joinedDate?: Date
}

type SettingsItemProp = {
  label: string
  value: React.ReactNode
}

const SettingsItem = ({ label, value }: SettingsItemProp) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box>
      <StyledSettingsItem variant="p2">{label}</StyledSettingsItem>
    </Box>
    <Box>
      <StyledSettingsItem variant="p2">{value}</StyledSettingsItem>
    </Box>
  </Box>
)

type SettingsMobileDrawerProps = {
  open: boolean
  onClose: () => void
}

const SettingsMobileDrawer = (props: SettingsMobileDrawerProps) => {
  const { open, onClose } = props
  const {
    legalEntities,
    selectedLegalEntity,
    investorContextLoading,
    investorAccountUuidOverride,
  } = useInvestorAccountContext()
  const [plaidAccountData, setPlaidAccountData] = useState({
    publicToken: '',
    accountIds: [''],
  })
  const [hasLegalEntityBankAccountData, setHasLegalEntityBankAccountData] =
    useState(false)
  const [bankAccountSettingsHidden, setBankAccountSettingsHidden] =
    useState(false)
  const [flexibleDistributionModalOpen, setFlexibleDistributionModalOpen] =
    useState(false)
  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false)
  const [primaryAddressModalOpen, setPrimaryAddressModalOpen] = useState(false)

  const [selectedLegalEntityUuid, setSelectedLegalEntityUuid] =
    useState<string>(selectedLegalEntity?.uuid)
  const [selectedLegalEntityIndex, setSelectedLegalEntityIndex] =
    useState<number>(0)

  const [cashFlowAllotment, setCashFlowAllotment] = useState<number>(0)
  const [cashFlowSelection, setCashFlowSelection] = useState<number>(0)
  const [totalEquity, setTotalEquity] = useState<number | undefined>()
  const [totalEquityValueArray, setTotalEquityValueArray] = useState(
    [] as number[]
  )

  const setSelectedLegalEntityUuidHelper = (uuid: string) => {
    setSelectedLegalEntityUuid(uuid)
  }

  const aggregateSelected = selectedLegalEntityUuid === 'aggregate'

  if (aggregateSelected) {
    setSelectedLegalEntityUuidHelper(legalEntities[1].uuid)
  }
  const computeAllTimeReturns = (timeSeries: Core_AggregateEquityInfo[]) => {
    if (timeSeries?.length > 0) {
      const mostRecentData = timeSeries[timeSeries.length - 1]
      const appreciation =
        (mostRecentData.aggregateGainLoss! +
          mostRecentData.aggregateNetIncome!) /
        100
      const contributions = mostRecentData.aggregateContributions! / 100
      const redemptions = mostRecentData.aggregateRedemptions! / 100
      const totalEquityValueDollars = mostRecentData.aggregateEquityValue! / 100
      const netAccountPerformance =
        (mostRecentData.aggregateGainLoss! +
          mostRecentData.aggregateNetIncome!) /
        100
      const joinedDate = new Date(timeSeries[0].effectiveDateTime) // get first transaction which should be the initial contribution
      return {
        appreciation,
        contributions,
        totalEquityValue: totalEquityValueDollars,
        redemptions,
        netAccountPerformance,
        joinedDate,
      }
    }

    return {
      reinvestedDistributions: 0,
      cashoutDistributions: 0,
      appreciation: 0,
      contributions: 0,
      redemptions: 0,
      totalEquityValue: 0,
      netAccountPerformance: 0,
    } as AllTimeReturns
  }

  const { loading: equityInfoTimeSeriesLoading, data: equityInfoTimeseries } =
    useQuery(InvestorDashboardGetEquityInfoTimeseriesForInvestorDocument, {
      variables: {
        input: {
          investorUuid: investorAccountUuidOverride,
          includeStaged: sessionStore.getItem('simulator') !== null,
        },
      },
      skip: !investorAccountUuidOverride || totalEquity !== undefined,
    })
  const equityTimeseriesArray =
    equityInfoTimeseries?.getEquityInfoTimeseriesForInvestor
      ?.aggregateEquityInfo

  useEffect(() => {
    if (!equityInfoTimeSeriesLoading && equityTimeseriesArray?.length! > 0) {
      const mostRecentData =
        equityTimeseriesArray![equityTimeseriesArray?.length! - 1]
      const totalEquityValue = mostRecentData!.aggregateEquityValue! / 100
      setTotalEquity(totalEquityValue)
    }
  }, [equityInfoTimeSeriesLoading, equityTimeseriesArray])

  const { refetch: refetchEquityInfoTimeseriesForInvestor } = useQuery(
    InvestorDashboardGetEquityInfoTimeseriesForInvestorDocument,
    {
      skip: true,
      notifyOnNetworkStatusChange: true,
    }
  )

  const { refetch: refetchEquityInfoTimeseries } = useQuery(
    InvestorDashboardGetEquityInfoTimeseriesForLegalEntityDocument,
    {
      skip: true,
      notifyOnNetworkStatusChange: true,
    }
  )

  const fetchTimeSeriesInfo = useCallback(async () => {
    if (legalEntities?.length > 1) {
      const timeSeriesQueries: any[] = []
      legalEntities.forEach((legalEntity) => {
        if (legalEntity.uuid === 'aggregate') {
          timeSeriesQueries.push(
            refetchEquityInfoTimeseriesForInvestor({
              input: {
                investorUuid: investorAccountUuidOverride,
                includeStaged: sessionStore.getItem('simulator') !== null,
              },
            })
          )
        } else {
          timeSeriesQueries.push(
            refetchEquityInfoTimeseries({
              input: {
                legalEntityUuid: legalEntity.uuid,
                includeStaged: sessionStore.getItem('simulator') !== null,
              },
            })
          )
        }
      })

      const timeSeriesQueryResults = await Promise.all(timeSeriesQueries)
      const allAllTimeReturnsTemp: AllTimeReturns[] = []
      timeSeriesQueryResults.forEach((r) => {
        let timeSeriesData = r?.data.getEquityInfoTimeseriesForLegalEntity
          ?.aggregateEquityInfo as Core_AggregateEquityInfo[]
        if (!timeSeriesData) {
          timeSeriesData = r?.data.getEquityInfoTimeseriesForInvestor
            ?.aggregateEquityInfo as Core_AggregateEquityInfo[]
        }
        const returns = computeAllTimeReturns(timeSeriesData)
        allAllTimeReturnsTemp.push(returns)
      })

      setTotalEquityValueArray(
        allAllTimeReturnsTemp.map((v) => v.totalEquityValue)
      )
    }
  }, [
    investorAccountUuidOverride,
    legalEntities,
    refetchEquityInfoTimeseries,
    refetchEquityInfoTimeseriesForInvestor,
  ])

  useEffect(() => {
    fetchTimeSeriesInfo()
  }, [fetchTimeSeriesInfo])

  useEffect(() => {
    if (legalEntities) {
      const idx =
        legalEntities.findIndex(
          (legalEntity) => legalEntity.uuid === selectedLegalEntityUuid
        ) || 0
      setSelectedLegalEntityIndex(idx)
      setCashFlowAllotment(legalEntities[idx].cashFlowAllotment || 0)
      setCashFlowSelection(legalEntities[idx].cashFlowSelection || 0)
    }
  }, [selectedLegalEntityUuid, legalEntities])

  if (investorContextLoading) {
    return (
      <Modal open={open}>
        <LoadingWrapper>
          <StyledCircularProgress size="2rem" />
        </LoadingWrapper>
      </Modal>
    )
  }

  return (
    <Drawer
      disableEnforceFocus
      open={open}
      PaperProps={{
        sx: {
          width: '100%',
          display:
            bankAccountSettingsHidden ||
            flexibleDistributionModalOpen ||
            primaryAddressModalOpen
              ? 'none'
              : 'inherit',
        },
      }}
    >
      <Box position="absolute" top="8px" right="8px">
        <IconButton onClick={onClose}>
          <CloseMobileIcon />
        </IconButton>
      </Box>
      <Grid columns={4} spacing={2} pl="32px" pr="32px" pb="32px">
        <Box width="100%" display="flex" flexDirection="column" gap="40px">
          <SettingsGroupHeaderWrapper
            sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
          >
            <Typography variant="h3">
              {`Cash Flow Settings (${legalEntities[selectedLegalEntityIndex]?.name})`}
            </Typography>
          </SettingsGroupHeaderWrapper>
        </Box>
        <AccountSelector
          selectedLegalEntityUuid={selectedLegalEntityUuid}
          includeAggregate={false}
          totalEquityValues={totalEquityValueArray}
          setSelectedLegalEntityUuidHelper={setSelectedLegalEntityUuidHelper}
        />
        <SettingsGroupContainer>
          <SettingsGroupHeaderWrapper>
            <SettingsGroupHeader variant="p1">
              Contact Information
            </SettingsGroupHeader>
            <TrackedLink onClick={() => setPrimaryAddressModalOpen(true)}>
              Edit
            </TrackedLink>
          </SettingsGroupHeaderWrapper>
          <SettingsItem
            label="Mailing Address"
            value={
              legalEntities[selectedLegalEntityIndex]?.address
                ?.formattedAddress || '-'
            }
          />
        </SettingsGroupContainer>
        <BankAccountSection
          selectedLegalEntityUuid={selectedLegalEntityUuid}
          setOuterHidden={setBankAccountSettingsHidden}
          plaidAccountData={plaidAccountData}
          setPlaidAccountData={setPlaidAccountData}
          setHasLegalEntityBankAccountData={setHasLegalEntityBankAccountData}
          showCashDistributionRequirement={false}
        />
        <FlexibleDistributionSection
          cashFlowAllotment={cashFlowAllotment}
          cashFlowSelection={cashFlowSelection}
          totalEquity={totalEquity || 0}
          hasLegalEntityBankAccountData={hasLegalEntityBankAccountData}
          legalEntityUuid={selectedLegalEntityUuid}
          setOuterHidden={setFlexibleDistributionModalOpen}
        />
        <Typography>
          <TrackedLink onClick={() => setAuditLogModalOpen(true)}>
            View Activity Log
          </TrackedLink>
        </Typography>
        <AuditLogModal
          open={auditLogModalOpen}
          entityUuid={selectedLegalEntityUuid}
          onClose={() => setAuditLogModalOpen(false)}
        />
        <PrimaryMailingAddressModal
          open={primaryAddressModalOpen}
          legalEntityUuid={selectedLegalEntityUuid}
          onClose={() => setPrimaryAddressModalOpen(false)}
        />
      </Grid>
    </Drawer>
  )
}

export default SettingsMobileDrawer
