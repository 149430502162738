import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const DocumentsIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M26 3.5H22.625V2.25C22.625 2.1125 22.5125 2 22.375 2H20.625C20.4875 2 20.375 2.1125 20.375 2.25V3.5H15.625V2.25C15.625 2.1125 15.5125 2 15.375 2H13.625C13.4875 2 13.375 2.1125 13.375 2.25V3.5H10C9.44688 3.5 9 3.94687 9 4.5V8.25H6C5.44687 8.25 5 8.69688 5 9.25V29C5 29.5531 5.44687 30 6 30H22C22.5531 30 23 29.5531 23 29V26H26C26.5531 26 27 25.5531 27 25V4.5C27 3.94687 26.5531 3.5 26 3.5ZM20.75 27.75H7.25V10.5H14.0625V15.9375C14.0625 16.6281 14.6219 17.1875 15.3125 17.1875H20.75V27.75ZM20.75 15.1875H16.0625V10.5H16.0688L20.75 15.1812V15.1875ZM24.75 23.75H23V14.25L17 8.25H11.25V5.75H13.375V6.75C13.375 6.8875 13.4875 7 13.625 7H15.375C15.5125 7 15.625 6.8875 15.625 6.75V5.75H20.375V6.75C20.375 6.8875 20.4875 7 20.625 7H22.375C22.5125 7 22.625 6.8875 22.625 6.75V5.75H24.75V23.75Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default DocumentsIcon
