import { Core_GainLossPerMarket } from '@flock/flock-gql-server/src/__generated__/graphql'
import theme from '@flock/shared-ui/src/theme/theme'
import { formatPercentage } from '@flock/utils'
import { useMediaQuery } from '@mui/material'
import { useCallback } from 'react'
import {
  GainLossChartProps,
  UseGainLossChartData,
  MarketData,
} from './gainLossChartTypes'

const useGainLossChart = (props: GainLossChartProps): UseGainLossChartData => {
  const { selectedQuarter, gainLossPerMarket } = props

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  // take gain loss per market data and format it for the chart
  const marketData: MarketData[] | undefined =
    gainLossPerMarket?.gainLossPerMarket
      .filter(
        (row: Core_GainLossPerMarket) => row.quarterString === selectedQuarter
      )
      .map((row: Core_GainLossPerMarket) => {
        const marketDataRow: MarketData = {
          market: row.marketName,
          flockPercentChange: row.flockPercentChange,
          redfinPercentChange: row.redfinPercentChange,
          zillowPercentChange: row.zillowPercentChange,
        }
        return marketDataRow
      })

  const tickFormatterX = useCallback((val: any) => val, [])

  const tickFormatterY = useCallback(
    (val: any) => {
      if (!val) {
        return selectedQuarter
      }
      return `${formatPercentage(val, 2)}`
    },
    [selectedQuarter]
  )

  return {
    marketData,
    tickFormatterX,
    tickFormatterY,
    isTablet,
    isMobile,
  }
}

export default useGainLossChart
