import type {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
} from 'recharts'

const ResponsiveContainerImpl: typeof ResponsiveContainer =
  require('recharts/es6/component/ResponsiveContainer').ResponsiveContainer
const CartesianGridImpl: typeof CartesianGrid =
  require('recharts/es6/cartesian/CartesianGrid').CartesianGrid
const AreaChartImpl: typeof AreaChart =
  require('recharts/es6/chart/AreaChart').AreaChart
const AreaImpl: typeof Area = require('recharts/es6/cartesian/Area').Area
const TooltipImpl: typeof Tooltip =
  require('recharts/es6/component/Tooltip').Tooltip
const XAxisImpl: typeof XAxis = require('recharts/es6/cartesian/XAxis').XAxis
const YAxisImpl: typeof YAxis = require('recharts/es6/cartesian/YAxis').YAxis
const ReferenceLineImpl: typeof ReferenceLine =
  require('recharts/es6/cartesian/ReferenceLine').ReferenceLine

export {
  ResponsiveContainerImpl as ResponsiveContainer,
  AreaChartImpl as AreaChart,
  AreaImpl as Area,
  XAxisImpl as XAxis,
  YAxisImpl as YAxis,
  TooltipImpl as Tooltip,
  CartesianGridImpl as CartesianGrid,
  ReferenceLineImpl as ReferenceLine,
}
