import React from 'react'
import NetIncomeTableDesktopTablet from './NetIncomeTableDesktopTablet'
import NetIncomeTableMobile from './NetIncomeTableMobile'
import { NetIncomeTableProps } from './netIncomeTableTypes'
import useNetIncomeTable from './useNetIncomeTable'

const NetIncomeTable = (props: NetIncomeTableProps) => {
  const { isMobile } = useNetIncomeTable(props)

  return (
    <>
      {isMobile ? (
        <NetIncomeTableMobile {...props} />
      ) : (
        <NetIncomeTableDesktopTablet {...props} />
      )}
    </>
  )
}

export default NetIncomeTable
