// These set up types for segment analytics
/// <reference types="@types/segment-analytics" />

import { UserEventType } from '@flock/utils'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

// If logSegment is true, events are not sent to segment and are
// instead logged to the console.
const logSegment = process.env.GATSBY_LOG_SEGMENT === 'true'

const debugSegment = () =>
  logSegment || window.location.href.includes('localhost')

// @ts-ignore
let shouldNotTrack = false
if (typeof window !== 'undefined' && !debugSegment()) {
  shouldNotTrack =
    window.doNotTrack === '1' ||
    navigator.doNotTrack === 'yes' ||
    navigator.doNotTrack === '1'
}

export const track = (name: string, properties?: any) => {
  if (shouldNotTrack) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Track', name, properties)
  } else {
    window.analytics.track(name, properties)
  }
}

export const trackPage = (name: string, properties: any) => {
  if (shouldNotTrack) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Page', name, properties)
  } else {
    window.analytics.page({
      title: name,
      url: `${window.location.origin}/${name}`,
      path: `/${name}`,
      search: `${window.location.search}`,
    })
  }
  track(name, {
    ...properties,
    actionType: UserEventType.PAGE_VISIT,
  })
}

type IdentifyProperties = {
  userId: string
  type: 'legalEntity' | 'investorAccount'
}

export const identify = (properties: IdentifyProperties) => {
  if (shouldNotTrack) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Identify', properties)
  } else {
    const { userId, ...otherProperties } = properties
    window.analytics.identify(userId, {
      ...otherProperties,
    })
  }
}
