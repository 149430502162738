import { Box, Link, Typography } from '@mui/material'
import React, { useState } from 'react'
import { OpenInNewIcon, ResponsiveContainer } from '@flock/shared-ui'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { GainLossChartProps } from './gainLossChartTypes'
import useGainLossChart from './useGainLossChart'
import GainLossTooltip from './GainLossTooltip'
import GainLossLegend from './GainLossLegend'
import { DETAILED_ASSET_URL, FLOCK_INVESTOR_URL } from '../../../../constants'

const GainLossChartDesktop = (props: GainLossChartProps) => {
  const { marketData, tickFormatterX, tickFormatterY } = useGainLossChart({
    ...props,
  })
  const [showZillow, setShowZillow] = useState(true)

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="4px"
      >
        <Typography variant="p1" color="moneyGreen.main">
          Gain (Loss) on Real Estate
        </Typography>
        <Link
          href={`${FLOCK_INVESTOR_URL}${DETAILED_ASSET_URL}`}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          View valuations per asset&nbsp;
          <OpenInNewIcon color="green4" />
        </Link>
      </Box>
      {marketData && marketData?.length > 0 && (
        <Box
          marginTop="8px"
          width="100%"
          height={{
            xs: 350,
            sm: 452,
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={marketData}
              margin={{
                top: 10,
                right: 25,
                left: 10,
                bottom: 0,
              }}
              barGap={0}
            >
              <CartesianGrid stroke="#f5f5f5" vertical={false} />
              <XAxis
                dataKey="market"
                tickLine={false}
                tickFormatter={tickFormatterX}
              />
              <YAxis
                domain={['auto', 'auto']}
                tickLine={false}
                tickFormatter={tickFormatterY}
              />
              <ReferenceLine y={0} stroke="#555B60" />
              <Tooltip
                content={<GainLossTooltip showZillow={showZillow} />}
                cursor={{ fill: 'transparent' }}
              />
              <Legend
                content={
                  <GainLossLegend
                    showZillow={showZillow}
                    setShowZillow={setShowZillow}
                  />
                }
              />
              <Bar
                name="Flock's Portfolio"
                dataKey="flockPercentChange"
                fill="#03341D"
              />
              {showZillow && (
                <Bar
                  name="Zillow"
                  dataKey="zillowPercentChange"
                  fill="#C9DADB"
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </>
  )
}

export default GainLossChartDesktop
