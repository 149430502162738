import React from 'react'
import { Box, styled } from '@mui/material'
import { Dropdown } from '@flock/shared-ui'
import { useFirebaseAuth } from '@flock/utils'
import { useParams } from '@reach/router'
import { navigate } from 'gatsby'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import { ORDERS_URL } from '../../../constants'

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'end',
})

const OrderSelector = () => {
  const { activeOrderStatuses } = useInvestorAccountContext()
  const { orderUuid } = useParams()
  const { logout } = useFirebaseAuth()
  const dropdownOptions = activeOrderStatuses.map((order) => {
    const addresses = order?.addresses || []
    const additionalAddresses = addresses.length > 1
    return {
      label:
        `${addresses?.[0].formattedAddress} ${
          additionalAddresses ? ` +${addresses.length - 1}` : ''
        }` || '',
      value: order.orderUuid,
    }
  })
  dropdownOptions.push({ label: 'Logout', value: 'logout' })

  const updateURLParams = (newOrderUuid: string) => {
    navigate(`${ORDERS_URL}/${newOrderUuid}`)
  }

  return (
    <Header>
      <Box>
        <Dropdown
          options={dropdownOptions}
          onChange={async (event) => {
            if (event.target.value === 'logout') {
              await logout()
              window.location.href = '/login'
            } else {
              updateURLParams(event.target.value as string)
            }
          }}
          value={orderUuid}
          sx={{
            padding: '12px',
          }}
        />
      </Box>
    </Header>
  )
}

export default OrderSelector
