import {
  FlexibleCashFlowProps,
  FlexibleCashFlowPresentationalProps,
} from './flexibleCashflowCalculatorTypes'

const useAppreciationPotential: (
  props: FlexibleCashFlowProps
) => FlexibleCashFlowPresentationalProps = (props: FlexibleCashFlowProps) => {
  const { totalEquity, cashFlowSelection, isMobile, isTablet } = props

  const startingInvestment = Math.round(totalEquity)

  return {
    ...props,

    cashFlowSelection,

    isMobile,
    isTablet,

    startingInvestment,
  }
}

export default useAppreciationPotential
