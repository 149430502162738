import { MinusIcon, PlusIcon } from '@flock/shared-ui'
import { formatPercentage } from '@flock/utils'
import { Info } from '@mui/icons-material'
import {
  Box,
  Collapse,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { formatDollarsAndCentsInvestor } from '../../../../utils/formatting'
import DistributionYieldTooltip from './DistributionYieldTooltip'
import { HistoricalPerformanceData } from './historicalPerformanceTypes'

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

const HistoricalPerformanceTablet = (props: HistoricalPerformanceData) => {
  const {
    quarterData,
    filteredQuarterData,
    periodSelection,
    hoveredQuarterLabel,
  } = props
  const hovered =
    hoveredQuarterLabel === undefined ? 'none' : hoveredQuarterLabel
  const ref = React.useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isQuarterlyPercentages, setIsQuarterlyPercentages] =
    useState<boolean>(true)
  useEffect(() => {
    if (periodSelection === 'ALL' && ref.current) {
      ref.current.scrollLeft = ref.current.scrollWidth
    }
  }, [periodSelection])

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="gray1.main"
      marginRight="-6px"
      p="16px"
      borderRadius="8px"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          '&:hover': {
            pointerEvents: 'all',
            cursor: 'pointer',
          },
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography variant="cta2" color="gray8.main">
          Historical Performance
        </Typography>
        <Box width="24px" height="24px" borderRadius="8px">
          {isOpen ? (
            <MinusIcon height="24px" width="24px" />
          ) : (
            <PlusIcon height="24px" width="24px" />
          )}
        </Box>
      </Box>
      <Collapse in={isOpen}>
        <TableContainer
          sx={{
            overflowX: 'auto',
          }}
          ref={ref}
        >
          <Table stickyHeader>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    m: '0 16px 16px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  &nbsp;
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {quarterString}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const quarterLabel = quarter.quarterString
                      return (
                        <TableCell
                          key={quarterLabel}
                          sx={{
                            border: 0,
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterLabel
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {quarterLabel}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    p: '8px 16px 8px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="p3"
                      marginRight="6px"
                      color="gray8.main"
                    >
                      Starting account value
                    </Typography>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString, startingAccountValue } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {startingAccountValue
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(startingAccountValue / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString, startingAccountValue } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {startingAccountValue
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(startingAccountValue / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    p: '8px 16px 8px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="p3"
                      marginRight="6px"
                      color="gray8.main"
                    >
                      Contributions
                    </Typography>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString, contributions } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {contributions
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(contributions / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString, contributions } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {contributions
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(contributions / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    p: '8px 16px 8px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="p3"
                      marginRight="6px"
                      color="gray8.main"
                    >
                      Fund value change
                    </Typography>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString, netIncome, gainLoss } = quarter
                      const fundValueChange = netIncome + gainLoss
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {fundValueChange
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(fundValueChange / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString, netIncome, gainLoss } = quarter
                      const fundValueChange = netIncome + gainLoss
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {fundValueChange
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(fundValueChange / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    p: '8px 16px 8px 16px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="p3"
                      marginRight="6px"
                      color="gray8.main"
                      fontWeight="300"
                    >
                      Real estate valuation gain/loss
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography
                          variant="p3"
                          marginRight="6px"
                          color="gray8.main"
                        >
                          Portfolio homes are valued quarterly. Just like
                          changes in real estate prices impact your investment
                          value outside of Flock, changes in real estate prices
                          impact Fund performance.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="fundValueTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString, gainLoss } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography
                              variant="p3"
                              fontWeight="300"
                              marginRight="6px"
                            >
                              {gainLoss
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(gainLoss / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString, gainLoss } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography
                              variant="p3"
                              fontWeight="300"
                              marginRight="6px"
                            >
                              {gainLoss
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(gainLoss / 100),
                                    true,
                                    false
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    p: '8px 16px 8px 16px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="p3"
                      marginRight="6px"
                      color="gray8.main"
                      fontWeight="300"
                    >
                      Net income
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography
                          variant="p3"
                          marginRight="6px"
                          color="gray8.main"
                        >
                          Every client receives their pro-rata share of Fund net
                          income quarterly. This is calculated as total rents
                          and other income, minus expenses. It includes non-cash
                          items like loan cost amortization, which may cause
                          quarterly net income to differ from quarterly Funds
                          from Operations.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="fundValueTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString, netIncome } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography
                              variant="p3"
                              fontWeight="300"
                              marginRight="6px"
                            >
                              {netIncome
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(netIncome / 100),
                                    true,
                                    false
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString, netIncome } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography
                              variant="p3"
                              fontWeight="300"
                              marginRight="6px"
                            >
                              {netIncome
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(netIncome / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              {/* empty line row */}
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '320px',
                    p: '0px 0px 0px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <hr style={{ width: '100%', color: 'gray3.main' }} />
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            p: '0px 0px 0px 0px',
                            border: 0,
                            width: '170px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <hr
                              style={{ width: '100%', color: 'gray3.main' }}
                            />
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            p: '0px 0px 0px 0px',
                            border: 0,
                            width: '170px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <hr
                              style={{ width: '100%', color: 'gray3.main' }}
                            />
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    p: '8px 16px 8px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="p3"
                      fontWeight="500"
                      color="moneyGreen.main"
                    >
                      Ending account value
                    </Typography>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString, endingAccountValue } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {endingAccountValue
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(endingAccountValue / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString, endingAccountValue } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography
                              variant="p3"
                              marginRight="6px"
                              fontWeight="500"
                            >
                              {endingAccountValue
                                ? formatDollarsAndCentsInvestor(
                                    Math.round(endingAccountValue / 100)
                                  )
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              {/* empty line row */}
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '320px',
                    p: '0px 0px 0px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <hr style={{ width: '100%', color: 'gray3.main' }} />
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            p: '0px 0px 0px 0px',
                            border: 0,
                            width: '170px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <hr
                              style={{ width: '100%', color: 'gray3.main' }}
                            />
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            p: '0px 0px 0px 0px',
                            border: 0,
                            width: '170px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <hr
                              style={{ width: '100%', color: 'gray3.main' }}
                            />
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    p: '8px 16px 8px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="p3"
                      fontWeight="500"
                      color="moneyGreen.main"
                    >
                      Cash taken
                    </Typography>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString, redemptions } = quarter
                      const cashDistributionsReceivedString = redemptions
                        ? formatDollarsAndCentsInvestor(
                            Math.round(redemptions / 100)
                          )
                        : formatDollarsAndCentsInvestor(0)
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {quarterString
                                ? cashDistributionsReceivedString
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString, redemptions } = quarter
                      const cashDistributionsReceivedString = redemptions
                        ? formatDollarsAndCentsInvestor(
                            Math.round(redemptions / 100)
                          )
                        : formatDollarsAndCentsInvestor(0)
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography
                              variant="p3"
                              marginRight="6px"
                              fontWeight="500"
                            >
                              {quarterString
                                ? cashDistributionsReceivedString
                                : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    p: '8px 16px 8px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="p3"
                      fontWeight="500"
                      color="gray5.main"
                    >
                      Taxes paid by Flock
                    </Typography>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString, taxFees } = quarter
                      const taxFeesString = taxFees
                        ? formatDollarsAndCentsInvestor(
                            Math.round(taxFees / 100)
                          )
                        : formatDollarsAndCentsInvestor(0)
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography
                              variant="p3"
                              marginRight="6px"
                              color="gray5.main"
                            >
                              {quarterString ? taxFeesString : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString, taxFees } = quarter
                      const taxFeesString = taxFees
                        ? formatDollarsAndCentsInvestor(
                            Math.round(taxFees / 100)
                          )
                        : formatDollarsAndCentsInvestor(0)
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography
                              variant="p3"
                              marginRight="6px"
                              fontWeight="500"
                              color="gray5.main"
                            >
                              {quarterString ? taxFeesString : '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    position: 'sticky',
                    p: '8px 16px 8px 0px',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="p3"
                      marginRight="6px"
                      color="moneyGreen.main"
                    >
                      Cash yield (annualized)
                    </Typography>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const {
                        quarterString,
                        cashYield,
                        redemptions,
                        hasContributionInQuarter,
                      } = quarter
                      const shouldRenderDistributionYieldTooltip =
                        hasContributionInQuarter && cashYield

                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {cashYield && redemptions > 0
                                ? formatPercentage(cashYield, 1)
                                : 'n/a'}
                            </Typography>
                            {shouldRenderDistributionYieldTooltip ? (
                              <DistributionYieldTooltip />
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const {
                        quarterString,
                        cashYield,
                        redemptions,
                        hasContributionInQuarter,
                      } = quarter
                      const shouldRenderDistributionYieldTooltip =
                        hasContributionInQuarter && cashYield

                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {cashYield && redemptions > 0
                                ? formatPercentage(cashYield, 1)
                                : 'n/a'}
                            </Typography>
                            {shouldRenderDistributionYieldTooltip ? (
                              <DistributionYieldTooltip />
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              {/* empty line row */}
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '320px',
                    p: '0px 0px 0px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <hr style={{ width: '100%', color: 'gray3.main' }} />
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            p: '0px 0px 0px 0px',
                            border: 0,
                            width: '170px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <hr
                              style={{ width: '100%', color: 'gray3.main' }}
                            />
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            p: '0px 0px 0px 0px',
                            border: 0,
                            width: '170px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <hr
                              style={{ width: '100%', color: 'gray3.main' }}
                            />
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    p: '8px 16px 8px 0px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="8px"
                    alignItems="center"
                  >
                    <Typography
                      variant="c1"
                      fontWeight="500"
                      color={
                        isQuarterlyPercentages
                          ? 'moneyGreen.main'
                          : 'gray5.main'
                      }
                    >
                      QUARTERLY
                    </Typography>
                    <Switch
                      checked={!isQuarterlyPercentages}
                      onChange={() =>
                        setIsQuarterlyPercentages(!isQuarterlyPercentages)
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography
                      variant="c1"
                      fontWeight="500"
                      color={
                        isQuarterlyPercentages
                          ? 'gray5.main'
                          : 'moneyGreen.main'
                      }
                    >
                      ANNUALIZED
                    </Typography>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          &nbsp;
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString } = quarter
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            &nbsp;
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: 0,
                    minWidth: '256px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    bgcolor: 'gray1.main',
                    p: '8px 16px 8px 0px',
                  }}
                  variant="head"
                  scope="col"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="p3"
                      marginRight="6px"
                      color="moneyGreen.main"
                    >
                      Return on equity
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography
                          variant="p3"
                          marginRight="6px"
                          color="gray8.main"
                        >
                          This is the total return on your equity invested at
                          the start of the quarter; this figure is calculated
                          before cash is taken (if applicable).
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="fundValueTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                </TableCell>
                {periodSelection === 'ALL'
                  ? quarterData.map((quarter) => {
                      const { quarterString, quarterReturn } = quarter
                      let returnString = '-'
                      if (quarterReturn) {
                        returnString = isQuarterlyPercentages
                          ? formatPercentage(quarterReturn - 1, 1)
                          : formatPercentage((quarterReturn - 1) * 4, 1)
                      }
                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {returnString}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })
                  : filteredQuarterData.map((quarter) => {
                      const { quarterString, quarterReturn } = quarter

                      let returnString = '-'
                      if (quarterReturn) {
                        returnString = isQuarterlyPercentages
                          ? formatPercentage(quarterReturn - 1, 1)
                          : formatPercentage((quarterReturn - 1) * 4, 1)
                      }

                      return (
                        <TableCell
                          key={quarterString}
                          sx={{
                            border: 0,
                            p: '0px 2px 0px 2px',
                            minWidth: '144px',
                            backgroundColor:
                              hovered === quarterString
                                ? 'rgba(225, 238, 239, .5)'
                                : '',
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Typography variant="p3" marginRight="6px">
                              {returnString}
                            </Typography>
                          </Box>
                        </TableCell>
                      )
                    })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Box>
  )
}

export default HistoricalPerformanceTablet
