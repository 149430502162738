import React, { useCallback, useEffect, useState } from 'react'
import { LoadingCard } from '@flock/flock-component-library'
import { loadParallel } from '@parallelmarkets/vanilla'
import {
  ParallelProvider,
  useParallel,
  PassportButton,
} from '@parallelmarkets/react'
import { useMutation } from '@apollo/client'
import * as Sentry from '@sentry/gatsby'
import {
  Core_OrderV3TaskStatus,
  InvestorCreateParallelMarketsTokensDocument,
  InvestorGetOrderV3Document,
  InvestorGetOrderV3SubscriptionDocumentSigningUrlDocument,
  InvestorUpdateOrderV3TaskDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  GATSBY_ENV,
  GATSBY_PARALLEL_MARKETS_CLIENT_ID,
} from '../../../../constants'

type ParallelMarketsButtonProps = {
  allowUserToContinue: () => void
  setOuterError: () => void
  setOuterLoading: (loading: boolean) => void
  taskUuid: string
  orderUuid: string
  legalEntityUuid: string
}

const LoginArea = ({
  allowUserToContinue,
  setOuterError,
  setOuterLoading,
  taskUuid,
  orderUuid,
  legalEntityUuid,
}: ParallelMarketsButtonProps) => {
  const { loginStatus, getProfile } = useParallel()
  const [createParallelMarketsTokens] = useMutation(
    InvestorCreateParallelMarketsTokensDocument
  )
  const [updateTask] = useMutation(InvestorUpdateOrderV3TaskDocument)
  const [profileInfo, setProfileInfo] = useState<{ id: string }>()

  useEffect(() => {
    // If the user is logged in, fetch profile info and set to state
    if (
      loginStatus?.status === 'connected' &&
      loginStatus?.authResponse?.access_token !== null
    ) {
      getProfile().then(setProfileInfo)
    }
  }, [loginStatus, getProfile])

  const handleCompletion = useCallback(async () => {
    // If the profile info is set, save token and complete task
    setOuterLoading(true)
    const parallelMarketsId = profileInfo?.id
    const selectedLegalEntityUuid = legalEntityUuid

    // Convert expiration seconds to expiry datetimes with a 1 minute buffer
    const currentTime = new Date()
    const tokenExpirationMs =
      currentTime.getTime() +
      loginStatus.authResponse?.expires_in * 1000 -
      60000
    const accessTokenExpiresAt = new Date(tokenExpirationMs).toISOString()
    const refreshExpirationMs =
      currentTime.getTime() +
      loginStatus.authResponse?.refresh_expires_in * 1000 -
      60000
    const refreshExpiresAt = new Date(refreshExpirationMs).toISOString()
    const accessToken = loginStatus.authResponse?.access_token
    const refreshToken = loginStatus.authResponse?.refresh_token

    try {
      await createParallelMarketsTokens({
        variables: {
          createParallelMarketsTokensInput: {
            legalEntityUuid: selectedLegalEntityUuid,
            accessToken,
            accessTokenExpiresAt,
            refreshToken,
            refreshExpiresAt,
            parallelMarketsId,
          },
        },
      })
      await updateTask({
        variables: {
          input: {
            orderUuid,
            taskData: {
              taskUuid,
              status: Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted,
            },
          },
        },
        refetchQueries: [
          InvestorGetOrderV3Document,
          InvestorGetOrderV3SubscriptionDocumentSigningUrlDocument,
        ],
      })
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          selectedLegalEntityUuid,
          parallelMarketsId,
        },
      })
      setOuterError()
    }

    setOuterLoading(false)
    allowUserToContinue()
  }, [
    allowUserToContinue,
    createParallelMarketsTokens,
    loginStatus,
    orderUuid,
    profileInfo,
    setOuterError,
    setOuterLoading,
    taskUuid,
    updateTask,
    legalEntityUuid,
  ])

  useEffect(() => {
    if (profileInfo) {
      handleCompletion()
    }
  }, [profileInfo, handleCompletion])

  if (!loginStatus) return <LoadingCard text="Loading Parallel Markets..." />
  if (loginStatus.error)
    return <LoadingCard text="Error Occurred, Please Refresh Page" hideLoader />

  if (loginStatus.status !== 'connected') {
    return <PassportButton />
  } else if (
    loginStatus.status === 'connected' &&
    loginStatus.authResponse?.access_token !== null
  ) {
    return null
  } else {
    setOuterError()
    return null
  }
}

// start loading the parallel library with the given configuration information
const parallel = loadParallel({
  client_id: GATSBY_PARALLEL_MARKETS_CLIENT_ID,
  scopes: ['profile', 'identity', 'accreditation_status'],
  environment: GATSBY_ENV === 'production' ? 'production' : 'demo',
  show_dismiss_button: 'true',
})

const ParallelMarketsButton = ({
  allowUserToContinue,
  setOuterError,
  setOuterLoading,
  taskUuid,
  orderUuid,
  legalEntityUuid,
}: ParallelMarketsButtonProps) => (
  <ParallelProvider parallel={parallel}>
    <LoginArea
      allowUserToContinue={allowUserToContinue}
      setOuterError={setOuterError}
      setOuterLoading={setOuterLoading}
      taskUuid={taskUuid}
      orderUuid={orderUuid}
      legalEntityUuid={legalEntityUuid}
    />
  </ParallelProvider>
)

export default ParallelMarketsButton
