export const formatNumberWithCommas = (value: number, decimals: number = 0) =>
  value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const formatIntegerDollars = (
  dollars: number,
  dollarSign: boolean = true
) => {
  const formattedWithCommas = formatNumberWithCommas(dollars)
  if (dollarSign && dollars < 0) {
    return `-$${formattedWithCommas.substring(1)}`
  } else if (dollarSign) {
    return `$${formattedWithCommas}`
  }

  return formattedWithCommas
}

export const formatDollarsAndCentsInvestor = (
  dollarsAndCents: number,
  dollarSign: boolean = true,
  useNegativeSign: boolean = false // use negative sign instead of parenthesis
) => {
  const formattedWithCommas = formatNumberWithCommas(dollarsAndCents, 0)

  if (dollarSign) {
    if (dollarsAndCents < 0) {
      if (useNegativeSign) {
        return `-$${formattedWithCommas.substring(1)}`
      }
      return `($${formattedWithCommas.substring(1)})`
    }
    return `$${formattedWithCommas}`
  } else if (dollarsAndCents < 0) {
    if (useNegativeSign) {
      return `-${formattedWithCommas.substring(1)}`
    }
    return `(${formattedWithCommas.substring(1)})`
  }

  return formattedWithCommas
}
