import { TrackingContextProvider } from '@flock/shared-ui'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

type SectionLayoutProps = {
  name: string
  id: string
  columns?: number
  children: React.ReactNode
}

const SectionLayout = (props: SectionLayoutProps) => {
  const { name, columns, id, children } = props
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <TrackingContextProvider name={name}>
      <Grid item xs={12}>
        <Grid
          id={id}
          container
          columns={columns || 9}
          spacing={isMobile ? 2 : 4}
        >
          {children}
        </Grid>
      </Grid>
    </TrackingContextProvider>
  )
}

export default SectionLayout

SectionLayout.defaultProps = {
  columns: 9,
}
