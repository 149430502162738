import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  NetIncomeTableColumn,
  NetIncomeTableData,
  NetIncomeTableProps,
} from './netIncomeTableTypes'

const useNetIncomeTable = (props: NetIncomeTableProps): NetIncomeTableData => {
  const { quarters, fundPerformanceWaterfallData, selectedQuarter } = props

  const [columns, setColumns] = useState<NetIncomeTableColumn[]>([])

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  useEffect(() => {
    const tempColumns: NetIncomeTableColumn[] = []
    quarters.forEach((quarter) => {
      const tempColumn: NetIncomeTableColumn = {} as NetIncomeTableColumn
      const currentQuarterData =
        fundPerformanceWaterfallData?.fundsFromOperationsRows?.filter(
          (row) => row.quarterString === quarter
        )

      const totalPortfolioNetIncome =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Total portfolio net income'
        )?.amountCents || 0) / 100

      const totalPortfolioIncome =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Total portfolio income'
        )?.amountCents || 0) / 100

      const totalPortfolioExpenses =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Total portfolio expenses'
        )?.amountCents || 0) / 100

      const totalRentalIncome =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Total rental income'
        )?.amountCents || 0) / 100

      const totalOtherIncome =
        (currentQuarterData?.find((row) => row.rowName === 'Total other income')
          ?.amountCents || 0) / 100

      const totalOperatingExpenses =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Total operating expense'
        )?.amountCents || 0) / 100

      const totalAdministrativeFees =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Total administrative expense'
        )?.amountCents || 0) / 100

      const totalInterestExpense =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Total interest expense'
        )?.amountCents || 0) / 100

      const loanFees =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Loan fees amortization'
        )?.amountCents || 0) / 100

      const leasingAmortization =
        (currentQuarterData?.find(
          (row) =>
            row.rowName ===
            'Leasing commission amortization - rogers leasing fee'
        )?.amountCents || 0) / 100

      const leasingFeeAmortization =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Leasing fee amortization'
        )?.amountCents || 0) / 100

      const earlyRedemptionFees =
        (currentQuarterData?.find(
          (row) => row.rowName === 'Early redemption fees'
        )?.amountCents || 0) / 100

      const processingFees =
        (currentQuarterData?.find((row) => row.rowName === 'LP processing fees')
          ?.amountCents || 0) / 100

      tempColumn.quarterString = quarter
      tempColumn.totalPortfolioIncome = totalPortfolioIncome
      tempColumn.totalPortfolioExpenses = totalPortfolioExpenses
      tempColumn.totalPortfolioNetIncome = totalPortfolioNetIncome
      tempColumn.totalRentalIncome = totalRentalIncome
      tempColumn.totalOtherIncome = totalOtherIncome
      tempColumn.totalOperatingExpenses = totalOperatingExpenses
      tempColumn.totalAdministrativeFees = totalAdministrativeFees
      tempColumn.totalInterestExpense = totalInterestExpense
      tempColumn.loanFees = loanFees
      tempColumn.nonCashItems =
        loanFees +
        leasingFeeAmortization +
        leasingAmortization -
        processingFees -
        earlyRedemptionFees
      tempColumn.fundsFromOperations =
        totalPortfolioNetIncome +
        loanFees +
        leasingFeeAmortization +
        leasingAmortization -
        processingFees -
        earlyRedemptionFees
      tempColumns.push(tempColumn)
    })

    if (isMobile) {
      const currQuarterColumn = tempColumns.find(
        (column) => column.quarterString === selectedQuarter
      )
      setColumns([currQuarterColumn as NetIncomeTableColumn])
    } else {
      setColumns(tempColumns)
    }
  }, [fundPerformanceWaterfallData, quarters, isMobile, selectedQuarter])

  return { columns, isTablet, isMobile }
}

export default useNetIncomeTable
