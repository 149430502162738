import {
  CaretIcon,
  ChatFilledIcon,
  Navbar,
  NavLink,
  SettingOutlinedIcon,
} from '@flock/shared-ui'
import theme from '@flock/shared-ui/src/theme/theme'
import { useFirebaseAuth } from '@flock/utils'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { Collapse, MenuItem, Typography, SvgIconProps } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { ACCOUNT_URL } from '../../constants'
import InvestorAccountSettingsMobileDrawer from './Settings/InvestorAccountSettingsMobileDrawer'
import SettingsMobileDrawer from './Settings/SettingsMobileDrawer'

type SidebarItem = {
  title: string
  unselectedIcon: React.FC<SvgIconProps>
  selectedIcon: React.FC<SvgIconProps>
}

const HeaderSectionMobile = (props: {
  sidebarItems: SidebarItem[]
  selectedSection: number
  setMessageCenterOpen: (open: boolean) => void
  onChangeSidebar: (newTab: number) => void
}) => {
  const {
    sidebarItems,
    selectedSection,
    setMessageCenterOpen,
    onChangeSidebar,
  } = props
  const [openSettings, setOpenSettings] = useState(false)
  const [
    investorAccountSettingsModalOpen,
    setInvestorAccountSettingsModalOpen,
  ] = useState(false)
  const [legalEntitySettingsModalOpen, setLegalEntitySettingsModalOpen] =
    useState(false)
  const { logout } = useFirebaseAuth()

  return (
    <>
      <Box
        position="sticky"
        left="18px"
        top="16px"
        zIndex={1200}
        onClick={() => {
          setMessageCenterOpen(true)
        }}
      >
        <Box position="absolute" left="18px">
          <ChatFilledIcon width="28px" height="28px" />
        </Box>
      </Box>
      <Navbar
        headerBackground="rgba(235, 241, 241, 1)"
        headerChange="green1.main"
        logoPath={ACCOUNT_URL}
        navItems={[]}
        drawerProps={{
          PaperProps: {
            sx: {
              width: '100vw',
              height: '100%',
              backgroundColor: 'trustBlue.main',
            },
          },
        }}
        mobileNavItemsFunction={(setMobileNavOpen: (open: boolean) => void) => {
          const stuff = sidebarItems?.map((item, idx) => {
            const {
              title,
              unselectedIcon: UnselectedIcon,
              selectedIcon: SelectedIcon,
            } = item
            const onClick = () => {
              onChangeSidebar(idx)
              setMobileNavOpen(false)
            }
            return (
              <>
                <NavLink onClick={onClick}>
                  <Box
                    display="flex"
                    alignContent="flex-start"
                    pl="0px"
                    gap="16px"
                  >
                    <Box>
                      {idx === selectedSection ? (
                        <SelectedIcon width="32px" height="32px" />
                      ) : (
                        <UnselectedIcon width="32px" height="32px" />
                      )}
                    </Box>
                    <Typography
                      variant="h3"
                      textAlign="left"
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          fontSize: '24px',
                          lineHeight: '32px',
                          fontWeight: 500,
                        },
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>
                </NavLink>
              </>
            )
          })
          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              height="100%"
              padding="32px 0px"
            >
              <Box display="flex" flexDirection="column" gap="16px">
                {stuff}
                <NavLink onClick={() => setOpenSettings(!openSettings)}>
                  <Box
                    display="flex"
                    alignContent="flex-start"
                    pl="0px"
                    gap="16px"
                  >
                    <Box>
                      <SettingOutlinedIcon width="32px" height="32px" />
                    </Box>
                    <Typography
                      variant="h3"
                      textAlign="left"
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          fontSize: '24px',
                          lineHeight: '32px',
                          fontWeight: 500,
                        },
                      }}
                    >
                      Settings
                    </Typography>
                    <Box>
                      <CaretIcon width="32px" height="32px" />
                    </Box>
                  </Box>
                </NavLink>
                <Collapse
                  in={openSettings}
                  timeout="auto"
                  unmountOnExit
                  sx={{ border: 0 }}
                >
                  <Box display="flex" flexDirection="column" alignItems="left">
                    <MenuItem
                      onClick={() => setInvestorAccountSettingsModalOpen(true)}
                    >
                      <AccountBoxIcon sx={{ paddingRight: '.5rem' }} />
                      <Typography>Investor</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => setLegalEntitySettingsModalOpen(true)}
                    >
                      <AccountBalanceIcon sx={{ paddingRight: '.5rem' }} />
                      <Typography>Cash Flow</Typography>
                    </MenuItem>
                  </Box>
                </Collapse>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <NavLink
                  onClick={async () => {
                    await logout()
                    window.location.href = '/login'
                  }}
                >
                  <Typography
                    variant="h3"
                    textAlign="left"
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        fontSize: '24px',
                        lineHeight: '32px',
                        fontWeight: 500,
                      },
                    }}
                  >
                    Log Out
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          )
        }}
        actions={[]}
      />
      <InvestorAccountSettingsMobileDrawer
        open={investorAccountSettingsModalOpen}
        onClose={() => setInvestorAccountSettingsModalOpen(false)}
      />
      <SettingsMobileDrawer
        open={legalEntitySettingsModalOpen}
        onClose={() => setLegalEntitySettingsModalOpen(false)}
      />
    </>
  )
}

export default HeaderSectionMobile
