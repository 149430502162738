import React, { useState } from 'react'
import {
  Modal,
  styled,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import theme from '@flock/shared-ui/src/theme/theme'
import { TrackedLink } from '@flock/shared-ui'
import ContactInfoSection from './ContactInfo/ContactInfoSection'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import AuditLogModal from './AuditLog/AuditLog'

const LoadingWrapper = styled('div')({
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%)',
})

const StyledCircularProgress = styled(CircularProgress)({
  color: theme.palette.trustBlue.main,
})

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '544px',
  minHeight: '200px',
  maxHeight: '75vh',
  overflowY: 'auto',
  padding: '48px',
  borderRadius: '16px',
})

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

type InvestorAccountSettingsModalProps = {
  open: boolean
  onClose: () => void
}

const InvestorAccountSettingsModal = (
  props: InvestorAccountSettingsModalProps
) => {
  const { open, onClose } = props
  const { investorContextLoading, investorAccountUuidOverride } =
    useInvestorAccountContext()
  const [contactInfoModalOpen, setContactInfoModalOpen] = useState(false)
  const [auditLogModalOpen, setAuditLogModalOpen] = useState(false)

  const anyLoading = investorContextLoading

  if (anyLoading) {
    return (
      <Modal open={open}>
        <LoadingWrapper>
          <StyledCircularProgress size="2rem" />
        </LoadingWrapper>
      </Modal>
    )
  }
  return (
    <Modal open={open}>
      <ModalWrapper
        sx={{
          display: contactInfoModalOpen ? 'none' : 'inherit',
        }}
      >
        <SettingsGroupHeaderWrapper>
          <Typography variant="h3">Investor Settings</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </SettingsGroupHeaderWrapper>
        <ContactInfoSection setOuterHidden={setContactInfoModalOpen} />
        <Typography variant="p3" color="gray6.main">
          To change bank account and and distributions, go to Cash Flow
          Settings, and select the entity you want to change.
        </Typography>
        <br />
        <br />
        <Typography>
          <TrackedLink onClick={() => setAuditLogModalOpen(true)}>
            View Activity Log
          </TrackedLink>
        </Typography>
        <AuditLogModal
          open={auditLogModalOpen}
          entityUuid={investorAccountUuidOverride}
          entityIsInvestor
          onClose={() => setAuditLogModalOpen(false)}
        />
      </ModalWrapper>
    </Modal>
  )
}

export default InvestorAccountSettingsModal
