import theme from '@flock/shared-ui/src/theme/theme'
import { Box, Typography } from '@mui/material'
import React from 'react'
import {
  GAIN_LOSS_SECTION,
  NET_PERFORMANCE_SECTION,
} from '../useFundPerformanceSection'
import { FundPerformanceWaterfallProps } from './fundPerformanceWaterFallTypes'

const FundPerformanceWaterfallTablet = (
  props: FundPerformanceWaterfallProps
) => {
  const {
    chartAxisStrings,

    gainLossOnRealEstateString,
    netPortfolioIncomeString,
    fundValueChangeString,

    gainLossOnRealEstateIsPositive,
    gainLossOnRealEstateTopPixel,
    netPortfolioIncomeTopPixel,

    fundValueChangeTopPixel,
    netPortfolioIncomePixelHeight,
    gainLossOnRealEstatePixelHeight,

    fundValueChangePixelHeight,

    selectedSection,
    setSelectedSection,
  } = props

  const START_LEFT_PIXEL = 120
  const BAR_WIDTH = 88
  const LINE_WIDTH = 56

  const gainLossActive = selectedSection === GAIN_LOSS_SECTION
  const gainLossColor = gainLossOnRealEstateIsPositive
    ? 'green4.main'
    : 'errorRed.main'
  const portfolioActive = selectedSection === NET_PERFORMANCE_SECTION

  return (
    <>
      <Box
        width="100%"
        height="100px"
        sx={{ overflowX: 'auto' }}
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="row"
          marginBottom="8px"
          flexWrap="wrap"
          height="100px"
          width="100%"
          position="absolute"
          sx={{ pointerEvents: 'none' }}
          zIndex={3}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="10%"
            alignItems="end"
          >
            <Typography variant="p3" fontWeight={300}>
              {chartAxisStrings[0]}
            </Typography>
            <Typography variant="p3" fontWeight={300}>
              {chartAxisStrings[1]}
            </Typography>
            <Typography variant="p3" fontWeight={300}>
              {chartAxisStrings[2]}
            </Typography>
          </Box>
          <Box
            width="89%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            zIndex={3}
          >
            <hr style={{ width: '100%', color: 'blue' }} />
            <hr style={{ width: '100%', color: 'gray5.main' }} />
            <hr style={{ width: '100%', color: 'gray2.main' }} />
          </Box>
        </Box>

        <Box
          width="100%"
          height="100px"
          position="absolute"
          zIndex={3}
          sx={{ pointerEvents: 'none' }}
        >
          <Box
            width={BAR_WIDTH}
            height={gainLossOnRealEstatePixelHeight}
            bgcolor={gainLossActive ? gainLossColor : 'gray5.main'}
            position="absolute"
            left={START_LEFT_PIXEL}
            top={gainLossOnRealEstateTopPixel}
            zIndex={4}
          />
          <Box
            width={LINE_WIDTH}
            position="absolute"
            left={START_LEFT_PIXEL + BAR_WIDTH}
            top={netPortfolioIncomeTopPixel + netPortfolioIncomePixelHeight}
          >
            <hr
              style={{
                width: '100%',
                color: 'gray2.main',
                borderBottom: '1px dashed',
                borderTop: 'none',
                margin: 0,
              }}
            />
          </Box>
          <Box
            width={BAR_WIDTH}
            height={netPortfolioIncomePixelHeight}
            bgcolor={portfolioActive ? 'green4.main' : 'gray5.main'}
            position="absolute"
            left={START_LEFT_PIXEL + BAR_WIDTH + LINE_WIDTH}
            top={netPortfolioIncomeTopPixel}
            zIndex={4}
          />

          <Box
            width={LINE_WIDTH}
            position="absolute"
            left={START_LEFT_PIXEL + BAR_WIDTH + LINE_WIDTH + BAR_WIDTH}
            top={netPortfolioIncomeTopPixel}
          >
            <hr
              style={{
                width: '100%',
                color: 'gray2.main',
                borderBottom: '1px dashed',
                borderTop: 'none',
                margin: 0,
              }}
            />
          </Box>
          <Box
            width={BAR_WIDTH}
            height={fundValueChangePixelHeight}
            bgcolor="moneyGreen.main"
            position="absolute"
            left={
              START_LEFT_PIXEL + BAR_WIDTH + LINE_WIDTH + BAR_WIDTH + LINE_WIDTH
            }
            top={fundValueChangeTopPixel}
            zIndex={4}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        pt="16px"
        pl="16px"
        gap="8px"
        zIndex={3}
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          color={gainLossActive ? gainLossColor : 'gray5.main'}
        >
          <Typography variant="p4">
            Gain &#40;loss&#41; on real estate
          </Typography>
          <Typography variant="p4">{gainLossOnRealEstateString}</Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          color={portfolioActive ? 'green4.main' : 'gray5.main'}
        >
          <Typography variant="p4">Portfolio net income</Typography>
          <Typography variant="p4">{netPortfolioIncomeString}</Typography>
        </Box>
        <hr
          style={{
            width: '100%',
            color: 'gray2.main',
            margin: 0,
          }}
        />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          color="moneyGreen.main"
        >
          <Typography variant="p4">Fund value change</Typography>
          <Typography variant="p4">{fundValueChangeString}</Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        pt="16px"
        gap="16px"
        zIndex={3}
        position="relative"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          px="8px"
          py="13px"
          width="50%"
          alignItems="center"
          bgcolor={
            selectedSection === GAIN_LOSS_SECTION
              ? 'green2.main'
              : 'green1.main'
          }
          borderRadius="24px"
          onClick={() => setSelectedSection(GAIN_LOSS_SECTION)}
        >
          <Typography variant="c1" fontSize="14px">
            Gain &#40;loss&#41; on RE
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          px="8px"
          py="13px"
          width="50%"
          alignItems="center"
          bgcolor={
            selectedSection === NET_PERFORMANCE_SECTION
              ? 'green2.main'
              : 'green1.main'
          }
          borderRadius="24px"
          onClick={() => setSelectedSection(NET_PERFORMANCE_SECTION)}
        >
          <Typography
            variant="c1"
            sx={{
              [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
              },
            }}
          >
            Net income
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default FundPerformanceWaterfallTablet
