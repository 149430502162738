import { MailIcon, PhoneIcon, TrackedLink } from '@flock/shared-ui'
import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { DirectorySectionProps } from './directorySectionTypes'
import DanielleHeadshot from '../../../images/danielle-headshot.png'
import JacobHeadshot from '../../../images/jacob-headshot.png'

const DirectorySectionTablet = (props: DirectorySectionProps) => {
  const { id } = props

  return (
    <SectionLayout name="directory-section" columns={6} id={id}>
      <Grid item xs={6}>
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          pb={{ xs: '64px', sm: '600px' }}
        >
          <Typography variant="h3">Directory.</Typography>
          <Paper
            sx={{
              display: 'flex',
              borderRadius: '16px',
              p: '24px',
              gap: '16px',
            }}
          >
            <Box display="flex" flexDirection="column" width="100%" gap="16px">
              <Box display="flex" flexDirection="column" gap="8px">
                <Typography variant="cta2">
                  Thinking of exchanging more homes?
                </Typography>
                <Typography variant="p3">
                  Contact Jacob directly and he’ll guide you through the
                  process.
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" gap="16px">
                <Box
                  component="img"
                  height="52px"
                  width="52px"
                  borderRadius="50%"
                  src={JacobHeadshot}
                />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography variant="ctam"> Jacob Schnapp</Typography>
                  <Box display="flex" flexDirection="row" gap="16px">
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap="4px"
                      alignItems="center"
                    >
                      <PhoneIcon />
                      <TrackedLink
                        onClick={() => window.open(`tel:(804) 567-8194`)}
                      >
                        <Typography variant="ctam" color="green4.main">
                          (804) 567-8194
                        </Typography>
                      </TrackedLink>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap="4px"
                      alignItems="center"
                    >
                      <MailIcon />
                      <TrackedLink
                        onClick={() =>
                          window.open(`mailto:jacob@flockhomes.com`)
                        }
                      >
                        <Typography variant="ctam" color="green4.main">
                          jacob@flockhomes.com
                        </Typography>
                      </TrackedLink>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              display: 'flex',
              borderRadius: '16px',
              p: '24px',
            }}
          >
            <Box display="flex" flexDirection="column" width="100%" gap="16px">
              <Box display="flex" flexDirection="column" gap="8px">
                <Typography variant="cta2">
                  Looking for information about your account, performance, or
                  upcoming Flock events?
                </Typography>
                <Typography variant="p3">
                  Danielle will provide insight powered by the asset management,
                  investments, engineering, and acquisitions teams.
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" gap="16px">
                <Box
                  component="img"
                  height="52px"
                  width="52px"
                  borderRadius="50%"
                  src={DanielleHeadshot}
                />
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography variant="ctam"> Danielle Rabman</Typography>
                  <Box display="flex" flexDirection="row" gap="16px">
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap="4px"
                      alignItems="center"
                    >
                      <PhoneIcon />
                      <TrackedLink
                        onClick={() => window.open(`tel:(720) 807-1711`)}
                      >
                        <Typography variant="ctam" color="green4.main">
                          (720) 807-1711
                        </Typography>
                      </TrackedLink>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap="4px"
                      alignItems="center"
                    >
                      <MailIcon />
                      <TrackedLink
                        onClick={() =>
                          window.open(`mailto:danielle@flockhomes.com`)
                        }
                      >
                        <Typography variant="ctam" color="green4.main">
                          danielle@flockhomes.com
                        </Typography>
                      </TrackedLink>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Typography variant="ctam" color="gray8.main">
            For anything else, please call Flock&apos;s main line{' '}
            <TrackedLink onClick={() => window.open(`tel:(720) 807-1711`)}>
              <Typography variant="ctam" color="green4.main">
                (720) 703-9992
              </Typography>
            </TrackedLink>{' '}
            and we&apos;ll be happy to assist.
          </Typography>
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default DirectorySectionTablet
