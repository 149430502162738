import React from 'react'
import { GainLossChartProps } from './gainLossChartTypes'
import useGainLossChart from './useGainLossChart'
import GainLossChartDesktop from './GainLossChartDesktop'
import GainLossChartTabletMobile from './GainLossChartTabletMobile'

const GainLossChart = (props: GainLossChartProps) => {
  const { isMobile, isTablet } = useGainLossChart(props)

  return (
    <>
      {isMobile || isTablet ? (
        <GainLossChartTabletMobile {...props} />
      ) : (
        <GainLossChartDesktop {...props} />
      )}
    </>
  )
}

export default GainLossChart
