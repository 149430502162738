import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const ChartOutlinedIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M55.5 49.5H12.5V10.5C12.5 10.225 12.275 10 12 10H8.5C8.225 10 8 10.225 8 10.5V53.5C8 53.775 8.225 54 8.5 54H55.5C55.775 54 56 53.775 56 53.5V50C56 49.725 55.775 49.5 55.5 49.5ZM19.1125 39.8563C19.3062 40.05 19.6188 40.05 19.8188 39.8563L28.4625 31.2563L36.4375 39.2812C36.6313 39.475 36.95 39.475 37.1437 39.2812L54.3563 22.075C54.55 21.8813 54.55 21.5625 54.3563 21.3687L51.8812 18.8937C51.7873 18.8007 51.6604 18.7485 51.5281 18.7485C51.3959 18.7485 51.269 18.8007 51.175 18.8937L36.8 33.2625L28.8375 25.25C28.7435 25.1569 28.6166 25.1047 28.4844 25.1047C28.3521 25.1047 28.2252 25.1569 28.1313 25.25L16.6437 36.6688C16.5507 36.7627 16.4985 36.8896 16.4985 37.0219C16.4985 37.1541 16.5507 37.281 16.6437 37.375L19.1125 39.8563Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default ChartOutlinedIcon
