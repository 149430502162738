import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
} from 'recharts'
import { AccountOverTimeProps } from './accountOverTimeTypes'
import PeriodSelector from './PeriodSelector'
import useAccountOverTime from './useAccountOverTime'

const AccountOverTime = (props: AccountOverTimeProps) => {
  const { periodSelection, setPeriodSelection } = props
  const {
    setMouseInChartArea,
    quarterData: filteredQuarterData,
    periodSelectorYears,
    hasNoQuarterData,
    tickFormatterX,
    tickFormatterY,
    tooltipContentRenderer,
  } = useAccountOverTime({
    ...props,
  })

  if (hasNoQuarterData) {
    return <></>
  }
  return (
    <Box pt="16px">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
        width="100%"
      >
        <Typography variant="p1" color="moneyGreen.main">
          Account Value Over Time
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          width={{
            xs: '100%',
            sm: 'auto',
          }}
          marginTop={{
            xs: '8px',
            sm: 'auto',
          }}
        >
          <PeriodSelector
            yearsToSelect={periodSelectorYears}
            selectedPeriod={periodSelection}
            setPeriodSelection={setPeriodSelection}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt="8px"
        mb="8px"
      >
        <Typography variant="p3" color="gray4.main">
          Relative performance coming soon!
        </Typography>
      </Box>
      <Box
        marginTop="8px"
        width="100%"
        height={{
          xs: 200,
          sm: 300,
          md: 400,
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={filteredQuarterData}
            margin={{
              top: 10,
              right: 25,
              left: -5,
              bottom: 0,
            }}
            onMouseMove={() => setMouseInChartArea(true)}
            onMouseLeave={() => setMouseInChartArea(false)}
          >
            <XAxis
              dataKey="quarterString"
              tickLine={false}
              tickFormatter={tickFormatterX}
              tick={{
                fontSize: 14,
              }}
            />
            <YAxis
              domain={[
                (dataMin: any) => Math.ceil(dataMin - Math.ceil(dataMin / 10)),
                (dataMax: any) => Math.ceil(dataMax + Math.ceil(dataMax / 10)),
              ]}
              tickLine={false}
              tickFormatter={tickFormatterY}
              tick={{
                letterSpacing: '-.7px',
                fontSize: 14,
              }}
            />
            <CartesianGrid stroke="#f5f5f5" />
            <Tooltip content={tooltipContentRenderer} />
            <Area
              type="linear"
              dataKey="endingAccountValue"
              fill="#E1EEEF"
              stroke="#03341D"
              dot={{
                stroke: '#03341D',
                fillOpacity: 1,
                fill: '#FFFFFF',
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default AccountOverTime
