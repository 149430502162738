import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const AccountFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M12 28.5H27.5C28.0531 28.5 28.5 28.0531 28.5 27.5V10.625H12V28.5ZM27.5 3.5H12V8.625H28.5V4.5C28.5 3.94687 28.0531 3.5 27.5 3.5ZM3.5 4.5V27.5C3.5 28.0531 3.94687 28.5 4.5 28.5H10V3.5H4.5C3.94687 3.5 3.5 3.94687 3.5 4.5Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default AccountFilledIcon
