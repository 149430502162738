import { useMutation, useQuery } from '@apollo/client'
import {
  Core_OrderV3,
  Core_ValidatedAddress,
  InvestorCreateAddressDocumentDocument,
  InvestorGetOrderV3Document,
  InvestorUpdateOrderV3TaskDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  InputType,
  PageContainer,
  StepConfig,
  useSnackbar,
} from '@flock/shared-ui'
import {
  Box,
  Button,
  Grid,
  Paper,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { Info } from '@mui/icons-material'
import React, { useCallback, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { RouteComponentProps, useParams } from '@reach/router'
import {
  DEPRECIATION_SCHEDULE_EXAMPLE_URL,
  FORM_4562_EXAMPLE_URL,
  ORDERS_URL,
  TURBOTAX_INSTRUCTIONS_URL,
} from '../../../../constants'
import PageWrapper from '../../../SharedComponents/PageWrapper'
import FormStepComponent, {
  FormStepComponentProps,
  InjectedFormStepProps,
} from '../../FormStepComponent'
import StepperForm from '../../StepperForm'
import LoadingCardPage from '../../../SharedComponents/LoadingCardPage'
import { useInvestorAccountContext } from '../../../InvestorAccountContext'
import { flattenTaxInformationData } from './api'
import ErrorCardPage from '../../../SharedComponents/ErrorCardPage'
import {
  TaxInformationData,
  TaxInformationFunctions,
} from './taxInformationTypes'

const progressDisplaySteps = [
  {
    label: 'Tax Information',
    value: 'taxInformation',
  },
]

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TaxInformationPage = (_: RouteComponentProps) => {
  const { investorContextLoading } = useInvestorAccountContext()

  const { orderUuid, taskUuid, propertyContributionUuid } = useParams()
  const { notify } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [currentAddress, setCurrentAddress] = useState<Core_ValidatedAddress>(
    {} as Core_ValidatedAddress
  )
  const [updateTaxInformationTask] = useMutation(
    InvestorUpdateOrderV3TaskDocument
  )
  const [createAddressDocument] = useMutation(
    InvestorCreateAddressDocumentDocument
  )

  const { refetch: refetchOrders } = useQuery(InvestorGetOrderV3Document, {
    skip: true,
  })

  const fetchRecentOrder = useCallback(async () => {
    try {
      const { data, error: orderError } = await refetchOrders({
        input: {
          uuid: orderUuid,
        },
      })
      const order: Core_OrderV3 = data?.getOrderV3?.order as Core_OrderV3
      const address = order?.orderPropertyContributions?.find(
        (orderPropertyContribution) =>
          orderPropertyContribution?.propertyContributionUuid ===
          propertyContributionUuid
      )?.address
      setCurrentAddress(address || ({} as Core_ValidatedAddress))
      if (orderError) {
        setError(true)
      }
      setLoading(false)
    } catch (e) {
      setError(true)
    }
  }, [orderUuid, propertyContributionUuid, refetchOrders])

  const generateEmailFunction = useCallback(async () => {
    try {
      window.location.href = `mailto:?subject=${currentAddress.formattedAddress}&body=Hi,%0D%0A%0D%0ACan you please send me the Form 4562 and Workpapers as well as the Form 1040 Schedule E from the most recently completed tax year for ${currentAddress.formattedAddress}? `
    } catch (e) {
      notify(
        'Oops, we had an issue generating an email for your CPA. Please reach out to our team if the issue persists.'
      )
    }
  }, [currentAddress, notify])

  useEffect(() => {
    if (!investorContextLoading && orderUuid) {
      fetchRecentOrder()
    }
  }, [investorContextLoading, orderUuid, fetchRecentOrder])

  if (error) {
    return <ErrorCardPage />
  }
  if (loading || investorContextLoading) {
    return <LoadingCardPage text="Loading Tax Information Form..." />
  }

  const injectProps =
    (stepName: keyof TaxInformationData) =>
    (flowData: Partial<TaxInformationData>) => ({
      flowData,
      stepData: flowData[stepName] || {},
    })

  const funcs: TaxInformationFunctions = {
    updateTaxInformationTask,
    createAddressDocument,
  }

  const taxInfoStep: StepConfig<
    TaxInformationData,
    TaxInformationFunctions,
    FormStepComponentProps,
    InjectedFormStepProps
  > = {
    injectProps: injectProps('taxInformation'),
    onBack: () => navigate(`${ORDERS_URL}/${orderUuid}`),
    stepName: 'taxInformation',
    component: FormStepComponent,
    onCompletion: async (
      flowData: Partial<TaxInformationData>,
      functions: TaxInformationFunctions
    ) => {
      await flattenTaxInformationData(
        {
          ...flowData,
        } as TaxInformationData,
        functions
      )
      return flowData
    },
    componentProps: {
      stepName: 'taxInfo',
      progress: 'taxInformation',
      backText: 'Return to Order',
      inputConfigs: [
        {
          name: 'costBasis',
          type: InputType.Section,
          props: {
            title: 'Cost Basis',
            inputConfigs: [
              {
                name: 'sendEmailCard',
                type: InputType.CustomComponent,
                props: {
                  component: (
                    <Paper
                      elevation={8}
                      sx={{
                        borderRadius: '24px',
                        background: 'rgba(255, 255, 255, 0.25)',
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="16px"
                        padding="24px"
                      >
                        <Typography variant="p2">
                          Do you want to email your CPA for your Form 4562 and
                          associated workpaper (depreciation report)?
                          <Box display="flex" justifyContent="center" pt="16px">
                            <Button
                              variant="secondary"
                              size="mini"
                              onClick={generateEmailFunction}
                            >
                              Email CPA
                            </Button>
                          </Box>
                        </Typography>
                      </Box>
                    </Paper>
                  ),
                },
              },
              {
                name: 'info',
                type: InputType.CustomComponent,
                props: {
                  component: (
                    <Typography variant="p2">
                      Please upload Form 4562 (
                      <a
                        href={FORM_4562_EXAMPLE_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        example
                      </a>
                      ) and associated workpaper (
                      <a
                        href={DEPRECIATION_SCHEDULE_EXAMPLE_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        example
                      </a>
                      ), the grid-style worksheet from your most recently
                      completed tax year. We need both documents for accurate
                      cost basis and depreciation allocation.
                    </Typography>
                  ),
                },
              },
              {
                name: 'instructions',
                type: InputType.CustomComponent,
                props: {
                  component: (
                    <Typography variant="p2">
                      TurboTax Tip: See direction on how to obtain this document
                      from your TurboTax files. (
                      <a
                        href={TURBOTAX_INSTRUCTIONS_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        instructions
                      </a>
                      )
                    </Typography>
                  ),
                },
              },
              {
                name: 'form4562Workpapers',
                type: InputType.FileUpload,
                required: true,
                props: {
                  label: (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="p2">
                        Please upload Form 4562 and Workpapers.
                      </Typography>
                      <StyledTooltip
                        title={
                          <Typography variant="p3" color="gray8.main">
                            Form 4562 and associated worksheet are tax documents
                            that are issued starting the year you put the
                            property into service as a rental.
                          </Typography>
                        }
                        enterTouchDelay={0}
                        data-cy="form4562WorkpapersTooltipIcon"
                        placement="right"
                        arrow
                      >
                        <StyledInfoOutline fontSize="small" color="disabled" />
                      </StyledTooltip>
                    </Box>
                  ),
                },
              },
            ],
          },
        },
        {
          name: 'cashOutRefinancing',
          type: InputType.Section,
          props: {
            title: 'Cash Out Refinancing',
            inputConfigs: [
              {
                name: 'cashOutRefinance',
                type: InputType.RadioSelect,
                defaultValue: '',
                required: true,
                props: {
                  label:
                    'Have you taken cash out during a refinancing of this property?',
                  labelSize: 'small',
                  row: true,
                  options: [
                    {
                      label: 'No',
                      value: 'false',
                    },
                    {
                      label: 'Yes',
                      value: 'true',
                    },
                  ],
                },
              },
              {
                name: 'cashOutRefinanceDescription',
                type: InputType.Text,
                required: true,
                renderIf: (watchedFields: any) =>
                  watchedFields.cashOutRefinance === 'true',
                props: {
                  label: 'Please describe the cash out refinancing.',
                },
              },
            ],
          },
        },
      ],
    },
  }

  const stepConfigs = [taxInfoStep]

  return (
    <PageContainer
      title="FLOCK | Tax Information"
      trackingName="tax-information"
    >
      <PageWrapper>
        <Grid container spacing={4} height="auto">
          <StepperForm
            title="Tax Information"
            address={currentAddress?.formattedAddress || ''}
            description="One benefit of Flock is maintaining cost basis and depreciation schedules after you exchange into the fund (said differently, cost basis is not reset when a property is contributed to Flock). In order to calculate and allocate depreciation amounts correctly for annual taxes we need to collect specific information from you regarding your properties."
            initialData={{
              taskUuid,
              orderUuid,
              propertyContributionUuid,
              addressUuid: currentAddress.uuid,
            }}
            stepConfigs={stepConfigs}
            progressDisplaySteps={progressDisplaySteps}
            functions={funcs}
            useStepComponentLoading
            useHashRouting={false}
            useStepComponentError
          />
        </Grid>
      </PageWrapper>
    </PageContainer>
  )
}

export default TaxInformationPage
