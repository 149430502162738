import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { OpenInNewIcon } from '@flock/shared-ui'
import React from 'react'
import SectionLayout from '../../SharedComponents/SectionLayout'
import FundPerformanceHeader from './FundPerformanceHeader/FundPerformanceHeader'
import NetIncomeTable from './NetIncomeTable/netIncomeTable'
import GainLossChart from './GainLossChart/GainLossChart'
import FundPerformanceWaterfall from './FundPerformanceWaterfall/FundPerformanceWaterfall'
import useFundPerformanceSection, {
  GAIN_LOSS_SECTION,
  NET_PERFORMANCE_SECTION,
} from './useFundPerformanceSection'
import { BALANCE_SHEET_URL, FLOCK_INVESTOR_URL } from '../../../constants'

type FundPerformanceSectionProps = {
  id: string
}

const FundPerformanceSection = (props: FundPerformanceSectionProps) => {
  const {
    isMobile,
    isTablet,
    mostRecentQuarterDatestring,
    selectedSection,
    ...otherProps
  } = useFundPerformanceSection()

  let numColumns = 9
  if (isMobile) {
    numColumns = 4
  } else if (isTablet) {
    numColumns = 6
  }

  const { id } = props

  return (
    <SectionLayout name="fund-performance-section" columns={numColumns} id={id}>
      <Grid item xs={numColumns}>
        <Box
          alignItems="baseline"
          display="flex"
          gap="8px"
          width="100%"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Typography variant="h3">Fund Performance</Typography>
          <Typography variant={isMobile ? 'p4' : 'p3'} color="gray5.main">
            {isMobile ? 'Updated' : 'Last updated'}{' '}
            {mostRecentQuarterDatestring}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={numColumns}>
        <Paper
          sx={{
            display: 'flex',
            borderRadius: '16px',
            p: '24px',
          }}
        >
          <Grid
            container
            columns={numColumns}
            spacing={{
              xs: 2,
              sm: 4,
            }}
          >
            <Grid item xs={numColumns}>
              <FundPerformanceHeader {...otherProps} />
            </Grid>
            <Grid item xs={numColumns}>
              <FundPerformanceWaterfall
                selectedSection={selectedSection}
                {...otherProps}
              />
            </Grid>
            {selectedSection === GAIN_LOSS_SECTION && (
              <Grid item xs={numColumns}>
                <GainLossChart {...otherProps} />
              </Grid>
            )}
            {selectedSection === NET_PERFORMANCE_SECTION && (
              <Grid item xs={numColumns}>
                <NetIncomeTable {...otherProps} />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      {!(isTablet || isMobile) && (
        <Grid item xs={numColumns}>
          <Box display="flex" justifyContent="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                window.open(
                  `${FLOCK_INVESTOR_URL}${BALANCE_SHEET_URL}`,
                  '_blank'
                )
              }
              sx={{
                width: '448px',
                height: '48px',
                alignItems: 'center',
              }}
            >
              <Typography>View detailed balance sheet</Typography>
              <OpenInNewIcon width="32px" height="32px" color="white" />
            </Button>
          </Box>
        </Grid>
      )}
    </SectionLayout>
  )
}

export default FundPerformanceSection
