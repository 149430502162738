export type InvestorFlexibleCashFlowInputs = {
  startingEquity: number
  netYield: number
  cashFlowPercentage: number
  holdingPeriodOverride: number
  hideNetYield: boolean
}

export type InvestorFlexibleCashFlowOutputs = {
  initialAccountValueHigh: number[]
  cashWithdrawnHigh: number[]
  cashDeductionHigh: number[]
  totalReturnHigh: number[]
  finalAccountValueHigh: number[]

  initialAccountValueAvg: number[]
  cashWithdrawnAvg: number[]
  cashDeductionAvg: number[]
  totalReturnAvg: number[]
  finalAccountValueAvg: number[]

  initialAccountValueLow: number[]
  cashWithdrawnLow: number[]
  cashDeductionLow: number[]
  totalReturnLow: number[]
  finalAccountValueLow: number[]
}

const DEDUCTION_FEE = 0.1

export const getHoldingPeriod = (netYield: number) => {
  if (netYield < 0.03) {
    return 7
  }
  if (netYield < 0.04) {
    return 6
  }
  if (netYield < 0.05) {
    return 5
  }
  if (netYield < 0.06) {
    return 4
  }
  return 3
}

// https://docs.google.com/document/d/1MQlObku_f852fFX8p2yY9_zr7wUdMd-N1mJrP48ba80/edit?pli=1#heading=h.pqomula5iejf
export const investorCalculateFlexibleCashFlowModel = (
  inputs: InvestorFlexibleCashFlowInputs
) => {
  const {
    startingEquity,
    netYield,
    cashFlowPercentage,
    holdingPeriodOverride,
    hideNetYield,
  } = inputs

  const flexibleCashFlowOutputs: InvestorFlexibleCashFlowOutputs = {
    initialAccountValueHigh: [],
    cashWithdrawnHigh: [],
    cashDeductionHigh: [],
    totalReturnHigh: [],
    finalAccountValueHigh: [],

    initialAccountValueAvg: [],
    cashWithdrawnAvg: [],
    cashDeductionAvg: [],
    totalReturnAvg: [],
    finalAccountValueAvg: [],

    initialAccountValueLow: [],
    cashWithdrawnLow: [],
    cashDeductionLow: [],
    totalReturnLow: [],
    finalAccountValueLow: [],
  }

  // Get holding period -- in years
  const holdingPeriod = holdingPeriodOverride || getHoldingPeriod(netYield)
  let maxAllowance = hideNetYield
    ? 0
    : Math.round((netYield - 0.02) * 10000) / 10000
  maxAllowance = Math.min(maxAllowance, 0.04)

  const HIGH_APPRECIATION = 0.12
  const AVG_APPRECIATION = 0.1
  const LOW_APPRECIATION = 0.08

  // High appreciation --  year 1
  flexibleCashFlowOutputs.initialAccountValueHigh = [startingEquity]
  flexibleCashFlowOutputs.totalReturnHigh = [startingEquity * HIGH_APPRECIATION]
  flexibleCashFlowOutputs.cashWithdrawnHigh = [
    (flexibleCashFlowOutputs.initialAccountValueHigh[0] +
      flexibleCashFlowOutputs.totalReturnHigh[0]) *
      cashFlowPercentage,
  ]

  if (cashFlowPercentage > maxAllowance) {
    const percentAdditionalCashflow =
      (cashFlowPercentage - maxAllowance) / cashFlowPercentage
    const additionalCashWithdrawn =
      percentAdditionalCashflow * flexibleCashFlowOutputs.cashWithdrawnHigh[0]
    const cashDeduction = additionalCashWithdrawn * DEDUCTION_FEE
    flexibleCashFlowOutputs.cashDeductionHigh = [cashDeduction]
  } else {
    flexibleCashFlowOutputs.cashDeductionHigh = [0]
  }

  flexibleCashFlowOutputs.finalAccountValueHigh = [
    flexibleCashFlowOutputs.initialAccountValueHigh[0] +
      flexibleCashFlowOutputs.totalReturnHigh[0] -
      flexibleCashFlowOutputs.cashWithdrawnHigh[0] -
      flexibleCashFlowOutputs.cashDeductionHigh[0],
  ]

  // High appreciation --  years 2 - 5
  for (let i = 1; i < 5; i += 1) {
    const initialAccountValue =
      flexibleCashFlowOutputs.finalAccountValueHigh[i - 1]
    const totalReturn = initialAccountValue * HIGH_APPRECIATION
    const cashWithdrawn =
      (initialAccountValue + totalReturn) * cashFlowPercentage

    flexibleCashFlowOutputs.initialAccountValueHigh.push(initialAccountValue)
    flexibleCashFlowOutputs.totalReturnHigh.push(totalReturn)
    flexibleCashFlowOutputs.cashWithdrawnHigh.push(cashWithdrawn)

    // Deduct 10% of the cash withdrawn if the cash flow percentage is greater than the max allowance
    let cashDeduction = 0
    if (i < holdingPeriod && cashFlowPercentage > maxAllowance) {
      cashDeduction =
        (flexibleCashFlowOutputs.cashWithdrawnHigh[i] *
          DEDUCTION_FEE *
          (cashFlowPercentage - maxAllowance)) /
        cashFlowPercentage
    }

    flexibleCashFlowOutputs.cashDeductionHigh.push(cashDeduction)

    flexibleCashFlowOutputs.finalAccountValueHigh.push(
      initialAccountValue + totalReturn - cashWithdrawn - cashDeduction
    )
  }

  // Avg appreciation --  year 1
  flexibleCashFlowOutputs.initialAccountValueAvg = [startingEquity]
  flexibleCashFlowOutputs.totalReturnAvg = [startingEquity * AVG_APPRECIATION]
  flexibleCashFlowOutputs.cashWithdrawnAvg = [
    (flexibleCashFlowOutputs.initialAccountValueAvg[0] +
      flexibleCashFlowOutputs.totalReturnAvg[0]) *
      cashFlowPercentage,
  ]

  if (cashFlowPercentage > maxAllowance) {
    const percentAdditionalCashflow =
      (cashFlowPercentage - maxAllowance) / cashFlowPercentage
    const additionalCashWithdrawn =
      percentAdditionalCashflow * flexibleCashFlowOutputs.cashWithdrawnAvg[0]
    const cashDeduction = additionalCashWithdrawn * DEDUCTION_FEE
    flexibleCashFlowOutputs.cashDeductionAvg = [cashDeduction]
  } else {
    flexibleCashFlowOutputs.cashDeductionAvg = [0]
  }

  flexibleCashFlowOutputs.finalAccountValueAvg = [
    flexibleCashFlowOutputs.initialAccountValueAvg[0] +
      flexibleCashFlowOutputs.totalReturnAvg[0] -
      flexibleCashFlowOutputs.cashWithdrawnAvg[0] -
      flexibleCashFlowOutputs.cashDeductionAvg[0],
  ]

  // Avg appreciation --  years 2 - 5
  for (let i = 1; i < 5; i += 1) {
    const initialAccountValue =
      flexibleCashFlowOutputs.finalAccountValueAvg[i - 1]
    const totalReturn = initialAccountValue * AVG_APPRECIATION
    const cashWithdrawn =
      (initialAccountValue + totalReturn) * cashFlowPercentage

    flexibleCashFlowOutputs.initialAccountValueAvg.push(initialAccountValue)
    flexibleCashFlowOutputs.totalReturnAvg.push(totalReturn)
    flexibleCashFlowOutputs.cashWithdrawnAvg.push(cashWithdrawn)

    // Deduct 10% of the cash withdrawn if the cash flow percentage is greater than the max allowance
    let cashDeduction = 0
    if (i < holdingPeriod && cashFlowPercentage > maxAllowance) {
      cashDeduction =
        (flexibleCashFlowOutputs.cashWithdrawnAvg[i] *
          DEDUCTION_FEE *
          (cashFlowPercentage - maxAllowance)) /
        cashFlowPercentage
    }

    flexibleCashFlowOutputs.cashDeductionAvg.push(cashDeduction)

    flexibleCashFlowOutputs.finalAccountValueAvg.push(
      initialAccountValue + totalReturn - cashWithdrawn - cashDeduction
    )
  }

  // Low appreciation - year 1
  flexibleCashFlowOutputs.initialAccountValueLow = [startingEquity]
  flexibleCashFlowOutputs.totalReturnLow = [startingEquity * LOW_APPRECIATION]
  flexibleCashFlowOutputs.cashWithdrawnLow = [
    (flexibleCashFlowOutputs.initialAccountValueLow[0] +
      flexibleCashFlowOutputs.totalReturnLow[0]) *
      cashFlowPercentage,
  ]

  if (cashFlowPercentage > maxAllowance) {
    const percentAdditionalCashflow =
      (cashFlowPercentage - maxAllowance) / cashFlowPercentage
    const additionalCashWithdrawn =
      percentAdditionalCashflow * flexibleCashFlowOutputs.cashWithdrawnLow[0]
    const cashDeduction = additionalCashWithdrawn * DEDUCTION_FEE
    flexibleCashFlowOutputs.cashDeductionLow = [cashDeduction]
  } else {
    flexibleCashFlowOutputs.cashDeductionLow = [0]
  }

  flexibleCashFlowOutputs.finalAccountValueLow = [
    flexibleCashFlowOutputs.initialAccountValueLow[0] +
      flexibleCashFlowOutputs.totalReturnLow[0] -
      flexibleCashFlowOutputs.cashWithdrawnLow[0] -
      flexibleCashFlowOutputs.cashDeductionLow[0],
  ]

  // Low appreciation - years 2 - 5
  for (let i = 1; i < 5; i += 1) {
    const initialAccountValue =
      flexibleCashFlowOutputs.finalAccountValueLow[i - 1]
    const totalReturn = initialAccountValue * LOW_APPRECIATION
    const cashWithdrawn =
      (initialAccountValue + totalReturn) * cashFlowPercentage

    flexibleCashFlowOutputs.initialAccountValueLow.push(initialAccountValue)
    flexibleCashFlowOutputs.totalReturnLow.push(totalReturn)
    flexibleCashFlowOutputs.cashWithdrawnLow.push(cashWithdrawn)

    // Deduct 10% of the cash withdrawn if the cash flow percentage is greater than the max allowance
    let cashDeduction = 0
    if (i < holdingPeriod && cashFlowPercentage > maxAllowance) {
      cashDeduction =
        (flexibleCashFlowOutputs.cashWithdrawnLow[i] *
          DEDUCTION_FEE *
          (cashFlowPercentage - maxAllowance)) /
        cashFlowPercentage
    }

    flexibleCashFlowOutputs.cashDeductionLow.push(cashDeduction)

    flexibleCashFlowOutputs.finalAccountValueLow.push(
      initialAccountValue + totalReturn - cashWithdrawn - cashDeduction
    )
  }

  return flexibleCashFlowOutputs
}
