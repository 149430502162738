import { TaxSummaryTableProps } from './TaxSummaryTableTypes'

const useTaxSummaryTable = (props: TaxSummaryTableProps) => {
  const { taxData } = props

  return {
    taxData,
  }
}

export default useTaxSummaryTable
