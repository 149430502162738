import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const InstitutionOutlinedIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M53.3337 16H61.3337V21.3333H58.667V50.6667H61.3337V56H2.66699V50.6667H5.33366V21.3333H2.66699V16H10.667V10.6667C10.667 9.95942 10.9479 9.28115 11.448 8.78105C11.9481 8.28095 12.6264 8 13.3337 8H50.667C51.3742 8 52.0525 8.28095 52.5526 8.78105C53.0527 9.28115 53.3337 9.95942 53.3337 10.6667V16ZM53.3337 21.3333H10.667V50.6667H18.667V32H24.0003V50.6667H29.3337V32H34.667V50.6667H40.0003V32H45.3337V50.6667H53.3337V21.3333ZM16.0003 13.3333V16H48.0003V13.3333H16.0003Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default InstitutionOutlinedIcon
