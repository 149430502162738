import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const HamburgerIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const strokeStyle = stroke || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M35.5293 33.4907L13.0911 33.4907"
        stroke={strokeStyle || '#2B2E31'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M35.5293 24.7742L20.4782 24.7742"
        stroke={strokeStyle || '#2B2E31'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M35.5293 15.8179L13.0911 15.8179"
        stroke={strokeStyle || '#2B2E31'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default HamburgerIcon
