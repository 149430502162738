import React, { useRef, useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { renderToString } from 'react-dom/server'

// @ts-ignore
// eslint-disable-next-line
import mapboxgl from '!mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import LocationFilled from './location-filled.png'
import LocationOutlined from './location-outlined.png'
import LeadLocationFilled from './lead-location-filled.png'
import LeadLocationOutlined from './lead-location-outlined.png'
import StaticMap from './static-portfolio-map.webp'
import { BathroomIcon } from '../icons/BathroomIcon'
import { BedroomIcon } from '../icons/BedroomIcon'
import { HouseOutlinedIcon } from '../icons/HouseOutlinedIcon'
import { MapPinOutlinedIcon } from '../icons/MapPinOutlinedIcon'
import { DimensionsIcon } from '../icons/DimensionsIcon'
import { TrackedLink } from '../TrackedLink'

type PropertyAsset = {
  propertyUuid: string
  beds: number
  baths: number
  yearBuilt: number
  sqft: number
  acquisitionDate: string
  propertyType: string
  street: string
  unit: string
  city: string
  state: string
  zipcode: string
  latitude: number
  longitude: number
}

const doesSupportWebGL = () => {
  if (typeof window !== 'undefined') {
    let supportsWebGL = false
    try {
      const canvas = document.createElement('canvas')
      supportsWebGL =
        !!window.WebGLRenderingContext &&
        !!(
          canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
        )
    } catch (e) {
      supportsWebGL = false
    }
    return supportsWebGL
  }
  return false
}
type ContainerStyle = {
  width?: string
  height?: string
  borderRadius?: string
}

type MapboxConfig = {
  containerStyle?: ContainerStyle
  center?: number[]
  bounds?: number[][]
  startingZoom?: number
  minZoom?: number
}

type PortfolioMapboxProps = {
  propertyAssets: PropertyAsset[]
  mapboxToken: string
  desktopConfig?: MapboxConfig
  tabletConfig?: MapboxConfig
  mobileConfig?: MapboxConfig
  disableZoom?: boolean
  disableFullscreen?: boolean
  detailedPropertyView?: boolean
  defaultBounds?: number[][]
  investorUrl?: string
  leadPropertyAssets?: PropertyAsset[]
}

const PortfolioMapbox = (props: PortfolioMapboxProps) => {
  const {
    propertyAssets,
    mapboxToken,
    desktopConfig,
    tabletConfig,
    mobileConfig,
    defaultBounds,
    disableZoom,
    disableFullscreen,
    detailedPropertyView,
    investorUrl,
    leadPropertyAssets,
  } = props
  mapboxgl.accessToken = mapboxToken
  const [supportsWebGL, setSupportsWebGL] = useState(true)
  const mapContainerRef = useRef<any>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const mapboxConfigs = {
    desktop: {
      containerStyle: {
        width: '100%',
        height: '560px',
        borderRadius: '24px',
      },
      center: [-104.9903, 37.7392],
      bounds: [
        [-139.975, 10.195], // Southwest coordinates
        [-56.161, 62.204], // Northeast coordinates
      ],
      startingZoom: 0,
      minZoom: 0,
      ...desktopConfig,
    },
    tablet: {
      containerStyle: {
        width: '100%',
        height: '560px',
        borderRadius: '24px',
      },
      center: [-104.9903, 39.7392],
      bounds: [
        [-139.975, 10.195], // Southwest coordinates
        [-56.161, 62.204], // Northeast coordinates
      ],
      startingZoom: 0,
      minZoom: 0,
      ...tabletConfig,
    },
    mobile: {
      containerStyle: {
        width: '100%',
        height: '252px',
        borderRadius: '24px',
      },
      center: [-104.9903, 39.7392],
      bounds: [
        [-127.975, 12.195], // Southwest coordinates
        [-64.161, 56.204], // Northeast coordinates
      ],
      startingZoom: 0,
      minZoom: 0,
      ...mobileConfig,
    },
  }

  let { containerStyle, center, bounds, startingZoom, minZoom } =
    mapboxConfigs.desktop

  if (isTablet) {
    containerStyle = mapboxConfigs.tablet.containerStyle
    center = mapboxConfigs.tablet.center
    bounds = mapboxConfigs.tablet.bounds
    startingZoom = mapboxConfigs.tablet.startingZoom
    minZoom = mapboxConfigs.tablet.minZoom
  }

  if (isMobile) {
    containerStyle = mapboxConfigs.mobile.containerStyle
    center = mapboxConfigs.mobile.center
    bounds = mapboxConfigs.mobile.bounds
    startingZoom = mapboxConfigs.mobile.startingZoom
    minZoom = mapboxConfigs.mobile.minZoom
  }

  const maxZoom = 13

  const detailTextStyles = {
    fontFamily: 'Outfit!important',
    color: theme.palette.gray8.main,
    fontSize: '0.75rem!important',
    lineHeight: '1rem!important',
    whiteSpace: 'nowrap',
  }

  useEffect(() => {
    setSupportsWebGL(doesSupportWebGL())
  }, [])

  useEffect(() => {
    const points = propertyAssets.map((asset: any) => ({
      type: 'Feature',
      properties: {
        orientation: asset.orientation,
        markup: renderToString(
          detailedPropertyView ? (
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              sx={{
                margin: '-24px',
                padding: '24px',
                borderRadius: '24px',
                backgroundColor: asset.isMainProperty ? '#FFF2E7' : '#fff',
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="8px"
              >
                {asset.isMainProperty && (
                  <Typography
                    sx={{
                      fontFamily: 'Outfit!important',
                      fontSize: '12px',
                      lineHeight: '16px',
                      color: '#03341D!important',
                    }}
                  >
                    YOUR HOUSE
                  </Typography>
                )}
                <Typography
                  sx={{
                    ...detailTextStyles,
                    fontSize: '1.25rem!important',
                  }}
                >
                  {asset.formattedStreet}
                </Typography>
                <Typography
                  sx={{
                    ...detailTextStyles,
                    fontSize: '1rem!important',
                  }}
                >
                  {asset.city}, {asset.state}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" gap="4px">
                  <BedroomIcon width="20px" height="20px" />
                  <Typography
                    sx={{
                      fontFamily: 'Outfit!important',
                      color: theme.palette.gray8.main,
                      fontSize: '0.75rem!important',
                      lineHeight: '1rem!important',
                      whiteSpace: 'nowrap',
                      marginRight: '8px',
                    }}
                  >
                    {asset.beds} Beds{' '}
                  </Typography>

                  <BathroomIcon width="20px" height="20px" />
                  <Typography sx={detailTextStyles}>
                    {asset.baths} Baths
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="4px">
                  <HouseOutlinedIcon width="20px" height="20px" />
                  <Typography sx={detailTextStyles}>
                    {asset.propertyType === 'SFR' ||
                    asset.propertyType === 'Single-family detached home'
                      ? 'single-family home'
                      : asset.propertyType}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box display="flex" flexDirection="column" gap="12px">
                <Box display="flex" alignItems="center" gap="4px">
                  <MapPinOutlinedIcon width="20px" height="20px" />
                  <Typography sx={detailTextStyles}>
                    {asset.city}, {asset.state}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="4px">
                  <HouseOutlinedIcon width="20px" height="20px" />
                  <Typography sx={detailTextStyles}>
                    {asset.propertyType}{' '}
                  </Typography>
                  <DimensionsIcon width="20px" height="20px" />
                  <Typography sx={detailTextStyles}>
                    {propertyAssets
                      .filter(
                        (propertyAsset) =>
                          asset.latitude === propertyAsset.latitude &&
                          asset.longitude === propertyAsset.longitude
                      )
                      .reduce((accum, val) => accum + val.sqft, 0)}{' '}
                    sq ft
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="4px">
                  <BedroomIcon width="20px" height="20px" />
                  <Typography
                    sx={{
                      fontFamily: 'Outfit!important',
                      color: theme.palette.gray8.main,
                      fontSize: '0.75rem!important',
                      lineHeight: '1rem!important',
                      whiteSpace: 'nowrap',
                      marginRight: '8px',
                    }}
                  >
                    {propertyAssets
                      .filter(
                        (propertyAsset) =>
                          asset.latitude === propertyAsset.latitude &&
                          asset.longitude === propertyAsset.longitude
                      )
                      .reduce((accum, val) => accum + val.beds, 0)}{' '}
                    bed{' '}
                  </Typography>

                  <BathroomIcon width="20px" height="20px" />
                  <Typography sx={detailTextStyles}>
                    {propertyAssets
                      .filter(
                        (propertyAsset) =>
                          asset.latitude === propertyAsset.latitude &&
                          asset.longitude === propertyAsset.longitude
                      )
                      .reduce((accum, val) => accum + val.baths, 0)}{' '}
                    bath
                  </Typography>
                </Box>

                {investorUrl !== '' && (
                  <Box display="flex" alignItems="center" gap="4px">
                    <TrackedLink
                      to={`${investorUrl}/${asset.propertyUuid}`}
                      target="_blank"
                      sx={{
                        textDecoration: 'none',
                      }}
                      aria-label="assets"
                    >
                      <Box
                        sx={{
                          backgroundColor: '#EBF1F1',
                          borderRadius: '8px',
                          textAlign: 'center',
                          color: '#03341D',
                          fontFamily: 'Outfit',
                          fontWeight: '500',
                          fontSize: '0.75rem!important',
                          lineHeight: '1rem!important',
                          wordWrap: 'break-word',
                          textTransform: 'none',
                          textDecoration: 'none',
                          padding: '4px 8px',
                          transition: 'background-color 0.3s',

                          '&:hover': {
                            backgroundColor: '#D3E0E0',
                          },
                        }}
                      >
                        View Flock Home details
                      </Box>
                    </TrackedLink>
                  </Box>
                )}
              </Box>
            </>
          )
        ),
      },
      geometry: {
        type: 'Point',
        coordinates: [asset.longitude, asset.latitude],
      },
    }))
    let leadPoints: any = []
    if (leadPropertyAssets) {
      leadPoints = leadPropertyAssets.map((asset: any) => {
        const bedCount = leadPropertyAssets
          .filter(
            (leadPropertyAsset) =>
              asset.latitude === leadPropertyAsset.latitude &&
              asset.longitude === leadPropertyAsset.longitude
          )
          .reduce((accum, val) => accum + val.beds, 0)

        const bathCount = leadPropertyAssets
          .filter(
            (propertyAsset) =>
              asset.latitude === propertyAsset.latitude &&
              asset.longitude === propertyAsset.longitude
          )
          .reduce((accum, val) => accum + val.baths, 0)

        return {
          type: 'Feature',
          properties: {
            orientation: asset.orientation,
            markup: renderToString(
              <>
                <Box display="flex" flexDirection="column" gap="12px">
                  <Box display="flex" alignItems="center" gap="4px">
                    <MapPinOutlinedIcon width="20px" height="20px" />
                    <Typography sx={detailTextStyles}>
                      {asset.formattedAddress}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap="4px">
                    <HouseOutlinedIcon width="20px" height="20px" />
                    <Typography sx={detailTextStyles}>
                      {asset.shortenedType || asset.type}{' '}
                    </Typography>
                    {asset.sqft !== 0 && (
                      <>
                        <DimensionsIcon width="20px" height="20px" />
                        <Typography sx={detailTextStyles}>
                          {asset.sqft} sq ft
                        </Typography>
                      </>
                    )}
                  </Box>
                  {bedCount !== 0 && bathCount !== 0 && (
                    <Box display="flex" alignItems="center" gap="4px">
                      <BedroomIcon width="20px" height="20px" />
                      <Typography
                        sx={{
                          fontFamily: 'Outfit!important',
                          color: theme.palette.gray8.main,
                          fontSize: '0.75rem!important',
                          lineHeight: '1rem!important',
                          whiteSpace: 'nowrap',
                          marginRight: '8px',
                        }}
                      >
                        {bedCount} bed{' '}
                      </Typography>
                      <BathroomIcon width="20px" height="20px" />
                      <Typography sx={detailTextStyles}>
                        {bathCount} bath
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            ),
          },
          geometry: {
            type: 'Point',
            coordinates: [asset.longitude, asset.latitude],
          },
        }
      })
    }
    let map: any
    let fHover: any = null

    if (supportsWebGL && mapboxgl?.Map) {
      map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/flocktech/clb17ybq2007714o2pl2reu73',
        center,
        minZoom,
        maxZoom,
        zoom: startingZoom,
        maxBounds: bounds,
        dragPan: !disableZoom,

        trackResize: true,
      })
      if (!disableFullscreen) {
        map.addControl(new mapboxgl.FullscreenControl())
      }
      if (!isMobile && !disableZoom) {
        map.addControl(new mapboxgl.NavigationControl(), 'top-right')
      }
      if (disableZoom) {
        map.scrollZoom.disable()
        map.boxZoom.disable()
        map.doubleClickZoom.disable()
        map.touchZoomRotate.disable()
      }
      map.on('load', () => {
        map.addSource('properties', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: points as any,
          },
          cluster: !detailedPropertyView,
          clusterMaxZoom: 9,
          clusterRadius: 15,
          generateId: true,
        })
        // Lead properties
        map.addSource('leadProperties', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: leadPoints as any,
          },
          cluster: true,
          clusterMaxZoom: 9,
          clusterRadius: 15,
          generateId: true,
        })
        if (!detailedPropertyView) {
          map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'properties',
            paint: {
              // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
              // with three steps to implement three types of circles:
              //   * Blue, 15px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              'circle-color':
                // @ts-ignore
                theme?.mapboxOverrides?.circleColor ||
                theme.palette.green0.main,
              'circle-radius': [
                'step',
                ['coalesce', ['get', 'point_count'], 0],
                12,
                40,
                14,
                60,
                16,
                80,
                18,
              ],
            },
          })

          map.on('click', 'clusters', (e: any) => {
            map.flyTo({ center: e.features[0].geometry.coordinates, zoom: 10 })
          })

          map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'properties',
            layout: {
              'text-field': [
                'coalesce',
                ['get', 'point_count_abbreviated'],
                '1',
              ],
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 12,
            },
          })

          // Lead properties
          map.addLayer({
            id: 'leadClusters',
            type: 'circle',
            source: 'leadProperties',
            paint: {
              // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
              // with three steps to implement three types of circles:
              //   * Blue, 15px circles when point count is less than 100
              //   * Yellow, 30px circles when point count is between 100 and 750
              //   * Pink, 40px circles when point count is greater than or equal to 750
              'circle-color':
                // @ts-ignore
                '#FFEBD9',
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                15,
                25,
                17,
                45,
                19,
                65,
                21,
                85,
                23,
                105,
                26,
                125,
                28,
                148,
                30,
              ],
            },
          })

          map.on('click', 'leadClusters', (e: any) => {
            map.flyTo({ center: e.features[0].geometry.coordinates, zoom: 10 })
          })

          map.addLayer({
            id: 'lead-cluster-count',
            type: 'symbol',
            source: 'leadProperties',
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 16,
            },
          })
        }

        map.loadImage(
          LocationFilled,
          (error: Error, locationFilledImg: any) => {
            if (error) throw error

            map.loadImage(
              LocationOutlined,
              (error2: Error, locationOutlinedImg: any) => {
                if (error2) throw error2
                map.addImage('location-filled', locationFilledImg)
                map.addImage('location-outlined', locationOutlinedImg)

                // Add a symbol layer
                map.addLayer({
                  id: 'points',
                  type: 'symbol',
                  source: 'properties',
                  filter: ['!', ['has', 'point_count']],
                  layout: {
                    'icon-anchor': 'bottom',
                    'icon-image': 'location-filled',
                    'icon-size': 0.35,
                    'icon-allow-overlap': detailedPropertyView,
                    visibility: detailedPropertyView ? 'visible' : 'none',
                  },
                  paint: {
                    // This checks if we're hovering and if we are, makes the icon opaque
                    'icon-opacity': detailedPropertyView
                      ? 1.0
                      : [
                          'case',
                          ['boolean', ['feature-state', 'hover'], false],
                          1,
                          0.5,
                        ],
                  },
                })

                function mouseover(feature: any) {
                  fHover = feature
                  map.getCanvasContainer().style.cursor = 'pointer'

                  map.setFeatureState(
                    {
                      source: 'properties',
                      id: fHover.id,
                    },
                    {
                      hover: true,
                    }
                  )
                }

                function mouseout() {
                  if (!fHover) return
                  map.getCanvasContainer().style.cursor = 'default'
                  map.setFeatureState(
                    {
                      source: 'properties',
                      id: fHover.id,
                    },
                    {
                      hover: false,
                    }
                  )
                  fHover = null
                }

                if (detailedPropertyView) {
                  points.forEach((assetPoint) => {
                    const { markup, orientation } = assetPoint.properties
                    let xOffset = 4
                    let yOffset = -16
                    if (orientation.includes('top')) {
                      yOffset = 0
                    }
                    if (orientation.includes('right')) {
                      xOffset = -4
                    }

                    if (!isMobile) {
                      new mapboxgl.Popup({
                        closeButton: false,
                        closeOnClick: false,
                        anchor: orientation,
                        offset: [xOffset, yOffset],
                      })
                        .setLngLat(assetPoint.geometry.coordinates)
                        .setHTML(markup)
                        .addTo(map)
                    }
                  })

                  if (defaultBounds) {
                    map.fitBounds(defaultBounds)
                  }
                } else {
                  map.on('click', 'points', (e: any) => {
                    e.preventDefault()

                    const coordinates =
                      e.features[0].geometry.coordinates.slice()
                    const { markup } = e.features[0].properties
                    new mapboxgl.Popup({
                      closeButton: false,
                      anchor: 'bottom-left',
                      offset: [4, -16],
                    })
                      .setLngLat(coordinates)
                      .setHTML(markup)
                      .addTo(map)

                    // When we click on a point, we set the image to the outlined image and
                    // ensure the opacity is opaque
                    map.setLayoutProperty('points', 'icon-image', [
                      'match',
                      ['id'],
                      e.features[0].id,
                      'location-outlined',
                      'location-filled',
                    ])

                    map.setPaintProperty('points', 'icon-opacity', [
                      'match',
                      ['id'],
                      e.features[0].id,
                      1,
                      [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        1,
                        0.5,
                      ],
                    ])
                  })

                  // when we click away from any point, we set the opacity to behave based on hover
                  map.on('click', (e: any) => {
                    if (e.defaultPrevented === false) {
                      map.setLayoutProperty(
                        'points',
                        'icon-image',
                        'location-filled'
                      ) // reset all markers default hasn't been prevented (meaning I haven't clicked the points layer)
                      map.setPaintProperty('points', 'icon-opacity', [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        1,
                        0.5,
                      ])
                    }
                  })

                  // Applies hover styles
                  map.on('mousemove', 'points', (e: any) => {
                    if (e.features[0]) {
                      mouseover(e.features[0])
                    } else {
                      mouseout()
                    }
                  })

                  map.on('mouseout', 'points', () => {
                    mouseout()
                  })

                  map.on('zoom', () => {
                    const zoomLevel = map.getZoom()
                    if (zoomLevel < 5) {
                      map.setLayoutProperty('points', 'visibility', 'none')
                    } else {
                      map.setLayoutProperty('points', 'visibility', 'visible')
                    }
                  })
                }

                // Lead properties
                map.loadImage(
                  LeadLocationFilled,
                  (error3: Error, leadLocationFilledImg: any) => {
                    if (error3) throw error3

                    map.loadImage(
                      LeadLocationOutlined,
                      (error4: Error, leadLocationOutlinedImg: any) => {
                        if (error4) throw error4
                        map.addImage(
                          'lead-location-filled',
                          leadLocationFilledImg
                        )
                        map.addImage(
                          'lead-location-outlined',
                          leadLocationOutlinedImg
                        )

                        // Add a symbol layer
                        map.addLayer({
                          id: 'leadPoints',
                          type: 'symbol',
                          source: 'leadProperties',
                          filter: ['!', ['has', 'point_count']],
                          layout: {
                            'icon-anchor': 'bottom',
                            'icon-image': 'lead-location-filled',
                            'icon-size': 0.65,
                            'icon-allow-overlap': false,
                          },
                          paint: {
                            // This checks if we're hovering and if we are, makes the icon opaque
                            'icon-opacity': 1,
                          },
                        })

                        function leadmouseover(feature: any) {
                          fHover = feature
                          map.getCanvasContainer().style.cursor = 'pointer'

                          map.setFeatureState(
                            {
                              source: 'leadProperties',
                              id: fHover.id,
                            },
                            {
                              hover: true,
                            }
                          )
                        }

                        function leadmouseout() {
                          if (!fHover) return
                          map.getCanvasContainer().style.cursor = 'default'
                          map.setFeatureState(
                            {
                              source: 'leadProperties',
                              id: fHover.id,
                            },
                            {
                              hover: false,
                            }
                          )
                          fHover = null
                        }

                        map.on('click', 'leadPoints', (e: any) => {
                          e.preventDefault()

                          const coordinates =
                            e.features[0].geometry.coordinates.slice()
                          const { markup } = e.features[0].properties
                          new mapboxgl.Popup({
                            closeButton: false,
                            anchor: 'bottom-left',
                            offset: [4, -16],
                          })
                            .setLngLat(coordinates)
                            .setHTML(markup)
                            .addTo(map)

                          // When we click on a point, we set the image to the outlined image and
                          // ensure the opacity is opaque
                          map.setLayoutProperty('leadPoints', 'icon-image', [
                            'match',
                            ['id'],
                            e.features[0].id,
                            'lead-location-outlined',
                            'lead-location-filled',
                          ])

                          map.setPaintProperty('leadPoints', 'icon-opacity', [
                            'match',
                            ['id'],
                            e.features[0].id,
                            1,
                            1,
                          ])
                        })

                        // when we click away from any point, we set the opacity to behave based on hover
                        map.on('click', (e: any) => {
                          if (e.defaultPrevented === false) {
                            map.setLayoutProperty(
                              'leadPoints',
                              'icon-image',
                              'lead-location-filled'
                            ) // reset all markers default hasn't been prevented (meaning I haven't clicked the points layer)
                            map.setPaintProperty(
                              'leadPoints',
                              'icon-opacity',
                              1
                            )
                          }
                        })

                        // Applies hover styles
                        map.on('mousemove', 'leadPoints', (e: any) => {
                          if (e.features[0]) {
                            leadmouseover(e.features[0])
                          } else {
                            leadmouseout()
                          }
                        })

                        map.on('mouseout', 'leadPoints', () => {
                          leadmouseout()
                        })
                      }
                    )
                  }
                )
              }
            )
          }
        )
      })
    }

    return () => {
      map?.remove()
    }
  }, [
    JSON.stringify(propertyAssets),
    isMobile,
    isTablet,
    JSON.stringify(leadPropertyAssets),
  ])

  return (
    <>
      {supportsWebGL ? (
        // doesn't work if you specify boxShadow in the containerStyle config
        <Box ref={mapContainerRef} sx={{ ...containerStyle, boxShadow: 8 }} />
      ) : (
        <Box
          sx={{
            ...containerStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            backgroundImage: `url(${StaticMap})`,
            backgroundSize: 'cover',
            borderRadius: '24px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backdropFilter: 'blur(8px)',
              zIndex: 3,
            }}
          />
          <Typography
            variant="body1"
            sx={{ color: 'white', marginLeft: '2rem', marginRight: '2rem' }}
            zIndex={4}
          >
            Please update to the latest browser version to view our portfolio
            map.
          </Typography>
        </Box>
      )}
    </>
  )
}

export default PortfolioMapbox

PortfolioMapbox.defaultProps = {
  desktopConfig: {},
  tabletConfig: {},
  mobileConfig: {},
  disableZoom: false,
  disableFullscreen: false,
  detailedPropertyView: false,
  defaultBounds: null,
  investorUrl: '',
  leadPropertyAssets: [],
}
