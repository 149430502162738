import { formatDollarsAndCents } from '@flock/utils'
import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend,
} from 'recharts'
import { AssetLeasingChartProps } from './detailedAssetViewTypes'

const CustomTooltip = (customTooltipProps: {
  label: any
  payload: any
  dateFormatter: (date: any) => string
}) => {
  const { label, payload } = customTooltipProps
  if (label && payload && payload.length) {
    const totalRent = payload[0]

    return (
      <Paper
        sx={{
          backgroundColor: 'white',
          borderRadius: '16px',
          padding: '16px',
        }}
        elevation={3}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          width="200px"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="self-start"
            width="50%"
          >
            <Typography variant="p2" color="moneyGreen.main" pr="16px">
              Total Rent
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="self-end"
            width="50%"
          >
            <Typography variant="p2" color="moneyGreen.main">
              {formatDollarsAndCents(totalRent.value / 100, true)}
            </Typography>
          </Box>
        </Box>
      </Paper>
    )
  }

  return null
}

const AssetLeasingChart = (props: AssetLeasingChartProps) => {
  const { leasingGraphData, tickFormatterX, tickFormatterY } = props

  const leasingDataSets = leasingGraphData.reduce((acc: any, curr: any) => {
    if (!acc[curr.set]) {
      acc[curr.set] = []
    }
    acc[curr.set].push(curr)
    return acc
  }, {})

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        margin={{
          top: 10,
          right: 25,
          left: -5,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="leaseStartDate"
          tickLine={false}
          domain={['auto', 'auto']}
          name="Time"
          tickFormatter={tickFormatterX}
          type="number"
          dy={10}
        />
        <YAxis
          domain={[0, (dataMax: any) => Math.ceil(dataMax / 100000) * 100000]}
          tickLine={false}
          tickFormatter={tickFormatterY}
        />
        <CartesianGrid stroke="#f5f5f5" />
        <Tooltip
          content={
            <CustomTooltip payload label dateFormatter={tickFormatterX} />
          }
        />
        <Legend
          wrapperStyle={{ top: -25, left: 360 }}
          payload={[
            {
              id: 'Rent',
              type: 'line',
              value: 'Rent',
              color: '#7B9488',
            },
          ]}
        />
        {Object.values(leasingDataSets).map((value: any) => (
          <Line
            dataKey="monthlyRent"
            isAnimationActive={false}
            data={value}
            key={value[0].set}
            stroke="#7B9488"
            strokeDasharray=""
            strokeWidth="2"
            dot={{
              stroke: '#7B9488',
              strokeDasharray: '',
              strokeWidth: '2',
              fillOpacity: 1,
              fill: '#FFFFFF',
            }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default AssetLeasingChart
