import React from 'react'
import DirectorySectionDesktop from './DirectorySectionDesktop'
import DirectorySectionMobile from './DirectorySectionMobile'
import DirectorySectionTablet from './DirectorySectionTablet'
import { DirectorySectionProps } from './directorySectionTypes'
import useDirectorySection from './useDirectorySection'

const DirectorySection = (props: DirectorySectionProps) => {
  const { isMobile, isTablet } = useDirectorySection()

  if (isMobile) {
    return <DirectorySectionMobile {...props} />
  } else if (isTablet) {
    return <DirectorySectionTablet {...props} />
  } else {
    return <DirectorySectionDesktop {...props} />
  }
}

export default DirectorySection
