import theme from '@flock/shared-ui/src/theme/theme'
import { formatPercentage } from '@flock/utils'
import { useMediaQuery } from '@mui/material'
import { formatDollarsAndCentsInvestor } from '../../../../utils/formatting'
import { SelectedLegalEntityAggregateTransactionData } from '../accountSectionTypes'

const useAccountDetailsHeader = (
  selectedLegalEntityAggregateTransactionData: SelectedLegalEntityAggregateTransactionData
) => {
  const {
    totalEquityValue,
    totalGainLoss,
    totalNetIncome,
    totalRedemptions,
    cumulativeReturn,
    annualizedReturn,
  } = selectedLegalEntityAggregateTransactionData

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const gainsAndLosses = totalGainLoss + totalNetIncome

  // todo: calculate annualized return
  // const annualizedReturn = totalReturn / totalEquityValue

  let cumulativeReturnString = formatPercentage(cumulativeReturn - 1, 1)
  if (cumulativeReturn >= 1) {
    cumulativeReturnString = `+${cumulativeReturnString}`
  } else {
    cumulativeReturnString = `${cumulativeReturnString}`
  }
  let annualizedReturnString = formatPercentage(annualizedReturn - 1, 1)
  if (cumulativeReturn >= 1) {
    annualizedReturnString = `+${annualizedReturnString}`
  } else {
    annualizedReturnString = `${annualizedReturnString}`
  }

  return {
    isMobile,
    isTablet,
    totalEquityValueString: formatDollarsAndCentsInvestor(
      Math.round(totalEquityValue / 100)
    ),
    returnsIsPositive: gainsAndLosses > 0,
    totalCashTakenString: formatDollarsAndCentsInvestor(
      totalRedemptions,
      true,
      false
    ),
    cumulativeReturnString,
    annualizedReturnString,
  }
}

export default useAccountDetailsHeader
