import { LeftArrowFilledIcon, PageContainer } from '@flock/shared-ui'
import { Info } from '@mui/icons-material'
import { Box, Grid, Paper, styled, Tooltip, Typography } from '@mui/material'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import React from 'react'
import { ACCOUNT_URL } from '../../constants'
import PageWrapper from '../SharedComponents/PageWrapper'
import useBalanceSheetPage from './useBalanceSheetPage'

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BalanceSheetPage = (_: RouteComponentProps) => {
  const {
    quarters,
    selectedQuarterBalanceSheetData,
    setSelectedQuarter,
    selectedQuarter,
  } = useBalanceSheetPage()
  return (
    <PageContainer title="FLOCK | Balance Sheet" trackingName="balanceSheet">
      <PageWrapper>
        <Grid
          container
          spacing={4}
          columns={12}
          height="auto"
          pl="32px"
          pr="32px"
          pb="32px"
        >
          <Grid item xs={3}>
            <Paper
              sx={{
                borderRadius: '32px',
                padding: '0px 16px 0px 0px',
                cursor: 'pointer',
              }}
              onClick={() => navigate(ACCOUNT_URL)}
            >
              <Box display="flex" alignItems="center">
                <LeftArrowFilledIcon height="48px" width="48px" />
                <Typography variant="p3">
                  Back to Owners&apos; Portal
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={9} />
          <Grid item xs={2} marginTop="60px" />
          <Grid item xs={10}>
            <Box display="flex" justifyContent="center" width="100%">
              <Typography variant="h2">Balance Sheet</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" width="100%" gap={1}>
              {quarters.map((quarter) => (
                <Paper
                  key={quarter}
                  sx={{
                    padding: '16px',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    borderRadius: '16px',
                    boxShadow:
                      selectedQuarter !== quarter
                        ? 'none'
                        : '0px 8px 24px 0px rgba(69, 73, 77, 0.08)',
                    backgroundColor:
                      selectedQuarter !== quarter
                        ? 'green0.main'
                        : 'green2.main',
                    cursor: selectedQuarter !== quarter ? 'pointer' : 'default',
                  }}
                  onClick={() =>
                    selectedQuarter !== quarter
                      ? setSelectedQuarter(quarter)
                      : undefined
                  }
                >
                  <Typography variant="p2">{quarter}</Typography>
                </Paper>
              ))}
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Paper
              sx={{
                borderRadius: '16px',
                padding: '24px',
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                flexDirection="column"
                gap="12px"
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="p1">Assets</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" color="gray5.main">
                    $,unaudited
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData.quarterString}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Total current assets
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterBalanceSheetData?.totalCurrentAssets}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total cash and restricted cash
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {
                      selectedQuarterBalanceSheetData?.totalCashAndRestrictedCash
                    }
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total accounts receivable
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterBalanceSheetData?.totalAccountsReceivable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Due from property manager
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.dueFromPropertyManager}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Due from property manager - security deposits
                  </Typography>
                  <Typography variant="l1">
                    {
                      selectedQuarterBalanceSheetData?.dueFromPropertyManagerSecurityDeposits
                    }
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Accounts receivable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.accountsReceivable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Accrued interest receivable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.accruedInterestReceivable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Insurance claims receivable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.insuranceClaimsReceivable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Other receivables
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.otherReceivables}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Bad debt write-off
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.badDebtWriteOff}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Utility reimbursement receivable
                  </Typography>
                  <Typography variant="l1">
                    {
                      selectedQuarterBalanceSheetData?.utilityReimbursementReceivable
                    }
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total other current assets
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterBalanceSheetData?.totalOtherCurrentAssets}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Prepaid association dues
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.prepaidAssociationDues}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Prepaid fees
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.prepaidFees}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Prepaid property insurance
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.prepaidPropertyInsurance}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Prepaid property taxes
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.prepaidPropertyTaxes}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Utility deposits
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.utilityDeposits}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Escrow deposits
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.escrowDeposits}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Other deposits&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          Tax payment escrow amount.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.otherDeposits}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Treasury securities
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.treasurySecurities}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Total fixed assets
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterBalanceSheetData?.totalFixedAssets}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Buildings/Land
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterBalanceSheetData?.buildings}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Capex - stabilizing
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {
                      selectedQuarterBalanceSheetData?.buildingImprovementsStabilizing
                    }
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Capex - status quo
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {
                      selectedQuarterBalanceSheetData?.buildingImprovementsStatusQuo
                    }
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Unrealized gain-loss
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterBalanceSheetData?.unrealizedGainLoss}
                  </Typography>
                </Box>
                <hr style={{ width: '100%', color: 'gray3.main' }} />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Total assets
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterBalanceSheetData?.totalAssets}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={8}>
            <Paper
              sx={{
                borderRadius: '16px',
                padding: '24px',
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                flexDirection="column"
                gap="12px"
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="p1">Liabilities & Equity</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" color="gray5.main">
                    $,unaudited
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData.quarterString}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Total liabilities
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterBalanceSheetData?.totalLiabilities}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total current liabilities
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterBalanceSheetData?.totalCurrentLiabilities}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Accounts payable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.accountsPayable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Due to Flock management company&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          Invoices reimbursed by Flock Homes, Inc.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {
                      selectedQuarterBalanceSheetData?.dueToFlockManagementCompany
                    }
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Property management fee payable
                  </Typography>
                  <Typography variant="l1">
                    {
                      selectedQuarterBalanceSheetData?.proeprtyManagementFeePayable
                    }
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Interest payable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.interestPayable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Other payables&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          Accrued expenses from property managers.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.otherPayables}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Accrued expense
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.accruedExpense}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant="l1" paddingLeft="32px">
                      Prepaid rent&nbsp;
                    </Typography>
                    <StyledTooltip
                      title={
                        <Typography variant="p3" color="gray8.main">
                          Shown as a liability until Flock can recognize rent in
                          the respective month owed.
                        </Typography>
                      }
                      enterTouchDelay={0}
                      data-cy="distributionsFFOTooltipIcon"
                      placement="right"
                      arrow
                    >
                      <StyledInfoOutline fontSize="small" color="disabled" />
                    </StyledTooltip>
                  </Box>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.prepaidRent}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Prepaid contribution
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.prepaidContribution}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Distributions + withdrawals payable
                  </Typography>
                  <Typography variant="l1">
                    {
                      selectedQuarterBalanceSheetData?.distributionsWithdrawalsPayable
                    }
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Property insurance payable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.propertyInsurancePayable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Property taxes payable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.propertyTaxesPayable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Pet deposits
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.petDeposits}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Security deposits
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.securityDeposits}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Utilities payable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.utilitiesPayable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Repairs payable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.repairsPayable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Asset management fee payable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.assetManagementFeePayable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Deferred revenue
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.deferredRevenue}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Security deposit clearing
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.securityDepositClearing}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total long-term liabilities
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterBalanceSheetData?.totalLongTermLiabilities}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Loans payable
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.loansPayable}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Debt issuance cost
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.debtIssuanceCost}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Total equity
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterBalanceSheetData?.totalEquity}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Equity from investors
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterBalanceSheetData?.equityFromInvestors}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Contributions
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.contributions}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Distributions and withdrawals
                  </Typography>
                  <Typography variant="l1">
                    {
                      selectedQuarterBalanceSheetData?.distributionsAndWithdrawals
                    }
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="16px" fontWeight="400">
                    Total retained earnings
                  </Typography>
                  <Typography variant="l1" fontWeight="400">
                    {selectedQuarterBalanceSheetData?.totalRetainedEarnings}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Retained earnings
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.retainedEarnings}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" paddingLeft="32px">
                    Prior year retained earnings
                  </Typography>
                  <Typography variant="l1">
                    {selectedQuarterBalanceSheetData?.priorYearRetainedEarnings}
                  </Typography>
                </Box>
                <hr style={{ width: '100%', color: 'gray3.main' }} />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="l1" fontWeight="500">
                    Total liabilities and equity
                  </Typography>
                  <Typography variant="l1" fontWeight="500">
                    {selectedQuarterBalanceSheetData?.totalLiabilitiesAndEquity}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </PageWrapper>
    </PageContainer>
  )
}

export default BalanceSheetPage
