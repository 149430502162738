import React from 'react'
import { AccountDetailsHeaderProps } from './accountDetailsHeaderTypes'
import AccountDetailsHeaderDesktop from './AccountDetailsHeaderDesktop'
import AccountDetailsHeaderMobile from './AccountDetailsHeaderMobile'
import AccountDetailsHeaderTablet from './AccountDetailsHeaderTablet'
import useAccountDetailsHeader from './useAccountDetailsHeader'

const AccountDetailsHeader = (props: AccountDetailsHeaderProps) => {
  const { selectedLegalEntityAggregateTransactionData } = props

  const { isMobile, isTablet, ...otherProps } = useAccountDetailsHeader(
    selectedLegalEntityAggregateTransactionData
  )

  if (isMobile) {
    return <AccountDetailsHeaderMobile {...otherProps} />
  } else if (isTablet) {
    return <AccountDetailsHeaderTablet {...otherProps} />
  } else {
    return <AccountDetailsHeaderDesktop {...otherProps} />
  }
}

export default AccountDetailsHeader
