import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const ChatFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 28}
      height={height || 28}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M26.8847 8.57503C26.1835 6.90167 25.1635 5.3807 23.8815 4.0969C22.6033 2.81087 21.085 1.78839 19.4128 1.08753C17.6997 0.366065 15.8591 -0.00375735 14.0003 2.87791e-05H13.9378C12.0472 0.00940378 10.219 0.384404 8.49715 1.1219C6.83925 1.82995 5.33518 2.85424 4.06903 4.13753C2.79944 5.41845 1.79113 6.93409 1.10028 8.60003C0.383136 10.3324 0.0197584 12.1907 0.031527 14.0657C0.040902 16.2344 0.559652 18.3875 1.5284 20.3125V25.0625C1.5284 25.8563 2.17215 26.5 2.96278 26.5H7.70653C9.64051 27.4759 11.7747 27.9894 13.9409 28H14.0065C15.8753 28 17.6847 27.6375 19.3909 26.9282C21.0547 26.2357 22.5673 25.2251 23.844 23.9532C25.1315 22.675 26.144 21.1813 26.8534 19.5157C27.5878 17.7907 27.9628 15.9563 27.9722 14.0625C27.9784 12.1594 27.6097 10.3125 26.8847 8.57503ZM7.76278 15.5C6.93778 15.5 6.2659 14.8282 6.2659 14C6.2659 13.1719 6.93778 12.5 7.76278 12.5C8.58778 12.5 9.25965 13.1719 9.25965 14C9.25965 14.8282 8.5909 15.5 7.76278 15.5ZM14.0003 15.5C13.1753 15.5 12.5034 14.8282 12.5034 14C12.5034 13.1719 13.1753 12.5 14.0003 12.5C14.8253 12.5 15.4972 13.1719 15.4972 14C15.4972 14.8282 14.8253 15.5 14.0003 15.5ZM20.2378 15.5C19.4128 15.5 18.7409 14.8282 18.7409 14C18.7409 13.1719 19.4128 12.5 20.2378 12.5C21.0628 12.5 21.7347 13.1719 21.7347 14C21.7347 14.8282 21.0628 15.5 20.2378 15.5Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default ChatFilledIcon
