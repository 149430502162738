import { useMutation } from '@apollo/client'
import { ProjectedDataPoint } from '@flock/shared-ui'
import {
  AccountProjectionCaseConfigurations,
  DEFAULT_DISTRIBUTION_FACTOR,
  localStore,
} from '@flock/utils'
import { InvestorPostSlackMessageDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useEffect } from 'react'
import {
  DANIELLE_SLACK_ID,
  EXCHANGE_SERVICING_SLACK_CHANNEL,
  JACOB_SLACK_ID,
} from '../constants'

// If logSegment is true, events are not sent to segment and are
// instead logged to the console.
const logSegment = process.env.GATSBY_LOG_SEGMENT === 'true'

const debugSegment = () =>
  logSegment || window.location.href.includes('localhost')

export const shouldTrack = () => {
  let doNotTrack = false
  if (typeof window !== 'undefined') {
    doNotTrack =
      window.doNotTrack === '1' ||
      navigator.doNotTrack === 'yes' ||
      navigator.doNotTrack === '1' ||
      localStore?.getItem('disableTracking') === 'true' ||
      debugSegment()
  }
  return !doNotTrack
}

const calculateEquity = (
  value: number,
  annualHPA: number,
  monthsLeft: number = 12
) =>
  value *
  (1 + monthsLeft * (annualHPA / 12) * 2) *
  (1 - monthsLeft * (0.01 / 12))

const calculateInitialDistribution = (
  value: number,
  annualRentGrowth: number,
  monthsLeft: number = 12
) =>
  ((monthsLeft * DEFAULT_DISTRIBUTION_FACTOR) / 12) *
  value *
  (1 + annualRentGrowth)

const calculateDistribution = (
  baseDistribution: number,
  annualRentGrowth: number,
  power: number
) => baseDistribution * (1 + annualRentGrowth) ** power

export const calculateProjectedReturns = (
  initialContribution: number,
  contributionDate: Date
) => {
  const year = contributionDate.getUTCFullYear()
  const monthsLeft = 11 - contributionDate.getMonth()

  const result: any[] = []

  if (initialContribution === 0) {
    return result
  }

  const previousEquities: { [key: string]: number } = {
    bull: initialContribution,
    base: initialContribution,
    bear: initialContribution,
  }

  const baseDistributions: { [key: string]: number } = {
    bull: 0,
    base: 0,
    bear: 0,
  }

  let resultObject: ProjectedDataPoint = {
    year,
    bull: 0,
    base: 0,
    bear: 0,
  }

  // Calculate the prorated value for the year based on the latest contribution
  AccountProjectionCaseConfigurations.forEach((config) => {
    const equity = calculateEquity(
      initialContribution,
      config.annualHPA,
      monthsLeft
    )
    const distribution = calculateInitialDistribution(equity, 0, monthsLeft)
    previousEquities[config.name] = equity

    resultObject[config.name] = equity + distribution
  })
  result.push(resultObject)

  for (let i = year + 1; i < year + 5; i += 1) {
    resultObject = {
      year: i,
      bull: 0,
      base: 0,
      bear: 0,
    }
    for (let j = 0; j < AccountProjectionCaseConfigurations.length; j += 1) {
      const config = AccountProjectionCaseConfigurations[j]
      const equity = calculateEquity(
        previousEquities[config.name],
        config.annualHPA
      )

      let distribution = 0
      if (!baseDistributions[config.name]) {
        distribution = calculateInitialDistribution(
          previousEquities[config.name],
          config.annualRentGrowth
        )
        baseDistributions[config.name] = distribution
      } else {
        distribution = calculateDistribution(
          baseDistributions[config.name],
          config.annualRentGrowth,
          i - year - 1
        )
      }

      previousEquities[config.name] = equity
      resultObject[config.name] = equity + distribution
    }
    result.push(resultObject)
  }

  return result
}

export const snakeToTitleCase = (text: string) => {
  if (!text) {
    return ''
  }
  const lowercaseText = text.toLowerCase().replace(/_/g, ' ')
  return lowercaseText.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export const useAlertDuration = (
  uuid: string,
  firstName: string,
  lastName: string
) => {
  const [alertDuration] = useMutation(InvestorPostSlackMessageDocument)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        !uuid ||
        uuid === 'f00a3f62-1baa-4e2b-bc3e-16eab3981891' ||
        uuid === 'fe26cb1f-1249-411b-8f96-a51fd4854075' ||
        !shouldTrack()
      ) {
        return
      }
      alertDuration({
        variables: {
          postSlackMessageInput: {
            channel: EXCHANGE_SERVICING_SLACK_CHANNEL,
            text: `Investor currently viewing dashboard for > 5 minutes: ${firstName} ${lastName} (${uuid}) cc: ${DANIELLE_SLACK_ID} ${JACOB_SLACK_ID}`,
          },
        },
      })
    }, 5 * 60 * 1000) // 5 minutes

    return () => clearTimeout(timer) // Cleanup timer on unmount
  }, [alertDuration, firstName, lastName, uuid])
}
