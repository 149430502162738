import { PortfolioMapbox, PillSelector, OpenInNewIcon } from '@flock/shared-ui'
import {
  Box,
  Typography,
  Paper,
  Grid,
  styled,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material'
import {
  FLOCK_FUND_STARTING_QUARTER,
  FLOCK_FUND_STARTING_YEAR,
  formatIntegerDollars,
} from '@flock/utils'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { millify } from 'millify'

import { DETAILED_ASSET_URL, MAPBOX_TOKEN } from '../../constants'
import { track } from '../../utils/analytics'
import SectionLayout from '../SharedComponents/SectionLayout'

type FundHoldingsProps = {
  id: string
}

type MetricDisplayValues = {
  portfolioValue: string
  actualValue: number
  numberOfHomes: number
  averageFMV: string
  averageRent: string
  occupancy: string
  homesAdded: string
  averageHomeValuePerSqft: string
  grossYield: string
}

type MapboxConfig = {
  containerStyle: {
    height: string
    borderRadius: string
  }
  center: number[]
  startingZoom: number
  minZoom?: number
  bounds?: number[][]
}

const PaperContainer = styled(Paper)({
  backgroundColor: 'white',
  borderRadius: '16px',
  padding: '16px',
})

const displayNameFromQuarter = (quarterString: string) =>
  `Q${quarterString.slice(-1)}'${quarterString.slice(2, 4)}`

const tooltipNameFromQuarter = (quarterString: string) =>
  `Q${quarterString.slice(-1)} ${quarterString.slice(0, 4)}`

const FundHoldings = (props: FundHoldingsProps) => {
  const { id } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  // TODO use contribution date instead when we switch to property acquisitions
  const { getPropertySummaryDetails, getDailyMetricsV1 } =
    useStaticQuery(graphql`
      query {
        getPropertySummaryDetails {
          propertyDetails {
            address {
              city
              formattedStreet
              latitude
              longitude
              state
              unit
              zipcode
              primaryPhotoUrl
            }
            baths
            acquisitionDate
            beds
            halfBaths
            propertyType
            sqft
            grossYield
            propertyUuid
            yearBuilt
          }
        }
        getDailyMetricsV1 {
          dailyMetrics {
            state
            numProperties
            numOccupiedProperties
            totalSqft
            totalValuation
            totalOccupiedValuation
            totalMonthlyRentCents
            numPropertiesThisQuarter
          }
        }
      }
    `)

  const { propertyDetails } = getPropertySummaryDetails
  const processedAssets = propertyDetails.map((asset: any) => ({
    ...asset,
    ...asset.address,
    baths: asset.baths + (asset.halfBaths / 2 || 0),
  }))

  const nationwideConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-94.9903, 37.7392],
    startingZoom: 2.5,
  }
  const coloradoConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-105.5, 39],
    bounds: [
      [-110, 36.5],
      [-101, 41.5],
    ],
    startingZoom: 5.2,
    minZoom: 5.2,
  }
  const arizonaConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-111.5, 30.5],
    bounds: [
      [-114.5, 25.5],
      [-109, 40.5],
    ],
    startingZoom: 3.5,
    minZoom: 3.5,
  }
  const georgiaConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-84, 32.5],
    bounds: [
      [-88, 30],
      [-79, 35.5],
    ],
    startingZoom: 3.4,
    minZoom: 3.4,
  }
  const kansasConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-97.5, 39],
    bounds: [
      [-103.5, 36.25],
      [-93.5, 41.25],
    ],
    startingZoom: 5.2,
    minZoom: 5.2,
  }
  const louisianaConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-91.5, 32.5],
    bounds: [
      [-95.5, 28.5],
      [-88, 34],
    ],
    startingZoom: 3.5,
    minZoom: 3.5,
  }
  const missouriConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-92.5, 38],
    bounds: [
      [-98.5, 36],
      [-87.5, 41.25],
    ],
    startingZoom: 4,
    minZoom: 4,
  }
  const northCarolinaConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-79.5, 35.5],
    bounds: [
      [-85.5, 32],
      [-75.5, 38],
    ],
    startingZoom: 5.3,
    minZoom: 5.3,
  }
  const ohioConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-83.5, 41.5],
    bounds: [
      [-85.5, 34.5],
      [-79.5, 47.5],
    ],
    startingZoom: 3.5,
    minZoom: 3.5,
  }
  const southCarolinaConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-79.5, 34],
    bounds: [
      [-85.5, 32],
      [-75.5, 36],
    ],
    startingZoom: 3.5,
    minZoom: 3.5,
  }
  const tennesseeConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-85.5, 36],
    bounds: [
      [-90.75, 33],
      [-80.5, 38],
    ],
    startingZoom: 4,
    minZoom: 4,
  }
  const texasConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-99.5, 31.5],
    bounds: [
      [-109, 25.5],
      [-91, 37.5],
    ],
    startingZoom: 3.5,
    minZoom: 3.5,
  }
  const washingtonConfig: MapboxConfig = {
    containerStyle: {
      height: '364px',
      borderRadius: '24px',
    },
    center: [-105.5, 39],
    bounds: [
      [-125, 45],
      [-116, 49.5],
    ],
    startingZoom: 4,
    minZoom: 4,
  }

  const mobileConfig = {
    containerStyle: {
      height: '200px',
      borderRadius: '24px',
    },
    center: [-94.9903, 37.7392],
    startingZoom: 0,
  }

  const portfolioViewToMapboxConfig: { [key: string]: MapboxConfig } = {
    nationwide: nationwideConfig,
    AZ: arizonaConfig,
    CO: coloradoConfig,
    GA: georgiaConfig,
    KS: kansasConfig,
    LA: louisianaConfig,
    MO: missouriConfig,
    NC: northCarolinaConfig,
    OH: ohioConfig,
    SC: southCarolinaConfig,
    TN: tennesseeConfig,
    TX: texasConfig,
    WA: washingtonConfig,
  }

  const pillOptions = [
    {
      label: 'All (Nationwide)',
      value: 'nationwide',
    },
    {
      label: 'Arizona',
      value: 'AZ',
    },
    {
      label: 'Colorado',
      value: 'CO',
    },
    {
      label: 'Georgia',
      value: 'GA',
    },
    {
      label: 'Kansas',
      value: 'KS',
    },
    {
      label: 'Louisiana',
      value: 'LA',
    },
    {
      label: 'Missouri',
      value: 'MO',
    },
    {
      label: 'North Carolina',
      value: 'NC',
    },
    {
      label: 'Ohio',
      value: 'OH',
    },
    {
      label: 'South Carolina',
      value: 'SC',
    },
    {
      label: 'Tennessee',
      value: 'TN',
    },
    {
      label: 'Texas',
      value: 'TX',
    },
    {
      label: 'Washington',
      value: 'WA',
    },
  ]

  // 20211 => 2021 the year, 1 at the end for the quarter
  // this allows us to sort in numerical order
  const flockOperatingQuarters: string[] = useMemo(() => {
    const currentDate = new Date()
    const operatingQuarters = []
    for (
      let year = FLOCK_FUND_STARTING_YEAR;
      year <= currentDate.getFullYear();
      year += 1
    ) {
      let startingQuarter = 1
      if (year === FLOCK_FUND_STARTING_YEAR) {
        startingQuarter = FLOCK_FUND_STARTING_QUARTER
      }
      let endingQuarter = 4
      if (year === currentDate.getFullYear()) {
        endingQuarter = Math.floor((currentDate.getMonth() + 3) / 3)
      }
      for (
        let quarter = startingQuarter;
        quarter <= endingQuarter;
        quarter += 1
      ) {
        operatingQuarters.push(`${year}${quarter}`)
      }
    }
    return operatingQuarters
  }, [])

  const [selectedView, setSelectedView] = useState<string>('nationwide')
  const [flockPortfolioValue, setFlockPortfolioValue] = useState<string>(
    'Flock portfolio value'
  )
  const [flockPortfolioValueMobile, setFlockPortfolioValueMobile] =
    useState<string>('Portfolio value')
  const [mapboxConfig, setMapboxConfig] =
    useState<MapboxConfig>(nationwideConfig)
  const [assets, setAssets] = useState<any>(processedAssets)
  const [portfolioHomeCountTitle, setPortfolioHomeCountTitle] =
    useState<string>('Flock portfolio home count')
  const [portfolioHomeCountTitleMobile, setPortfolioHomeCountTitleMobile] =
    useState<string>('Home count')
  const [metricDisplayValues, setMetricDisplayValues] =
    useState<MetricDisplayValues>()

  const updatePortfolioView = (v: string) => {
    const portfolioView = v
    setSelectedView(portfolioView as string)
    setMapboxConfig(portfolioViewToMapboxConfig[portfolioView as string])
    let realAssets = processedAssets
    if (portfolioView !== 'nationwide') {
      realAssets = processedAssets.filter(
        (asset: any) => asset.address.state === portfolioView
      )
      setPortfolioHomeCountTitle(
        `Flock portfolio home count in ${portfolioView}`
      )
      setPortfolioHomeCountTitleMobile(`Home count in ${portfolioView}`)
      setFlockPortfolioValue(`Flock portfolio value in ${portfolioView}`)
      setFlockPortfolioValueMobile(`Portfolio value in ${portfolioView}`)
    } else {
      setPortfolioHomeCountTitle('Flock portfolio home count')
      setPortfolioHomeCountTitleMobile('Home count')
      setFlockPortfolioValue('Flock portfolio value')
      setFlockPortfolioValueMobile('Portfolio value')
    }
    setAssets(realAssets)
    track(`portfolio-filter-${portfolioView}`)
  }

  const parseDailyMetricsDataHelper = useCallback(async () => {
    const { dailyMetrics } = getDailyMetricsV1

    // defaults to portfolio, which will always be the last item in the array
    let dailyMetric = dailyMetrics[dailyMetrics.length - 1]

    if (selectedView !== 'nationwide') {
      ;[dailyMetric] = dailyMetrics.filter(
        (metric: any) => metric.state === selectedView
      )
    }

    const {
      totalValuation,
      totalOccupiedValuation,
      numProperties,
      totalMonthlyRentCents,
      numOccupiedProperties,
      numPropertiesThisQuarter,
    } = dailyMetric

    const totalValuationNum = parseFloat(totalValuation) / 100

    let displayTotalValuation = totalValuationNum.toLocaleString()
    if (totalValuationNum >= 1000000) {
      displayTotalValuation = millify(totalValuationNum, {
        precision: 1,
        lowercase: true,
        units: ['', 'k', ' million', ' billion', ' trillion'],
      })
    }

    const totalOccupiedValuationNum = parseFloat(totalOccupiedValuation) / 100
    const totalRent = totalMonthlyRentCents / 100

    const averageFMV = totalValuationNum / numProperties
    const averageRent = totalRent / numProperties
    const occupancyPercentage = numOccupiedProperties / numProperties
    const homesAddedThisQuarter = numPropertiesThisQuarter.toString()
    const averageHomeValuePerSqft = totalValuationNum / dailyMetric.totalSqft
    const grossYield = (totalRent / totalOccupiedValuationNum) * 12

    const dailyMetricsView: MetricDisplayValues = {
      portfolioValue: displayTotalValuation,
      actualValue: 0,
      numberOfHomes: numProperties.toString(),
      averageFMV: formatIntegerDollars(averageFMV, true),
      averageRent: formatIntegerDollars(averageRent, true),
      occupancy: `${(occupancyPercentage * 100).toFixed(1)}%`,
      homesAdded: homesAddedThisQuarter,
      averageHomeValuePerSqft: formatIntegerDollars(
        averageHomeValuePerSqft,
        true
      ),
      grossYield: `${(grossYield * 100).toFixed(1)}%`,
    }
    setMetricDisplayValues(dailyMetricsView)
  }, [selectedView])

  useEffect(() => {
    parseDailyMetricsDataHelper()
  }, [
    assets,
    flockOperatingQuarters,
    selectedView,
    getDailyMetricsV1,
    parseDailyMetricsDataHelper,
  ])

  return isMobile ? (
    <SectionLayout name="fund-holdings-section" columns={4} id={id}>
      <Grid xs={4} item>
        <Typography variant="h3">Fund Holdings</Typography>
      </Grid>
      <Grid xs={4} item>
        <PillSelector
          options={pillOptions}
          selectedValue={selectedView}
          onSelect={updatePortfolioView}
        />
      </Grid>
      <Grid xs={4} item>
        <Box>
          <PortfolioMapbox
            propertyAssets={assets}
            mapboxToken={MAPBOX_TOKEN || ''}
            desktopConfig={mapboxConfig}
            tabletConfig={mapboxConfig}
            mobileConfig={mobileConfig}
          />
        </Box>
      </Grid>
      <Grid xs={4} item>
        <PaperContainer>
          <Box
            display="flex"
            flexDirection="row"
            padding="8px"
            alignItems="left"
            justifyContent="space-evenly"
            gap="8px"
          >
            <Box
              gap="8px"
              display="flex"
              flexDirection="column"
              alignItems="left"
            >
              <Typography variant="p3" color="moneyGreen.main">
                {flockPortfolioValueMobile}
              </Typography>
              <Typography variant="h4" fontWeight={500} color="moneyGreen.main">
                ${metricDisplayValues?.portfolioValue}
              </Typography>
            </Box>
            <Box
              gap="8px"
              display="flex"
              flexDirection="column"
              alignItems="left"
            >
              <Typography variant="p3" color="moneyGreen.main">
                {portfolioHomeCountTitleMobile}
              </Typography>
              <Typography variant="h4" fontWeight={500} color="moneyGreen.main">
                {`${metricDisplayValues?.numberOfHomes} houses`}
              </Typography>
            </Box>
          </Box>
        </PaperContainer>
      </Grid>
    </SectionLayout>
  ) : (
    <SectionLayout name="fund-holdings-section" columns={12} id={id}>
      <Grid xs={12} item>
        <Typography variant="h3">Fund Holdings</Typography>
      </Grid>
      <Grid xs={12} item>
        <PillSelector
          options={pillOptions}
          selectedValue={selectedView}
          onSelect={updatePortfolioView}
        />
      </Grid>
      <Grid xs={12} item>
        <Box sx={{ height: '364px' }}>
          <PortfolioMapbox
            propertyAssets={assets}
            mapboxToken={MAPBOX_TOKEN || ''}
            desktopConfig={mapboxConfig}
            tabletConfig={mapboxConfig}
            mobileConfig={mobileConfig}
            investorUrl={isTablet ? '' : DETAILED_ASSET_URL}
          />
        </Box>
      </Grid>
      {!isTablet && (
        <Grid xs={12} item>
          <Box display="flex" justifyContent="center">
            <Button
              variant="primary"
              color="primary"
              onClick={() => window.open(DETAILED_ASSET_URL)}
              sx={{
                display: 'flex',
                width: '448px',
                height: '48px',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <Typography>View all homes in Flock&apos;s portfolio</Typography>
              <OpenInNewIcon width="32px" height="32px" color="white" />
            </Button>
          </Box>
        </Grid>
      )}
      <Grid xs={12} item>
        <PaperContainer>
          <Box
            display="flex"
            flexDirection="row"
            padding="32px"
            alignItems="center"
            justifyContent="space-evenly"
            gap="16px"
          >
            <Box
              width="100%"
              gap="16px"
              display="flex"
              flexDirection="column"
              alignItems="left"
            >
              <Box
                gap="8px"
                display="flex"
                flexDirection="column"
                alignItems="left"
              >
                <Typography variant="p3" color="moneyGreen.main">
                  {isTablet ? flockPortfolioValueMobile : flockPortfolioValue}
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  color="moneyGreen.main"
                >
                  ${metricDisplayValues?.portfolioValue}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="16px"
              >
                <Box
                  gap="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="left"
                >
                  <Typography variant="p3" color="moneyGreen.main">
                    {isTablet ? 'Avg rent' : 'Average rent'}
                  </Typography>
                  <Typography
                    variant="p1"
                    fontWeight={500}
                    color="moneyGreen.main"
                  >
                    {metricDisplayValues?.averageRent}
                  </Typography>
                </Box>
                <Box
                  gap="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="left"
                >
                  <Typography variant="p3" color="moneyGreen.main">
                    {isTablet ? 'Avg FMV' : 'Average FMV'}
                  </Typography>
                  <Typography
                    variant="p1"
                    fontWeight={500}
                    color="moneyGreen.main"
                  >
                    {isTablet
                      ? `$${millify(
                          (metricDisplayValues?.actualValue || 0) /
                            (metricDisplayValues?.numberOfHomes || 1),
                          { precision: 1, units: ['', 'K', 'M', 'B', 'T'] }
                        )}`
                      : metricDisplayValues?.averageFMV}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              width="100%"
              gap="16px"
              display="flex"
              flexDirection="column"
              alignItems="left"
            >
              <Box
                gap="8px"
                display="flex"
                flexDirection="column"
                alignItems="left"
              >
                <Typography variant="p3" color="moneyGreen.main">
                  {isTablet
                    ? portfolioHomeCountTitleMobile
                    : portfolioHomeCountTitle}
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={500}
                  color="moneyGreen.main"
                >
                  {`${metricDisplayValues?.numberOfHomes} houses`}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="16px"
              >
                <Box
                  gap="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="left"
                >
                  <Typography variant="p3" color="moneyGreen.main">
                    Gross yield
                  </Typography>
                  <Typography
                    variant="p1"
                    fontWeight={500}
                    color="moneyGreen.main"
                  >
                    {metricDisplayValues?.grossYield}
                  </Typography>
                </Box>
                <Box
                  gap="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="left"
                >
                  <Typography variant="p3" color="moneyGreen.main">
                    Occupancy
                  </Typography>
                  <Typography
                    variant="p1"
                    fontWeight={500}
                    color="moneyGreen.main"
                  >
                    {metricDisplayValues?.occupancy}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              width="100%"
              gap="16px"
              display="flex"
              flexDirection="column"
              alignItems="left"
            >
              <Box
                gap="8px"
                display="flex"
                flexDirection="column"
                alignItems="left"
              >
                <Typography variant="p3" color="moneyGreen.main">
                  {isTablet
                    ? `Homes acq. ${displayNameFromQuarter(
                        flockOperatingQuarters[
                          flockOperatingQuarters.length - 1
                        ]
                      )}${
                        selectedView !== 'nationwide'
                          ? ` in ${selectedView}`
                          : ''
                      }`
                    : `Homes acquired in ${tooltipNameFromQuarter(
                        flockOperatingQuarters[
                          flockOperatingQuarters.length - 1
                        ]
                      )}${
                        selectedView !== 'nationwide'
                          ? ` in ${selectedView}`
                          : ''
                      }`}
                </Typography>
                <Typography variant="h4" fontWeight={500} color="#136D42">
                  {`+${metricDisplayValues?.homesAdded} houses`}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="16px"
              >
                <Box
                  gap="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="left"
                >
                  <Typography variant="p3" color="moneyGreen.main">
                    {isTablet ? 'Avg. per sqft' : 'Average per sqft'}
                  </Typography>
                  <Typography
                    variant="p1"
                    fontWeight={500}
                    color="moneyGreen.main"
                  >
                    {metricDisplayValues?.averageHomeValuePerSqft}
                  </Typography>
                </Box>
                <Box
                  gap="8px"
                  display="flex"
                  flexDirection="column"
                  alignItems="left"
                />
              </Box>
            </Box>
          </Box>
        </PaperContainer>
      </Grid>
    </SectionLayout>
  )
}

export default FundHoldings
