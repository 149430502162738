import { UpArrowIcon } from '@flock/shared-ui'
import { formatDollarsAndCents, formatPercentage } from '@flock/utils'
import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  Legend,
} from 'recharts'
import { AssetValuationChartProps } from './detailedAssetViewTypes'

const CustomTooltip = (customTooltipProps: {
  label: any
  payload: any
  dateFormatter: (date: any) => string
}) => {
  const { label, payload } = customTooltipProps
  if (label && payload && payload.length) {
    const isNotFirstValuation = payload[0].payload.previousValuation > 0
    const currentValuation = payload[payload.length - 1]
    const appreciation =
      currentValuation.value -
      currentValuation.payload.previousValuation -
      currentValuation.payload.capex

    return (
      <Paper
        sx={{
          backgroundColor: 'white',
          borderRadius: '16px',
          padding: '16px',
        }}
        elevation={3}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          width={isNotFirstValuation ? '300px' : '200px'}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="self-start"
            width={isNotFirstValuation ? '40%' : '50%'}
          >
            <Typography variant="p2" color="moneyGreen.main" pr="16px">
              Valuation
            </Typography>
            {currentValuation.payload.capex > 0 && (
              <Typography variant="p2" color="moneyGreen.main" pr="16px">
                Capex
              </Typography>
            )}
            {isNotFirstValuation && (
              <Typography variant="p2" color="moneyGreen.main" pr="16px">
                Appreciation
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="self-end"
            width={isNotFirstValuation ? '30%' : '50%'}
          >
            <Typography variant="p2" color="moneyGreen.main">
              {formatDollarsAndCents(currentValuation.value / 100, true)}
            </Typography>
            {currentValuation.payload.capex > 0 && (
              <Typography variant="p2" color="moneyGreen.main">
                {formatDollarsAndCents(
                  currentValuation.payload.capex / 100,
                  true
                )}
              </Typography>
            )}
            {isNotFirstValuation && (
              <Typography variant="p2" color="moneyGreen.main">
                {formatDollarsAndCents(appreciation / 100, true)}{' '}
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="self-start"
            width={isNotFirstValuation ? '25%' : '0%'}
          >
            <Typography
              variant="p2"
              color="moneyGreen.main"
              visibility="hidden"
            >
              _
            </Typography>
            {currentValuation.payload.capex > 0 && (
              <Typography
                variant="p2"
                color="moneyGreen.main"
                visibility="hidden"
              >
                _
              </Typography>
            )}
            {isNotFirstValuation && (
              <Typography
                variant="p2"
                color={appreciation > 0 ? 'green4.main' : 'moneyGreen.main'}
              >
                {appreciation > 0 && <UpArrowIcon height="12px" width="12px" />}
                {formatPercentage(
                  appreciation / currentValuation.payload.previousValuation,
                  2
                )}
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    )
  }

  return null
}

const AssetValuationChart = (props: AssetValuationChartProps) => {
  const { valuationGraphData, tickFormatterX, tickFormatterY } = props

  const valuationDataSets = valuationGraphData.reduce((acc: any, curr: any) => {
    if (!acc[curr.set]) {
      acc[curr.set] = []
    }
    acc[curr.set].push(curr)
    return acc
  }, {})

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        margin={{
          top: 10,
          right: 25,
          left: -5,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="effectiveDateTime"
          type="category"
          allowDuplicatedCategory={false}
          tickLine={false}
          tickFormatter={tickFormatterX}
          dy={10}
        />
        <YAxis
          domain={['auto', 'auto']}
          tickLine={false}
          tickFormatter={tickFormatterY}
        />
        <CartesianGrid stroke="#f5f5f5" />
        <Tooltip
          content={
            <CustomTooltip payload label dateFormatter={tickFormatterX} />
          }
        />
        <Legend
          wrapperStyle={{ top: -25, left: 300 }}
          payload={[
            {
              id: 'Valuations',
              type: 'line',
              value: 'Valuations',
              color: '#03341D',
            },
            {
              id: 'Capex',
              type: 'line',
              value: 'Capex',
              color: '#FFBB7E',
            },
          ]}
        />
        {Object.values(valuationDataSets).map((value: any) => (
          <Line
            dataKey="currentValuation"
            isAnimationActive={false}
            data={value}
            key={value[0].set}
            stroke={value[0].capexSet ? '#FFBB7E' : '#03341D'}
            strokeDasharray={value[0].capexSet ? '3 3' : ''}
            strokeWidth="2"
            dot={{
              stroke: value[0].capexSet ? '#FFBB7E' : '#03341D',
              strokeDasharray: '',
              strokeWidth: '2',
              fillOpacity: 1,
              fill: '#FFFFFF',
            }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default AssetValuationChart
