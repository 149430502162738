import { formatPercentage } from '@flock/utils'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { GainLossChartProps } from './gainLossChartTypes'
import useGainLossChart from './useGainLossChart'

const GainLossChartTabletMobile = (props: GainLossChartProps) => {
  const { marketData } = useGainLossChart({
    ...props,
  })

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          paddingX: '16px',
          paddingY: '4px',
        }}
      >
        <Typography variant="p3">Flock</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '28px',
          paddingX: '16px',
          paddingY: '4px',
        }}
      >
        {marketData
          ?.filter((data) => data.market !== 'Total')
          .map((data) => (
            <Box
              key={data.market}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="p3">{data.market}</Typography>
              <Typography variant="p3">
                {formatPercentage(data.flockPercentChange, 2)}
              </Typography>
            </Box>
          ))}
        <hr style={{ width: '100%', color: 'gray3.main' }} />
        {marketData
          ?.filter((data) => data.market === 'Total')
          .map((data) => (
            <Box
              key={data.market}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="p3">Total gains (loss)</Typography>
              <Typography variant="p3">
                {formatPercentage(data.flockPercentChange, 2)}
              </Typography>
            </Box>
          ))}
      </Box>
    </>
  )
}

export default GainLossChartTabletMobile
