import React from 'react'
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  FormControl,
} from '@mui/material'
import { flockTheme } from '../../theme'

export type ToggleButtonProps = MuiToggleButtonGroupProps & {
  options: {
    label: React.ReactNode
    value: any
  }[]
}

const ToggleButton = (props: ToggleButtonProps) => {
  const { value, onChange, options } = props
  return (
    <FormControl>
      <MuiToggleButtonGroup value={value} exclusive onChange={onChange}>
        {options.map((option, idx) => (
          <MuiToggleButton
            key={option.value}
            sx={{
              width: '64px',
              height: '32px',
              border: `2px solid ${flockTheme.palette.primary.main}`,
              borderTopLeftRadius: idx === 0 ? '200px' : '0px',
              borderBottomLeftRadius: idx === 0 ? '200px' : '0px',
              borderTopRightRadius:
                idx === options.length - 1 ? '200px' : '0px',
              borderBottomRightRadius:
                idx === options.length - 1 ? '200px' : '0px',
            }}
            value={option.value}
          >
            {option.label}
          </MuiToggleButton>
        ))}
      </MuiToggleButtonGroup>
    </FormControl>
  )
}

export default ToggleButton
