import {
  Core_OrderV3EntityInformationCompletedTaskResolutionDataInput,
  Core_OrderV3TaskStatus,
  CreateDocumentInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import { ORDERS_URL } from '../../../../constants'
import {
  EntityInformationData,
  EntityInformationFunctions,
} from './entityInformationTypes'

const uploadDocument = async (
  documentType: string,
  file: File,
  idx: number,
  legalEntityUuid: string,
  key: string,
  createLegalEntityDocument: (params: {
    variables: {
      createDocumentInput: CreateDocumentInput
    }
  }) => Promise<any>
) => {
  const timestamp = new Date()
  const fileExtension = file.name.split('.').pop()
  const createDocumentInput = {
    legalEntityUuid,
    fileName: `${file.name}-${timestamp.getTime()}-${idx}.${fileExtension}`,
    documentType,
    file,
    hidden: true,
  }
  const result = await createLegalEntityDocument({
    variables: {
      createDocumentInput,
    },
  })
  return {
    key,
    uuid: result?.data?.createDocument?.documentUuid,
  }
}

// eslint-disable-next-line import/prefer-default-export
export const flattenEntityInformationData = async (
  flowData: EntityInformationData,
  functions: EntityInformationFunctions
) => {
  const { entityInformation, orderUuid, taskUuid, legalEntityUuid } = flowData
  const { updateEntityInformationTask, createLegalEntityDocument } = functions

  const uploadPromises: Promise<{ key: string; uuid: string }>[] = []
  if (entityInformation?.entityDocuments) {
    const entityDocuments = (entityInformation.entityDocuments as File[]) || []
    entityDocuments.forEach((file, idx) => {
      uploadPromises.push(
        uploadDocument(
          'entity_information',
          file,
          idx,
          legalEntityUuid,
          'entityDocuments',
          createLegalEntityDocument
        )
      )
    })
  }

  const fileUploadResults: { key: string; uuid: string }[] = await Promise.all(
    uploadPromises
  )

  const entityInformationCompletedData: Core_OrderV3EntityInformationCompletedTaskResolutionDataInput =
    {
      entityName: entityInformation?.entityName,
      entityMailingAddress:
        entityInformation?.entityMailingAddress?.formattedAddress,
      entityDocuments: fileUploadResults
        .filter((fileUpload) => fileUpload.key === 'entityDocuments')
        .map((fileUpload) => fileUpload.uuid),
      entityIdType: entityInformation?.entityIdType,
      entityIdNumber:
        entityInformation?.entityIdType === 'ein'
          ? entityInformation?.entityEin
          : entityInformation?.entitySsn,
    }

  await updateEntityInformationTask({
    variables: {
      input: {
        orderUuid,
        taskData: {
          taskUuid,
          status: Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted,
          resolutionData: {
            data: {
              entityInformationCompletedTaskResolutionData:
                entityInformationCompletedData,
            },
          },
        },
      },
    },
  })
  navigate(`${ORDERS_URL}/${orderUuid}`)
}
