import { usePlaidLink } from 'react-plaid-link'
import React, { useCallback } from 'react'
import { TrackedButton } from '@flock/shared-ui'

// LINK COMPONENT
// Use Plaid Link and pass link token and onSuccess function
// in configuration to initialize Plaid Link
interface LinkProps {
  linkToken: string | null
  handlePlaidAccountData: (publicToken: string, accountIds: string[]) => void
}

const PlaidLink = (props: LinkProps) => {
  const { linkToken, handlePlaidAccountData } = props

  const onPlaidLinkSuccess = useCallback(
    (public_token, metadata) => {
      const plaidAccountIds = metadata.accounts.map(
        (account: any) => account.id
      )
      handlePlaidAccountData(public_token, plaidAccountIds)
    },
    [handlePlaidAccountData]
  )

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess: onPlaidLinkSuccess,
  }

  const { open, ready } = usePlaidLink(config)

  return (
    <TrackedButton
      onClick={() => open()}
      disabled={!ready}
      variant="primary"
      fullWidth
      sx={{ 'font-size': '21px', 'font-weight': 300 }}
    >
      Log in to your Bank Account
    </TrackedButton>
  )
}

export default PlaidLink
