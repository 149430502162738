import { ApolloError, useQuery } from '@apollo/client'
import { navigate, WindowLocation } from '@reach/router'
import {
  Core_LegalEntity as LegalEntity,
  Core_OrderV3Status as OrderV3Status,
  Core_InvestorAccount as InvestorAccount,
  InvestorGetInvestorAccountDocument,
  InvestorGetInvestmentInfoForLegalEntityDocument,
  InvestorGetInvestmentInfoForInvestorDocument,
  InvestorGetOrderV3StatusesForInvestorDocument,
  InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
  Core_InvestorAccount,
  Core_LegalEntity,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { sessionStore } from '@flock/utils'
import { useCookies } from 'react-cookie'
import { ACCOUNT_URL, ENABLE_SIMULATOR } from '../constants'
import { identify } from '../utils/analytics'

type InvestmentData = {
  pricePerShare: number
  totalContribution: number
  totalEquityValue: number
  totalShareCount: number
}

export type InvestorAccountContextProps = {
  investorAccountUuidOverride: string
  investorFirstName: string
  investorAccount: InvestorAccount
  legalEntities: LegalEntity[]
  investorContextLoading: boolean
  investorContextError: ApolloError | undefined
  selectedLegalEntity: LegalEntity
  setSelectedLegalEntity: (legalEntity: LegalEntity) => void
  selectedLegalEntityInvestmentData: InvestmentData
  legalEntityInvestmentData: InvestmentData[]
  activeOrderStatuses: OrderV3Status[]
  legalEntityUuid: string
  setLegalEntityUuid: (uuid: string) => void
}

export const InvestorAccountContext = createContext(
  {} as InvestorAccountContextProps
)

type InvestorAccountContextProviderProps = {
  location: WindowLocation
  children: React.ReactNode
}

export const InvestorAccountContextProvider = (
  props: InvestorAccountContextProviderProps
) => {
  const { location, children } = props
  const [investorAccountUuid, setInvestorAccountUuid] = useState('')
  const [investorAccountFirstName, setInvestorAccountFirstName] = useState('')
  const [investorAccount, setInvestorAccount] = useState({} as InvestorAccount)
  const [legalEntities, setLegalEntities] = useState([] as Core_LegalEntity[])
  const [selectedLegalEntity, setSelected] = useState({} as LegalEntity)
  const [investmentData, setInvestmentData] = useState([] as InvestmentData[])
  const [legalEntityUuid, setLegalEntityUuid] = useState('')
  const [selectedInvestmentData, setSelectedInvestmentData] = useState(
    {} as InvestmentData
  )
  const [activeOrderStatuses, setActiveOrderStatuses] = useState(
    [] as OrderV3Status[]
  )
  const [cookies, setCookie] = useCookies()

  const {
    refetch: getInvestorAccountUuid,
    loading: getInvestorAccountLoading,
    error: getInvestorAccountError,
  } = useQuery(InvestorGetInvestorAccountDocument, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  const {
    refetch: getInvestmentData,
    loading: investmentDataLoading,
    error: investmentDataError,
  } = useQuery(InvestorGetInvestmentInfoForLegalEntityDocument, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  const {
    refetch: getInvestmentDataForInvestorAccount,
    loading: investmentDataForInvestorLoading,
    error: investmentDataForInvestorError,
  } = useQuery(InvestorGetInvestmentInfoForInvestorDocument, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  const {
    refetch: getOrderStatusesForInvestor,
    loading: getOrderStatusesForInvestorLoading,
    error: getOrderStatusesForInvestorError,
  } = useQuery(InvestorGetOrderV3StatusesForInvestorDocument, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  // we must refetch this query after making updates to a legal entity
  const {
    loading: investorLegalEntityLoading,
    error: investorLegalEntityError,
    data: investorLegalEntityData,
  } = useQuery(InvestorGetLegalEntitiesByInvestorAccountUuidDocument, {
    skip: !investorAccountUuid,
    variables: {
      input: { investorAccountUuid },
    },
  })

  const anyLoading =
    investorLegalEntityLoading ||
    getInvestorAccountLoading ||
    investmentDataLoading ||
    investmentDataForInvestorLoading ||
    getOrderStatusesForInvestorLoading

  const error =
    investorLegalEntityError ||
    getInvestorAccountError ||
    investmentDataError ||
    investmentDataForInvestorError ||
    getOrderStatusesForInvestorError

  const setupContext = useCallback(
    async (overrideUuid?: string) => {
      let investorUuid = ''
      let investorFirstName = ''
      // we can get the investor account uuid from the auth session. if there's an override, we use that instead
      const { data: account } = await getInvestorAccountUuid({
        input: {
          investorAccountUuid: overrideUuid,
        },
      })
      investorUuid = account?.investorAccount?.investorAccount?.uuid as string
      investorFirstName = account?.investorAccount?.investorAccount
        ?.firstName as string

      setInvestorAccountUuid(investorUuid)
      setInvestorAccountFirstName(investorFirstName)
      setInvestorAccount(
        account?.investorAccount?.investorAccount as Core_InvestorAccount
      )
      identify({
        userId: investorUuid,
        type: 'investorAccount',
      })
    },
    [getInvestorAccountUuid]
  )

  // fetches investment data for all legal entities associated with this investorAccount
  const fetchInvestmentData = useCallback(
    async (investmentDataQueries: any[]) => {
      const res = await Promise.all(investmentDataQueries)
      const tempInvestmentData: InvestmentData[] = []

      res.forEach((data) => {
        let infoData = data.data.getInvestmentInfoForLegalEntity
          ?.legalEntityInvestmentInfo as InvestmentData
        if (!infoData) {
          infoData = data.data.getInvestmentInfoForInvestor
            ?.investorInvestmentInfo as InvestmentData
        }
        tempInvestmentData.push(infoData)
      })
      setInvestmentData(tempInvestmentData)
    },
    []
  )

  const fetchActiveOrdersForInvestor = useCallback(async () => {
    const { data: orderStatuses } = await getOrderStatusesForInvestor({
      input: {
        investorUuid: investorAccountUuid,
        onlyInProgress: true,
      },
    })
    setActiveOrderStatuses(
      orderStatuses?.getOrderV3StatusesForInvestor?.orders || []
    )
  }, [getOrderStatusesForInvestor, investorAccountUuid])

  // on render, get the investor account
  useEffect(() => {
    let adminInvestorUuidOverride = ''
    if (ENABLE_SIMULATOR && location?.href.includes('simulator')) {
      const splitUrl = location.href.split('simulator/')
      if (splitUrl.length > 0 && splitUrl[1]) {
        ;[adminInvestorUuidOverride] = splitUrl[1].split('/')
        setCookie('simulator', adminInvestorUuidOverride, { path: '/' })
        setupContext(adminInvestorUuidOverride)
      }
    } else if (ENABLE_SIMULATOR && cookies.simulator) {
      adminInvestorUuidOverride = cookies.simulator!
      setupContext(adminInvestorUuidOverride)
    } else {
      setupContext()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // sets list of all legal entities associated with investor account
  useEffect(() => {
    if (
      investorLegalEntityData?.getLegalEntitiesByInvestorAccountUuid
        ?.legalEntities &&
      investorLegalEntityData?.getLegalEntitiesByInvestorAccountUuid
        ?.legalEntities?.length
    ) {
      const allLegalEntities = [
        ...investorLegalEntityData.getLegalEntitiesByInvestorAccountUuid
          .legalEntities,
      ]

      // if there is more than one legal entity, add an aggregate option
      if (allLegalEntities.length > 1) {
        allLegalEntities.unshift({
          uuid: 'aggregate',
          name: 'Overview',
        } as LegalEntity)
      }

      setLegalEntities([...(allLegalEntities as Core_LegalEntity[])])
    }
  }, [investorLegalEntityData])

  useEffect(() => {
    let adminInvestorUuidOverride = ''
    if (ENABLE_SIMULATOR && location?.href.includes('simulator')) {
      const splitUrl = location.href.split('simulator/')
      if (splitUrl.length > 0 && splitUrl[1]) {
        ;[adminInvestorUuidOverride] = splitUrl[1].split('/')
        setCookie('simulator', adminInvestorUuidOverride, { path: '/' })
      }
    } else if (ENABLE_SIMULATOR && cookies.simulator) {
      adminInvestorUuidOverride = cookies.simulator!
    }

    const persistLegalEntityUuid = sessionStore.getItem(
      'simulator_legal_entity'
    )

    if (adminInvestorUuidOverride && persistLegalEntityUuid) {
      const persistLegalEntity =
        legalEntities.find(
          (entity) => entity.uuid === persistLegalEntityUuid
        ) || legalEntities[0]

      setSelected(persistLegalEntity)
    }
  }, [setCookie, legalEntities, location.href, cookies.simulator])

  // gets all investment data for each legal entity and sets the current legal entity
  useEffect(() => {
    const adminInvestorUuidOverride = ''

    if (!selectedLegalEntity?.uuid) {
      if (legalEntities?.length > 1) {
        const investmentDataQueries: any[] = []
        legalEntities.forEach((legalEntity) => {
          if (legalEntity) {
            if (legalEntity.uuid === 'aggregate') {
              investmentDataQueries.push(
                getInvestmentDataForInvestorAccount({
                  input: {
                    investorUuid: investorAccountUuid,
                    includeStaged: cookies.simulator !== null,
                  },
                })
              )
            } else {
              investmentDataQueries.push(
                getInvestmentData({
                  input: {
                    legalEntityUuid: legalEntity.uuid,
                    includeStaged: cookies.simulator !== null,
                  },
                })
              )
            }
          }
        })
        fetchInvestmentData(investmentDataQueries)

        setSelected(
          adminInvestorUuidOverride
            ? legalEntities.find(
                (entity) => entity.uuid === adminInvestorUuidOverride
              ) || legalEntities[0]
            : legalEntities[0]
        )
      } else if (legalEntities?.length) {
        fetchInvestmentData([
          getInvestmentData({
            input: {
              legalEntityUuid: legalEntities[0]?.uuid,
              includeStaged: cookies.simulator !== null,
            },
          }),
        ])
        setSelected(legalEntities[0])
      }
    } else if (legalEntities?.length) {
      setSelected(
        legalEntities.find(
          (legalEntity) => legalEntity.uuid === selectedLegalEntity.uuid
        ) || legalEntities[0]
      )
    }
  }, [
    cookies.simulator,
    fetchInvestmentData,
    getInvestmentData,
    getInvestmentDataForInvestorAccount,
    investorAccountUuid,
    legalEntities,
    selectedLegalEntity.uuid,
  ])

  // sets the current investment data based on the selected legal entity
  useEffect(() => {
    if (selectedLegalEntity?.uuid) {
      const legalEntityIdx = legalEntities.findIndex(
        (legalEntity) => legalEntity.uuid === selectedLegalEntity.uuid
      )
      setSelectedInvestmentData(
        investmentData[legalEntityIdx] ||
          ({
            pricePerShare: 0,
            totalContribution: 0,
            totalShareCount: 0,
          } as InvestmentData)
      )
    }
  }, [selectedLegalEntity, investmentData, legalEntities])

  // fetch the most recent order for the selected legal entity and redirect to the order page if there is no investment data
  useEffect(() => {
    if (investorAccountUuid) {
      fetchActiveOrdersForInvestor()
    }
  }, [investorAccountUuid, fetchActiveOrdersForInvestor])

  const setSelectedLegalEntity = useCallback(
    (legalEntity: LegalEntity) => {
      if (
        ENABLE_SIMULATOR &&
        (location?.href.includes('simulator') || cookies.simulator)
      ) {
        sessionStorage.setItem('simulator_legal_entity', legalEntity.uuid)
      }
      setSelected(legalEntity)
      navigate(ACCOUNT_URL)
    },
    [cookies.simulator, location?.href]
  )

  useEffect(() => {
    if (sessionStore.getItem('lp_onboarding_legal_entity_uuid')) {
      setLegalEntityUuid(
        sessionStore.getItem('lp_onboarding_legal_entity_uuid')!
      )
    }
  }, [])

  const setLegalEntityUuidStorage = useCallback(
    (newLegalEntityUuid: string) => {
      setLegalEntityUuid(newLegalEntityUuid)
      sessionStorage.setItem(
        'lp_onboarding_legal_entity_uuid',
        newLegalEntityUuid
      )
    },
    []
  )

  return (
    <InvestorAccountContext.Provider
      value={{
        investorAccountUuidOverride: investorAccountUuid,
        investorFirstName: investorAccountFirstName,
        investorAccount,
        legalEntities,
        investorContextLoading: anyLoading,
        investorContextError: error,
        selectedLegalEntity,
        setSelectedLegalEntity,
        selectedLegalEntityInvestmentData: selectedInvestmentData,
        legalEntityInvestmentData: investmentData,
        activeOrderStatuses,
        legalEntityUuid,
        setLegalEntityUuid: setLegalEntityUuidStorage,
      }}
    >
      {children}
    </InvestorAccountContext.Provider>
  )
}

export default InvestorAccountContextProvider

export const useInvestorAccountContext = () =>
  useContext(InvestorAccountContext)
