import { Box, Typography } from '@mui/material'
import React from 'react'
import { AccountDetailsHeaderComponentProps } from './accountDetailsHeaderTypes'

const AccountDetailsHeaderDesktop = (
  props: AccountDetailsHeaderComponentProps
) => {
  // todo: pass through new props
  const {
    totalEquityValueString,
    cumulativeReturnString,
    annualizedReturnString,
  } = props
  const totalReturnIsPositive =
    cumulativeReturnString !== undefined && cumulativeReturnString[0] === '+'

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      id="header"
    >
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" gap="4px">
          <Typography variant="p2" color="gray8.main" lineHeight="24px">
            My total Flock account value
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="baseline"
            gap="8px"
          >
            <Typography
              variant="p1"
              color="gray8.main"
              fontSize="40px"
              fontWeight="500"
              lineHeight="48px"
            >
              {totalEquityValueString}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box display="flex" flexDirection="column" gap="4px" alignItems="end">
          <Typography variant="c1" color="gray5.main">
            TOTAL RETURN
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="16px"
          >
            <Box display="flex" flexDirection="column" alignItems="end">
              <Typography
                variant="cta2"
                color={totalReturnIsPositive ? 'green4.main' : 'gray6.main'}
              >
                {cumulativeReturnString}
              </Typography>
              <Typography
                variant="p2"
                color={totalReturnIsPositive ? 'green4.main' : 'gray6.main'}
              >
                cumulative
              </Typography>
            </Box>
            <hr
              style={{
                marginTop: '-2px',
                width: '1px',
                height: '48px',
                background: 'black',
                border: 'none',
              }}
            />
            <Box display="flex" flexDirection="column" alignItems="end">
              <Typography
                variant="cta2"
                color={totalReturnIsPositive ? 'green4.main' : 'gray6.main'}
              >
                {annualizedReturnString}
              </Typography>
              <Typography
                variant="p2"
                color={totalReturnIsPositive ? 'green4.main' : 'gray6.main'}
              >
                annualized
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountDetailsHeaderDesktop
