import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import millify from 'millify'
import { useState, useEffect, useCallback } from 'react'
import { TooltipProps } from 'recharts'
import { QuarterData } from '../accountSectionTypes'
import {
  AccountOverTimeData,
  AccountOverTimeProps,
} from './accountOverTimeTypes'

const useAccountOverTime = (
  props: AccountOverTimeProps
): AccountOverTimeData => {
  const { quarterData, setHoveredQuarterLabel, periodSelection } = props
  const [mouseInChartArea, setMouseInChartArea] = useState<boolean>(false)
  const [hoveredQuarter, setHoveredQuarter] = useState<string>()
  const [filteredQuarterData, setFilteredQuarterData] = useState<QuarterData[]>(
    []
  )
  const [periodSelectorYears, setPeriodSelectorYears] = useState<number[]>([])
  const [hasNoQuarterData, setHasNoQuarterData] = useState<boolean>(true)
  const [yPrecision, setYPrecision] = useState<number>(1)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (mouseInChartArea) {
      setHoveredQuarterLabel(hoveredQuarter)
    } else {
      setHoveredQuarterLabel(undefined)
    }
  }, [mouseInChartArea, hoveredQuarter, setHoveredQuarterLabel])

  useEffect(() => {
    if (quarterData.length === 0) {
      setHasNoQuarterData(true)
      return
    }
    let quarterDataToReturn = quarterData.filter(
      (data) => data.quarterString !== undefined
    )

    if (quarterDataToReturn.length === 0) {
      return
    }

    const yearsToSelect = quarterDataToReturn.map((data) =>
      parseInt(data.quarterString?.split(' ')[1] || '2022', 10)
    )

    const uniqueYears = Array.from(new Set(yearsToSelect))

    if (periodSelection === '1Y') {
      quarterDataToReturn = quarterDataToReturn.slice(
        Math.max(quarterDataToReturn.length - 4, 0) // either most recent 4 quarters or all quarters if less than 4
      )
    }

    if (periodSelection !== 'ALL' && periodSelection !== '1Y') {
      quarterDataToReturn = quarterDataToReturn.filter((data) =>
        data.quarterString?.includes(periodSelection.toString())
      )
    }

    if (quarterDataToReturn.length === 0) {
      setHasNoQuarterData(true)
      return
    }

    for (let i = quarterDataToReturn.length; i < 4; i += 1) {
      quarterDataToReturn.unshift({} as QuarterData)
    }

    setFilteredQuarterData(quarterDataToReturn)
    setHasNoQuarterData(false)
    setPeriodSelectorYears(uniqueYears)
  }, [quarterData, periodSelection])

  const tickFormatterX = useCallback(
    (val: any) => {
      if (periodSelection === 'ALL' || isMobile) {
        const newQString = val.replace(' 20', "'")
        return newQString
      }

      return val
    },
    [isMobile, periodSelection]
  )

  const tickFormatterY = useCallback((val: any, idx: number) => {
    if (idx === 0 || !val) {
      return ''
    }
    let precision = 1
    if (val / 100 < 10000 || (val / 100 >= 1000000 && val / 100 < 100000000)) {
      // if val is under 10k or between 1m and 100m, show 2 decimal places to avoid duplicate labels
      precision = 2
    } else if (
      (val / 100 >= 100000 && val / 100 < 1000000) ||
      (val / 100 >= 100000000 && val / 100 < 1000000000)
    ) {
      // if val is from 100k to 1m or 100m to 1t, show no decimal places since we will overflow on the chart
      precision = 0
    }
    setYPrecision(precision)
    return `$${millify(val / 100, {
      precision,
      units: ['', 'k', 'M', 'B', 'T'],
    })}`
  }, [])

  const tooltipContentRenderer = useCallback(
    (e: TooltipProps<number, string>) => {
      setHoveredQuarter(e.label)
      return null
    },
    []
  )

  return {
    setMouseInChartArea,
    quarterData: filteredQuarterData,
    periodSelectorYears,
    hasNoQuarterData,
    tickFormatterX,
    tickFormatterY,
    yPrecision,
    tooltipContentRenderer,
  }
}

export default useAccountOverTime
