import React from 'react'
import PerformanceSummaryTableDesktop from './PerformanceSummaryTableDesktop'
import PerformanceSummaryTableMobile from './PerformanceSummaryTableMobile'
import PerformanceSummaryTableTablet from './PerformanceSummaryTableTablet'
import { PerformanceSummaryTableProps } from './performanceSummaryTableTypes'
import usePerformanceSummaryTable from './usePerformanceSummaryTable'

const PerformanceSummaryTable = (props: PerformanceSummaryTableProps) => {
  const {
    selectedLegalEntityAggregateTransactionData,
    selectedLegalEntityTaxData,
  } = props

  const { isMobile, isTablet, ...otherProps } = usePerformanceSummaryTable(
    selectedLegalEntityAggregateTransactionData,
    selectedLegalEntityTaxData
  )

  if (isMobile) {
    return <PerformanceSummaryTableMobile {...otherProps} />
  } else if (isTablet) {
    return <PerformanceSummaryTableTablet {...otherProps} />
  } else {
    return <PerformanceSummaryTableDesktop {...otherProps} />
  }
}

export default PerformanceSummaryTable
