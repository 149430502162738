import React, { useEffect } from 'react'
import confetti from 'canvas-confetti'
import { styled } from '@mui/material/styles'
import { Box, IconButton, Modal, Paper, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { INVESTOR_EMAIL, TrackedButton, TrackedLink } from '@flock/shared-ui'

type WelcomeToFlockModalParams = {
  isOpen: boolean
  close: () => void
}

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  maxHeight: '80vh',
  overflowY: 'auto',
  padding: '2rem',
})

const WelcomeToFlockModal = (props: WelcomeToFlockModalParams) => {
  const { isOpen, close } = props

  useEffect(() => {
    if (isOpen) {
      confetti({
        particleCount: 200,
        spread: 90,
        disableForReducedMotion: true,
        zIndex: 10000000,
      })
      confetti({
        particleCount: 50,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        disableForReducedMotion: true,
        zIndex: 10000000,
      })
      confetti({
        particleCount: 50,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        disableForReducedMotion: true,
        zIndex: 10000000,
      })
    }
  }, [isOpen])

  return (
    <Modal open={isOpen} onClose={close}>
      <ModalWrapper>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box textAlign="center">
            <Typography variant="h3" alignSelf="center">
              Welcome to Flock!
            </Typography>
          </Box>
          <div
            style={{
              position: 'relative',
              paddingBottom: '56.25%',
              height: '0',
            }}
          >
            <iframe
              title="Welcome to Flock"
              src="https://www.loom.com/embed/ee5d11db016c4b40b14d5dee29883217?sid=2429124a-ad1d-42f1-af6a-bba79fbba773?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
              frameBorder="0"
              allowFullScreen
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          <Typography variant="p3" color="moneyGreen.main">
            If you have any questions, please reach out to me at{' '}
            <TrackedLink
              onClick={() => window.open(`mailto:${INVESTOR_EMAIL}`)}
            >
              danielle@flockhomes.com
            </TrackedLink>{' '}
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="right">
            <Box width="120px">
              <TrackedButton
                variant="primary"
                size="mini"
                onClick={() => {
                  close()
                }}
              >
                Close
              </TrackedButton>
            </Box>
          </Box>
        </Box>
      </ModalWrapper>
    </Modal>
  )
}

export default WelcomeToFlockModal
