import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import { DirectorySectionData } from './directorySectionTypes'

const useDirectorySection = (): DirectorySectionData => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return {
    isMobile,
    isTablet,
  }
}

export default useDirectorySection
