import { useState } from 'react'
import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import { decimalToPercent, formatDollarsAndCents } from '@flock/utils'
import { FundPerformanceWaterfallContainerProps } from './fundPerformanceWaterFallTypes'

function roundToNearestHalfMillion(number: number) {
  return Math.ceil(number / 500000) * 500000
}

function abbreviateHalfMillion(number: number) {
  const sign = number >= 0 ? '+' : '-'
  const abbreviatedNumber = (Math.abs(number) / 1000000).toFixed(2)
  return `${sign}$${abbreviatedNumber}M`
}

function abbreviatePercent(number: number) {
  const sign = number >= 0 ? '+' : '-'
  const abbreviatedNumber = Math.abs(number * 100).toFixed(2)
  return `${sign}${abbreviatedNumber}%`
}

function concatenateQuarterName(string: string): string {
  // Split the string into two parts: quarter and year
  const [quarter, year] = string?.split(' ')
  // Extract the last two digits from the year
  const shortYear = year?.slice(-2)
  // Reconstruct the string with the short year
  const convertedString = `${quarter} ${shortYear}`

  return convertedString
}

const useFundPerformanceWaterfall = (
  props: FundPerformanceWaterfallContainerProps
) => {
  const {
    selectedQuarterTotalGainLossOnRealEstate: gainLossOnRealEstate,
    selectedQuarterTotalNetIncome: netPortfolioIncome,
    selectedQuarterPortfolioExpenses: portfolioExpenses,
    selectedQuarterPortfolioIncome: portfolioIncome,
    selectedQuarterTotalFundValuation,
    selectedQuarterFundValueChange: fundValueChange,
    selectedQuarter,
    shouldShowDollarView,
  } = props

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  let MAX_CHART_PIXEL_HEIGHT = 171
  let MIN_CHART_PIXEL_HEIGHT = 8

  let MAX_CHART_PIXEL_AREA = 163
  let MID_CHART_PIXEL_HEIGHT = MIN_CHART_PIXEL_HEIGHT + MAX_CHART_PIXEL_AREA / 2

  if (isMobile || isTablet) {
    MAX_CHART_PIXEL_HEIGHT = 92
    MIN_CHART_PIXEL_HEIGHT = 8

    MAX_CHART_PIXEL_AREA = 84
    MID_CHART_PIXEL_HEIGHT = MIN_CHART_PIXEL_HEIGHT + MAX_CHART_PIXEL_AREA / 2
  }

  const formattedSelectedQuarter = concatenateQuarterName(selectedQuarter)
  const [hoveredSection, setHoveredSection] = useState<string>('')

  let chartType = 'mixed'

  const chartAxisStrings: string[] = []

  let gainLossOnRealEstatePixelHeight = 0
  let gainLossOnRealEstateTopPixel = 0
  let fundValueChangePixelHeight = 0
  let fundValueChangeTopPixel = 0

  // desktop
  let portfolioIncomeTopPixel = 0
  let portfolioExpensesTopPixel = 0
  let portfolioIncomePixelHeight = 0
  let portfolioExpensesPixelHeight = 0

  // mobile
  let netPortfolioIncomeTopPixel = 0
  let netPortfolioIncomePixelHeight = 0

  if (isMobile || isTablet) {
    const maxPositive = gainLossOnRealEstate + netPortfolioIncome
    const maxNegative = Math.min(
      gainLossOnRealEstate,
      gainLossOnRealEstate + netPortfolioIncome
    )
    const maxPositiveScale = Math.max(
      Math.abs(maxPositive),
      Math.abs(maxNegative)
    )

    const maxScale = roundToNearestHalfMillion(maxPositiveScale)

    let totalChartScale = maxScale * 2

    if (maxPositive <= 0) {
      chartType = 'negative'
      totalChartScale = maxScale
    }
    if (maxNegative >= 0) {
      chartType = 'positive'
      totalChartScale = maxScale
    }

    // get height of each box
    gainLossOnRealEstatePixelHeight =
      (Math.abs(gainLossOnRealEstate) / totalChartScale) * MAX_CHART_PIXEL_AREA
    netPortfolioIncomePixelHeight =
      (netPortfolioIncome / totalChartScale) * MAX_CHART_PIXEL_AREA
    fundValueChangePixelHeight =
      (Math.abs(fundValueChange) / totalChartScale) * MAX_CHART_PIXEL_AREA

    // set heights to minimum if below 1
    if (gainLossOnRealEstatePixelHeight < 1) {
      gainLossOnRealEstatePixelHeight = 1.01
    }
    if (portfolioIncomePixelHeight < 1) {
      portfolioIncomePixelHeight = 1.01
    }
    if (portfolioExpensesPixelHeight < 1) {
      portfolioExpensesPixelHeight = 1.01
    }
    if (fundValueChangePixelHeight < 1) {
      fundValueChangePixelHeight = 1.01
    }

    if (chartType === 'mixed') {
      if (shouldShowDollarView) {
        chartAxisStrings.push(abbreviateHalfMillion(maxScale))
        chartAxisStrings.push(formattedSelectedQuarter)
        chartAxisStrings.push(abbreviateHalfMillion(-maxScale))
      } else {
        const percent1String = decimalToPercent(
          Math.round((maxScale / selectedQuarterTotalFundValuation) * 100) / 100
        )

        chartAxisStrings.push(percent1String)
        chartAxisStrings.push(formattedSelectedQuarter)
        chartAxisStrings.push(
          decimalToPercent(-maxScale / selectedQuarterTotalFundValuation)
        )
      }

      if (gainLossOnRealEstate >= 0) {
        gainLossOnRealEstateTopPixel =
          MID_CHART_PIXEL_HEIGHT - gainLossOnRealEstatePixelHeight

        netPortfolioIncomeTopPixel =
          MID_CHART_PIXEL_HEIGHT -
          gainLossOnRealEstatePixelHeight -
          netPortfolioIncomePixelHeight
      } else {
        gainLossOnRealEstateTopPixel = MID_CHART_PIXEL_HEIGHT

        netPortfolioIncomeTopPixel =
          MID_CHART_PIXEL_HEIGHT +
          gainLossOnRealEstatePixelHeight -
          netPortfolioIncomePixelHeight
      }

      if (fundValueChange >= 0) {
        fundValueChangeTopPixel =
          MID_CHART_PIXEL_HEIGHT - fundValueChangePixelHeight
      } else {
        fundValueChangeTopPixel = MID_CHART_PIXEL_HEIGHT
      }
    }

    if (chartType === 'positive') {
      if (shouldShowDollarView) {
        chartAxisStrings.push(abbreviateHalfMillion(maxScale))
        chartAxisStrings.push(abbreviateHalfMillion(maxScale / 2))
        chartAxisStrings.push(formattedSelectedQuarter)
      } else {
        chartAxisStrings.push(
          abbreviatePercent(maxScale / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(
          abbreviatePercent(maxScale / 2 / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(formattedSelectedQuarter)
      }

      // this must be positive
      gainLossOnRealEstateTopPixel =
        MAX_CHART_PIXEL_HEIGHT - gainLossOnRealEstatePixelHeight

      netPortfolioIncomeTopPixel =
        gainLossOnRealEstateTopPixel - netPortfolioIncomePixelHeight
      fundValueChangeTopPixel = netPortfolioIncomeTopPixel
    }

    if (chartType === 'negative') {
      if (shouldShowDollarView) {
        chartAxisStrings.push(formattedSelectedQuarter)
        chartAxisStrings.push(abbreviateHalfMillion(-maxScale / 2))
        chartAxisStrings.push(abbreviateHalfMillion(-maxScale))
      } else {
        chartAxisStrings.push(formattedSelectedQuarter)
        chartAxisStrings.push(
          abbreviatePercent(-maxScale / 2 / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(
          abbreviatePercent(-maxScale / selectedQuarterTotalFundValuation)
        )
      }

      // this must be negative
      gainLossOnRealEstateTopPixel = MIN_CHART_PIXEL_HEIGHT

      netPortfolioIncomeTopPixel =
        MIN_CHART_PIXEL_HEIGHT +
        gainLossOnRealEstatePixelHeight -
        netPortfolioIncomePixelHeight
      fundValueChangeTopPixel = MIN_CHART_PIXEL_HEIGHT
    }
  } else {
    const maxPositive = gainLossOnRealEstate + portfolioIncome
    const maxNegative = Math.min(
      gainLossOnRealEstate + portfolioIncome - portfolioExpenses,
      gainLossOnRealEstate
    )
    const maxPositiveScale = Math.max(
      Math.abs(maxPositive),
      Math.abs(maxNegative)
    )
    const maxScale = roundToNearestHalfMillion(maxPositiveScale)

    let totalChartScale = maxScale * 2

    if (maxPositive <= 0) {
      chartType = 'negative'
      totalChartScale = maxScale
    }
    if (maxNegative >= 0) {
      chartType = 'positive'
      totalChartScale = maxScale
    }

    // get height of each box
    gainLossOnRealEstatePixelHeight =
      (Math.abs(gainLossOnRealEstate) / totalChartScale) * MAX_CHART_PIXEL_AREA
    portfolioIncomePixelHeight =
      (portfolioIncome / totalChartScale) * MAX_CHART_PIXEL_AREA
    portfolioExpensesPixelHeight =
      (portfolioExpenses / totalChartScale) * MAX_CHART_PIXEL_AREA
    fundValueChangePixelHeight =
      (Math.abs(fundValueChange) / totalChartScale) * MAX_CHART_PIXEL_AREA

    // set heights to minimum if below 1
    if (gainLossOnRealEstatePixelHeight < 1) {
      gainLossOnRealEstatePixelHeight = 1.01
    }
    if (portfolioIncomePixelHeight < 1) {
      portfolioIncomePixelHeight = 1.01
    }
    if (portfolioExpensesPixelHeight < 1) {
      portfolioExpensesPixelHeight = 1.01
    }
    if (fundValueChangePixelHeight < 1) {
      fundValueChangePixelHeight = 1.01
    }

    if (chartType === 'mixed') {
      if (shouldShowDollarView) {
        chartAxisStrings.push(abbreviateHalfMillion(maxScale))
        chartAxisStrings.push(abbreviateHalfMillion(maxScale / 2))
        chartAxisStrings.push(formattedSelectedQuarter)
        chartAxisStrings.push(abbreviateHalfMillion(-maxScale / 2))
        chartAxisStrings.push(abbreviateHalfMillion(-maxScale))
      } else {
        chartAxisStrings.push(
          abbreviatePercent(maxScale / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(
          abbreviatePercent(maxScale / 2 / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(formattedSelectedQuarter)
        chartAxisStrings.push(
          abbreviatePercent(-maxScale / 2 / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(
          abbreviatePercent(-maxScale / selectedQuarterTotalFundValuation)
        )
      }

      if (gainLossOnRealEstate >= 0) {
        gainLossOnRealEstateTopPixel =
          MID_CHART_PIXEL_HEIGHT - gainLossOnRealEstatePixelHeight

        portfolioIncomeTopPixel =
          MID_CHART_PIXEL_HEIGHT -
          gainLossOnRealEstatePixelHeight -
          portfolioIncomePixelHeight
        portfolioExpensesTopPixel = portfolioIncomeTopPixel
      } else {
        gainLossOnRealEstateTopPixel = MID_CHART_PIXEL_HEIGHT

        portfolioIncomeTopPixel =
          MID_CHART_PIXEL_HEIGHT +
          gainLossOnRealEstatePixelHeight -
          portfolioIncomePixelHeight
        portfolioExpensesTopPixel = portfolioIncomeTopPixel
      }

      if (fundValueChange >= 0) {
        fundValueChangeTopPixel =
          MID_CHART_PIXEL_HEIGHT - fundValueChangePixelHeight
      } else {
        fundValueChangeTopPixel = MID_CHART_PIXEL_HEIGHT
      }
    }

    if (chartType === 'positive') {
      if (shouldShowDollarView) {
        chartAxisStrings.push(abbreviateHalfMillion(maxScale))
        chartAxisStrings.push(abbreviateHalfMillion((maxScale / 4) * 3))
        chartAxisStrings.push(abbreviateHalfMillion(maxScale / 2))
        chartAxisStrings.push(abbreviateHalfMillion(maxScale / 4))
        chartAxisStrings.push(formattedSelectedQuarter)
      } else {
        chartAxisStrings.push(
          abbreviatePercent(maxScale / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(
          abbreviatePercent(
            ((maxScale / 4) * 3) / selectedQuarterTotalFundValuation
          )
        )
        chartAxisStrings.push(
          abbreviatePercent(maxScale / 2 / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(
          abbreviatePercent(maxScale / 4 / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(formattedSelectedQuarter)
      }

      // this must be positive
      gainLossOnRealEstateTopPixel =
        MAX_CHART_PIXEL_HEIGHT - gainLossOnRealEstatePixelHeight

      portfolioIncomeTopPixel =
        gainLossOnRealEstateTopPixel - portfolioIncomePixelHeight
      portfolioExpensesTopPixel = portfolioIncomeTopPixel
      fundValueChangeTopPixel =
        portfolioExpensesTopPixel + portfolioExpensesPixelHeight
    }

    if (chartType === 'negative') {
      if (shouldShowDollarView) {
        chartAxisStrings.push(formattedSelectedQuarter)
        chartAxisStrings.push(abbreviateHalfMillion(-maxScale / 4))
        chartAxisStrings.push(abbreviateHalfMillion(-maxScale / 2))
        chartAxisStrings.push(abbreviateHalfMillion((-maxScale / 4) * 3))
        chartAxisStrings.push(abbreviateHalfMillion(-maxScale))
      } else {
        chartAxisStrings.push(formattedSelectedQuarter)
        chartAxisStrings.push(
          abbreviatePercent(
            ((-maxScale / 4) * 3) / selectedQuarterTotalFundValuation
          )
        )
        chartAxisStrings.push(
          abbreviatePercent(-maxScale / 2 / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(
          abbreviatePercent(-maxScale / 4 / selectedQuarterTotalFundValuation)
        )
        chartAxisStrings.push(
          abbreviatePercent(-maxScale / selectedQuarterTotalFundValuation)
        )
      }

      // this must be negative
      gainLossOnRealEstateTopPixel = MIN_CHART_PIXEL_HEIGHT

      portfolioIncomeTopPixel =
        MIN_CHART_PIXEL_HEIGHT +
        gainLossOnRealEstatePixelHeight -
        portfolioIncomePixelHeight
      portfolioExpensesTopPixel = portfolioIncomeTopPixel
      fundValueChangeTopPixel = MIN_CHART_PIXEL_HEIGHT
    }
  }

  return {
    isMobile,
    isTablet,

    chartAxisStrings,

    gainLossOnRealEstateString: formatDollarsAndCents(
      gainLossOnRealEstate,
      true,
      false
    ),
    netPortfolioIncomeString: formatDollarsAndCents(
      netPortfolioIncome,
      true,
      false
    ),
    fundValueChangeString: formatDollarsAndCents(fundValueChange, true, false),

    gainLossOnRealEstateIsPositive: gainLossOnRealEstate >= 0,
    gainLossOnRealEstateTopPixel,
    portfolioIncomeTopPixel,
    portfolioExpensesTopPixel,
    netPortfolioIncomeTopPixel,
    fundValueChangeTopPixel,

    gainLossOnRealEstatePixelHeight,
    portfolioIncomePixelHeight,
    netPortfolioIncomePixelHeight,
    portfolioExpensesPixelHeight,
    fundValueChangePixelHeight,

    hoveredSection,
    setHoveredSection,
  }
}

export default useFundPerformanceWaterfall
