import {
  Core_OrderV3TaxInformationCompletedTaskResolutionDataInput,
  Core_OrderV3TaskStatus,
  CreateAddressDocumentInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import { ORDERS_URL } from '../../../../constants'
import {
  TaxAddressDocumentType,
  TaxInformationData,
  TaxInformationFunctions,
} from './taxInformationTypes'

const uploadDocument = async (
  documentType: string,
  file: File,
  idx: number,
  addressUuid: string,
  createAddressDocument: (params: {
    variables: {
      createAddressDocumentInput: CreateAddressDocumentInput
    }
  }) => Promise<any>
) => {
  const timestamp = new Date()
  const fileExtension = file.name.split('.').pop()
  const createAddressDocumentInput = {
    addressUuid,
    name: `${file.name}-${timestamp.getTime()}-${idx}.${fileExtension}`,
    type: documentType,
    files: [file],
  }
  const result = await createAddressDocument({
    variables: {
      createAddressDocumentInput,
    },
  })
  return {
    key: documentType,
    uuid: result?.data?.createAddressDocument?.addressDocuments[0]
      ?.documentUuid,
  }
}

// eslint-disable-next-line import/prefer-default-export
export const flattenTaxInformationData = async (
  flowData: TaxInformationData,
  functions: TaxInformationFunctions
) => {
  const { taxInformation, orderUuid, taskUuid, addressUuid } = flowData
  const { updateTaxInformationTask, createAddressDocument } = functions

  const uploadPromises: Promise<{ key: string; uuid: string }>[] = []
  if (taxInformation?.form4562Workpapers) {
    const taxDocuments = (taxInformation.form4562Workpapers as File[]) || []
    taxDocuments.forEach((file, idx) => {
      uploadPromises.push(
        uploadDocument(
          TaxAddressDocumentType.FORM_4562,
          file,
          idx,
          addressUuid,
          createAddressDocument
        )
      )
    })
  }

  const fileUploadResults: { key: string; uuid: string }[] = await Promise.all(
    uploadPromises
  )

  const taxInformationCompletedData: Core_OrderV3TaxInformationCompletedTaskResolutionDataInput =
    {
      cashOutRefinanceDescription:
        taxInformation.cashOutRefinance === 'true'
          ? taxInformation.cashOutRefinanceDescription
          : undefined,
      form4562Workpapers: fileUploadResults
        .filter(
          (fileUpload) => fileUpload.key === TaxAddressDocumentType.FORM_4562
        )
        .map((fileUpload) => fileUpload.uuid),
      hasCashOutRefinance: taxInformation.cashOutRefinance === 'true',
    }

  await updateTaxInformationTask({
    variables: {
      input: {
        orderUuid,
        taskData: {
          taskUuid,
          status: Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted,
          resolutionData: {
            data: {
              taxInformationCompletedTaskResolutionData:
                taxInformationCompletedData,
            },
          },
        },
      },
    },
  })
  navigate(`${ORDERS_URL}/${orderUuid}`)
}
