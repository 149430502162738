import React from 'react'
import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import TaxSummaryTableDesktop from './TaxSummaryTableDesktop'
import TaxSummaryTableMobile from './TaxSummaryTableMobile'
import TaxSummaryTableTablet from './TaxSummaryTableTablet'
import { TaxSummaryTableProps } from './TaxSummaryTableTypes'
import useTaxSummaryTable from './useTaxSummaryTable'

const TaxSummaryTable = (props: TaxSummaryTableProps) => {
  const { taxData } = useTaxSummaryTable(props)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobile) {
    return <TaxSummaryTableMobile taxData={taxData} />
  } else if (isTablet) {
    return <TaxSummaryTableTablet taxData={taxData} />
  } else {
    return <TaxSummaryTableDesktop taxData={taxData} />
  }
}

export default TaxSummaryTable
