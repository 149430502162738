import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const UpArrowIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.5 11.0969L18.2277 12.3597L12.9208 7.16108L12.9208 20.625L11.0792 20.625L11.0792 7.16108L5.78906 12.3597L4.5 11.0969L12 3.75L19.5 11.0969Z"
        fill={fillStyle || '#136D42'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default UpArrowIcon
