// export the data from the calculation engine. This should be one of the legal entities data, or the cumulative sum. It should also export the selected legal entity among other things like callbacks for the accountOverTime to historical performance chart

import { gql, useQuery } from '@apollo/client'
import {
  Core_LegalEntity,
  Core_QuarterlyInvestmentTransactionSummary,
  InvestorAccountSectionGetTransactionHistoryForLegalEntityByQuarterDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { sessionStore } from '@flock/utils'
import { useCallback, useEffect, useState } from 'react'
import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import {
  QuarterData,
  AccountSectionData,
  ChartPeriodSelection,
  SelectedLegalEntityAggregateTransactionData,
  TaxData,
} from './accountSectionTypes'
import { quarterStringToDate } from '../../../utils'
import { INVESTOR_DASHBOARD_GET_LEGAL_ENTITY_TAX_SUMMARY } from '../Home/graphql/queries'

// network layer
// This is used by Overmoon
export const GET_INVESTMENT_TRANSACTION_INFO_FOR_LEGAL_ENTITY_REQUEST = gql`
  query InvestorAccountSectionGetInvestmentTransactionInfoForLegalEntity(
    $input: Core_GetInvestmentTransactionInfoForLegalEntityRequestInput!
  ) {
    getInvestmentTransactionInfoForLegalEntity(input: $input) {
      investmentTransactionInfo {
        effectiveDate
        transactionType
        uuid
        shareCountChange
        equityValueChangeCents
        isPublished
      }
    }
  }
`

export const GET_TRANSACTION_HISTORY_FOR_LEGAL_ENTITY_BY_QUARTER = gql`
  query InvestorAccountSectionGetTransactionHistoryForLegalEntityByQuarter(
    $input: Core_GetInvestmentTransactionInfoForLegalEntityByQuarterRequestInput!
  ) {
    getInvestmentTransactionInfoForLegalEntityByQuarter(input: $input) {
      quarterlyInvestmentTransactionSummaries {
        quarterString
        startingEquityValueCents
        redemptionCents
        redemptionShareCountChange
        gainLossCents
        netIncomeCents
        taxFeeCents
        quarterEndProcessCreatedAtDate
        quarterSharePriceCents
        shareCount
        quarterReturn
        quarterlyContributions {
          contributedValueCents
          contributionDate
        }
        quarterlyTransfers {
          transferredDate
          transferredValueCents
        }
      }
      cumulativeReturn
      annualizedReturn
    }
  }
`

const getDateFromQuarterData = (data: QuarterData) => {
  let date = new Date()
  const quarter = data.quarterString.split(' ')[0]
  const year = data.quarterString.split(' ')[1]
  switch (quarter) {
    case 'Q1':
      date = new Date(`march, 2, ${year}`)
      break
    case 'Q2':
      date = new Date(`june, 2, ${year}`)
      break
    case 'Q3':
      date = new Date(`september, 2, ${year}`)
      break
    case 'Q4':
      date = new Date(`december, 2, ${year}`)
      break
    default:
      break
  }

  return date
}

const mapQuarterlyTransactionSummaryToQuarterData = (
  data: Core_QuarterlyInvestmentTransactionSummary | null
) => {
  if (!data) {
    return []
  }
  // to generate the correct sequence of start and ending account values, we need to keep track of the starting value in order across contributions and transfers
  const tempParsedQuarterlyData: QuarterData[] = []
  const startingValue = data?.startingEquityValueCents || 0
  let totalContributions = 0
  // TODO: this will fail if there is a transfer BEFORE a contribution in the same quarter. We eventually need to sort the data by date first. Currently there's no scenario where this happens
  if (data?.quarterlyContributions?.length! > 0) {
    data?.quarterlyContributions?.forEach((contribution) => {
      totalContributions += contribution.contributedValueCents
    })
  }

  if (data?.quarterlyTransfers?.length! > 0) {
    data?.quarterlyTransfers?.forEach((transfer) => {
      totalContributions += transfer.transferredValueCents
    })
  }

  const endingValue =
    startingValue +
    totalContributions +
    (data?.netIncomeCents || 0) +
    (data?.gainLossCents || 0) -
    (data?.redemptionCents || 0) -
    (data?.taxFeeCents || 0)

  const quarter = data.quarterString.split(' ')[1]
  const year = data.quarterString.split(' ')[0]
  let annualizedCashYield = (data?.redemptionCents / endingValue) * 4 // multiply by 4 to annualize
  if (endingValue === 0) {
    annualizedCashYield =
      (data?.redemptionCents / Math.abs(totalContributions)) * 4
  }

  tempParsedQuarterlyData.push({
    quarterString: `${quarter} ${year}`,
    startingAccountValue: startingValue,
    endingAccountValue: endingValue,
    contributions: totalContributions,
    gainLoss: data?.gainLossCents || 0,
    netIncome: data?.netIncomeCents || 0,
    taxFees: data?.taxFeeCents || 0,
    redemptions: data?.redemptionCents || 0,
    cashYield: data?.quarterString ? annualizedCashYield : 0,
    quarterSharePrice: data?.quarterSharePriceCents || 0,
    shareCount: data?.shareCount || 0,
    hasContributionInQuarter: (data?.quarterlyContributions?.length || 0) > 0,
    quarterReturn: data?.quarterReturn || 0,
  })

  return tempParsedQuarterlyData
}

// iterate backwards thru quarter data to find the first date that is less than today. Default to today if no date is found
const getEffectiveDateHelper = (quarterData: QuarterData[]) => {
  let effectiveDate = new Date() // default to current date
  quarterData
    .slice()
    .reverse()
    .every((currQuarterData) => {
      const quarterDataDate = getDateFromQuarterData(currQuarterData)
      if (quarterDataDate.getTime() < new Date().getTime()) {
        const quarterEndDate = quarterStringToDate(
          currQuarterData?.quarterString
        )
        if (quarterEndDate.getTime() < new Date().getTime()) {
          effectiveDate = quarterEndDate
        }
        return false
      }

      return true
    })

  return `${effectiveDate.toLocaleString('default', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })}`
}
export const useAccountSection = (): AccountSectionData => {
  const [quarterlyData, setQuarterlyData] = useState<QuarterData[][]>([])
  const [aggregateTransactionData, setAggregateTransactionData] = useState<
    SelectedLegalEntityAggregateTransactionData[]
  >([])
  const [
    selectedLegalEntityAggregateTransactionData,
    setSelectedLegalEntityAggregateTransactionData,
  ] = useState<SelectedLegalEntityAggregateTransactionData>({
    totalContribution: 0,
    totalEquityValue: 0,
    totalGainLoss: 0,
    totalNetIncome: 0,
    totalRedemptions: 0,
    totalTaxFees: 0,
    totalCashYield: 0,
    currentSharePrice: 0,
    currentShareCount: 0,
    annualizedReturn: 1.0,
    cumulativeReturn: 1.0,
  })
  const [
    selectedLegalEntityQuarterlyData,
    setSelectedLegalEntityQuarterlyData,
  ] = useState<QuarterData[]>()
  const [selectedLegalEntityTaxData, setSelectedLegalEntityTaxData] =
    useState<TaxData>({
      totalDepreciationCents: 0,
      totalTaxableIncomeCents: 0,
      currentCostBasisCents: 0,
      totalEmbeddedGainCents: 0,
      totalExpectedTaxableIncomeCents: 0,
    })
  const [legalEntityTaxData, setLegalEntityTaxData] = useState<TaxData[]>([])
  const [selectedLegalEntity, setSelectedLegalEntity] =
    useState<Core_LegalEntity>()
  const [hoveredQuarterLabel, setHoveredQuarterLabel] = useState<
    string | undefined
  >()
  const [periodSelection, setPeriodSelection] =
    useState<ChartPeriodSelection>('1Y')
  const [computing, setComputing] = useState(true)
  // context hooks
  const { legalEntities } = useInvestorAccountContext()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  // we can't use `useLazyQuery` here because of this bug https://github.com/apollographql/apollo-client/issues/9755
  // tldr: on v3.6, useLazyQuery will overwrite the previous query result with the new one
  const {
    refetch: getLegalEntityInfoByQuarter,
    loading,
    error,
  } = useQuery(
    InvestorAccountSectionGetTransactionHistoryForLegalEntityByQuarterDocument,
    {
      skip: true,
      notifyOnNetworkStatusChange: true,
    }
  )

  const {
    refetch: refetchLegalEntityTaxSummary,
    loading: getLegalEntityTaxSummaryLoading,
    error: getLegalEntityTaxSummaryError,
  } = useQuery(INVESTOR_DASHBOARD_GET_LEGAL_ENTITY_TAX_SUMMARY, {
    skip: true,
    notifyOnNetworkStatusChange: true,
  })

  type ReturnsData = {
    annualizedReturn: number
    cumulativeReturn: number
  }

  type LegalEntityUuidList = {
    uuids: string[]
  }

  const getQuarterlyDataHelper = useCallback(async () => {
    setComputing(true)

    const legalEntitiesUuidsList: LegalEntityUuidList[] = []
    if (legalEntities[0].uuid === 'aggregate') {
      const allLegalEntitiesUUIDList: LegalEntityUuidList = {
        uuids: [],
      }
      legalEntities.slice(1).forEach((legalEntity) => {
        legalEntitiesUuidsList.push({
          uuids: [legalEntity.uuid],
        } as LegalEntityUuidList)

        allLegalEntitiesUUIDList.uuids.push(legalEntity.uuid)
      })

      legalEntitiesUuidsList.unshift(allLegalEntitiesUUIDList)
    } else {
      legalEntitiesUuidsList.push({
        uuids: [legalEntities[0].uuid],
      } as LegalEntityUuidList)
    }

    const parsedQuarterlyData: QuarterData[][] = []
    const returnsDataHelper: ReturnsData[] = []
    const taxDataArr: TaxData[] = []

    const timeSeriesQueries: any[] = []
    const taxDataQueries: any[] = []
    legalEntitiesUuidsList.forEach(async (legalEntityUuids) => {
      timeSeriesQueries.push(
        getLegalEntityInfoByQuarter({
          input: {
            legalEntityUuids: legalEntityUuids.uuids,
            includeStaged: sessionStore.getItem('simulator') !== null,
          },
        })
      )
      // @neil to refactor this
      if (legalEntityUuids.uuids.length === 1) {
        taxDataQueries.push(
          refetchLegalEntityTaxSummary({
            input: {
              legalEntityUuid: legalEntityUuids.uuids[0],
            },
          })
        )
      }
    })

    const timeSeriesQueryResults = await Promise.all(timeSeriesQueries)
    timeSeriesQueryResults.forEach((data) => {
      const currQuarterData: QuarterData[] = []
      data?.data.getInvestmentTransactionInfoForLegalEntityByQuarter?.quarterlyInvestmentTransactionSummaries?.forEach(
        (quarterData: Core_QuarterlyInvestmentTransactionSummary | null) => {
          currQuarterData.push(
            ...mapQuarterlyTransactionSummaryToQuarterData(quarterData)
          )
        }
      )
      returnsDataHelper.push({
        annualizedReturn:
          data?.data.getInvestmentTransactionInfoForLegalEntityByQuarter
            ?.annualizedReturn || 1.0,
        cumulativeReturn:
          data?.data.getInvestmentTransactionInfoForLegalEntityByQuarter
            ?.cumulativeReturn || 1.0,
      })
      parsedQuarterlyData.push(currQuarterData)
    })

    // tax data parsing to be refactored
    const taxDataQueryResults = await Promise.all(taxDataQueries)
    const aggregateTaxData: TaxData = {
      currentCostBasisCents: 0,
      totalDepreciationCents: 0,
      totalTaxableIncomeCents: 0,
      totalEmbeddedGainCents: 0,
      totalExpectedTaxableIncomeCents: 0,
    }
    taxDataQueryResults.forEach((data) => {
      const taxData: TaxData = {
        currentCostBasisCents: 0,
        totalDepreciationCents: 0,
        totalTaxableIncomeCents: 0,
        totalEmbeddedGainCents: 0,
        totalExpectedTaxableIncomeCents: 0,
      }
      if (data?.data?.getLegalEntityTaxSummary) {
        taxData.currentCostBasisCents =
          data?.data?.getLegalEntityTaxSummary?.currentCostBasisCents
        taxData.totalDepreciationCents =
          data?.data?.getLegalEntityTaxSummary?.totalDepreciationCents
        taxData.totalTaxableIncomeCents =
          data?.data?.getLegalEntityTaxSummary?.totalTaxableIncomeCents
        taxData.totalEmbeddedGainCents =
          data?.data?.getLegalEntityTaxSummary?.totalEmbeddedGainCents
        taxData.totalExpectedTaxableIncomeCents =
          data?.data?.getLegalEntityTaxSummary?.totalExpectedTaxableIncomeCents

        aggregateTaxData.currentCostBasisCents += taxData.currentCostBasisCents
        aggregateTaxData.totalDepreciationCents +=
          taxData.totalDepreciationCents
        aggregateTaxData.totalTaxableIncomeCents +=
          taxData.totalTaxableIncomeCents
        aggregateTaxData.totalEmbeddedGainCents +=
          taxData.totalEmbeddedGainCents
        aggregateTaxData.totalExpectedTaxableIncomeCents +=
          taxData.totalExpectedTaxableIncomeCents
        taxDataArr.push(taxData)
      }
    })

    if (legalEntities.length > 1) {
      taxDataArr.unshift(aggregateTaxData)
    }

    // get aggregate data from parsedQuarterlyData
    const parsedAggregateTransactionData: SelectedLegalEntityAggregateTransactionData[] =
      []

    parsedQuarterlyData.forEach((quarterData, legalEntityNum) => {
      const aggregateSelectedLegalEntityTransactionData = {
        totalContribution: 0,
        totalEquityValue: 0,
        totalGainLoss: 0,
        totalNetIncome: 0,
        totalTaxFees: 0,
        totalRedemptions: 0,
        totalCashYield: 0,
        currentSharePrice: 0,
        currentShareCount: 0,
        annualizedReturn: 1.0,
        cumulativeReturn: 1.0,
      }
      quarterData.forEach((data, index) => {
        // we have the ending account value check so preserve the return value in the case of if someone fully transfers out
        aggregateSelectedLegalEntityTransactionData.totalContribution +=
          data.contributions
        aggregateSelectedLegalEntityTransactionData.totalGainLoss +=
          data.gainLoss
        aggregateSelectedLegalEntityTransactionData.totalNetIncome +=
          data.netIncome
        aggregateSelectedLegalEntityTransactionData.totalTaxFees += data.taxFees
        aggregateSelectedLegalEntityTransactionData.totalRedemptions +=
          data.redemptions
        aggregateSelectedLegalEntityTransactionData.currentShareCount =
          data.shareCount
        if (index === quarterData.length - 1) {
          aggregateSelectedLegalEntityTransactionData.totalEquityValue =
            data.endingAccountValue
          aggregateSelectedLegalEntityTransactionData.currentSharePrice =
            data.quarterSharePrice
        }
      })

      // TEMPORARY CALCULATION FOR TOTAL CASH YIELD
      aggregateSelectedLegalEntityTransactionData.totalCashYield =
        aggregateSelectedLegalEntityTransactionData.totalRedemptions /
        aggregateSelectedLegalEntityTransactionData.totalEquityValue

      aggregateSelectedLegalEntityTransactionData.annualizedReturn =
        returnsDataHelper[legalEntityNum].annualizedReturn
      aggregateSelectedLegalEntityTransactionData.cumulativeReturn =
        returnsDataHelper[legalEntityNum].cumulativeReturn
      // append to aggregateTransactionData
      parsedAggregateTransactionData.push(
        aggregateSelectedLegalEntityTransactionData
      )
    })

    setSelectedLegalEntityTaxData(taxDataArr[0]) // first index is aggregate tax data
    setAggregateTransactionData(parsedAggregateTransactionData)
    setSelectedLegalEntityAggregateTransactionData(
      parsedAggregateTransactionData[0]
    )

    setQuarterlyData(parsedQuarterlyData)
    setLegalEntityTaxData(taxDataArr)
    setSelectedLegalEntity(legalEntities[0])
    setSelectedLegalEntityQuarterlyData(parsedQuarterlyData[0])
    setComputing(false)
  }, [legalEntities, getLegalEntityInfoByQuarter, refetchLegalEntityTaxSummary])

  useEffect(() => {
    getQuarterlyDataHelper()
  }, [getQuarterlyDataHelper])

  const setSelectedLegalEntityHelper = (uuid: string) => {
    const index = legalEntities.findIndex((entity) => entity.uuid === uuid)
    setSelectedLegalEntity(legalEntities[index])
    setSelectedLegalEntityQuarterlyData(quarterlyData[index])
    setSelectedLegalEntityTaxData(legalEntityTaxData[index])
    setSelectedLegalEntityAggregateTransactionData(
      aggregateTransactionData[index]
    )
  }

  return {
    loading: loading || computing || getLegalEntityTaxSummaryLoading,
    error: error || getLegalEntityTaxSummaryError,

    legalEntities,
    selectedLegalEntity: selectedLegalEntity || ({} as Core_LegalEntity),
    setSelectedLegalEntityByUuid: setSelectedLegalEntityHelper,
    selectedLegalEntityQuarterlyTransactionData:
      selectedLegalEntityQuarterlyData || [],
    legalEntityQuarterlyTransactionData: quarterlyData,
    selectedLegalEntityTaxData,
    effectiveDate: getEffectiveDateHelper(
      selectedLegalEntityQuarterlyData || []
    ),
    selectedLegalEntityAggregateTransactionData,

    hoveredQuarterLabel,
    setHoveredQuarterLabel,
    periodSelection,
    setPeriodSelection,

    isMobile,
    isTablet,
  }
}
