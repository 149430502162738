// for mobile, tablet, desktop, use grid layout change rather than separate files

import { PillSelector } from '@flock/shared-ui'
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import SectionLayout from '../../SharedComponents/SectionLayout'
import AccountDetailsHeader from './AccountDetailsHeader/AccountDetailsHeader'
import AccountOverTime from './AccountOverTime/AccountOverTime'
import HistoricalPerformance from './HistoricalPerformance/HistoricalPerformance'
import PerformanceSummaryTable from './PerformanceSummaryTable/PerformanceSummaryTable'
import { useAccountSection } from './useAccountSection'
import TaxSummaryTable from './TaxSummaryTable/TaxSummaryTable'

type AccountSectionProps = {
  id: string
}

const AccountSection = (props: AccountSectionProps) => {
  const {
    isMobile,
    isTablet,
    legalEntityQuarterlyTransactionData,
    selectedLegalEntity,
    selectedLegalEntityQuarterlyTransactionData,
    legalEntities,
    effectiveDate,
    setSelectedLegalEntityByUuid,
    loading,
    selectedLegalEntityTaxData,
    ...otherProps
  } = useAccountSection()
  let numColumns = 9
  if (isMobile) {
    numColumns = 4
  } else if (isTablet) {
    numColumns = 6
  }

  const isDesktop = !isMobile && !isTablet
  const { id } = props

  /**
   * Presentational layer
   * Contains the presentational pieces of the UI. This area should be as
   * simple as possible and any logic should be delegated to the logic hook.
   * Use separate components for the mobile, tablet, and desktop views to avoid
   * excessive ternaries.
   */

  return (
    <SectionLayout name="account-section" columns={numColumns} id={id}>
      <Grid item xs={numColumns}>
        <Box
          alignItems="baseline"
          display="flex"
          gap="8px"
          width="100%"
          justifyContent="space-between"
        >
          <Typography variant="h3">My Account</Typography>
          {!loading && (
            <Typography variant="p3" color="gray5.main">
              {`Last updated ${effectiveDate}`}
            </Typography>
          )}
        </Box>
      </Grid>
      {!loading && legalEntities.length > 1 && (
        <Grid item xs={numColumns}>
          <PillSelector
            options={legalEntities.map((entity, idx) => ({
              label: entity.name || '',
              value: entity.uuid,
              disabled: legalEntityQuarterlyTransactionData[idx]?.length === 0,
            }))}
            selectedValue={selectedLegalEntity.uuid}
            onSelect={setSelectedLegalEntityByUuid}
          />
        </Grid>
      )}
      <Grid item xs={numColumns}>
        <Paper
          sx={{
            display: 'flex',
            borderRadius: '16px',
            p: isMobile ? '24px 24px 0 24px' : '24px',
          }}
        >
          {loading ? (
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="600px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid container columns={numColumns} spacing={isMobile ? 2 : 4}>
              <Grid item xs={numColumns}>
                <AccountDetailsHeader {...otherProps} />
              </Grid>

              {isDesktop && (
                <>
                  <Grid item xs={4}>
                    <Box display="flex" flexDirection="column" gap="16px">
                      <PerformanceSummaryTable
                        selectedLegalEntityTaxData={selectedLegalEntityTaxData}
                        {...otherProps}
                      />
                      <TaxSummaryTable
                        taxData={selectedLegalEntityTaxData}
                        {...otherProps}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={numColumns} md={5}>
                    <AccountOverTime
                      quarterData={selectedLegalEntityQuarterlyTransactionData}
                      {...otherProps}
                    />
                  </Grid>
                </>
              )}

              {isTablet && (
                <>
                  <Grid item xs={numColumns}>
                    <Box display="flex" flexDirection="row" gap="16px">
                      <PerformanceSummaryTable
                        selectedLegalEntityTaxData={selectedLegalEntityTaxData}
                        {...otherProps}
                      />
                      <TaxSummaryTable
                        taxData={selectedLegalEntityTaxData}
                        {...otherProps}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={numColumns}>
                    <AccountOverTime
                      quarterData={selectedLegalEntityQuarterlyTransactionData}
                      {...otherProps}
                    />
                  </Grid>
                </>
              )}

              {isMobile && (
                <>
                  <Grid item xs={numColumns}>
                    <PerformanceSummaryTable
                      selectedLegalEntityTaxData={selectedLegalEntityTaxData}
                      {...otherProps}
                    />
                  </Grid>
                  <Grid item xs={numColumns}>
                    <TaxSummaryTable
                      taxData={selectedLegalEntityTaxData}
                      {...otherProps}
                    />
                  </Grid>
                  <Grid item xs={numColumns}>
                    <AccountOverTime
                      quarterData={selectedLegalEntityQuarterlyTransactionData}
                      {...otherProps}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={numColumns}>
                <HistoricalPerformance
                  quarterData={selectedLegalEntityQuarterlyTransactionData}
                  selectedLegalEntity={selectedLegalEntity}
                  {...otherProps}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    </SectionLayout>
  )
}

export default AccountSection
