import {
  EmptyDotIcon,
  FilledDotIcon,
  INVESTOR_EMAIL,
  TrackedButton,
  TrackedLink,
} from '@flock/shared-ui'
import { Close } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
} from '@mui/material'
import confetti from 'canvas-confetti'
import React, { useEffect, useState } from 'react'
import { OrderProgressState } from './OrderV3HomeSection'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  maxHeight: '80vh',
  overflowY: 'auto',
  padding: '2rem',
})

type ContributionAgreementSignedModalProps = {
  progressState: OrderProgressState
  openPersonalInfoUrl: () => void
  isOpen: boolean
  onClose: () => void
}

const PostContributionAgreementSignedModal = (
  props: ContributionAgreementSignedModalProps
) => {
  const { progressState, openPersonalInfoUrl, isOpen, onClose } = props
  const { progressBarConfigs } = progressState
  const [nextPage, setNextPage] = useState(false)

  useEffect(() => {
    if (isOpen) {
      confetti({
        particleCount: 200,
        spread: 90,
        disableForReducedMotion: true,
        zIndex: 10000000,
      })
      confetti({
        particleCount: 50,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        disableForReducedMotion: true,
        zIndex: 10000000,
      })
      confetti({
        particleCount: 50,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        disableForReducedMotion: true,
        zIndex: 10000000,
      })
    }
  }, [isOpen])

  let hasPersonalInformation = false
  let hasPropertyQuestionnaire = false
  let hasTaxQuestionnaire = false
  let hasTitleAuthorizationAndOwnerAffidavit = false

  progressBarConfigs.forEach((config) => {
    if (config.text === 'Personal Information') {
      hasPersonalInformation = true
    }
    if (config.text === 'Property Questionnaire') {
      hasPropertyQuestionnaire = true
    }
    if (config.text === 'Tax Questionnaire') {
      hasTaxQuestionnaire = true
    }
    if (config.text === 'Title Authorization & Owner Affidavit') {
      hasTitleAuthorizationAndOwnerAffidavit = true
    }
  })

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalWrapper>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column" gap="16px">
          {nextPage ? (
            <>
              <Typography variant="p1" color="moneyGreen.main" fontWeight={500}>
                To finalize your exchange, you&apos;ll need to complete these
                onboarding tasks:
              </Typography>
              {hasPersonalInformation && (
                <Box display="flex" flexDirection="row" gap="8px" pl="8px">
                  &bull;
                  <Typography variant="p3" color="moneyGreen.main">
                    <Typography
                      fontWeight={500}
                      variant="p3"
                      color="moneyGreen.main"
                    >
                      Personal Information:
                    </Typography>{' '}
                    Share information for each person involved in your
                    transaction
                  </Typography>
                </Box>
              )}
              {hasPropertyQuestionnaire && (
                <Box display="flex" flexDirection="row" gap="8px" pl="8px">
                  &bull;
                  <Typography variant="p3" color="moneyGreen.main">
                    <Typography
                      fontWeight={500}
                      variant="p3"
                      color="moneyGreen.main"
                    >
                      Property Questionnaire:
                    </Typography>{' '}
                    Answer more detailed property-specific questions to prepare
                    our Asset Management team
                  </Typography>
                </Box>
              )}
              {hasTaxQuestionnaire && (
                <Box display="flex" flexDirection="row" gap="8px" pl="8px">
                  &bull;
                  <Typography variant="p3" color="moneyGreen.main">
                    <Typography
                      fontWeight={500}
                      variant="p3"
                      color="moneyGreen.main"
                    >
                      Tax Questionnaire:
                    </Typography>{' '}
                    Ensure you utilize all of Flock&apos;s tax advantages by
                    supplying the requested documentation
                  </Typography>
                </Box>
              )}
              {hasTitleAuthorizationAndOwnerAffidavit && (
                <Box display="flex" flexDirection="row" gap="8px" pl="8px">
                  &bull;
                  <Typography variant="p3" color="moneyGreen.main">
                    <Typography
                      fontWeight={500}
                      variant="p3"
                      color="moneyGreen.main"
                    >
                      Title Authorization &amp; Owner Affidavit:
                    </Typography>{' '}
                    Grant authorization for our title partner to process your
                    transaction
                  </Typography>
                </Box>
              )}

              <Box display="flex" flexDirection="row" gap="8px" pl="8px">
                &bull;
                <Typography variant="p3" color="moneyGreen.main">
                  <Typography
                    fontWeight={500}
                    variant="p3"
                    color="moneyGreen.main"
                  >
                    LP Onboarding:
                  </Typography>{' '}
                  Complete subscription documents and accredited investor
                  verification
                </Typography>
              </Box>

              <Typography variant="p3" color="moneyGreen.main">
                Flock&apos;s title partner, {progressState.titleCompany}, will
                reach out to schedule your closing appointment. They may reach
                out with additional questions as well.
              </Typography>
            </>
          ) : (
            <>
              <Box textAlign="center">
                <Typography variant="h3" alignSelf="center">
                  Congratulations on your decision to move forward with Flock!
                </Typography>
              </Box>
              <div
                style={{
                  position: 'relative',
                  paddingBottom: '56.25%',
                  height: '0',
                }}
              >
                <iframe
                  title="Welcome to your Flock Order"
                  src="https://www.loom.com/embed/10c192db5dbc455582d55a8637433917?sid=1e4a9db9-b596-4bb6-8379-08f32fe3d709?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true"
                  frameBorder="0"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
              <Typography variant="p3" color="moneyGreen.main">
                If you have any questions, please reach out to me at{' '}
                <TrackedLink
                  onClick={() => window.open(`mailto:${INVESTOR_EMAIL}`)}
                >
                  danielle@flockhomes.com
                </TrackedLink>{' '}
              </Typography>
            </>
          )}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row">
              <Box width="120px">
                {nextPage && (
                  <TrackedButton
                    variant="outlined"
                    size="mini"
                    onClick={() => {
                      setNextPage(false)
                    }}
                  >
                    Previous
                  </TrackedButton>
                )}
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
              >
                {nextPage ? (
                  <>
                    <EmptyDotIcon />
                    <FilledDotIcon />
                  </>
                ) : (
                  <>
                    <FilledDotIcon />
                    <EmptyDotIcon />
                  </>
                )}
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box width="120px">
                {nextPage ? (
                  <>
                    {openPersonalInfoUrl && (
                      <TrackedButton
                        variant="primary"
                        size="mini"
                        onClick={() => {
                          openPersonalInfoUrl()
                        }}
                      >
                        Get Started
                      </TrackedButton>
                    )}
                  </>
                ) : (
                  <TrackedButton
                    variant="primary"
                    size="mini"
                    onClick={() => {
                      setNextPage(true)
                    }}
                  >
                    Next
                  </TrackedButton>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </ModalWrapper>
    </Modal>
  )
}

export default PostContributionAgreementSignedModal
