import {
  abbreviationToState,
  CaretIcon,
  Checkbox,
  LeftArrowFilledIcon,
  PageContainer,
  SearchIcon,
} from '@flock/shared-ui'
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Pagination,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import React from 'react'
import LazyLoad from 'react-lazyload'
import { ACCOUNT_URL } from '../../constants'
import PageWrapper from '../SharedComponents/PageWrapper'
import AssetViewCard from './AssetViewCard/AssetViewCard'
import {
  abbreviatedPropertyTypeToDisplay,
  FMV,
  NEWEST,
  propertyTypes,
  propertyTypeToDisplay,
  RELEVANCE,
  RENT,
} from './detailedAssetViewTypes'
import MultifamilyAssetViewCard from './AssetViewCard/MultifamilyAssetViewCard'
import useDetailedAssetViewPage from './useDetailedAssetViewPage'

const ASSETS_PER_PAGE = 8

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DetailedAssetViewPage = (_: RouteComponentProps) => {
  const {
    filteredPropertyAssets,
    statesWithCountsList,
    selectedMarkets,
    setSelectedMarkets,
    setSearchText,
    sortOpen,
    setSortOpen,
    sortText,
    setSortText,
    stateOpen,
    setStateOpen,
    typeOpen,
    setTypeOpen,
    selectedTypes,
    setSelectedTypes,
    page,
    handleChangePage,
    hasNoPropertyAssets,
    loading,
  } = useDetailedAssetViewPage()

  return (
    <PageContainer title="FLOCK | Assets" trackingName="assets">
      <PageWrapper>
        <Grid container spacing={4} columns={12} height="auto" pb="32px">
          <Grid item xs={3}>
            <Paper
              sx={{
                borderRadius: '16px',
                padding: '0px 16px 0px 0px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'gray1.main',
                  transition: 'background-color 0.2s ease',
                },
              }}
              onClick={() => navigate(ACCOUNT_URL)}
            >
              <Box display="flex" alignItems="center">
                <LeftArrowFilledIcon height="48px" width="48px" />
                <Typography variant="p3">
                  Back to Owners&apos; Portal
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <TextField
              variant="standard"
              fullWidth
              sx={{
                '& > input': {
                  padding: 0,
                },
                '& > div > input': {
                  fontSize: '1rem!important',
                  padding: '0!important',
                  height: '48px!important',
                  fontFamily: 'Outfit',
                  fontWeight: 400,
                },
                '& .MuiInputBase-input': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              placeholder="Type Search Here"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ paddingLeft: '10px' }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={setSearchText}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={9}>
            <Box display="flex" justifyContent="center" width="100%">
              <Typography variant="h4" fontSize="32px">
                Homes in Flock&apos;s Portfolio
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" width="100%" gap="16px">
              <Box display="flex" flexDirection="column" gap="4px">
                <Paper
                  key="sort"
                  sx={{
                    padding: '16px 20px 16px 24px',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    backgroundColor: sortOpen
                      ? 'green2.main'
                      : 'rgba(200.64, 217.57, 218.88, 0.50)',
                    transition: 'background-color 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'green2.main',
                      transition: 'background-color 0.2s ease',
                    },
                  }}
                  onClick={() => {
                    setSortOpen(!sortOpen)
                    setTypeOpen(false)
                    setStateOpen(false)
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="cta2" fontWeight="500">
                      Sort: {sortText}
                    </Typography>
                    <Box
                      width="24px"
                      height="24px"
                      sx={{
                        transform: sortOpen ? 'rotate(-180deg)' : '',
                        transition: 'transform 0.2s ease-in-out',
                      }}
                    >
                      <CaretIcon />
                    </Box>
                  </Box>
                </Paper>
                {sortOpen && (
                  <Paper
                    key="sortMenu"
                    sx={{
                      padding: '16px 0px 16px 0px',
                      justifyContent: 'left',
                      borderRadius: '16px',
                      boxShadow: 'none',
                      backgroundColor: '#F5F8F8',
                    }}
                  >
                    <Box
                      padding="8px 24px 8px 24px"
                      bgcolor="#F5F8F8"
                      gap="8px"
                      sx={{
                        '&:hover': {
                          backgroundColor: '#E9EAEB',
                          transition: 'background-color 0.2s ease',
                        },
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSortText(RELEVANCE)
                        setSortOpen(!sortOpen)
                      }}
                    >
                      <Typography variant="p1">Relevance</Typography>
                    </Box>
                    <Box
                      padding="8px 24px 8px 24px"
                      bgcolor="#F5F8F8"
                      gap="8px"
                      sx={{
                        '&:hover': {
                          backgroundColor: '#E9EAEB',
                          transition: 'background-color 0.2s ease',
                        },
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSortText(NEWEST)
                        setSortOpen(!sortOpen)
                      }}
                    >
                      <Typography variant="p1">Newest</Typography>
                    </Box>
                    <Box
                      padding="4px 24px 4px 24px"
                      bgcolor="#F5F8F8"
                      gap="8px"
                      sx={{
                        '&:hover': {
                          backgroundColor: '#E9EAEB',
                          transition: 'background-color 0.2s ease',
                        },
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSortText(RENT)
                        setSortOpen(!sortOpen)
                      }}
                    >
                      <Typography variant="p1">Rent, high to low</Typography>
                    </Box>
                    <Box
                      padding="4px 24px 4px 24px"
                      bgcolor="#F5F8F8"
                      gap="8px"
                      sx={{
                        '&:hover': {
                          backgroundColor: '#E9EAEB',
                          transition: 'background-color 0.2s ease',
                        },
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSortText(FMV)
                        setSortOpen(!sortOpen)
                      }}
                    >
                      <Typography variant="p1">
                        Valuation, high to low
                      </Typography>
                    </Box>
                  </Paper>
                )}
              </Box>
              <Box display="flex" flexDirection="column">
                <Paper
                  key="state"
                  sx={{
                    padding: '16px 20px 16px 24px',
                    mb: '4px',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    backgroundColor: stateOpen
                      ? 'green2.main'
                      : 'rgba(200.64, 217.57, 218.88, 0.50)',
                    transition: 'background-color 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'green2.main',
                      transition: 'background-color 0.2s ease',
                    },
                  }}
                  onClick={() => {
                    setStateOpen(!stateOpen)
                    setTypeOpen(false)
                    setSortOpen(false)
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {selectedMarkets.length === 0 && (
                      <Typography variant="cta2" fontWeight="500">
                        State: All
                      </Typography>
                    )}
                    {selectedMarkets.length === 1 && (
                      <Typography variant="cta2" fontWeight="500">
                        State: {selectedMarkets[0]}
                      </Typography>
                    )}
                    {selectedMarkets.length > 1 && (
                      <Typography variant="cta2" fontWeight="500">
                        State:{' '}
                        {selectedMarkets.length === statesWithCountsList.length
                          ? 'All'
                          : `${selectedMarkets.length} states`}
                      </Typography>
                    )}
                    <Box
                      width="24px"
                      height="24px"
                      sx={{
                        transform: stateOpen ? 'rotate(-180deg)' : '',
                        transition: 'transform 0.2s ease-in-out',
                      }}
                    >
                      <CaretIcon />
                    </Box>
                  </Box>
                </Paper>
                {stateOpen && (
                  <>
                    {statesWithCountsList.map((data) => (
                      <Box padding="4px 24px 4px 24px">
                        <Checkbox
                          checked={selectedMarkets.includes(data.state)}
                          onChange={() => {
                            const states = [...selectedMarkets]
                            if (selectedMarkets.includes(data.state)) {
                              states.splice(states.indexOf(data.state), 1)
                            } else {
                              states.push(data.state)
                            }
                            setSelectedMarkets(states)
                          }}
                          label={
                            <Box
                              width="176px"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography variant="p1">
                                {abbreviationToState[data.state] || data.state}
                              </Typography>
                              <Typography variant="p2">{data.count}</Typography>
                            </Box>
                          }
                        />
                      </Box>
                    ))}
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      gap="4px"
                    >
                      <Typography
                        variant="p2"
                        fontWeight="500"
                        color="green4.main"
                        onClick={() => {
                          setSelectedMarkets(
                            statesWithCountsList.map((data) => data.state)
                          )
                        }}
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        SELECT ALL
                      </Typography>
                      <Typography
                        variant="p2"
                        fontWeight="500"
                        color="moneyGreen.main"
                      >
                        /
                      </Typography>
                      <Typography
                        variant="p2"
                        fontWeight="500"
                        color="errorRed.main"
                        onClick={() => {
                          setSelectedMarkets([])
                        }}
                        sx={{
                          cursor: 'pointer',
                        }}
                      >
                        CLEAR ALL
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              <Box display="flex" flexDirection="column">
                <Paper
                  key="type"
                  sx={{
                    padding: '16px 20px 16px 24px',
                    mb: '4px',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    backgroundColor: typeOpen
                      ? 'green2.main'
                      : 'rgba(200.64, 217.57, 218.88, 0.50)',
                    transition: 'background-color 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'green2.main',
                      transition: 'background-color 0.2s ease',
                    },
                  }}
                  onClick={() => {
                    setTypeOpen(!typeOpen)
                    setSortOpen(false)
                    setStateOpen(false)
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {selectedTypes.length === 0 && (
                      <Typography variant="cta2" fontWeight="500">
                        Type: All
                      </Typography>
                    )}
                    {selectedTypes.length === 1 && (
                      <Typography variant="cta2" fontWeight="500">
                        Type:{' '}
                        {abbreviatedPropertyTypeToDisplay[selectedTypes[0]]}
                      </Typography>
                    )}
                    {selectedTypes.length > 1 && (
                      <Typography variant="cta2" fontWeight="500">
                        Type:{' '}
                        {selectedTypes.length === propertyTypes.length
                          ? 'All'
                          : `${selectedTypes.length} types`}
                      </Typography>
                    )}
                    <Box
                      width="24px"
                      height="24px"
                      sx={{
                        transform: typeOpen ? 'rotate(-180deg)' : '',
                        transition: 'transform 0.2s ease-in-out',
                      }}
                    >
                      <CaretIcon />
                    </Box>
                  </Box>
                </Paper>
                {typeOpen &&
                  propertyTypes.map((data) => (
                    <Box padding="4px 24px 4px 24px">
                      <Checkbox
                        checked={selectedTypes.includes(data)}
                        onChange={() => {
                          const types = [...selectedTypes]
                          if (selectedTypes.includes(data)) {
                            types.splice(types.indexOf(data), 1)
                          } else {
                            types.push(data)
                          }
                          setSelectedTypes(types)
                        }}
                        label={
                          <Box
                            width="176px"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Typography variant="p1">
                              {propertyTypeToDisplay[data]}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              width="100%"
              gap="16px"
            >
              {Array.from(filteredPropertyAssets?.values() || [])
                .slice(
                  (page - 1) * ASSETS_PER_PAGE,
                  (page - 1) * ASSETS_PER_PAGE + ASSETS_PER_PAGE
                )
                .map((asset) => {
                  if (asset.length > 1) {
                    return (
                      <LazyLoad once key={asset[0]?.propertyUuid}>
                        <MultifamilyAssetViewCard assetDetailsArray={asset} />
                      </LazyLoad>
                    )
                  } else {
                    return (
                      <LazyLoad once key={asset[0]?.propertyUuid}>
                        <AssetViewCard assetDetailsArray={asset} />
                      </LazyLoad>
                    )
                  }
                })}
            </Box>
            <Box
              pt="16px"
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              {!hasNoPropertyAssets && !loading && (
                <Pagination
                  count={
                    filteredPropertyAssets
                      ? Math.ceil(
                          Array.from(filteredPropertyAssets?.values() || [])
                            .length / ASSETS_PER_PAGE
                        )
                      : 1
                  }
                  defaultPage={1}
                  siblingCount={1}
                  page={page}
                  onChange={handleChangePage}
                />
              )}
              {hasNoPropertyAssets && (
                <Typography variant="p2">No results found.</Typography>
              )}
              {loading && <CircularProgress />}
            </Box>
          </Grid>
        </Grid>
      </PageWrapper>
    </PageContainer>
  )
}

export default DetailedAssetViewPage
