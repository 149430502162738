import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const OpenInNewIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 20}
      height={height || 21}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M14.8155 15.2542H5.18584C4.81547 15.2542 4.81547 15.2542 4.81547 14.8838V5.2542C4.81547 4.88383 4.81547 4.88383 5.18584 4.88383H9.63028C10.0377 4.88383 10.0007 4.55049 10.0007 4.14308C10.0007 3.73568 10.0377 3.40234 9.63028 3.40234H4.04716C3.33398 3.40234 3.33417 3.40234 3.33417 4.14308L3.33398 15.9949C3.33398 16.7357 3.33398 16.7357 4.07441 16.7357H15.9586C16.6673 16.7357 16.6673 16.7357 16.6673 15.9949V10.4394C16.6673 10.069 16.6673 10.069 15.9266 10.069C15.1858 10.069 15.1858 10.069 15.1858 10.4394V14.8838C15.1858 15.2542 15.1858 15.2542 14.8155 15.2542ZM11.4821 4.14308C11.4821 4.88383 11.4821 4.88383 11.8525 4.88383H14.1414L7.37843 11.6468C7.03769 11.9209 8.1488 13.032 8.42287 12.6912L15.1858 5.92827V8.21716C15.1858 8.58753 15.1858 8.58753 15.9266 8.58753C16.6673 8.58753 16.6673 8.58753 16.6673 8.21716V4.14308C16.6673 3.40234 16.6673 3.40234 15.9266 3.40234H11.8525C11.4821 3.40234 11.4821 3.40234 11.4821 4.14308Z"
        fill={fillStyle || '#000000'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default OpenInNewIcon
