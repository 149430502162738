import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const StocksIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 19}
      height={height || 18}
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M18.3125 15.5625H2.1875V0.9375C2.1875 0.834375 2.10312 0.75 2 0.75H0.6875C0.584375 0.75 0.5 0.834375 0.5 0.9375V17.0625C0.5 17.1656 0.584375 17.25 0.6875 17.25H18.3125C18.4156 17.25 18.5 17.1656 18.5 17.0625V15.75C18.5 15.6469 18.4156 15.5625 18.3125 15.5625ZM4.25 13.6875H5.5625C5.66563 13.6875 5.75 13.6031 5.75 13.5V10.125C5.75 10.0219 5.66563 9.9375 5.5625 9.9375H4.25C4.14687 9.9375 4.0625 10.0219 4.0625 10.125V13.5C4.0625 13.6031 4.14687 13.6875 4.25 13.6875ZM7.8125 13.6875H9.125C9.22812 13.6875 9.3125 13.6031 9.3125 13.5V6C9.3125 5.89687 9.22812 5.8125 9.125 5.8125H7.8125C7.70937 5.8125 7.625 5.89687 7.625 6V13.5C7.625 13.6031 7.70937 13.6875 7.8125 13.6875ZM11.375 13.6875H12.6875C12.7906 13.6875 12.875 13.6031 12.875 13.5V7.82812C12.875 7.725 12.7906 7.64062 12.6875 7.64062H11.375C11.2719 7.64062 11.1875 7.725 11.1875 7.82812V13.5C11.1875 13.6031 11.2719 13.6875 11.375 13.6875ZM14.9375 13.6875H16.25C16.3531 13.6875 16.4375 13.6031 16.4375 13.5V4.125C16.4375 4.02187 16.3531 3.9375 16.25 3.9375H14.9375C14.8344 3.9375 14.75 4.02187 14.75 4.125V13.5C14.75 13.6031 14.8344 13.6875 14.9375 13.6875Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default StocksIcon
