import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  Box,
  Modal,
  styled,
  Typography,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
  Drawer,
  Grid,
} from '@mui/material'
import {
  AnyInputConfig,
  CloseMobileIcon,
  GridForm,
  InputType,
  TrackedLink,
} from '@flock/shared-ui'
import { Close } from '@mui/icons-material'
import { useSnackbar } from '@flock/flock-component-library'
import { formatPhoneNumber } from '@flock/utils'
import {
  InvestorGetInvestorAccountDocument,
  InvestorUpdateInvestorAccountDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useInvestorAccountContext } from '../../../InvestorAccountContext'

const SettingsGroupHeader = styled(Typography)({
  paddingBottom: '0.4rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const SettingsGroupContainer = styled('div')({
  paddingTop: '0.7rem',
  paddingBottom: '0.7rem',
})

const StyledSettingsItem = styled(Typography)({
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
})

const SmallModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '25rem',
  maxHeight: '75vh',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

type SettingsItemProp = {
  label: string
  value: React.ReactNode
}

const SettingsItem = ({ label, value }: SettingsItemProp) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box>
      <StyledSettingsItem variant="p2">{label}</StyledSettingsItem>
    </Box>
    <Box>
      <StyledSettingsItem variant="p2">{value}</StyledSettingsItem>
    </Box>
  </Box>
)

type ContactInfoSectionProps = {
  setOuterHidden: any
}

const ContactInfoSection = (props: ContactInfoSectionProps) => {
  const { setOuterHidden } = props
  const { investorAccount: investorAccountData } = useInvestorAccountContext()
  const [contactInfoModalOpen, setContactInfoModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updateInvestorAccount] = useMutation(
    InvestorUpdateInvestorAccountDocument
  )
  const { notify } = useSnackbar()
  const openContactInfoModal = () => {
    setContactInfoModalOpen(true)
    setOuterHidden?.(true)
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const closeContactInfoModal = () => {
    setContactInfoModalOpen(false)
    setOuterHidden?.(false)
  }

  const submitContactInfo = async (result: any) => {
    try {
      setLoading(true)

      await updateInvestorAccount({
        variables: {
          input: {
            uuid: investorAccountData?.uuid as string,
            firstName: result.firstName,
            lastName: result.lastName,
            email: result.email,
            phoneNumber: result.phoneNumber,
          },
        },
        refetchQueries: [InvestorGetInvestorAccountDocument],
      })
      setLoading(false)
      notify('Successfully updated contact information')
      closeContactInfoModal()
    } catch (e) {
      notify(
        'An error has occurred and our team has been notified. Please refresh and try again or contact our team for assistance.',
        'error'
      )
      setLoading(false)
    }
  }

  const contactInfoInputConfigs: AnyInputConfig[] = [
    {
      name: 'firstName',
      type: InputType.Text,
      required: true,
      props: {
        label: 'First Name',
      },
      defaultValue: investorAccountData?.firstName,
    },
    {
      name: 'lastName',
      type: InputType.Text,
      required: true,
      props: {
        label: 'Last Name',
      },
      defaultValue: investorAccountData?.lastName,
    },
    {
      name: 'email',
      type: InputType.Text,
      required: true,
      props: {
        label: 'Email',
        format: 'email',
      },
      defaultValue: investorAccountData?.email,
    },
    {
      name: 'phoneNumber',
      type: InputType.Text,
      required: true,
      props: {
        label: 'Phone',
        format: 'phone',
      },
      defaultValue: investorAccountData?.phoneNumber,
    },
  ]

  return (
    <>
      <SettingsGroupContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <SettingsGroupHeader variant="p1">
            Contact Information
          </SettingsGroupHeader>
          <TrackedLink onClick={openContactInfoModal}>Edit</TrackedLink>
        </Box>
        <SettingsItem
          label="First Name"
          value={investorAccountData?.firstName}
        />
        <SettingsItem label="Last Name" value={investorAccountData?.lastName} />
        <SettingsItem label="Email" value={investorAccountData?.email} />
        <SettingsItem
          label="Phone"
          value={formatPhoneNumber(investorAccountData?.phoneNumber || '')}
        />
      </SettingsGroupContainer>
      {isMobile ? (
        <Drawer
          open={contactInfoModalOpen}
          PaperProps={{ sx: { width: '100%' } }}
        >
          <Box position="fixed" top="8px" right="8px">
            <IconButton onClick={closeContactInfoModal}>
              <CloseMobileIcon />
            </IconButton>
          </Box>
          <Grid columns={4} spacing={2} pl="32px" pr="32px">
            <Box width="100%" display="flex" flexDirection="column" gap="40px">
              <SettingsGroupHeaderWrapper
                sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
              >
                <Typography variant="h3">Contact Information</Typography>
              </SettingsGroupHeaderWrapper>
              <GridForm
                onSubmit={submitContactInfo}
                inputConfigs={contactInfoInputConfigs}
                loading={loading}
                ctaBoxProps={{
                  width: '114px',
                  position: 'fixed',
                  bottom: '40px',
                  right: '32px',
                }}
                gridProps={{
                  spacing: 3,
                }}
                ctaText="Submit"
              />
            </Box>
          </Grid>
        </Drawer>
      ) : (
        <Modal open={contactInfoModalOpen} onClose={closeContactInfoModal}>
          <SmallModalWrapper>
            <SettingsGroupHeaderWrapper
              sx={{ width: '100%', marginBottom: '1rem' }}
            >
              <Typography variant="h3">Contact Information</Typography>
              <IconButton onClick={closeContactInfoModal}>
                <Close />
              </IconButton>
            </SettingsGroupHeaderWrapper>
            <GridForm
              onSubmit={submitContactInfo}
              inputConfigs={contactInfoInputConfigs}
              loading={loading}
              ctaBoxProps={{
                pb: '24px',
                pt: '16px',
              }}
              gridProps={{
                spacing: 3,
              }}
              ctaText="Submit"
            />
          </SmallModalWrapper>
        </Modal>
      )}
    </>
  )
}

export default ContactInfoSection
