import styled from '@emotion/styled'
import { formatDollarsAndCents } from '@flock/utils'
import { Help } from '@mui/icons-material'
import { Typography, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { NetIncomeTableProps } from './netIncomeTableTypes'
import useNetIncomeTable from './useNetIncomeTable'

const StyledHelpOutline = styled(Help)({
  width: '16px',
  height: '16px',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip
    classes={{ popper: props.className }}
    leaveTouchDelay={999999}
    {...props}
  />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const NetIncomeTableMobile = (props: NetIncomeTableProps) => {
  const { columns } = useNetIncomeTable(props)

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingX="16px"
          paddingY="4px"
        >
          <Typography variant="l1" color="gray5.main">
            $,unaudited
          </Typography>
          <Typography variant="l1">{columns[0]?.quarterString}</Typography>
        </Box>
        <Box
          display="flex"
          sx={{
            border: '2px solid',
            borderRadius: '8px',
            borderColor: 'green4.main',
            padding: '8px',
          }}
          flexDirection="column"
          gap={2}
        >
          <StyledBox color="green4.main" fontWeight="500">
            <Typography variant="l1" fontWeight="500">
              Portfolio income
            </Typography>
            <Typography variant="l1" fontWeight="500">
              {columns[0]?.totalPortfolioIncome
                ? formatDollarsAndCents(
                    columns[0]?.totalPortfolioIncome,
                    false,
                    false
                  )
                : '-'}
            </Typography>
          </StyledBox>
          <StyledBox paddingLeft="16px">
            <Typography variant="l1">Rental income</Typography>
            <Typography variant="l1">
              {columns[0]?.totalRentalIncome
                ? formatDollarsAndCents(
                    columns[0]?.totalRentalIncome,
                    false,
                    false
                  )
                : '-'}
            </Typography>
          </StyledBox>
          <StyledBox paddingLeft="16px">
            <Typography variant="l1">Other income</Typography>
            <Typography variant="l1">
              {columns[0]?.totalOtherIncome
                ? formatDollarsAndCents(
                    columns[0]?.totalOtherIncome,
                    false,
                    false
                  )
                : '-'}
            </Typography>
          </StyledBox>
        </Box>
        <Box
          display="flex"
          sx={{
            border: '2px solid',
            borderRadius: '8px',
            borderColor: 'errorRed.main',
            padding: '8px',
          }}
          flexDirection="column"
          gap={2}
        >
          <StyledBox color="errorRed.main">
            <Typography variant="l1" fontWeight="500">
              Portfolio expenses
            </Typography>
            <Typography variant="l1" fontWeight="500">
              {columns[0]?.totalPortfolioExpenses
                ? formatDollarsAndCents(
                    columns[0]?.totalPortfolioExpenses,
                    false,
                    false
                  )
                : '-'}
            </Typography>
          </StyledBox>
          <StyledBox paddingLeft="16px">
            <Typography variant="l1">Op. expenses</Typography>
            <Typography variant="l1">
              {columns[0]?.totalOperatingExpenses
                ? formatDollarsAndCents(
                    columns[0]?.totalOperatingExpenses,
                    false,
                    false
                  )
                : '-'}
            </Typography>
          </StyledBox>
          <StyledBox paddingLeft="16px">
            <Typography variant="l1">Admin fees</Typography>
            <Typography variant="l1">
              {columns[0]?.totalAdministrativeFees
                ? formatDollarsAndCents(
                    columns[0]?.totalAdministrativeFees,
                    false,
                    false
                  )
                : '-'}
            </Typography>
          </StyledBox>
          <StyledBox paddingLeft="16px">
            <Typography variant="l1">Interest expense</Typography>
            <Typography variant="l1">
              {columns[0]?.totalInterestExpense
                ? formatDollarsAndCents(
                    columns[0]?.totalInterestExpense,
                    false,
                    false
                  )
                : '-'}
            </Typography>
          </StyledBox>
        </Box>
        <Box
          display="flex"
          sx={{
            padding: '8px',
          }}
          flexDirection="column"
          gap={2}
        >
          <StyledBox>
            <Typography variant="l1" fontWeight="500">
              Net income
            </Typography>
            <Typography variant="l1" fontWeight="500">
              {columns[0]?.totalPortfolioNetIncome
                ? formatDollarsAndCents(
                    columns[0]?.totalPortfolioNetIncome,
                    false,
                    false
                  )
                : '-'}
            </Typography>
          </StyledBox>
          <StyledBox>
            <Box display="flex" alignItems="center">
              <Typography variant="l1">Non-cash items&nbsp;</Typography>
              <StyledTooltip
                title={
                  <Typography variant="p3" color="gray8.main">
                    Non-cash items include depreciation and amortization
                    expenses.
                  </Typography>
                }
                enterTouchDelay={0}
                placement="top"
                arrow
              >
                <StyledHelpOutline fontSize="small" color="disabled" />
              </StyledTooltip>
            </Box>
            <Typography variant="l1">
              {columns[0]?.nonCashItems
                ? formatDollarsAndCents(columns[0]?.nonCashItems, false, false)
                : '-'}
            </Typography>
          </StyledBox>
          <hr style={{ width: '100%', color: 'gray3.main' }} />
          <StyledBox>
            <Box display="flex" alignItems="center">
              <Typography variant="l1" fontWeight="500">
                FFO&nbsp;
              </Typography>
              <StyledTooltip
                enterTouchDelay={0}
                title={
                  <Typography variant="p3" color="gray8.main">
                    FFO refers to funds from operations.
                  </Typography>
                }
                placement="top"
                arrow
              >
                <StyledHelpOutline fontSize="small" color="disabled" />
              </StyledTooltip>
            </Box>
            <Typography variant="l1" fontWeight="500">
              {columns[0]?.fundsFromOperations
                ? formatDollarsAndCents(
                    columns[0]?.fundsFromOperations,
                    false,
                    false
                  )
                : '-'}
            </Typography>
          </StyledBox>
        </Box>
      </Box>
    </>
  )
}

export default NetIncomeTableMobile
