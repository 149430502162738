import { GridIcon, StocksIcon } from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import {
  GAIN_LOSS_SECTION,
  NET_PERFORMANCE_SECTION,
} from '../useFundPerformanceSection'
import { FundPerformanceWaterfallProps } from './fundPerformanceWaterFallTypes'

const FundPerformanceWaterfallDesktop = (
  props: FundPerformanceWaterfallProps
) => {
  const {
    chartAxisStrings,

    gainLossOnRealEstateIsPositive,
    gainLossOnRealEstateTopPixel,
    portfolioIncomeTopPixel,
    portfolioExpensesTopPixel,
    fundValueChangeTopPixel,

    gainLossOnRealEstatePixelHeight,
    portfolioIncomePixelHeight,
    portfolioExpensesPixelHeight,
    fundValueChangePixelHeight,

    selectedSection,
    setSelectedSection,
    hoveredSection,
    setHoveredSection,
  } = props

  const startLeftPixel = 130
  const barWidth = 88

  const gainLossActive =
    hoveredSection === GAIN_LOSS_SECTION ||
    selectedSection === GAIN_LOSS_SECTION
  const gainLossColor = gainLossOnRealEstateIsPositive
    ? 'green4.main'
    : 'errorRed.main'
  const portfolioActive =
    hoveredSection === NET_PERFORMANCE_SECTION ||
    selectedSection === NET_PERFORMANCE_SECTION

  return (
    <>
      <Box
        width="100%"
        height="200px"
        sx={{ overflowX: 'auto' }}
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="row"
          marginBottom="8px"
          flexWrap="wrap"
          height="180px"
          width="100%"
          position="absolute"
          sx={{ pointerEvents: 'none' }}
          zIndex={3}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="8%"
            alignItems="end"
          >
            <Typography variant="p3" fontWeight={300}>
              {chartAxisStrings[0]}
            </Typography>
            <Typography variant="p3" fontWeight={300}>
              {chartAxisStrings[1]}
            </Typography>
            <Typography variant="p3" fontWeight={300}>
              {chartAxisStrings[2]}
            </Typography>
            <Typography variant="p3" fontWeight={300}>
              {chartAxisStrings[3]}
            </Typography>
            <Typography variant="p3" fontWeight={300}>
              {chartAxisStrings[4]}
            </Typography>
          </Box>
          <Box
            width="91%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            zIndex={3}
          >
            <hr style={{ width: '100%', color: 'blue' }} />
            <hr style={{ width: '100%', color: 'gray2.main' }} />
            <hr style={{ width: '100%', color: 'gray5.main' }} />
            <hr style={{ width: '100%', color: 'gray2.main' }} />
            <hr style={{ width: '100%', color: 'gray2.main' }} />
          </Box>
        </Box>

        <Box
          width="100%"
          height="180px"
          position="absolute"
          zIndex={3}
          sx={{ pointerEvents: 'none' }}
        >
          <Box
            width={barWidth}
            height={gainLossOnRealEstatePixelHeight}
            bgcolor={gainLossActive ? gainLossColor : 'gray5.main'}
            position="absolute"
            left={startLeftPixel}
            top={gainLossOnRealEstateTopPixel}
            zIndex={4}
          />
          <Box
            width="125px"
            position="absolute"
            left={startLeftPixel + 88}
            top={portfolioIncomeTopPixel + portfolioIncomePixelHeight}
          >
            <hr
              style={{
                width: '100%',
                color: 'gray2.main',
                borderBottom: '1px dashed',
                borderTop: 'none',
                margin: 0,
              }}
            />
          </Box>
          <Box
            width={barWidth}
            height={portfolioIncomePixelHeight}
            bgcolor={portfolioActive ? 'green4.main' : 'gray5.main'}
            position="absolute"
            left={startLeftPixel + 88 + 125}
            top={portfolioIncomeTopPixel}
            zIndex={4}
          />
          <Box
            width={barWidth}
            height={portfolioExpensesPixelHeight}
            bgcolor={portfolioActive ? 'errorRed.main' : 'gray6.main'}
            position="absolute"
            left={startLeftPixel + 88 + 125 + 88}
            top={portfolioExpensesTopPixel}
            zIndex={4}
          />
          <Box
            width="113px"
            position="absolute"
            left={startLeftPixel + 88 + 125 + 88 + 88}
            top={portfolioExpensesTopPixel + portfolioExpensesPixelHeight}
          >
            <hr
              style={{
                width: '100%',
                color: 'gray2.main',
                borderBottom: '1px dashed',
                borderTop: 'none',
                margin: 0,
              }}
            />
          </Box>
          <Box
            width={barWidth}
            height={fundValueChangePixelHeight}
            bgcolor="moneyGreen.main"
            position="absolute"
            left={startLeftPixel + 88 + 125 + 88 + 88 + 113}
            top={fundValueChangeTopPixel}
            zIndex={4}
          />
        </Box>
      </Box>

      <Box
        marginTop="-200px"
        width="100%"
        // height="200px"
        sx={{ overflowY: 'visible' }}
        position="absolute"
        zIndex={2}
      >
        {/* hover boxes */}
        <Box
          position="absolute"
          width="223px"
          height="240px"
          left={64}
          onMouseEnter={() => setHoveredSection(GAIN_LOSS_SECTION)}
          onMouseLeave={() => setHoveredSection('')}
          onClick={() => setSelectedSection(GAIN_LOSS_SECTION)}
          bgcolor={
            selectedSection === GAIN_LOSS_SECTION
              ? 'green0.main'
              : 'transparent'
          }
          sx={{
            '&:hover': {
              bgcolor: gainLossActive ? 'green0.main' : 'green1.main',
              pointerEvents: 'all',
              cursor: 'pointer',
            },
          }}
          borderRadius="16px"
          zIndex={2}
        />
        <Box
          position="absolute"
          width="200px"
          height="240px"
          left={330}
          onMouseEnter={() => setHoveredSection(NET_PERFORMANCE_SECTION)}
          onMouseLeave={() => setHoveredSection('')}
          onClick={() => setSelectedSection(NET_PERFORMANCE_SECTION)}
          bgcolor={
            selectedSection === NET_PERFORMANCE_SECTION
              ? 'green0.main'
              : 'transparent'
          }
          sx={{
            '&:hover': {
              bgcolor: portfolioActive ? 'green0.main' : 'green1.main',
              pointerEvents: 'all',
              cursor: 'pointer',
            },
          }}
          borderRadius="16px"
          zIndex={2}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        pl="64px"
        gap="16px"
        zIndex={3}
        position="relative"
        sx={{ pointerEvents: 'none' }}
      >
        <Box
          display="flex"
          flexDirection="row"
          p="8px"
          alignItems="center"
          gap="8px"
          bgcolor={gainLossActive ? 'green0.main' : 'green1.main'}
          borderRadius="16px"
        >
          <StocksIcon />
          <Typography variant="p2">
            Gain &#40;Loss&#41; on real estate
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="p2">+</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          p="8px"
          alignItems="center"
          gap="8px"
          bgcolor={portfolioActive ? 'green0.main' : 'green1.main'}
          borderRadius="16px"
          ml="10px"
        >
          <GridIcon />
          <Typography variant="p2">Portfolio net income</Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" pl="20px">
          <Typography variant="p2">=</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          p="8px"
          alignItems="center"
          gap="8px"
          pl="25px"
        >
          <Typography variant="p2">Fund value change</Typography>
        </Box>
      </Box>
    </>
  )
}

export default FundPerformanceWaterfallDesktop
