import { Paper, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

type PillSelectorOption = {
  label: string
  value: any
  disabled?: boolean
}

export type PillSelectorProps = {
  options: PillSelectorOption[]
  selectedValue: any
  onSelect: (value: any) => void
}
const PillSelector = (props: PillSelectorProps) => {
  const theme = useTheme()

  const { options, selectedValue, onSelect } = props
  if (options.length < 2) {
    return <></>
  }
  return (
    <Box display="flex" flexDirection="row" gap="8px" flexWrap="wrap">
      {options.map((option) => {
        const selected = option.value === selectedValue

        const backgroundColor = () => {
          if (option.disabled) {
            return 'gray2.main'
          } else if (selected) {
            return (
              // @ts-ignore
              theme?.pillSelectorOptions?.selectedBackgroundColor ||
              'green1.main'
            )
          }
          return undefined
        }
        return (
          <Paper
            key={option.value}
            sx={{
              padding: '2px 12px 4px 12px',
              borderRadius: '24px',
              border: '2px solid',
              borderColor: 'primary.main',
              display: 'inline-block',
              backgroundColor: backgroundColor(),
              boxShadow: 'none',
              cursor: selected || option.disabled ? 'default' : 'pointer',
            }}
            onClick={
              !selected && !option.disabled
                ? () => {
                    onSelect(option.value)
                  }
                : undefined
            }
          >
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              sx={{ whiteSpace: 'nowrap' }}
            >
              <Typography variant="l1" color="primary.main">
                <div key={option.value}>{option.label}</div>
              </Typography>
            </Box>
          </Paper>
        )
      })}
    </Box>
  )
}

export default PillSelector
