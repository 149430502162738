import { Dropdown } from '@flock/shared-ui'
import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { ChartPeriodSelection } from '../accountSectionTypes'

const GenericPeriodSelectorBubble = (props: {
  selection: ChartPeriodSelection
  currentSelection: ChartPeriodSelection
  handleOnClick: (selection: ChartPeriodSelection) => void
}) => {
  const { selection, currentSelection, handleOnClick } = props
  const selected = currentSelection === selection
  return (
    <Paper
      sx={{
        padding: '4px 12px',
        borderRadius: '24px',
        border: '2px solid',
        borderColor: 'moneyGreen.main',
        display: 'inline-block',
        backgroundColor: selected ? 'green0.main' : undefined,
        boxShadow: 'none',
        cursor: selected ? 'default' : 'pointer',
      }}
      onClick={() => handleOnClick(selection)}
    >
      <Box
        display="flex"
        gap="10px"
        alignItems="center"
        sx={{ whiteSpace: 'nowrap' }}
      >
        <Typography variant="p3" color="moneyGreen.main">
          {selection}
        </Typography>
      </Box>
    </Paper>
  )
}
type PeriodSelectorProps = {
  selectedPeriod: ChartPeriodSelection
  yearsToSelect: number[]
  setPeriodSelection: (period: ChartPeriodSelection) => void
}
const PeriodSelector = (props: PeriodSelectorProps) => {
  const { selectedPeriod, yearsToSelect, setPeriodSelection } = props
  const [yearSelected, setYearSelected] = useState<number>()

  useEffect(() => {
    if (selectedPeriod !== 'ALL' && selectedPeriod !== '1Y') {
      setYearSelected(selectedPeriod)
      return
    }
    if (yearsToSelect.length > 0 && !yearSelected) {
      setYearSelected(yearsToSelect[0])
    }
  }, [yearsToSelect, selectedPeriod, yearSelected])

  if (yearsToSelect.length === 0 || !yearSelected) {
    return <></>
  }
  const dropdownActive = selectedPeriod !== 'ALL' && selectedPeriod !== '1Y'
  return (
    <Box display="flex" flexDirection="row" gap="4px" flexWrap="wrap">
      <GenericPeriodSelectorBubble
        selection="1Y"
        currentSelection={selectedPeriod}
        handleOnClick={setPeriodSelection}
      />
      <Box width="80px" height="30px">
        <Dropdown
          options={yearsToSelect.map((year) => ({
            label: year.toString(),
            value: year,
          }))}
          InputProps={{
            style: {
              fontSize: '14px',
              backgroundColor: dropdownActive ? '#EBF1F1' : undefined,
            },
          }}
          onChange={(event) => {
            const year = parseInt(event.target.value, 10)
            setPeriodSelection(year)
          }}
          value={yearSelected.toString()} // we do this so on change is triggered even when the year clicked is the same
          size="dropdownMini"
        />
      </Box>
      <GenericPeriodSelectorBubble
        selection="ALL"
        currentSelection={selectedPeriod}
        handleOnClick={setPeriodSelection}
      />
    </Box>
  )
}

export default PeriodSelector
