import { Dropdown, MinusIcon, PlusIcon } from '@flock/shared-ui'
import { formatDollarsAndCents, formatPercentage } from '@flock/utils'
import { Info } from '@mui/icons-material'
import {
  Grid,
  Box,
  styled,
  Tooltip,
  Typography,
  Collapse,
  Switch,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { QuarterData } from '../accountSectionTypes'
import DistributionYieldTooltip from './DistributionYieldTooltip'
import { HistoricalPerformanceData } from './historicalPerformanceTypes'

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip
    classes={{ popper: props.className }}
    {...props}
    leaveTouchDelay={999999}
  />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

const HistoricalPerformanceMobile = (props: HistoricalPerformanceData) => {
  const { quarterData, filteredQuarterData, periodSelection } = props
  const ref = React.useRef<HTMLDivElement>(null)
  const mostRecent = quarterData[quarterData.length - 1]
  const [selected, setSelected] = useState<string>(
    mostRecent?.quarterString || ''
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isQuarterlyPercentages, setIsQuarterlyPercentages] =
    useState<boolean>(true)
  const [selectedData, setSelectedData] = useState<QuarterData>(mostRecent)

  useEffect(() => {
    setSelected(mostRecent?.quarterString || '')
  }, [quarterData, mostRecent?.quarterString])

  useEffect(() => {
    const data = quarterData.find((d) => d.quarterString === selected)
    if (data) {
      setSelectedData(data)
    }
  }, [selected, quarterData])

  useEffect(() => {
    if (periodSelection === 'ALL' && ref.current) {
      ref.current.scrollLeft = ref.current.scrollWidth
    }
  }, [periodSelection])

  return (
    <Grid item xs={6}>
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="gray1.main"
        p="24px"
        mr="-24px"
        ml="-24px"
        borderRadius="0 0 16px 16px"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            '&:hover': {
              pointerEvents: 'all',
              cursor: 'pointer',
            },
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Typography variant="p1" color="gray8.main">
            Historical Performance
          </Typography>
          <Box width="24px" height="24px" borderRadius="8px">
            {isOpen ? (
              <MinusIcon height="24px" width="24px" />
            ) : (
              <PlusIcon height="24px" width="24px" />
            )}
          </Box>
        </Box>
        <Collapse in={isOpen}>
          <Box display="flex" flexDirection="column" gap="8px">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap="16px"
              pt="20px"
              pb="16px"
            >
              <Dropdown
                options={filteredQuarterData.map((data) => ({
                  label: data.quarterString || '',
                  value: data.quarterString || '',
                }))}
                onChange={(event) => {
                  const quarter = event.target.value
                  setSelected(quarter)
                }}
                value={selected}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="p3" color="moneyGreen.main">
                Starting account value
              </Typography>
              <Typography variant="p3" color="moneyGreen.main">
                {selectedData?.startingAccountValue
                  ? formatDollarsAndCents(
                      selectedData.startingAccountValue / 100
                    )
                  : '-'}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="p3" color="moneyGreen.main">
                Contributions
              </Typography>
              <Typography variant="p3" color="moneyGreen.main">
                {selectedData?.contributions
                  ? formatDollarsAndCents(selectedData.contributions / 100)
                  : '-'}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="p3" color="moneyGreen.main">
                Fund value change
              </Typography>
              <Typography variant="p3" color="moneyGreen.main">
                {selectedData?.netIncome && selectedData?.gainLoss
                  ? formatDollarsAndCents(
                      (selectedData.gainLoss + selectedData.netIncome) / 100,
                      true,
                      false
                    )
                  : '-'}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
              ml="12px"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="p3" color="moneyGreen.main">
                  Real estate gain(loss) &nbsp;
                </Typography>
                <StyledTooltip
                  title={
                    <Typography variant="p3" color="gray8.main">
                      Portfolio homes are valued quarterly. Just like changes in
                      real estate prices impact your investment value outside of
                      Flock, changes in real estate prices impact Fund
                      performance.
                    </Typography>
                  }
                  enterTouchDelay={0}
                  data-cy="fundValueTooltipIcon"
                  placement="top"
                  arrow
                >
                  <StyledInfoOutline fontSize="small" color="disabled" />
                </StyledTooltip>
              </Box>
              <Typography variant="p3" color="moneyGreen.main">
                {selectedData?.gainLoss
                  ? formatDollarsAndCents(
                      selectedData.gainLoss / 100,
                      true,
                      false
                    )
                  : '-'}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
              ml="12px"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="p3" color="moneyGreen.main">
                  Net income &nbsp;
                </Typography>
                <StyledTooltip
                  title={
                    <Typography variant="p3" color="gray8.main">
                      Every client receives their pro-rata share of Fund net
                      income quarterly. This is calculated as total rents and
                      other income, minus expenses. It includes non-cash items
                      like loan cost amortization, which may cause quarterly net
                      income to differ from quarterly Funds from Operations.
                    </Typography>
                  }
                  enterTouchDelay={0}
                  data-cy="fundValueTooltipIcon"
                  placement="top"
                  arrow
                >
                  <StyledInfoOutline fontSize="small" color="disabled" />
                </StyledTooltip>
              </Box>
              <Typography variant="p3" color="moneyGreen.main">
                {selectedData?.netIncome
                  ? formatDollarsAndCents(
                      selectedData.netIncome / 100,
                      true,
                      false
                    )
                  : '-'}
              </Typography>
            </Box>
            <hr style={{ width: '100%', color: 'gray3.main' }} />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="p3" fontWeight="500" color="moneyGreen.main">
                Ending account value
              </Typography>
              <Typography variant="p3" fontWeight="500" color="moneyGreen.main">
                {selectedData?.endingAccountValue
                  ? formatDollarsAndCents(selectedData.endingAccountValue / 100)
                  : ''}
              </Typography>
            </Box>
            <hr style={{ width: '100%', color: 'gray3.main' }} />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="p3" fontWeight="500" color="moneyGreen.main">
                Cash taken
              </Typography>
              <Typography variant="p3" fontWeight="500" color="moneyGreen.main">
                {selectedData?.redemptions
                  ? formatDollarsAndCents(selectedData.redemptions / 100)
                  : '-'}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="p3" fontWeight="500" color="gray5.main">
                Taxes paid by Flock
              </Typography>
              <Typography variant="p3" fontWeight="500" color="gray5.main">
                {selectedData?.redemptions
                  ? formatDollarsAndCents(selectedData.taxFees / 100)
                  : '-'}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="p3" color="moneyGreen.main">
                  Cash yield &nbsp;
                </Typography>
                {selectedData.hasContributionInQuarter &&
                selectedData.cashYield ? (
                  <DistributionYieldTooltip />
                ) : (
                  <></>
                )}
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="p3" color="moneyGreen.main">
                  {selectedData?.cashYield && selectedData?.redemptions > 0
                    ? formatPercentage(selectedData.cashYield, 1)
                    : 'n/a'}
                </Typography>
              </Box>
            </Box>
            <hr style={{ width: '100%', color: 'gray3.main' }} />
            <Box
              display="flex"
              flexDirection="row"
              gap="8px"
              alignItems="center"
            >
              <Typography
                variant="c1"
                fontWeight="500"
                color={
                  isQuarterlyPercentages ? 'moneyGreen.main' : 'gray5.main'
                }
              >
                QUARTERLY
              </Typography>
              <Switch
                checked={!isQuarterlyPercentages}
                onChange={() =>
                  setIsQuarterlyPercentages(!isQuarterlyPercentages)
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography
                variant="c1"
                fontWeight="500"
                color={
                  isQuarterlyPercentages ? 'gray5.main' : 'moneyGreen.main'
                }
              >
                ANNUALIZED
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="p3" color="moneyGreen.main">
                  Return on equity &nbsp;
                </Typography>
                <StyledTooltip
                  title={
                    <Typography
                      variant="p3"
                      marginRight="6px"
                      color="gray8.main"
                    >
                      This is the total return on your equity invested at the
                      start of the quarter; this figure is calculated before
                      cash is taken (if applicable).
                    </Typography>
                  }
                  enterTouchDelay={0}
                  data-cy="fundValueTooltipIcon"
                  placement="right"
                  arrow
                >
                  <StyledInfoOutline fontSize="small" color="disabled" />
                </StyledTooltip>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="p3" color="moneyGreen.main">
                  {isQuarterlyPercentages
                    ? formatPercentage(selectedData.quarterReturn - 1, 1)
                    : formatPercentage((selectedData.quarterReturn - 1) * 4, 1)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Grid>
  )
}

export default HistoricalPerformanceMobile
