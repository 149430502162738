import { useMutation } from '@apollo/client'
import { InvestorGenerateV3OneTimeLinkDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  FLOCK_PHONE_NUMBER,
  INVESTOR_EMAIL,
  PHONE_URL,
  TrackedLink,
} from '@flock/shared-ui'
import { CheckCircleOutlineOutlined, Close } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  maxHeight: '75vh',
  overflowY: 'auto',
  padding: '2rem',
  borderRadius: '16px',
})

export type ContributionAgreementOneTimeLinkProps = {
  orderUuid: string
  taskUuid: string
  legalEntityUuid: string
  email: string
  generateAndOpenCADocusignLink: () => Promise<void>
  isOneTimeLinkSent: boolean
}

type SignContributionAgreementModalProps = {
  contributionAgreementOneTimeLinkProps: ContributionAgreementOneTimeLinkProps
  isOpen: boolean
  onClose: () => void
  reRenderOrderHome: () => void
}

const SignContributionAgreementModal = (
  props: SignContributionAgreementModalProps
) => {
  const {
    contributionAgreementOneTimeLinkProps,
    isOpen,
    onClose,
    reRenderOrderHome,
  } = props
  const {
    orderUuid,
    taskUuid,
    legalEntityUuid,
    email,
    generateAndOpenCADocusignLink,
    isOneTimeLinkSent,
  } = contributionAgreementOneTimeLinkProps

  const [isLinkSent, setIsLinkSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [generateOneTimeLink] = useMutation(
    InvestorGenerateV3OneTimeLinkDocument
  )

  const generateOneTimeLinkOnSubmit = async () => {
    await generateOneTimeLink({
      variables: {
        input: {
          orderUuid,
          taskUuid,
          legalEntityUuid,
          email,
        },
      },
    })
  }

  const conditionalRerenderOnClose = () => {
    if (isLinkSent) {
      reRenderOrderHome()
    }
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={conditionalRerenderOnClose}>
      <ModalWrapper>
        <SettingsGroupHeaderWrapper
          sx={{ width: '100%', marginBottom: '1rem' }}
        >
          <Typography variant="h3">Contribution Agreement</Typography>
          <IconButton onClick={conditionalRerenderOnClose}>
            <Close />
          </IconButton>
        </SettingsGroupHeaderWrapper>
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography variant="p3" color="gray5.main">
            Sending the contribution agreement to a separate email means you
            will no longer be able to access it from this account. Please make
            sure the email is correct.
          </Typography>
          {isOneTimeLinkSent || isLinkSent ? (
            <Box
              bgcolor="gray1.main"
              p="16px"
              borderRadius="8px"
              display="flex"
              flexDirection="column"
              gap="16px"
            >
              <Box display="flex" flexDirection="row" gap="16px">
                <CheckCircleOutlineOutlined sx={{ fontSize: 60 }} />
                <Typography variant="p1" color="green4.main">
                  Contribution agreement email was sent to the secondary signer
                  at: {email}
                </Typography>
              </Box>

              <Typography variant="p3" color="gray5.main">
                If this is the wrong email, or you no longer have access to this
                email, please call{' '}
                <TrackedLink onClick={() => window.open(PHONE_URL)}>
                  {FLOCK_PHONE_NUMBER}
                </TrackedLink>{' '}
                or email{' '}
                <TrackedLink
                  onClick={() => window.open(`mailto:${INVESTOR_EMAIL}`)}
                >
                  danielle@flockhomes.com
                </TrackedLink>
              </Typography>
            </Box>
          ) : (
            <>
              <Box
                bgcolor="green0.main"
                p="16px"
                borderRadius="8px"
                onClick={async () => {
                  setIsLoading(true)
                  await generateAndOpenCADocusignLink()
                  setIsLoading(false)
                }}
                sx={{
                  '&:hover': {
                    bgcolor: 'green1.main',
                    cursor: 'pointer',
                  },
                }}
              >
                {isLoading ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    height="53px"
                    alignItems="center"
                  >
                    <CircularProgress size={32} />
                  </Box>
                ) : (
                  <Typography variant="p1" color="green4.main">
                    The secondary signer is here and ready to sign the
                    contribution agreement.
                  </Typography>
                )}
              </Box>
              <Box
                bgcolor="green0.main"
                p="16px"
                borderRadius="8px"
                onClick={async () => {
                  await generateOneTimeLinkOnSubmit()
                  setIsLinkSent(true)
                }}
                sx={{
                  '&:hover': {
                    bgcolor: 'green1.main',
                    cursor: 'pointer',
                  },
                }}
              >
                <Typography variant="p1" color="green4.main">
                  I want to send this contribution agreement link to the
                  secondary signer {email}.
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </ModalWrapper>
    </Modal>
  )
}

export default SignContributionAgreementModal
