import React, { useState } from 'react'
import {
  Box,
  Typography,
  useMediaQuery,
  CircularProgress,
  styled,
} from '@mui/material'
import { Tooltip, TrackedLink } from '@flock/shared-ui'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import theme from '@flock/shared-ui/src/theme/theme'
import {
  Core_LegalEntity,
  InvestorEditLegalEntityMutationDocument,
  InvestorGetLegalEntitiesByInvestorAccountUuidDocument,
  InvestorGetLegalEntityDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { Info } from '@mui/icons-material'
import PreferencesModal from './PreferencesModal'
import PreferencesMobileDrawer from './PreferencesMobileDrawer'

type FlexibleDistributionSectionProps = {
  cashFlowAllotment: number
  cashFlowSelection: number
  totalEquity: number
  hasLegalEntityBankAccountData: any
  legalEntityUuid: string
  setOuterHidden: any
}

const SettingsGroupHeader = styled(Typography)({
  paddingBottom: '0.4rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
})

const SettingsGroupContainer = styled('div')({
  paddingTop: '0.7rem',
  paddingBottom: '0.7rem',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: white;
    color: black;
    border-radius: 0;
    max-width: 12rem;
    line-height: 150%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 5px;
  }

  & .MuiTooltip-arrow::before {
    color: white;
  }
`

const StyledHelp = styled(Info)({
  fontSize: '1rem',
  cursor: 'pointer',
  marginLeft: '0.25rem',
  marginBottom: '-0.1rem',
})

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '1rem',
})

const FlexibleDistributionSection = (
  props: FlexibleDistributionSectionProps
) => {
  const {
    cashFlowAllotment,
    cashFlowSelection,
    totalEquity,
    hasLegalEntityBankAccountData,
    legalEntityUuid,
    setOuterHidden,
  } = props
  const [flexibleDistributionsModalOpen, setFlexibleDistributionsModalOpen] =
    useState(false)
  const [editLegalEntity] = useMutation(InvestorEditLegalEntityMutationDocument)
  const { notify } = useSnackbar()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const openFlexibleDistributionsModal = () => {
    setFlexibleDistributionsModalOpen(true)
    setOuterHidden?.(true)
  }

  const closeFlexibleDistributionsModal = () => {
    setFlexibleDistributionsModalOpen(false)
    setOuterHidden?.(false)
  }

  let disableCashDistributionChange = false
  if (!hasLegalEntityBankAccountData) {
    disableCashDistributionChange = true
  }

  const { data: legalEntityData, loading: legalEntityLoading } = useQuery(
    InvestorGetLegalEntityDocument,
    {
      variables: {
        input: {
          legalEntityUuid,
        },
      },
    }
  )
  const legalEntity = legalEntityData?.legalEntity
    ?.legalEntity as Core_LegalEntity

  const confirmCashFlow = async (newCashFlowPercentage: number) => {
    let cashDistributionEnabled = false
    if (newCashFlowPercentage > 0) {
      // Enable cash distribution
      cashDistributionEnabled = true
    }

    try {
      await editLegalEntity({
        variables: {
          editLegalEntityInput: {
            uuid: legalEntity?.uuid || legalEntityUuid,
            cashDistributionEnabled,
            cashFlowSelection: newCashFlowPercentage,
          },
        },
        refetchQueries: [InvestorGetLegalEntitiesByInvestorAccountUuidDocument],
      })

      notify('Successfully updated cash flow preference.', 'success')
    } catch (e) {
      notify(
        'An error has occurred and our team has been notified. Please refresh and try again or contact our team for assistance.',
        'error'
      )
    }
  }

  const current = new Date()
  current.setHours(0, 0, 0, 0)
  const distributionDates: [Date, string, string][] = [
    [
      new Date(current.getFullYear(), 0, 1),
      `March 29, ${current.getFullYear().toString()}`,
      'April 25',
    ],
    [
      new Date(current.getFullYear(), 3, 29),
      `June 29, ${current.getFullYear().toString()}`,
      'July 25',
    ],
    [
      new Date(current.getFullYear(), 6, 29),
      `September 29, ${current.getFullYear().toString()}`,
      'October 25',
    ],
    [
      new Date(current.getFullYear(), 9, 29),
      `December 29, ${current.getFullYear().toString()}`,
      'January 25',
    ],
    [
      new Date(current.getFullYear(), 12, 29),
      `March 29, ${(current.getFullYear() + 1).toString()}`,
      'April 25',
    ],
  ]

  let distributionTimeText = ''
  let distributionCutoffText = ''
  let quarterString = ''
  distributionDates.forEach((entry) => {
    const [date, nextDateText, distributionText] = entry
    if (current > date) {
      distributionCutoffText = `${nextDateText}`
      distributionTimeText = `${distributionText}`

      const month = date.getMonth()
      const year = date.getFullYear()
      let quarter = ''
      if (month < 3) {
        quarter = 'Q1'
      } else if (month < 6) {
        quarter = 'Q2'
      } else if (month < 9) {
        quarter = 'Q3'
      } else {
        quarter = 'Q4'
      }
      quarterString = `${quarter} ${year}`
    }
  })

  if (legalEntityLoading) {
    return <CircularProgress size="1rem" />
  }
  return (
    <>
      <SettingsGroupContainer>
        <SettingsGroupHeaderWrapper>
          <SettingsGroupHeader variant="p1">
            Quarterly Cash Flow
          </SettingsGroupHeader>
          {disableCashDistributionChange ? (
            <StyledTooltip
              title="A bank account must be linked to your account to enable Cash Distributions."
              arrow
              placement="top"
              enterTouchDelay={0}
            >
              <StyledHelp sx={{ color: `${theme.palette.gray7.main}50` }} />
            </StyledTooltip>
          ) : (
            <TrackedLink onClick={openFlexibleDistributionsModal}>
              Edit
            </TrackedLink>
          )}
        </SettingsGroupHeaderWrapper>

        <Typography variant="p2">
          {cashFlowSelection > 0
            ? 'I am taking quarterly cash flow.'
            : 'I am reinvesting all my cash flow.'}
        </Typography>
        {isMobile ? (
          <PreferencesMobileDrawer
            open={flexibleDistributionsModalOpen}
            cashFlowAllotment={cashFlowAllotment || 0}
            cashFlowSelection={cashFlowSelection || 0}
            totalEquity={totalEquity}
            onSubmit={confirmCashFlow}
            onClose={closeFlexibleDistributionsModal}
          />
        ) : (
          <PreferencesModal
            open={flexibleDistributionsModalOpen}
            cashFlowAllotment={cashFlowAllotment || 0}
            cashFlowSelection={cashFlowSelection || 0}
            totalEquity={totalEquity}
            onSubmit={confirmCashFlow}
            onClose={closeFlexibleDistributionsModal}
          />
        )}
      </SettingsGroupContainer>
      <SettingsGroupContainer>
        <Box
          sx={{
            padding: '24px',
            backgroundColor: theme.palette.green0.main,
            marginBottom: '24px',
            borderRadius: '12px',
          }}
          display="flex"
          flexDirection="column"
          gap="8px"
        >
          <Typography
            sx={{
              color: theme.palette.moneyGreen.main,
              fontSize: '16px',
            }}
            variant="p2"
          >
            {`${quarterString} Cash Flow`}
          </Typography>
          <Typography variant="p3" color="moneyGreen.main">
            {' '}
            {cashFlowSelection > 0
              ? `${
                  legalEntity?.name || 'You'
                } will receive cash deposited to your linked bank account on ${distributionTimeText}.`
              : `Congratulations on staying reinvested! Your account value will reflect these changes on ${distributionTimeText}.`}
          </Typography>
        </Box>
        <StyledBox>
          <Typography
            sx={{
              color: theme.palette.gray5.main,
            }}
            variant="p3"
          >
            We will send you an email to confirm your settings at the end of the
            quarter. You are able to change your cash flow preference until{' '}
            {distributionCutoffText} at 12AM PT.
          </Typography>
        </StyledBox>
      </SettingsGroupContainer>
    </>
  )
}

export default FlexibleDistributionSection
