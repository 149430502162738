import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const MailIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M29 5H3C2.44687 5 2 5.44687 2 6V26C2 26.5531 2.44687 27 3 27H29C29.5531 27 30 26.5531 30 26V6C30 5.44687 29.5531 5 29 5ZM27.75 8.4625V24.75H4.25V8.4625L3.3875 7.79063L4.61562 6.2125L5.95312 7.25313H26.05L27.3875 6.2125L28.6156 7.79063L27.75 8.4625ZM26.05 7.25L16 15.0625L5.95 7.25L4.6125 6.20937L3.38438 7.7875L4.24687 8.45938L14.9219 16.7594C15.2289 16.9979 15.6066 17.1273 15.9953 17.1273C16.3841 17.1273 16.7618 16.9979 17.0688 16.7594L27.75 8.4625L28.6125 7.79063L27.3844 6.2125L26.05 7.25Z"
        fill={fillStyle || '#03341D'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default MailIcon
