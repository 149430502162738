import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const GridIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 22}
      height={height || 18}
      viewBox="0 0 22 18"
      fill="none"
    >
      <path
        d="M20.75 0.75H1.25C0.835156 0.75 0.5 1.08516 0.5 1.5V16.5C0.5 16.9148 0.835156 17.25 1.25 17.25H20.75C21.1648 17.25 21.5 16.9148 21.5 16.5V1.5C21.5 1.08516 21.1648 0.75 20.75 0.75ZM19.8125 5.625H14.8438V2.4375H19.8125V5.625ZM19.8125 10.875H14.8438V7.125H19.8125V10.875ZM8.65625 7.125H13.3438V10.875H8.65625V7.125ZM13.3438 5.625H8.65625V2.4375H13.3438V5.625ZM2.1875 7.125H7.15625V10.875H2.1875V7.125ZM2.1875 2.4375H7.15625V5.625H2.1875V2.4375ZM2.1875 12.375H7.15625V15.5625H2.1875V12.375ZM8.65625 12.375H13.3438V15.5625H8.65625V12.375ZM19.8125 15.5625H14.8438V12.375H19.8125V15.5625Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default GridIcon
