import React from 'react'
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material'
import { navigate } from 'gatsby'
import { ORDERS_URL } from '../../../../constants'
import ErrorCardPage from '../../../SharedComponents/ErrorCardPage'
import { LpOnboardingFlowData } from './lpOnboardingTypes'

function renderSubscriptionDocsDocusignStatus(
  isSigningComplete: boolean,
  docusignStatusParam: string | null | undefined,
  docusignURL: string
) {
  if (
    docusignStatusParam === 'signing_complete' ||
    docusignStatusParam === 'viewing_complete' ||
    isSigningComplete
  ) {
    return (
      <>
        <Typography variant="p2" color="gray8.main">
          Thank you for signing your Subscription Booklet! Please wait to be
          redirected back to the orders page
        </Typography>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <CircularProgress size="24px" />
        </Box>
      </>
    )
  }
  if (docusignStatusParam === 'ttl_expired') {
    return (
      <>
        <Typography variant="p2" color="gray8.main">
          Your previous link expired, please use the updated link below to sign
          your Subscription Booklet.
        </Typography>
        <Button
          variant="primary"
          onClick={() => {
            window.open(docusignURL, '_self')
          }}
        >
          <Typography variant="cta2">Subscription Booklet</Typography>
        </Button>
      </>
    )
  } else {
    return (
      <>
        <Typography variant="p2" color="gray8.main">
          The last step of onboarding is to sign your Subscription Booklet via
          Docusign.
        </Typography>
        <Button
          variant="primary"
          onClick={() => {
            window.open(docusignURL, '_self')
          }}
        >
          <Typography variant="cta2">Subscription Booklet</Typography>
        </Button>
      </>
    )
  }
}

export type SubscriptionDocumentSigningInjectedProps = {
  docusignURL: string
  isSigningComplete: boolean
  orderUuid: string
}

type SubscriptionDocumentSigningProps = {
  onSubmit: (flowData: Partial<LpOnboardingFlowData>) => void
  error?: boolean
}

const SubscriptionDocumentSigning = ({
  docusignURL,
  isSigningComplete,
  orderUuid,
  onSubmit,
  error,
}: SubscriptionDocumentSigningProps &
  SubscriptionDocumentSigningInjectedProps) => {
  let docusignStatus: URLSearchParams | null = null
  if (typeof window !== `undefined`) {
    docusignStatus = new URLSearchParams(window.location.search)
  }
  const docusignStatusParam = docusignStatus?.get('event')
  const isFinished =
    docusignStatusParam === 'signing_complete' ||
    docusignStatusParam === 'viewing_complete' ||
    isSigningComplete

  if (isFinished) {
    onSubmit({})
  }
  if (error) {
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="16px"
      >
        <ErrorCardPage customViewHeight />
      </Box>
    )
  }

  return (
    <>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <Box
          display="flex"
          flexDirection="column"
          pt="16px"
          gap="32px"
          alignItems="center"
          height="352px"
        >
          {renderSubscriptionDocsDocusignStatus(
            isSigningComplete,
            docusignStatusParam,
            docusignURL
          )}
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          {!isFinished ? (
            <Button
              sx={{ width: '200px', height: '40px' }}
              variant="secondary"
              onClick={() => navigate(`${ORDERS_URL}/${orderUuid}`)}
            >
              <Typography variant="cta2">Return to Order</Typography>
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Grid>
      <Grid item xs={3} />
    </>
  )
}

export default SubscriptionDocumentSigning

SubscriptionDocumentSigning.defaultProps = {
  error: false,
}
