import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { AccountDetailsHeaderComponentProps } from './accountDetailsHeaderTypes'

const AccountDetailsHeaderMobile = (
  props: AccountDetailsHeaderComponentProps
) => {
  const {
    totalEquityValueString,
    cumulativeReturnString,
    annualizedReturnString,
  } = props
  const totalReturnIsPositive =
    cumulativeReturnString !== undefined && cumulativeReturnString[0] === '+'

  const theme = useTheme()

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap="16px"
      id="header"
    >
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" gap="4px">
          <Typography variant="p3">My total Flock account value</Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="baseline"
            gap="8px"
          >
            <Typography
              variant="p1"
              sx={{
                [theme.breakpoints.down('sm')]: {
                  fontSize: '24px',
                  fontWeight: 500,
                },
              }}
            >
              {totalEquityValueString}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        borderRadius="8px"
        bgcolor="green0.main"
        p="12px"
        gap="4px"
      >
        <Typography variant="c1" color="gray5.main">
          TOTAL RETURN
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          gap="16px"
          justifyContent="start"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="2px"
            alignItems="start"
          >
            <Typography
              fontFamily="Outfit"
              fontSize="1.25rem"
              lineHeight="1.5rem"
              fontWeight="500"
              color={totalReturnIsPositive ? 'green4.main' : 'gray6.main'}
            >
              {cumulativeReturnString}
            </Typography>
            <Typography
              variant="p3"
              lineHeight="18px"
              color={totalReturnIsPositive ? 'green4.main' : 'gray6.main'}
            >
              cumulative
            </Typography>
          </Box>
          <hr
            style={{
              marginTop: '-2px',
              width: '1px',
              height: '48px',
              background: 'black',
              border: 'none',
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            gap="2px"
            alignItems="start"
          >
            <Typography
              fontFamily="Outfit"
              fontSize="1.25rem"
              lineHeight="1.5rem"
              fontWeight="500"
              color={totalReturnIsPositive ? 'green4.main' : 'gray6.main'}
            >
              {annualizedReturnString}
            </Typography>
            <Typography
              variant="p3"
              lineHeight="18px"
              color={totalReturnIsPositive ? 'green4.main' : 'gray6.main'}
            >
              annualized
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountDetailsHeaderMobile
