import {
  Core_UpdateOrderV3TaskRequestInput,
  CreateAddressDocumentInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'

export type TaxInformationData = {
  taxInformation: {
    form4562Workpapers: File[]
    cashOutRefinance: 'false' | 'true'
    cashOutRefinanceDescription: string
  }

  orderUuid: string
  taskUuid: string
  addressUuid: string
}

export type TaxInformationFunctions = {
  updateTaxInformationTask: (params: {
    variables: {
      input: Core_UpdateOrderV3TaskRequestInput
    }
  }) => Promise<any>
  createAddressDocument: (params: {
    variables: {
      createAddressDocumentInput: CreateAddressDocumentInput
    }
  }) => Promise<any>
}

export enum TaxAddressDocumentType {
  DEPRECIATION_SCHEDULE = 'depreciation_schedule',
  FORM_4562 = 'form_4562',
}
