import { CircleFilledIcon, CircleCheckedIcon } from '@flock/shared-ui'
import { Help } from '@mui/icons-material'
import { Box, styled, Tooltip, Typography } from '@mui/material'
import React from 'react'

type GainLossLegendProps = {
  showZillow: boolean
  setShowZillow: (showZillow: boolean) => void
}

const StyledHelpOutline = styled(Help)({
  width: '16px',
  height: '16px',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

const GainLossLegend = (props: GainLossLegendProps) => {
  const { showZillow, setShowZillow } = props

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      p="24px 0px 0px 56px"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-evenly"
        width="25%"
      >
        <CircleFilledIcon color="moneyGreen" />
        <Typography
          variant="p2"
          color="gray8.main"
          fontWeight={400}
          fontFamily="Outfit"
        >
          Flock&apos;s Portfolio
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-evenly"
        width="50%"
      >
        <Typography
          variant="p2"
          color="gray8.main"
          fontWeight={400}
          fontFamily="Outfit"
        >
          Benchmarks:
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-evenly"
          width="33%"
          onClick={() => setShowZillow(!showZillow)}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          {showZillow ? (
            <CircleCheckedIcon color="green2" />
          ) : (
            <CircleFilledIcon color="green2" />
          )}
          <Typography
            variant="p2"
            color="gray8.main"
            fontWeight={400}
            fontFamily="Outfit"
          >
            Zillow
          </Typography>
          <StyledTooltip
            title={
              <Typography variant="p3" color="gray8.main">
                Zillow Home Value Index appreciation is the theoretical
                financial return that could be gained from buying all homes in a
                given subset in one period and selling them in the next period.
              </Typography>
            }
            enterTouchDelay={0}
            data-cy="zillowBenchmarkTooltipIcon"
            placement="right"
            arrow
          >
            <StyledHelpOutline fontSize="small" color="disabled" />
          </StyledTooltip>
        </Box>
      </Box>
    </Box>
  )
}

export default GainLossLegend
