import theme from '@flock/shared-ui/src/theme/theme'
import { useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { UseHistoricalPerformanceProps } from './historicalPerformanceTypes'
import { QuarterData } from '../accountSectionTypes'

const useHistoricalPerformance = (props: UseHistoricalPerformanceProps) => {
  const { quarterData, periodSelection } = props
  const [filteredQuarterData, setFilteredQuarterData] = useState<QuarterData[]>(
    []
  )
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    let quarterDataToReturn = quarterData.filter(
      (data) => data.quarterString !== undefined
    )

    if (quarterDataToReturn.length === 0) {
      return
    }

    if (periodSelection === '1Y') {
      quarterDataToReturn = quarterDataToReturn.slice(
        Math.max(quarterDataToReturn.length - 4, 0) // either most recent 4 quarters or all quarters if less than 4
      )
    }

    if (periodSelection !== 'ALL' && periodSelection !== '1Y') {
      quarterDataToReturn = quarterDataToReturn.filter((data) =>
        data.quarterString?.includes(periodSelection.toString())
      )
    }

    for (let i = quarterDataToReturn.length; i < 4; i += 1) {
      quarterDataToReturn.unshift({} as QuarterData)
    }

    setFilteredQuarterData(quarterDataToReturn)
  }, [quarterData, periodSelection])

  return {
    filteredQuarterData,
    isMobile,
    isTablet,
  }
}

export default useHistoricalPerformance
