import { Core_PropertySummaryDetails } from '@flock/flock-gql-server/src/__generated__/graphql'

export type StateCount = {
  [state: string]: number
}

export type IndividualStateCount = {
  state: string
  count: number
}

export const RELEVANCE = 'Relevance'
export const NEWEST = 'Newest'
export const RENT = 'Rent'
export const FMV = 'FMV'

export const propertyTypes = ['sfr', 'duplex', 'triplex', 'fourplex']

export const propertyTypeToDisplay: { [key: string]: string } = {
  sfr: 'Single-Family',
  duplex: 'Duplex',
  triplex: 'Triplex',
  fourplex: 'Fourplex',
}

export const abbreviatedPropertyTypeToDisplay: { [key: string]: string } = {
  sfr: 'SFR',
  duplex: 'Duplex',
  triplex: 'Triplex',
  fourplex: 'Fourplex',
}

export const VACANT = 'vacant'
export const OCCUPIED = 'occupied'
export const MTM = 'mtm'

export type AssetValuationChartProps = {
  tickFormatterX: (val: any) => string
  tickFormatterY: (val: any) => string
  valuationGraphData: ValuationData[]
}

export type AssetLeasingChartProps = {
  tickFormatterX: (val: any) => string
  tickFormatterY: (val: any) => string
  leasingGraphData: LeasingData[]
}

export type AssetViewCardDropdownProps = {
  assetDetailsArray: Core_PropertySummaryDetails[]
  hasValuationData: boolean
}

export type AssetViewPageProps = {
  assetUuid: string
}

export type ValuationData = {
  source: string | undefined | null
  currentValuation: number
  previousValuation?: number
  capex: number
  effectiveDateTime: string
  set: number
  capexSet?: boolean
}

export type LeasingData = {
  leaseStartDate: number
  leaseEndDate?: string
  moveoutDate?: string
  monthlyRent: number
  leaseType?: string
  unitRents?: any
  set: number
}
