import styled from '@emotion/styled'
import { OpenInNewIcon } from '@flock/shared-ui'
import { Help } from '@mui/icons-material'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
  Link,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef } from 'react'
import { FFO_URL, FLOCK_INVESTOR_URL } from '../../../../constants'
import { formatDollarsAndCentsInvestor } from '../../../../utils/formatting'
import { NetIncomeTableProps } from './netIncomeTableTypes'
import useNetIncomeTable from './useNetIncomeTable'

const StyledHelpOutline = styled(Help)({
  width: '16px',
  height: '16px',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip
    classes={{ popper: props.className }}
    leaveTouchDelay={999999}
    {...props}
  />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

const StyledTableCell = styled(
  (props: { selected: boolean; inheritBorder?: boolean }) => {
    const { selected, inheritBorder, ...otherProps } = props
    return (
      <TableCell
        sx={{
          minWidth: '90px',
          p: '0px',
          border: inheritBorder ? undefined : 0,
          backgroundColor: selected ? 'rgba(225, 238, 239, .5)' : '',
        }}
        {...otherProps}
      />
    )
  }
)``

const StyledCellContentContainer = styled(
  (props: {
    isTop?: boolean
    isBottom?: boolean
    isTopRight?: boolean
    isBottomRight?: boolean
    borderColor?: string
    isLastColumn?: boolean
  }) => {
    const {
      isTop,
      isBottom,
      isTopRight,
      isBottomRight,
      borderColor,
      isLastColumn,
      ...otherProps
    } = props

    let styles = {}
    const borderLineStyle = '1px solid'

    if (isTopRight) {
      styles = {
        borderTop: borderLineStyle,
        borderRight: borderLineStyle,
        borderColor,
        borderTopRightRadius: '8px',
        borderLeft: 0,
        borderBottom: 0,
      }
    } else if (isBottomRight) {
      styles = {
        borderBottom: borderLineStyle,
        borderRight: borderLineStyle,
        borderColor,
        borderTop: 0,
        borderLeft: 0,
        borderBottomRightRadius: '8px',
      }
    } else if (isLastColumn) {
      styles = {
        borderRight: borderLineStyle,
        borderColor,
        borderBottom: 0,
        borderTop: 0,
        borderLeft: 0,
      }
    } else if (isTop) {
      styles = {
        borderTop: borderLineStyle,
        borderColor,
        borderRight: 0,
        borderBottom: 0,
        borderLeft: 0,
      }
    } else if (isBottom) {
      styles = {
        borderBottom: borderLineStyle,
        borderColor,
        borderRight: 0,
        borderTop: 0,
        borderLeft: 0,
      }
    }

    const stylesWithPaddingAndSelected = {
      ...styles,
      p: '8px 12px',
    }

    return <Box sx={stylesWithPaddingAndSelected} {...otherProps} />
  }
)``

const StyledTableCellData = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const StyledTableCellHeader = styled(
  (props: {
    isTopLeft?: boolean
    isBottomLeft?: boolean
    borderColor?: string
  }) => {
    const { isTopLeft, isBottomLeft, borderColor, ...otherProps } = props
    const borderLineStyle = '1px solid'

    let styles = {}

    if (isTopLeft) {
      styles = {
        borderTop: borderLineStyle,
        borderLeft: borderLineStyle,
        borderColor,
        borderTopLeftRadius: '8px',
        borderBottom: 0,
        borderRight: 0,
      }
    } else if (isBottomLeft) {
      styles = {
        borderBottom: borderLineStyle,
        borderLeft: borderLineStyle,
        borderColor,
        borderBottomLeftRadius: '8px',
        borderTop: 0,
        borderRight: 0,
      }
    } else {
      styles = {
        borderLeft: borderLineStyle,
        borderColor,
        borderTop: 0,
        borderBottom: 0,
        borderRight: 0,
      }
    }

    const stylesWithMoreProps = {
      ...styles,
      minWidth: '260px',
      p: '8px 16px 8px 16px',
      position: 'sticky',
      left: 0,
      zIndex: 999,
    }

    return (
      <TableCell
        sx={stylesWithMoreProps}
        variant="head"
        scope="col"
        {...otherProps}
      />
    )
  }
)``

const StyledTableCellHeaderTotalData = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const StyledTableCellHeaderSubtotalData = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '12px',
})

const NetIncomeTableDesktopTablet = (props: NetIncomeTableProps) => {
  const { quarters, selectedQuarter } = props
  const { columns, isTablet } = useNetIncomeTable(props)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollLeft = ref.current.scrollWidth
    }
  }, [quarters])

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="4px"
      >
        {!isTablet && (
          <Typography variant="p1" color="moneyGreen.main">
            Portfolio Net Income
          </Typography>
        )}

        {!isTablet && (
          <Link
            href={`${FLOCK_INVESTOR_URL}${FFO_URL}`}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            View detailed income statement&nbsp;
            <OpenInNewIcon color="green4" />
          </Link>
        )}
      </Box>
      <TableContainer
        sx={{
          overflowX: 'auto',
        }}
        ref={ref}
      >
        <Table stickyHeader>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  border: 0,
                  minWidth: '260px',
                  position: 'sticky',
                  left: 0,
                  zIndex: 999,
                }}
                variant="head"
                scope="col"
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="p3" color="gray5.main">
                    $,unaudited
                  </Typography>
                </Box>
              </TableCell>
              {quarters.map((quarterString) => (
                <TableCell
                  key={quarterString}
                  sx={{
                    border: 0,
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    p: '8px 12px',
                    minWidth: '90px',
                    backgroundColor:
                      selectedQuarter === quarterString
                        ? 'rgba(225, 238, 239, .5)'
                        : '',
                  }}
                >
                  <StyledTableCellData>
                    <Typography variant="p3">{quarterString}</Typography>
                  </StyledTableCellData>
                </TableCell>
              ))}
            </TableRow>
            {/* PORTFOLIO INCOME SECTION */}
            <TableRow>
              <StyledTableCellHeader isTopLeft borderColor="green4.main">
                <StyledTableCellHeaderTotalData>
                  <Typography variant="p3" color="green4.main" fontWeight="500">
                    Total portfolio income
                  </Typography>
                </StyledTableCellHeaderTotalData>
              </StyledTableCellHeader>
              {quarters.map((quarter, idx) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                const isLastColumn = idx === quarters.length - 1
                return (
                  <StyledTableCell
                    selected={selectedQuarter === quarter}
                    key={currQuarterData?.quarterString}
                  >
                    <StyledCellContentContainer
                      isTopRight={isLastColumn}
                      isTop={!isLastColumn}
                      borderColor="green4.main"
                    >
                      <StyledTableCellData>
                        <Typography
                          variant="p3"
                          fontWeight="500"
                          color={
                            currQuarterData?.totalPortfolioIncome
                              ? 'green4.main'
                              : ''
                          }
                        >
                          {currQuarterData?.totalPortfolioIncome
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.totalPortfolioIncome,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledTableCellData>
                    </StyledCellContentContainer>
                  </StyledTableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <StyledTableCellHeader borderColor="green4.main">
                <StyledTableCellHeaderSubtotalData>
                  <Typography variant="p3" color="moneyGreen.main">
                    Total rental income
                  </Typography>
                </StyledTableCellHeaderSubtotalData>
              </StyledTableCellHeader>
              {quarters.map((quarter, idx) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                const isLastColumn = idx === quarters.length - 1
                return (
                  <StyledTableCell
                    selected={selectedQuarter === quarter}
                    key={currQuarterData?.quarterString}
                  >
                    <StyledCellContentContainer
                      borderColor="green4.main"
                      isLastColumn={isLastColumn}
                    >
                      <StyledTableCellData>
                        <Typography variant="p3">
                          {currQuarterData?.totalRentalIncome
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.totalRentalIncome,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledTableCellData>
                    </StyledCellContentContainer>
                  </StyledTableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <StyledTableCellHeader borderColor="green4.main" isBottomLeft>
                <StyledTableCellHeaderSubtotalData>
                  <Typography variant="p3" color="moneyGreen.main">
                    Total other income
                  </Typography>
                </StyledTableCellHeaderSubtotalData>
              </StyledTableCellHeader>
              {quarters.map((quarter, idx) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                const isLastColumn = idx === quarters.length - 1
                return (
                  <StyledTableCell
                    selected={selectedQuarter === quarter}
                    key={currQuarterData?.quarterString}
                  >
                    <StyledCellContentContainer
                      isBottomRight={isLastColumn}
                      isBottom={!isLastColumn}
                      borderColor="green4.main"
                    >
                      <StyledTableCellData>
                        <Typography variant="p3">
                          {currQuarterData?.totalOtherIncome
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.totalOtherIncome,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledTableCellData>
                    </StyledCellContentContainer>
                  </StyledTableCell>
                )
              })}
            </TableRow>
            {/* PORTFOLIO EXPENSES SECTION */}
            <TableRow>
              <StyledTableCellHeader isTopLeft borderColor="errorRed.main">
                <StyledTableCellHeaderTotalData>
                  <Typography
                    variant="p3"
                    color="errorRed.main"
                    fontWeight="500"
                  >
                    Total portfolio expenses
                  </Typography>
                </StyledTableCellHeaderTotalData>
              </StyledTableCellHeader>
              {quarters.map((quarter, idx) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                const isLastColumn = idx === quarters.length - 1
                return (
                  <StyledTableCell
                    selected={selectedQuarter === quarter}
                    key={currQuarterData?.quarterString}
                  >
                    <StyledCellContentContainer
                      borderColor="errorRed.main"
                      isTopRight={isLastColumn}
                      isTop={!isLastColumn}
                    >
                      <StyledTableCellData>
                        <Typography
                          variant="p3"
                          color={
                            currQuarterData?.totalPortfolioExpenses
                              ? 'errorRed.main'
                              : ''
                          }
                          fontWeight="500"
                        >
                          {currQuarterData?.totalPortfolioExpenses
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.totalPortfolioExpenses,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledTableCellData>
                    </StyledCellContentContainer>
                  </StyledTableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <StyledTableCellHeader borderColor="errorRed.main">
                <StyledTableCellHeaderSubtotalData>
                  <Typography variant="p3" color="moneyGreen.main">
                    Total operating expenses
                  </Typography>
                </StyledTableCellHeaderSubtotalData>
              </StyledTableCellHeader>
              {quarters.map((quarter, idx) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                const isLastColumn = idx === quarters.length - 1
                return (
                  <StyledTableCell
                    selected={selectedQuarter === quarter}
                    key={currQuarterData?.quarterString}
                  >
                    <StyledCellContentContainer
                      borderColor="errorRed.main"
                      isLastColumn={isLastColumn}
                    >
                      <StyledTableCellData>
                        <Typography variant="p3">
                          {currQuarterData?.totalOperatingExpenses
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.totalOperatingExpenses,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledTableCellData>
                    </StyledCellContentContainer>
                  </StyledTableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <StyledTableCellHeader borderColor="errorRed.main">
                <StyledTableCellHeaderSubtotalData>
                  <Typography variant="p3" color="moneyGreen.main">
                    Total administrative fees
                  </Typography>
                </StyledTableCellHeaderSubtotalData>
              </StyledTableCellHeader>
              {quarters.map((quarter, idx) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                const isLastColumn = idx === quarters.length - 1
                return (
                  <StyledTableCell
                    selected={selectedQuarter === quarter}
                    key={currQuarterData?.quarterString}
                  >
                    <StyledCellContentContainer
                      borderColor="errorRed.main"
                      isLastColumn={isLastColumn}
                    >
                      <StyledTableCellData>
                        <Typography variant="p3">
                          {currQuarterData?.totalAdministrativeFees
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.totalAdministrativeFees,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledTableCellData>
                    </StyledCellContentContainer>
                  </StyledTableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <StyledTableCellHeader borderColor="errorRed.main" isBottomLeft>
                <StyledTableCellHeaderSubtotalData>
                  <Typography variant="p3" color="moneyGreen.main">
                    Total interest expense
                  </Typography>
                </StyledTableCellHeaderSubtotalData>
              </StyledTableCellHeader>
              {quarters.map((quarter, idx) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                const isLastColumn = idx === quarters.length - 1
                return (
                  <StyledTableCell
                    selected={selectedQuarter === quarter}
                    key={currQuarterData?.quarterString}
                  >
                    <StyledCellContentContainer
                      isBottomRight={isLastColumn}
                      isBottom={!isLastColumn}
                      borderColor="errorRed.main"
                    >
                      <StyledTableCellData>
                        <Typography variant="p3">
                          {currQuarterData?.totalInterestExpense
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.totalInterestExpense,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledTableCellData>
                    </StyledCellContentContainer>
                  </StyledTableCell>
                )
              })}
            </TableRow>
            {/* PORTFOLIO NET INCOME SECTION */}
            <TableRow>
              <TableCell
                sx={{
                  border: 0,
                  minWidth: '260px',
                  p: '8px 16px 8px 16px',
                  position: 'sticky',
                  left: 0,
                  zIndex: 999,
                }}
                variant="head"
                scope="col"
              >
                <StyledTableCellHeaderTotalData>
                  <Typography
                    variant="p3"
                    color="moneyGreen.main"
                    fontWeight="500"
                  >
                    Portfolio net income
                  </Typography>
                </StyledTableCellHeaderTotalData>
              </TableCell>
              {quarters.map((quarter) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                return (
                  <StyledTableCell
                    selected={selectedQuarter === quarter}
                    key={currQuarterData?.quarterString}
                  >
                    <StyledTableCellData>
                      <StyledCellContentContainer>
                        <Typography variant="p3" fontWeight="500">
                          {currQuarterData?.totalPortfolioNetIncome
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.totalPortfolioNetIncome,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledCellContentContainer>
                    </StyledTableCellData>
                  </StyledTableCell>
                )
              })}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  minWidth: '260px',
                  p: '8px 16px 8px 16px',
                  position: 'sticky',
                  left: 0,
                  zIndex: 999,
                }}
                variant="head"
                scope="col"
              >
                <StyledTableCellHeaderTotalData>
                  <Typography variant="p3" color="moneyGreen.main">
                    <Box display="flex" flexDirection="row" alignItems="center">
                      Non-cash items&nbsp;&nbsp;
                      <StyledTooltip
                        title={
                          <Typography variant="p3" color="gray8.main">
                            Non-cash items include depreciation and amortization
                            expenses.
                          </Typography>
                        }
                        enterTouchDelay={0}
                        placement="right"
                        arrow
                      >
                        <StyledHelpOutline fontSize="small" color="disabled" />
                      </StyledTooltip>
                    </Box>
                  </Typography>
                </StyledTableCellHeaderTotalData>
              </TableCell>
              {quarters.map((quarter) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                return (
                  <StyledTableCell
                    selected={selectedQuarter === quarter}
                    inheritBorder
                    key={currQuarterData?.quarterString}
                  >
                    <StyledTableCellData>
                      <StyledCellContentContainer>
                        <Typography variant="p3">
                          {currQuarterData?.nonCashItems
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.nonCashItems,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledCellContentContainer>
                    </StyledTableCellData>
                  </StyledTableCell>
                )
              })}
            </TableRow>
            <TableRow sx={{ borderTop: '1px solid #CBCDCF' }}>
              <TableCell
                sx={{
                  border: 0,
                  minWidth: '260px',
                  p: '8px 16px 8px 16px',
                  position: 'sticky',
                  left: 0,
                  zIndex: 999,
                }}
                variant="head"
                scope="col"
              >
                <StyledTableCellHeaderTotalData>
                  <Typography
                    variant="p3"
                    fontWeight="500"
                    color="moneyGreen.main"
                  >
                    Funds From Operations
                  </Typography>
                </StyledTableCellHeaderTotalData>
              </TableCell>
              {quarters.map((quarter) => {
                const currQuarterData = columns.find(
                  (col) => col.quarterString === quarter
                )
                return (
                  <TableCell
                    sx={{
                      border: 0,
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      p: '0px',
                      minWidth: '90px',
                      backgroundColor:
                        selectedQuarter === quarter
                          ? 'rgba(225, 238, 239, .5)'
                          : '',
                    }}
                    key={currQuarterData?.quarterString}
                  >
                    <StyledTableCellData>
                      <StyledCellContentContainer>
                        <Typography variant="p3" fontWeight="500">
                          {currQuarterData?.fundsFromOperations
                            ? formatDollarsAndCentsInvestor(
                                currQuarterData?.fundsFromOperations,
                                false,
                                false
                              )
                            : '-'}
                        </Typography>
                      </StyledCellContentContainer>
                    </StyledTableCellData>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default NetIncomeTableDesktopTablet
