import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const CloseMobileIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M17.2938 17.2938C17.3866 17.2006 17.497 17.1268 17.6185 17.0764C17.7399 17.0259 17.8702 17 18.0017 17C18.1332 17 18.2634 17.0259 18.3849 17.0764C18.5064 17.1268 18.6167 17.2006 18.7096 17.2938L24.0011 22.5873L29.2926 17.2938C29.3856 17.2008 29.4959 17.127 29.6174 17.0767C29.7389 17.0264 29.8691 17.0005 30.0005 17.0005C30.132 17.0005 30.2622 17.0264 30.3837 17.0767C30.5051 17.127 30.6155 17.2008 30.7085 17.2938C30.8014 17.3867 30.8752 17.4971 30.9255 17.6186C30.9758 17.74 31.0017 17.8702 31.0017 18.0017C31.0017 18.1332 30.9758 18.2634 30.9255 18.3848C30.8752 18.5063 30.8014 18.6167 30.7085 18.7096L25.415 24.0011L30.7085 29.2926C30.8014 29.3856 30.8752 29.4959 30.9255 29.6174C30.9758 29.7389 31.0017 29.8691 31.0017 30.0005C31.0017 30.132 30.9758 30.2622 30.9255 30.3837C30.8752 30.5051 30.8014 30.6155 30.7085 30.7085C30.6155 30.8014 30.5051 30.8752 30.3837 30.9255C30.2622 30.9758 30.132 31.0017 30.0005 31.0017C29.8691 31.0017 29.7389 30.9758 29.6174 30.9255C29.4959 30.8752 29.3856 30.8014 29.2926 30.7085L24.0011 25.415L18.7096 30.7085C18.6167 30.8014 18.5063 30.8752 18.3848 30.9255C18.2634 30.9758 18.1332 31.0017 18.0017 31.0017C17.8702 31.0017 17.74 30.9758 17.6186 30.9255C17.4971 30.8752 17.3867 30.8014 17.2938 30.7085C17.2008 30.6155 17.127 30.5051 17.0767 30.3837C17.0264 30.2622 17.0005 30.132 17.0005 30.0005C17.0005 29.8691 17.0264 29.7389 17.0767 29.6174C17.127 29.4959 17.2008 29.3856 17.2938 29.2926L22.5873 24.0011L17.2938 18.7096C17.2006 18.6167 17.1268 18.5064 17.0764 18.3849C17.0259 18.2634 17 18.1332 17 18.0017C17 17.8702 17.0259 17.7399 17.0764 17.6185C17.1268 17.497 17.2006 17.3866 17.2938 17.2938Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default CloseMobileIcon
