import { TrackedLink } from '@flock/shared-ui'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { DirectorySectionProps } from './directorySectionTypes'
import DanielleHeadshot from '../../../images/danielle-headshot.png'
import JacobHeadshot from '../../../images/jacob-headshot.png'

const DirectorySectionMobile = (props: DirectorySectionProps) => {
  const { id } = props

  return (
    <SectionLayout name="directory-section" columns={4} id={id}>
      <Grid item xs={4}>
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          pb={{ xs: '64px', sm: '300px' }}
        >
          <Typography variant="h3">Directory.</Typography>
          <Paper
            sx={{
              display: 'flex',
              borderRadius: '16px',
              p: '24px',
              gap: '16px',
            }}
          >
            <Box display="flex" flexDirection="column" gap="16px">
              <Box display="flex" flexDirection="column" gap="8px">
                <Typography variant="ctam">
                  Thinking of exchanging more homes?
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  gap="8px"
                  justifyContent="space-between"
                >
                  <Box
                    component="img"
                    height="40px"
                    width="40px"
                    borderRadius="50%"
                    src={JacobHeadshot}
                  />
                  <Typography variant="p3">
                    Contact Jacob directly and he’ll guide you through the
                    process.
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="8px">
                  <Button
                    variant="secondary"
                    color="primary"
                    onClick={() => window.open(`tel:(804) 567-8194`)}
                    sx={{
                      height: '40px',
                      alignItems: 'center',
                      // padding: '12px 16px',
                      width: 'auto',
                    }}
                  >
                    <Typography variant="ctam" fontSize="16px">
                      Call (804) 567-8194
                    </Typography>
                  </Button>
                  <Button
                    variant="primary"
                    color="primary"
                    onClick={() => window.open(`mailto:jacob@flockhomes.com`)}
                    sx={{
                      height: '40px',
                      alignItems: 'center',
                      padding: '12px 16px',
                      width: 'auto',
                    }}
                  >
                    <Typography variant="ctam" fontSize="16px">
                      Email jacob@flockhomes.com
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              display: 'flex',
              borderRadius: '16px',
              p: '24px',
              gap: '16px',
            }}
          >
            <Box display="flex" flexDirection="column" gap="16px">
              <Box display="flex" flexDirection="column" gap="8px">
                <Typography variant="ctam">
                  Looking for information about your account, performance, or
                  upcoming Flock events?
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  gap="8px"
                  justifyContent="space-between"
                >
                  <Box
                    component="img"
                    height="40px"
                    width="40px"
                    borderRadius="50%"
                    src={DanielleHeadshot}
                  />
                  <Typography variant="p3">
                    Danielle will provide insight powered by the asset
                    management, investments, engineering, and acquisitions
                    teams.
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="8px">
                  <Button
                    variant="secondary"
                    color="primary"
                    onClick={() => window.open(`tel:(720) 807-1711`)}
                    sx={{
                      height: '40px',
                      alignItems: 'center',
                      width: 'auto',
                    }}
                  >
                    <Typography variant="ctam" fontSize="16px">
                      Call (720) 807-1711
                    </Typography>
                  </Button>
                  <Button
                    variant="primary"
                    color="primary"
                    onClick={() =>
                      window.open(`mailto:danielle@flockhomes.com`)
                    }
                    sx={{
                      height: '40px',
                      alignItems: 'center',
                      width: 'auto',
                      padding: '0px 0px',
                    }}
                  >
                    <Typography variant="ctam" fontSize="16px">
                      Email danielle@flockhomes.com
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Typography variant="ctam" color="gray8.main">
            For anything else, please call Flock&apos;s main line{' '}
            <TrackedLink onClick={() => window.open(`tel:(720) 807-1711`)}>
              <Typography variant="ctam" color="green4.main">
                (720) 703-9992
              </Typography>
            </TrackedLink>{' '}
            and we&apos;ll be happy to assist.
          </Typography>
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default DirectorySectionMobile
