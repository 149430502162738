import { Core_PropertySummaryDetails } from '@flock/flock-gql-server/src/__generated__/graphql'
import { graphql, useStaticQuery } from 'gatsby'
import { debounce } from 'lodash'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useInvestorAccountContext } from '../InvestorAccountContext'
import {
  FMV,
  IndividualStateCount,
  NEWEST,
  propertyTypes,
  RELEVANCE,
  RENT,
  StateCount,
  VACANT,
} from './detailedAssetViewTypes'

const useDetailedAssetViewPage = () => {
  const [allPropertyAssets, setAllPropertyAssets] = useState<
    Core_PropertySummaryDetails[] | undefined
  >()
  const [filteredPropertyAssets, setFilteredPropertyAssets] = useState<
    Map<string, Core_PropertySummaryDetails[]> | undefined
  >()
  const [statesWithCountsList, setStatesWithCountsList] = useState<
    IndividualStateCount[]
  >([])
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>([])
  const [searchText, setSearchText] = useState('')
  const [sortOpen, setSortOpen] = useState(false)
  const [sortText, setSortText] = useState(RELEVANCE)
  const [usedQueryString, setUsedQueryString] = useState(false)
  const [stateOpen, setStateOpen] = useState(false)
  const [typeOpen, setTypeOpen] = useState(false)
  const [selectedTypes, setSelectedTypes] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(true)
  const queryString = window.location.search

  const handleChangePage = (_event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  const { investorAccountUuidOverride } = useInvestorAccountContext()

  const { getPropertySummaryDetails } = useStaticQuery(graphql`
    query InvestorPropertyAssetDetailedAssetViewPageQuery {
      getPropertySummaryDetails {
        propertyDetails {
          propertyUuid
          investorAccountUuid
          address {
            city
            streetNumber
            formattedStreet
            formattedAddress
            latitude
            longitude
            state
            unit
            zipcode
            primaryPhotoUrl
            parentAddressUuid
          }
          addressUuid
          baths
          acquisitionDate
          beds
          halfBaths
          propertyType
          sqft
          grossYield
          propertyUuid
          yearBuilt
          leaseEndDate
          leaseStatus
          monthlyRentCents
          acquisitionPriceCents
          propertyValuationHistory {
            propertyUuid
            currentValuationCents
            currentValuationSource
            currentQuarterCapexCents
            valuationBasePriceCents
            effectiveDatetime
            startDateInFund
          }
        }
      }
    }
  `)

  useEffect(() => {
    setLoading(true)
    if (getPropertySummaryDetails && investorAccountUuidOverride) {
      // get property uuid from query string
      let urlProperty: Core_PropertySummaryDetails | undefined
      if (queryString !== '' && !usedQueryString) {
        const urlParams = new URLSearchParams(queryString)
        const sortType = (urlParams.get('sort') as string).toLowerCase()
        switch (sortType) {
          case 'relevance':
            setSortText(RELEVANCE)
            break
          case 'newest':
            setSortText(NEWEST)
            break
          case 'rent':
            setSortText(RENT)
            break
          case 'fmv':
            setSortText(FMV)
            break
          default:
            break
        }
        setUsedQueryString(true)
      }
      // sort by investor account uuid first
      const details = (
        getPropertySummaryDetails.propertyDetails as Core_PropertySummaryDetails[]
      ).filter(
        (elem) => elem.propertyUuid !== '00000000-0000-0000-0000-000000000000'
      )
      if (sortText === RELEVANCE) {
        details.sort((a, b) => {
          if (urlProperty) {
            if (a.propertyUuid === urlProperty.propertyUuid) {
              return -1
            } else if (b.propertyUuid === urlProperty.propertyUuid) {
              return 1
            }
          }
          if (
            a.investorAccountUuid === investorAccountUuidOverride &&
            b.investorAccountUuid === investorAccountUuidOverride
          ) {
            if (a.address?.primaryPhotoUrl) {
              return -1
            }
            return 1
          } else if (a.investorAccountUuid === investorAccountUuidOverride) {
            return -1
          } else if (b.investorAccountUuid === investorAccountUuidOverride) {
            return 1
          } else if (a.address?.primaryPhotoUrl) {
            return -1
          }
          return 1
        })
      } else if (sortText === NEWEST) {
        details.sort((a, b) => {
          const aDate = new Date(a.acquisitionDate || '')
          const bDate = new Date(b.acquisitionDate || '')
          return bDate.getTime() - aDate.getTime()
        })
      } else if (sortText === RENT) {
        details.sort((a, b) => {
          if (a.leaseStatus === VACANT) {
            return 1
          } else if (b.leaseStatus === VACANT) {
            return -1
          }
          return (b.monthlyRentCents || 0) - (a.monthlyRentCents || 0)
        })
      } else if (sortText === FMV) {
        details.sort((a, b) => {
          if (
            a.propertyValuationHistory &&
            a.propertyValuationHistory?.length !== 0
          ) {
            if (
              b.propertyValuationHistory &&
              b.propertyValuationHistory?.length !== 0
            ) {
              const aValuation =
                a.propertyValuationHistory[
                  a.propertyValuationHistory?.length - 1
                ]
              const bValuation =
                b.propertyValuationHistory[
                  b.propertyValuationHistory?.length - 1
                ]
              if (aValuation && bValuation) {
                return (
                  (bValuation.currentValuationCents || 0) -
                  (aValuation.currentValuationCents || 0)
                )
              }
            } else {
              return -1
            }
          } else {
            return 1
          }
          return 0
        })
      }
      const parentAssets: string[] = []
      const statesWithCountsObject = details.reduce(
        (total: StateCount, asset) => {
          const state = asset.address?.state || ''
          if (
            asset?.address?.parentAddressUuid &&
            asset?.address?.parentAddressUuid !==
              '00000000-0000-0000-0000-000000000000'
          ) {
            if (parentAssets.includes(asset.address.parentAddressUuid)) {
              const curr = { ...total }
              return curr
            } else {
              parentAssets.push(asset.address?.parentAddressUuid || '')
            }
          }
          const curr = { ...total }
          curr[state] = total[state] || 0
          curr[state] += 1
          return curr
        },
        {} as StateCount
      )

      const tempStatesWithCountsList: IndividualStateCount[] = []

      Object.keys(statesWithCountsObject).map((key) =>
        tempStatesWithCountsList.push({
          state: key,
          count: statesWithCountsObject[key],
        })
      )

      tempStatesWithCountsList.sort((a, b) => (a.count > b.count ? -1 : 1))
      setStatesWithCountsList(tempStatesWithCountsList)
      setAllPropertyAssets(details)
    }
  }, [
    queryString,
    getPropertySummaryDetails,
    investorAccountUuidOverride,
    sortText,
  ])

  useEffect(() => {
    if (allPropertyAssets) {
      let tempFilteredPropertyAssets: Core_PropertySummaryDetails[] | undefined
      if (
        selectedMarkets.length === 0 ||
        selectedMarkets.length === statesWithCountsList.length
      ) {
        tempFilteredPropertyAssets = allPropertyAssets
      } else {
        tempFilteredPropertyAssets = allPropertyAssets?.filter((asset) =>
          selectedMarkets.includes(asset.address?.state!)
        )
      }

      if (
        selectedTypes.length !== 0 &&
        selectedTypes.length !== propertyTypes.length
      ) {
        tempFilteredPropertyAssets = tempFilteredPropertyAssets?.filter(
          (asset) => selectedTypes.includes(asset.propertyType!.toLowerCase())
        )
      }

      const tempFilteredPropertyAssetsMap: Map<
        string,
        Core_PropertySummaryDetails[]
      > = new Map<string, Core_PropertySummaryDetails[]>()
      if (searchText === '') {
        tempFilteredPropertyAssets.map((asset) => {
          if (
            asset?.address?.parentAddressUuid &&
            asset?.address?.parentAddressUuid !==
              '00000000-0000-0000-0000-000000000000'
          ) {
            const parentAddressUuid = asset.address.parentAddressUuid || ''
            if (tempFilteredPropertyAssetsMap.has(parentAddressUuid)) {
              const temp = tempFilteredPropertyAssetsMap.get(
                parentAddressUuid
              ) as Core_PropertySummaryDetails[]
              temp.push(asset)
              // Sort first street number to front for display name and valuation
              temp.sort((a, b) => {
                if (a.address?.streetNumber && b.address?.streetNumber) {
                  if (a.address?.streetNumber === b.address?.streetNumber) {
                    return (a.address?.unit || '') < (b.address?.unit || '')
                      ? -1
                      : 1
                  } else {
                    return (
                      parseInt(a.address?.streetNumber || '0', 10) -
                      parseInt(b.address?.streetNumber || '0', 10)
                    )
                  }
                } else {
                  return 0
                }
              })
              tempFilteredPropertyAssetsMap.set(parentAddressUuid, temp)
            } else {
              tempFilteredPropertyAssetsMap.set(parentAddressUuid, [asset])
            }
          } else {
            tempFilteredPropertyAssetsMap.set(asset.addressUuid, [asset])
          }
          return asset
        })
        setFilteredPropertyAssets(tempFilteredPropertyAssetsMap)
      } else {
        tempFilteredPropertyAssets.map((asset) => {
          const address = asset.address?.formattedAddress || ''
          const city = asset.address?.city || ''
          const state = asset.address?.state || ''
          const zipcode = asset.address?.zipcode || ''
          const formattedAddress = `${address} ${city} ${state} ${zipcode}`
          const isSearchResult =
            formattedAddress.toLowerCase().includes(searchText.toLowerCase()) ||
            asset.propertyUuid?.toLocaleLowerCase() ===
              searchText.toLocaleLowerCase() ||
            asset?.propertyType
              ?.toLowerCase()
              .includes(searchText.toLowerCase())

          if (
            asset?.address?.parentAddressUuid &&
            asset?.address?.parentAddressUuid !==
              '00000000-0000-0000-0000-000000000000'
          ) {
            const parentAddressUuid = asset.address.parentAddressUuid || ''
            if (tempFilteredPropertyAssetsMap.has(parentAddressUuid)) {
              const temp = tempFilteredPropertyAssetsMap.get(
                parentAddressUuid
              ) as Core_PropertySummaryDetails[]
              temp.push(asset)
              // Sort first street number to front for display name and valuation
              temp.sort((a, b) => {
                if (a.address?.streetNumber && b.address?.streetNumber) {
                  if (a.address?.streetNumber === b.address?.streetNumber) {
                    return (a.address?.unit || '') < (b.address?.unit || '')
                      ? -1
                      : 1
                  } else {
                    return (
                      parseInt(a.address?.streetNumber || '0', 10) -
                      parseInt(b.address?.streetNumber || '0', 10)
                    )
                  }
                } else {
                  return 0
                }
              })
              tempFilteredPropertyAssetsMap.set(parentAddressUuid, temp)
            } else if (isSearchResult) {
              tempFilteredPropertyAssetsMap.set(parentAddressUuid, [asset])
            }
          } else if (isSearchResult) {
            tempFilteredPropertyAssetsMap.set(asset.addressUuid, [asset])
          }
          return asset
        })
        // Append any multifamily unit records that haven't already been added
        tempFilteredPropertyAssets.map((asset) => {
          if (
            asset?.address?.parentAddressUuid &&
            asset?.address?.parentAddressUuid !==
              '00000000-0000-0000-0000-000000000000'
          ) {
            const parentAddressUuid = asset.address.parentAddressUuid || ''
            if (tempFilteredPropertyAssetsMap.has(parentAddressUuid)) {
              const temp = tempFilteredPropertyAssetsMap.get(
                parentAddressUuid
              ) as Core_PropertySummaryDetails[]
              let alreadyAdded = false
              temp.forEach((elem) => {
                if (elem.propertyUuid === asset.propertyUuid) {
                  alreadyAdded = true
                }
              })
              if (!alreadyAdded) {
                temp.push(asset)
                temp.sort((a, b) => {
                  if (a.address?.streetNumber && b.address?.streetNumber) {
                    if (a.address?.streetNumber === b.address?.streetNumber) {
                      return (a.address?.unit || '') < (b.address?.unit || '')
                        ? -1
                        : 1
                    } else {
                      return (
                        parseInt(a.address?.streetNumber || '0', 10) -
                        parseInt(b.address?.streetNumber || '0', 10)
                      )
                    }
                  } else {
                    return 0
                  }
                })
                tempFilteredPropertyAssetsMap.set(parentAddressUuid, temp)
              }
            }
          }
          return asset
        })
        setFilteredPropertyAssets(tempFilteredPropertyAssetsMap)
      }

      setPage(1)
      setLoading(false)
    }
  }, [
    selectedMarkets,
    selectedTypes,
    allPropertyAssets,
    searchText,
    statesWithCountsList,
  ])

  useEffect(() => {
    if (selectedMarkets.length === 0) {
      setSelectedMarkets(statesWithCountsList.map((data) => data.state))
    }
    if (selectedTypes.length === 0) {
      setSelectedTypes(propertyTypes)
    }
  }, [statesWithCountsList])

  const setSearchTextHelper = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value)
    },
    [setSearchText]
  )

  const debounceSetSearchTextHelper = useMemo(
    () => debounce(setSearchTextHelper, 700),
    [setSearchTextHelper]
  )

  return {
    filteredPropertyAssets,
    statesWithCountsList,
    selectedMarkets,
    setSelectedMarkets,
    setSearchText: debounceSetSearchTextHelper,
    sortOpen,
    setSortOpen,
    sortText,
    setSortText,
    stateOpen,
    setStateOpen,
    typeOpen,
    setTypeOpen,
    selectedTypes,
    setSelectedTypes,
    page,
    handleChangePage,
    loading,
    hasNoPropertyAssets:
      (Array.from(filteredPropertyAssets?.values() || []).length || 0) === 0 &&
      !loading,
  }
}

export default useDetailedAssetViewPage
