import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const ChartFilledIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M55.5 49.5H12.5V10.5C12.5 10.225 12.275 10 12 10H8.5C8.225 10 8 10.225 8 10.5V53.5C8 53.775 8.225 54 8.5 54H55.5C55.775 54 56 53.775 56 53.5V50C56 49.725 55.775 49.5 55.5 49.5ZM17 45.5H50.5C50.775 45.5 51 45.275 51 45V17.75C51 17.3 50.4562 17.0812 50.1437 17.3937L37 30.5375L29.1625 22.7875C29.0685 22.6944 28.9416 22.6423 28.8094 22.6423C28.6771 22.6423 28.5502 22.6944 28.4562 22.7875L16.6437 34.6375C16.5979 34.6835 16.5615 34.7381 16.5369 34.7981C16.5122 34.8582 16.4997 34.9226 16.5 34.9875V45C16.5 45.275 16.725 45.5 17 45.5Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default ChartFilledIcon
