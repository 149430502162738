import { useQuery } from '@apollo/client'
import { InvestorDashboardGetEquityInfoTimeseriesForInvestorDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { LeftArrowFilledIcon, PageContainer } from '@flock/shared-ui'
import { sessionStore } from '@flock/utils'
import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { navigate, RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import { ACCOUNT_URL } from '../../../constants'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import PageWrapper from '../../SharedComponents/PageWrapper'
import FlexibleCashflowCalculator from './FlexibleCashflowCalculator/FlexibleCashflowCalculator'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InvestmentProjection = (_: RouteComponentProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  let numColumns = 12
  if (isMobile) {
    numColumns = 6
  } else if (isTablet) {
    numColumns = 9
  } else {
    numColumns = 12
  }

  const {
    selectedLegalEntity: legalEntityData,
    investorAccountUuidOverride,
    investorContextLoading,
  } = useInvestorAccountContext()

  const [totalEquity, setTotalEquity] = useState(0)

  const legalEntity = legalEntityData

  const { loading: equityInfoTimeSeriesLoading, data: equityInfoTimeseries } =
    useQuery(InvestorDashboardGetEquityInfoTimeseriesForInvestorDocument, {
      variables: {
        input: {
          investorUuid: investorAccountUuidOverride,
          includeStaged: sessionStore.getItem('simulator') !== null,
        },
      },
      skip: !investorAccountUuidOverride || totalEquity !== 0,
    })

  const equityTimeseriesArray =
    equityInfoTimeseries?.getEquityInfoTimeseriesForInvestor
      ?.aggregateEquityInfo
  if (equityTimeseriesArray?.length! > 0 && totalEquity === 0) {
    const mostRecentData =
      equityTimeseriesArray![equityTimeseriesArray?.length! - 1]
    const totalEquityValue = mostRecentData!.aggregateEquityValue! / 100

    setTotalEquity(totalEquityValue)
  }

  const cashFlowAllotment = legalEntity?.cashFlowAllotment
  const cashFlowSelection = legalEntity?.cashFlowSelection
  const holdingExpiryDate = legalEntity?.holdingExpiryDate

  let yearsUntilExpiry = 5
  if (holdingExpiryDate && holdingExpiryDate !== '0001-01-01T00:00:00.000Z') {
    const holdingExpiryDateDate = new Date(holdingExpiryDate!)
    const currentDate = new Date()
    yearsUntilExpiry =
      holdingExpiryDateDate.getFullYear() - currentDate.getFullYear()
  }

  return (
    <PageContainer
      title="FLOCK | Investment Projection"
      trackingName="projection"
    >
      <PageWrapper>
        <Grid container columns={numColumns} height="auto" mb="32px">
          {isMobile || isTablet ? (
            <>
              <Grid item xs={numColumns}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  margin="20px 32px 20px 32px"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="h4"
                      fontWeight={500}
                      color="moneyGreen.main"
                    >
                      F L O C K
                    </Typography>
                  </Box>
                  <Paper
                    sx={{
                      borderRadius: '16px',
                      padding: '0px 16px 0px 0px',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(ACCOUNT_URL)}
                  >
                    <Box display="flex" alignItems="center">
                      <LeftArrowFilledIcon height="48px" width="48px" />
                      <Typography variant="p3">
                        Back to Owners&apos; Portal
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={numColumns / 4}>
                <Paper
                  sx={{
                    borderRadius: '16px',
                    padding: '0px 16px 0px 0px',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(ACCOUNT_URL)}
                >
                  <Box display="flex" alignItems="center">
                    <LeftArrowFilledIcon height="48px" width="48px" />
                    <Typography variant="p3">
                      Back to Owners&apos; Portal
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={numColumns / 2} />
              <Grid item xs={numColumns / 4} />
            </>
          )}
          <Grid item xs={numColumns}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h4"
                fontSize="32px"
                fontWeight={500}
                m={isMobile ? '16px 0px 24px 0px' : '40px 0px 32px 0px'}
              >
                Staying Invested in Flock
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={numColumns}>
            <Paper
              sx={{
                borderRadius: '16px',
                padding: isMobile ? '16px' : '24px',
                margin: isMobile || isTablet ? '0px 32px' : '0px',
              }}
            >
              {equityInfoTimeSeriesLoading || investorContextLoading ? (
                <Skeleton width="100%" height="32px" />
              ) : (
                <FlexibleCashflowCalculator
                  totalEquity={totalEquity}
                  cashFlowSelection={cashFlowSelection || 0}
                  cashFlowAllotment={cashFlowAllotment || 4}
                  holdingYears={yearsUntilExpiry || 0}
                  showCashFlowDeductions={false}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={numColumns}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              m={
                isMobile || isTablet ? '24px 32px 0px 32px' : '24px 0px 0px 0px'
              }
            >
              <Typography variant="p4" color="gray5.main">
                This model projects meeting a target annual return of 10% with
                annual cash flow. Cash flow includes your{' '}
                {Math.floor(cashFlowAllotment || 4)}% Annual Cash Flow Allotment
                + 2% Top Up Allowance. Your capital account will be debited
                0.10% for every 1% in cash flow you receive above your annual
                allotment. This deduction compensates all other clients in the
                Fund. Taking cash beyond 3% annually in the first 3 years of
                owning Flock may have tax impacts. Actual impacts are expected
                to be limited and generally in the form of capital gains tax on
                the amount of cash exceeding this threshold.
                <br />
              </Typography>
              <Typography variant="p4" color="gray5.main">
                Performance Disclaimer: In considering the target performance
                information contained herein, prospective investors should bear
                in mind that past or targeted performance is not a guarantee,
                projection or prediction and is not necessarily indicative of
                future results. There can be no assurance that the Fund will
                achieve comparable results, that targeted returns will be met or
                that the Fund will be able to implement its investment strategy
                and investment approach or achieve its investment objectives.
                Actual gross and net returns for the Fund may vary significantly
                from the targeted returns set forth herein.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </PageWrapper>
    </PageContainer>
  )
}

export default InvestmentProjection
