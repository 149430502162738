import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { formatPercentage } from '@flock/utils'
import { CircleFilledIcon } from '@flock/shared-ui'

type LineItemProps = {
  label: string
  value?: string
}

const LineItem = (props: LineItemProps) => {
  const { label, value } = props

  // map label to color value
  const colorMap: Record<string, 'moneyGreen' | 'green2'> = {
    Flock: 'moneyGreen',
    Zillow: 'green2',
  }

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <CircleFilledIcon color={colorMap[label]} />
      <Typography
        fontSize="12px"
        lineHeight="16px"
        fontWeight={400}
        fontFamily="Outfit"
        color="gray8.main"
      >
        {label}
      </Typography>
      <Typography
        fontSize="12px"
        lineHeight="16px"
        fontWeight={400}
        fontFamily="Outfit"
        color="gray8.main"
      >
        {value}
      </Typography>
    </Box>
  )
}

LineItem.defaultProps = {
  value: '',
}

type TooltipProps = {
  showZillow: boolean
  active?: boolean
  payload?: {
    payload: {
      market: string
      flockPercentChange: number
      zillowPercentChange: number
      redfinPercentChange: number
    }
  }[]
}

const GainLossTooltip = (props: TooltipProps) => {
  const { showZillow, active, payload } = props
  if (active && payload && payload.length) {
    const innerPayload = payload[0].payload
    return (
      <Paper
        sx={{
          p: '16px',
        }}
      >
        <Box display="flex" flexDirection="column" gap="8px">
          <Typography
            fontSize="20px"
            lineHeight="16px"
            fontWeight="500"
            fontFamily="Outfit"
            color="gray8.main"
          >
            {innerPayload.market} Valuations
          </Typography>
          <LineItem
            label="Flock"
            value={formatPercentage(innerPayload.flockPercentChange, 2)}
          />
          {showZillow && (
            <LineItem
              label="Zillow"
              value={formatPercentage(innerPayload.zillowPercentChange, 2)}
            />
          )}
        </Box>
      </Paper>
    )
  }
  return null
}

GainLossTooltip.defaultProps = {
  active: false,
  payload: [],
}

export default GainLossTooltip
