import {
  Box,
  Card,
  IconButton,
  Modal,
  Paper,
  Skeleton,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import React, { useState } from 'react'
import { Close } from '@mui/icons-material'
import Pagination from '@mui/material/Pagination'
import { TrackedLink } from '@flock/shared-ui'
import { Core_LegalEntityUpdate } from '@flock/flock-gql-server/src/__generated__/graphql'

import HomeMessage from './HomeMessage'

type HomeMessageCenterProps = {
  messages: Core_LegalEntityUpdate[]
  loading: boolean
}

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '544px',
  height: '324px',
  padding: '48px',
  borderRadius: '16px',
})

const HomeMessageCenter = (props: HomeMessageCenterProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { messages, loading } = props
  const [page, setPage] = useState(1)
  const pageCount = Math.ceil((messages?.length || 0) / 5)
  const prevOnClick = () => {
    setPage(Math.max(1, page - 1))
  }
  const nextOnClick = () => {
    setPage(Math.min(pageCount, page + 1))
  }
  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }
  const [viewAllMessages, setViewAllMessages] = useState(false)

  const closeModal = () => {
    setPage(1)
    setViewAllMessages(false)
  }

  return (
    <>
      <Box>
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white.main',
            borderRadius: '16px',
            textAlign: 'center',
            padding: '24px',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            sx={{ textAlign: 'left' }}
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={loading ? 'flex-start' : 'space-between'}
            >
              <Typography variant="p1">Message Center</Typography>
              {!loading && messages?.length > 0 && (
                <Typography
                  variant="p3"
                  color="green4.main"
                  onClick={() => setViewAllMessages(!viewAllMessages)}
                  sx={{ '&:hover': { cursor: 'pointer' } }}
                >
                  view all
                </Typography>
              )}
            </Box>
            {!loading &&
              messages?.length > 0 &&
              messages
                ?.slice(0, 2)
                .map((message: any) => (
                  <HomeMessage
                    key={message.updateUuid}
                    viewAllMessages={viewAllMessages}
                    message={message}
                  />
                ))}
            {!loading && messages?.length === 0 && (
              <Typography variant="c1">No Messages</Typography>
            )}
            {loading && (
              <>
                <Skeleton height="28px" />
                <Skeleton height="28px" />
              </>
            )}
          </Box>
        </Card>
      </Box>
      <Modal open={viewAllMessages} onClose={closeModal}>
        <ModalWrapper>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            position="relative"
          >
            <Box display="flex" flexDirection="column" gap="16px">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography>Message Center</Typography>
                <IconButton
                  sx={{
                    height: '24px',
                    marginTop: '-18px',
                    marginRight: '-18px',
                  }}
                  onClick={() => setViewAllMessages(false)}
                >
                  <Close />
                </IconButton>
              </Box>
              {messages?.slice((page - 1) * 5, page * 5).map((message) => (
                <HomeMessage
                  key={message.updateUuid}
                  viewAllMessages={viewAllMessages}
                  message={message}
                  modal
                />
              ))}
            </Box>
            <Box
              width={isMobile ? '340px' : '420px'}
              bottom={isMobile ? '40px' : '0px'}
              display="flex"
              left="0px"
              right="0px"
              m="auto"
              position={isMobile ? 'fixed' : 'absolute'}
              justifyContent="center"
            >
              <Box
                width="72px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: 'center' }}
              >
                {page !== 1 && (
                  <Typography variant="p3">
                    <TrackedLink
                      onClick={() => setPage(1)}
                      sx={{ color: 'gray8.main' }}
                    >
                      {'<< '} First
                    </TrackedLink>
                  </Typography>
                )}
              </Box>
              <Box
                width="72px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: 'center' }}
              >
                {page !== 1 && (
                  <Typography variant="p3" color="gray8.main">
                    <TrackedLink onClick={prevOnClick}>{'<  '}Prev</TrackedLink>
                  </Typography>
                )}
              </Box>
              <Box width="190px">
                {pageCount > 1 && (
                  <Pagination
                    sx={{ width: '190px' }}
                    count={pageCount}
                    color="green1"
                    hideNextButton
                    hidePrevButton
                    defaultPage={0}
                    boundaryCount={0}
                    siblingCount={1}
                    page={page}
                    onChange={handlePageChange}
                  />
                )}
              </Box>
              <Box
                width="72px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: 'center' }}
              >
                {page !== pageCount && (
                  <Typography variant="p3" color="gray8.main">
                    <TrackedLink onClick={nextOnClick}>Next{'  >'}</TrackedLink>
                  </Typography>
                )}
              </Box>
              <Box
                width="72px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ textAlign: 'center' }}
              >
                {page !== pageCount && (
                  <Typography variant="p3">
                    <TrackedLink
                      onClick={() => setPage(pageCount)}
                      sx={{ color: 'gray8.main' }}
                    >
                      Last{'  >>'}
                    </TrackedLink>
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  )
}

export default HomeMessageCenter
