import { Dropdown, ToggleButton } from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { FundPerformanceHeaderProps } from './fundPerformanceHeaderTypes'
import useFundPerformanceHeader from './useFundPerformanceHeader'

const options = [
  {
    label: (
      <Typography variant="p3" color="moneyGreen.main">
        %
      </Typography>
    ),
    value: false,
  },
  {
    label: (
      <Typography variant="p3" color="moneyGreen.main">
        $
      </Typography>
    ),
    value: true,
  },
]

const FundPerformanceHeader = (props: FundPerformanceHeaderProps) => {
  const {
    quarters,
    selectedQuarter,
    setSelectedQuarter,
    shouldShowDollarView,
    toggleDollarViewSelected,
  } = props
  const {
    gainLossIsLoss,
    gainLossString,
    netIncomeString,
    fundValueChangeString,
    fundValueChangeIsLoss,
  } = useFundPerformanceHeader({ ...props })

  return (
    <>
      <Box
        display="flex"
        marginBottom="8px"
        justifyContent="space-between"
        flexWrap="wrap"
        gap="8px"
      >
        <Typography variant="p1">{selectedQuarter} Fund Performance</Typography>
        <Box
          display="flex"
          justifyContent={{
            xs: 'space-between',
            sm: 'flex-end',
          }}
          gap={2}
          width={{
            xs: '100%',
            sm: 'auto',
          }}
        >
          <ToggleButton
            options={options}
            value={shouldShowDollarView}
            onChange={toggleDollarViewSelected}
          />
          <Box width="105px">
            <Dropdown
              size="dropdownMini"
              options={quarters.map((quarterString) => ({
                label: quarterString,
                value: quarterString,
              }))}
              onChange={(e) => setSelectedQuarter(e.target.value)}
              value={selectedQuarter}
            />
          </Box>
        </Box>
      </Box>
      <Typography variant="p3">
        Flock&apos;s real estate portfolio{' '}
        {gainLossIsLoss ? 'declined' : 'increased'} in value by{' '}
        <Typography
          variant="p3"
          sx={{ color: gainLossIsLoss ? 'errorRed.main' : 'green4.main' }}
        >
          {gainLossString}
        </Typography>
        , while portfolio net income contributed{' '}
        <Typography variant="p3" sx={{ color: 'green4.main' }}>
          {netIncomeString}
        </Typography>{' '}
        to overall fund performance. The total fund value change for the quarter
        was{' '}
        <Typography
          variant="p3"
          sx={{
            color: fundValueChangeIsLoss ? 'errorRed.main' : 'green4.main',
          }}
        >
          {fundValueChangeString}
        </Typography>
        .
      </Typography>
    </>
  )
}

export default FundPerformanceHeader
