import theme from '@flock/shared-ui/src/theme/theme'
import { formatPercentage } from '@flock/utils'
import { useMediaQuery } from '@mui/material'
import { formatDollarsAndCentsInvestor } from '../../../../utils/formatting'
import {
  SelectedLegalEntityAggregateTransactionData,
  TaxData,
} from '../accountSectionTypes'

const usePerformanceSummaryTable = (
  selectedLegalEntityAggregateTransactionData: SelectedLegalEntityAggregateTransactionData,
  selectedLegalEntityTaxData: TaxData
) => {
  const {
    totalContribution,
    totalEquityValue,
    totalGainLoss,
    totalNetIncome,
    totalTaxFees,
    totalRedemptions,
  } = selectedLegalEntityAggregateTransactionData
  const { totalTaxableIncomeCents, totalExpectedTaxableIncomeCents } =
    selectedLegalEntityTaxData
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  // total return is just current equity value with redemptions added back in, divided by total contribution
  const totalReturn =
    (totalEquityValue + totalRedemptions) / totalContribution - 1
  const totalCashTaken = totalRedemptions

  return {
    isMobile,
    isTablet,

    totalReturnIsPositive: totalReturn >= 0,
    totalContributionString: formatDollarsAndCentsInvestor(
      Math.round(totalContribution / 100)
    ),
    totalEquityValueString: formatDollarsAndCentsInvestor(
      Math.round(totalEquityValue / 100)
    ),
    // fund value change in the summaryis the original share appreciation (marked as fund value change) minus all distributions
    totalFundValueChangeString: formatDollarsAndCentsInvestor(
      Math.round((totalNetIncome + totalGainLoss) / 100),
      true,
      false
    ),
    totalTaxFeeString: formatDollarsAndCentsInvestor(
      Math.round(totalTaxFees / 100),
      true,
      false
    ),
    totalCashTakenString: formatDollarsAndCentsInvestor(
      Math.round(totalCashTaken / 100),
      true,
      false
    ),
    // POTENTIALLY RENAME TO NET ACCOUNT RETURN
    totalReturnString: formatPercentage(totalReturn, 2),
    totalTaxableIncomeCents,
    totalExpectedTaxableIncomeCents,
  }
}

export default usePerformanceSummaryTable
